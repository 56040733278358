import { createWebHistory, createRouter } from "vue-router";
import HomeView from './views/HomeView'
import AboutView from './views/AboutView'
import PrintView from './views/PrintView'
import SearchView from './views/SearchView'
import ResourcesView from './views/ResourcesView'
import ResearchView from './views/ResearchView.vue'
import DissertationsView from "./views/DissertationsView.vue";
import StudyView from "./views/StudyView.vue";
import ScholarshipView from "./views/ScholarshipView.vue";
import ResultsViewPagination from './views/ResultsViewPagination'
import ContactView from './views/ContactView'
import GenresView from "./views/GenresView.vue";
import HistoryView from "./views/HistoryView.vue";
import TechniquesView from "./views/TechniquesView.vue";
import DirectoriesView from "./views/DirectoriesView.vue";
import PrintsInFocusView from "./views/PrintsInFocusView.vue";
import NotFoundComponent from "./views/NotFoundComponent.vue";


const routes = [
    {
      path: '/',
      name: 'Home',
      component: HomeView,
    },
    {
      path: '/about',
      name: 'About',
      component: AboutView,
    },
    {
      path: '/prints',
      name: 'Prints',
      component: PrintView,
    },
    {
      path: '/contact',
      name: 'Contact',
      component: ContactView
    },
    {
      path: '/search',
      name: 'Search',
      component: SearchView
    },
    {
      path: '/research',
      name: 'Research',
      component: ResearchView
    },
    {
      path: '/research/dissertations',
      name: 'Dissertations',
      component: DissertationsView
    },
    {
      path: '/research/studying',
      name: 'Study',
      component: StudyView
    },
    {
      path: '/research/scholarship',
      name: 'Scholarship',
      component: ScholarshipView
    },
    {
      path: '/resources',
      name: 'Resources',
      component: ResourcesView
    },
    {
      path: '/resources/genres',
      name: 'Genres',
      component: GenresView
    },
    {
      path: '/resources/history',
      name: 'History',
      component: HistoryView
    },
    {
      path: '/resources/techniques',
      name: 'Techniques',
      component: TechniquesView
    },
    {
      path: '/resources/directories',
      name: 'Directories',
      component: DirectoriesView
    },
    {
      path: '/resultsViewPagination',
      name: 'Results',
      component: ResultsViewPagination
    },
    {
      path: '/printsInFocusView/:id',
      name: 'PrintsInFocusView',
      component: PrintsInFocusView
    },  
    { 
      path: '/:catchAll(.*)', 
      name: 'NotFound',
      component: NotFoundComponent
    }
 ]; 

 // create singleton router object
const router = createRouter({
    history: createWebHistory(),
    routes,
  });

  export default router;