<template>
 <div>
        <h3 class="py-6 text-3xl">The Sucklington Faction: or (Sucklings) Roaring Boyes</h3>
        <h4 class="py-2 text-xl">No. 21 June/July 2008 </h4>
    </div>
    <div class='relative text-left overflow-y-auto'>
      <p class="py-2"> <strong>© British Library Board. All Rights Reserved.</strong> </p>
      <p class="pt-2 pb-4"> <strong>British Library 669.f.4(17). Dimensions of original: 359 mm x 277 mm (sheet); 221 mm x 143 mm (plate); 187 mm x 138 mm (engraved area)</strong></p> 
        <span class="float-left pr-6 pb-2"><img :src="thumbnail" width="200"/> <a href="#" @click="getlargerImage()" class="underline font-bold" >View larger image</a></span> 

      <p class="pb-2">A sheet issued in 1641 entitled <span class="italic">The Sucklington Faction: or (Sucklings) Roaring Boyes</span><sup>i</sup> depicts two extravagantly hatted cavaliers sitting smoking round a makeshift table. As the title makes clear, the text satirises the dandified members of the troop of horse raised in the service of the King by the poet, Sir John Suckling. The two quatrains which caption the inset engraving clearly, and appropriately, poke fun at the habit of smoking:  </p>

      <p class="pb-2 italic">
      Much meate doth gluttony produce.<br>
      And make a man a swine -- <br>
      But hee's a temperate man indeed<br>
      That with a leafe can dine -<br>
      Hee needes no napkin for his handes<br>
      His fingers for to wipe<br>
      Hee hath his kitchen in a box,<br>
      His Roast meate in a pipe.
      </p>

      <p class="pb-2">
      All the more strange, therefore, that there is no mention of smoking in the lengthy text! The text is a preacher's harangue against such 'Prodigal Sons' as waste their substance—through drinking in the main, and all the attendant vices that drunken rioting leads to. It would appear that the printer has pressed into service an originally mildly amusing, almost cartoon-like, swipe at smokers, to illustrate Suckling's dissipated cavaliers. In fact, the English plate copies very closely (though in reverse) an engraving made c.1630 by Abraham Bosse (after Jean de Saint-Igny) known as <span class="italic">Les Fumeurs</span><sup>ii</sup> captioned with a single quatrain: 
      </p>

      <p class="pb-2 italic">
      Quand nous sommes remplis d'humeur melancolique<br>
      La vapeur du Tabac rauiue noz espris;<br>
      Lors de nouuelle ardeur entierement surpris,<br>
      Nous veincrions le Dieu Mars en sa fureur bellique.
      </p> 

      <p class="pb-2">
      [When we are filled with the melancholy humour<br>
      The vapour of tobacco revives our spirits;<br>
      Then, entirely surprised by a new ardour,<br>
      We could vanquish the god Mars in his warlike fury]
      </p>

      <p class="pb-2">The importance of this particular, uniquely surviving print—as so many English prints of this era are—is twofold: firstly it shows, yet again, dependence on a foreign model, and secondly, it shows how very ready contemporary English print-publishers were to shoe-horn into their money-spinning prints anything vaguely appropriate, in order to seize a commercial opportunity while the subject was still topical (a practice very familiar from the woodcut illustrations to broadside ballads). What it is not, <span class="italic">pace</span> F.G. Stephens et al., is a satire of Sucklington—it is a satire of the contemporary cavalier dandy (See <router-link :to="`/printsInFocusView/9`" class="text-blue-600 dark:text-blue-500 hover:underline">Funeral Obsequies</router-link>), and not even an English one!</p>

      <p class="pb-2">A transcription of the letterpress text follows:</p>

      <p class="pb-2 font-bold">THE SVCKLINGTON FACTION: OR ( SUCKLINGS ) Roaring Boyes.</p>

      <p class="pb-2">Here sits the prodigall Children, the younger brothers (<span class="italic">Luk.</span> 15.12.) acting the parts of hot-spur Cavaliers and disguised ding-thrifts, habiting themselves after the fashions of the world, as one that is to travaile into a farre Countrey. <span class="italic">Nisi hominibus placuerit Deus, non erit eorum, Deus</span>. Because his father humors him not, with the Idolatrous Ceremonies to follow Popish Innovations, he becomes an errand Peripateticke, flying in a dudgeon and discontent from Gods household, and consequently from the Almighties direction and protection. Not having God for his guide, hee hath the Devill for his conducter, walking now not only after the lusts of the flesh, and of his mind, fulfilling the desires of both; but after the Prince of the ayre, the spirit that now worketh in the children of disobedience.<sup>iii</sup>  With the debaucht Gallants of these lascivious and loose-living times, he drawes his Patrimony through his throat, bequeathing the creatures to consumption for consummation of his intemperate voracity, delicate luxury, and wastfull prodigality, spending all either upon his belly or his backe, following the proud, apish, anticke, and disguised fashions of the times, to present himselfe a painted Puppet on the stage of vanity.</p>

      <p class="pb-2"><span class="italic">Alia</span> [sic], <span class="italic">vina, Venus, tribus his sum factus egenus</span><sup>iv</sup>  </p>

      <p class="pb-2">What with wine and women, horses, hounds, and whores, dauncing, dicing, drabbing, drinking, may the prodigall man say: I am brought unto a morsel of bread, yea unto the very huskes of Swine.<sup>v</sup> Pride of spirit makes him to scorne an Alehouse, and therefore with greater eagernesse hee daily haunts Tavernes: where sometimes he sits by his liquor, and bloud of the Vine, and the spirits of the Celler, exhausting, and infusing them unto mad ebriety: thus drinking <span class="italic">ad modum sine mensura</span>,<sup>vi</sup> whole ones, by measure, without measure, like the Elephant through the juice of Mulberries, he is enraged unto bloud, and most damnable resolutions and designes, terminated in the death and destruction of the next man he meetes, that never did, neither thought him harme. Or having a noyse of renegade Fidlers, Musicke-abusers, they with him, and he with them, sings and danceth, danceth and sings like a Nightingale*, or Canarie bird. He is profuse and lavish.</p>

      <p class="pb-2 italic">
      — — Donec deceptus & exspes,<br> s
      Necquicquam fundo suspiret nummus in imo<sup>vii</sup> 
      </p>

      <p class="pb-2">Never sparing till all be spent, dancing, and drinking away both wit and wealth. Now he acts his ryots, anon his revels, and forthwith ferries to a Play-house, or Bawdy-house, where the woman with the attire of an harlot kissing him, allures this simple sot, voyd of understanding, to <span class="italic">solace himselfe</span>, (ver. 18.) <span class="italic">and take his fill of love vntill the morning </span>. Lust leades him to dalliance, till a dart (Ver. 23.) strike thorow his liver, until hee be cast downe and wounded, yea and slaine by her.<sup>viii</sup> </p>

      <p class="pb-2">This notorious good-fellow (corruptly so called) being a confederate of the Greekes, <span class="italic">Titeretus</span>, or joviall roaring Boyes, is of the Poets mind, when he said: <span class="italic">Fœcundi calices quem non fecere disertum</span>? Whom hath not wine made witty?   He drinkes that he may be eloquent and facete, after his cup of <span class="italic">nimis</span>,<sup>x</sup> he harps on <span class="italic">Barnabies</span> Hymne,<sup>xi</sup> or <span class="italic">Bacchus</span> his inebriating Catch, bousing verily, and chanting on this wise merrily:</p>

      <p class="pb-2">
      <span class="italic">
      Æsculapi tandem sapi,<br>
      quid medelas blateras?<br>
      Mithridatum est potatum<br>
      inter vini pateras.<br>
      Ad liquors & humores<br>
      tandum </span>[sic] <span class="italic">crescunt salices<br>
      Si quis ægrotet, mox epotet<br>
      decem vini calices.<br>
      Qui emblema, aut poema<br>
      vult acuté texere,<br>
      Ordiatur, vino satur,<br>
      & uvarum nectare.<br>
      Nil acuté, nil argutè,<br>
      dictum sine dolio;<br>
      Audivi sales, nunquam tales<br>
      ac in ænopolio.<br>
      Quorsum plura, hæc figura<br>
      satis rem nobilitat.<br>
      Vas rotundum totum mundum<br>
      plene consignificat.</span>
      </p>

      <p class="pb-2">These are children of Spirituall fornication, such as goe a Whoring from God after the idols of their owne braines: <span class="italic">Hos</span>. I.2. such are superstitious Romanists, tutoured by their Ghostly Fathers, to beleeve in grosse as the Church beleeveth, which (as Luther saith) is grosse Divinity. These fall not onely from piety to impuritie, but also from Christian verities, to Antichristian vanities, fopperies, and trumperies.</p>

      <p class="pb-2">
      FINIS.<br>
      Printed in the Yeare, MDC.XLI.<br>
      * <span class="italic">Vox est & praeterea nihil</span>.<sup>xii</sup>   
      </p>
      <h4 class="py-2 text-xl font-bold">Footnotes</h4> 
      <ul class="list-[lower-roman] list-inside">
        <li class="py-2">F.G.Stephens, <cite>Catalogue of Prints and Drawings in the British Museum: Satirical and Personal Subjects</cite>, vol. 1 (London, 1870), pp. 205-6 (BM Sat. 268). </li>
        <li class="py-2">Paris, Bibliothèque Nationale de France, Cabinet des Estampes, Collection Hennin, 2453. </li>
        <li class="py-2">Ephesians 2, 1-3.   </li>
        <li class="py-2">A Latin proverb: 'dice, wine, Venus - these three have made me poor'.  </li>
        <li class="py-2">Luke 15.16. </li>
        <li class="py-2">without measure'. </li>
        <li class="py-2">Persius, <cite>Satire</cite> 2, 51-2.</li>
        <li class="py-2">All of the quotations in this sentence are from Proverbs 7. The last is from verse 26, the previous ones from verses 10, 7, 18 and 23 (as is indicated in the original in the latter two instances).  </li>
        <li class="py-2">Horace, <cite>Epistles</cite>, 1.5.19.</li>
        <li class="py-2">'too much'. </li>
        <li class="py-2">Not in OED. Brathwait's celebrated book concerning the nationwide pub-crawls of Barnaby was not published until 1638, but a print issued in 1620 by Edward Allde for Henry Gosson, entitled <cite>Fill Gut, & Pinch belly: One being Fat with eating good Men, the other Leane for want of good Women</cite> and with verse attributed to John Taylor labels a wife cudgelling her drunken husband,”Out Barnaby!”, evidence that the name was already proverbial for a drunkard in 1620. A decade after the present sheet appeared, another single sheet entitled <cite>Barnabies Summons: or, Paie your Groat in the Morning</cite> (1652), a mock-proclamation on the theme of excessive drinking, was published.</li>
        <li class="py-2">Literally, 'is a voice and nothing more'. Brewer's <cite>Dictionary of Phrase & Fable</cite> explains that 'when the Lacedemonian plucked the nightingale, on seeing so little substance he exclaimed, “Vox tu es, et nihil præterea”. [you are just a voice and nothing more] (<cite>Plut. Opp. Mor. Apophthegmata Laconica</cite>.)'</li>
      </ul> 

  </div>   
</template>

<script>
    export default {
        data() {
          return { 
            imagePath : 'https://britishprintedimages.blob.core.windows.net/input-images/printsinfocus/',
            thumbnail : '',
            largeImage : ''
          }
        },
        methods: {
          getlargerImage() {
                let currentImagePath = this.imagePath + 'sucklington.jpg' 
                window.open(currentImagePath, "_blank");
            },
        },
        created() {
          this.thumbnail = this.imagePath  + '21.jpg' 
        }
    }
  
  </script>
