<template>
 <div>
        <h3 class="py-6 text-3xl">The Winchester Wedding</h3>
        <h4 class="py-2 text-xl">No.11 June/July 2007</h4>
    </div>
    <div class='relative text-left overflow-y-auto'>
      <p class="py-2"> <strong>Copyright of the Trustees of the British Museum.</strong> </p>
      <p class="pt-2 pb-4"> <strong>British Museum 1850,1109.75. Dimensions of original: 213 mm x 168 mm</strong></p> 
        <span class="float-left pr-6 pb-2"><img :src="thumbnail" width="200"/> <button type="button" @click="getlargerImage()" class="underline font-bold" >View larger image</button></span> 

        <p class="pb-2">This image is by Robert Robinson (fl. 1674-1706), an engraver of mezzotint prints who specialised in genre subjects, including architectural caprices, still lifes, and landscapes. The Winchester Wedding is based on a popular ballad by Tom D'Urfey (1653-1723) which features Ralph of Reading, and Black Bess of the Green, who 'Did together resort, and caused such sport, as before scarce ever was seen'.<sup>i</sup> It has been suggested that the ballad was composed by D'Urfey in 1682 on the occasion of Charles II's visit to Winchester; it was first published in 1682 or 1683. It shows an interior with rustics dancing and carousing, while two fiddlers play seated on giant barrels. Certain of the motifs derive from Johannes Visscher's etching and engraving <span class="italics">The Country Dance</span> after the popular Dutch artist Nicholas Berchem.<sup>ii</sup></p>

        <p class="pb-2">
          A painting of this title which the mezzotint presumably copies was sold at auction on 20th February 1690, lot 300 [C5124]. 
        </p>
 
        <h4 class="py-2 text-xl font-bold">Footnotes</h4> 
        <ul class="list-[lower-roman] list-inside">
          <li class="py-2">J. Woodfall Ebsworth (ed.), <cite>The Roxburghe Ballads, Illustrating the Last Years of the Stuarts</cite>, vol. 7, part 1 (Hertford, 1890), pp. 207-9.   </li>
          <li class="py-2"><cite>Hollstein's Dutch & Flemish Etchings, Engravings and Woodcuts, 1450-1700</cite>, vol. XLI, <cite>Johannes [De] Visscher to Robert Van Voerst</cite>, compiled by Christiaan Schuckman, ed. by D. De Hoop Scheffer (Rosendaal, 1992), p. 25 (i.e. Hollstein 23). This and other information has been derived from unpublished research by James A. Ganz. </li>
        </ul> 
  </div>   
</template>

<script>
    export default {
        data() {
          return { 
            imagePath : 'https://britishprintedimages.blob.core.windows.net/input-images/printsinfocus/',
            thumbnail : '',
            largeImage : ''
          }
        },
        methods: {
          getlargerImage() {
                let currentImagePath = this.imagePath + 'Winchester Wedding.png' 
                window.open(currentImagePath, "_blank");
            },
        },
        created() {
          this.thumbnail = this.imagePath  + '27.jpg' 
        }
    }
  
  </script>
