<template>

    <div class="relative container mx-auto px-20 bg-gray-200">
        <HomeLink />
        <HomeBanner />
        <NavMenu />
        <section class="bg-white dark:bg-gray-900">
            <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700 bg-white px-6 py-12 pb-6 lg:px-8">
            <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Scholarship on Printed Images</h1>
            <p class="mt-6 text-xl leading-8 text-left">
                This section provides short overviews of existing scholarship on printed images in early modern Britain, organised by theme. The list is not exhaustive but should provide a starting point for those wishing to conduct research. </p>
            <div class="mt-10 text-left">
                <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900">Where to start?</h2>
                <p class="py-4">
                    The following works provide excellent overviews of British printmaking in the early modern period:</p>
                <ul>
                    <li> - Antony Griffiths, <cite>The Print in Stuart Britain</cite> (London, 1998).</li>
                    <li> - Michael Hunter (ed.), <cite>Printed Images in Early Modern Britain: Essays in Interpretation</cite> (Farnham, 2010).</li>
                    <li> - Malcolm Jones, <cite>The Print in Early Modern England: An Historical Oversight</cite>, (New Haven, 2010). </li>
                    <li> - Sheila O'Connell, <cite>The Popular Print in England</cite> (London, 1999).</li>
                </ul>
                <p class="py-4">
                    The books by Griffiths and Jones are excellent overviews. Each charts the development of printmaking in the 17th-century and provides vital iconographic detail, bibliographic information, and references. They are key starting points for all research. Jones' cultural history treats the material by theme. Griffiths focuses on chronology and the role of individual engravers.  O'Connell's book places early modern prints in a broader chronology and provides invaluable references and material. Hunter's collection includes interpretive essays on printed images as they relate to the political, religious, social, cultural, and book histories of the period. In addition to these books, Alastair Fowler's <cite>The Mind of the Book: Pictorial Title-pages</cite> (Oxford, 2017) is an important consideration of the development and purpose of pictorial frontispieces.</p>

                <p class="py-4">
                    For a consideration of the 18th-century, Timothy Clayton, <cite>The English Print</cite> 1688-1802 (New Haven and London, 1997) provides a good overview. For graphic satire, see Diana Donald's <cite>The Age of Caricature: Satirical Prints in the reign of George III</cite> (New Haven, 1997) and Vic Gatrell's <cite>City of Laughter: Sex and Satire in Eighteenth Century London</cite> (London, 2006).</p>

                <h3 class="mt-2 text-1xl font-bold italic tracking-tight text-gray-900"> Politics</h3>

                <ul>
                    <li> - Meredith McNeil Hale, <cite>The Birth of Modern Satire: Romeyn de Hooghe and the Glorious Revolution</cite> (Oxford, 2020).</li>
                    <li> - Tim Harris, <cite>London Crowds in the reign of Charles II: Propaganda and Politics from the Restoration to the Exclusion Crisis</cite> (Cambridge, 1987). </li>
                    <li> - Helen Pierce, <cite>Unseemly Pictures: Graphic Satire and Politics in Early Modern England</cite> (New Haven and London, 2008).  </li>
                    <li> - Kevin Sharpe, <cite>Selling the Tudor Monarchy: Authority and Image in 16th-century England </cite>(New Haven and London, 2009).</li>
                    <li> - Kevin Sharpe, <cite>Image Wars: Promoting kings and Commonwealths in England</cite>, 1603-60 (New Haven and London, 2010).</li>
                    <li> - Kevin Sharpe, <cite>Rebranding Rule: The Restoration and Revolution Monarchy</cite> (New Haven and London, 2013).</li>
                </ul>

                <p class="py-4">
                    Pierce's study provides close readings of printed images in the period up to the Civil Wars. Harris's book was pioneering in integrating visual sources into a political history of London in the Restoration period. Sharpe's trilogy considers the construction and contesting of power across the 16th and 17th centuries and analyses visual alongside textual and oral sources. Hale's book traces the importance that one artist during the Revolution of 1688/9. </p>

                <p class="py-4">
                    For more focussed studies of the role of prints at particular political moments, see: </p>

                <ul>
                    <li> - Alastair Bellany, 'Buckingham Engraved: politics, prints images and royal favourites in the 1620s' in <cite>Printed Images in early modern Britain: essays in interpretation</cite> ed. Michael Hunter (Farnham, 2010), pp. 215-35.</li>
                    <li> - Mark Knights, 'Possessing the Visual: the materiality of visual print culture in later Stuart Britain' in <cite>Material Readings of early modern culture: texts and social practices</cite> 1580-1730 ed. James Daybell and Peter Hinds (Houndmills/New York, 2010), pp. 85-122.</li>
                    <li> - Adam Morton, 'Intensive Ephemera: <cite>The Catholick Gamesters</cite> and the Visual Culture of <cite>News in Restoration London' in News in early modern London: currents and connections</cite> ed Simon F Davis and Puck Fletcher (Leiden, 2014), pp. 115-40. </li>
                    <li> - Adam Morton, 'Popery, Politics, and Play: Visual Culture in Succession Crisis England' <cite>Seventeenth Century</cite>, 31 (2016): 411-49.</li>
                    <li> - Adam Morton, 'Laughing at Hypocrisy: <cite>The Turncoats</cite> (1711), visual culture, and dissent in early-eighteenth century England' <cite>Studies in Church History</cite>, 60 (2024).</li>
                    <li> - Eirwen C. Nicholson, 'Sacheverell's Harlots: Non-Resistance on Paper and in Practice', <cite>Parliamentary History</cite>, 31 (2012): 69-79.</li>
                    <li> - Alexandra Walsham, '“The Fatall Vesper”: providentialism and anti-popery in late Jacobean London', <cite>Past & Present</cite> 144 (1994): 36-87.</li>   
                </ul>  
                
                <h3 class="mt-2 text-1xl font-bold italic tracking-tight text-gray-900"> Religion</h3>

                <ul>
                    <li> - Margaret Aston, <cite>The King's Bedpost: Reformation and Iconography in a Tudor Group Portrait</cite> (Cambridge, 1993).</li>
                    <li> - David Davis, <cite>Seeing Faith, Printing Pictures : Religious Identity during the English Reformation</cite> (Brill, 2013).</li>
                    <li> - Tara Hamling, <cite>Decorating the Godly Household: Religious Art in post-Reformation Britain</cite> (New Haven, 2010).</li>
                    <li> - George Henderson, 'Bible Illustration in the Age of Laud', Transactions of the Cambridge Bibliographical Society, 8 (1982), 173-216.</li>
                    <li> - Alexandra Walsham, <cite>Providence in early modern England</cite> (Oxford, 1999). </li>
                    <li> - Alexandra Walsham, <cite>Generations: Age, Ancestry, and Memory in the English Reformations</cite> (Oxford, 2023).</li>
                    <li> - Tessa Watt, <cite>Cheap Print and Popular Piety</cite>, 1550-1640 (Cambridge, 1991)..</li>
                </ul>  

                <p class="py-4">
                    Hamling's book shows the importance of images to the practice of piety in godly households. Her study places carvings, plasterwork, and paintings in domestic spaces in the broader visual culture of early modern England (including printed images). The books by Walsham and Watt use visual sources alongside textual ones - images are used as one way into the history of period. Watt considers the purpose of images on religious ballads, and Walsham shows how early modern understandings of providence and generations (respectively) can be unlocked through the study of visual sources. Aston's book uses one painting as a way into the history of England's Reformation and its history of iconoclasm and conflict. It is a testament of how close readings and case studies can open up broader themes of a period. </p>

                <p class="py-4">The debate about the extent of Protestant hostility to imagery is longstanding. For the view that post-Reformation England became iconophobic, and that visual culture declined as a result, see Patrick Collinson 'From Iconoclasm to Iconophobia'. For the debate that followed, and studies of post-Reformation imagery, see:</p>

                <ul>
                    <li> - Margaret Aston, <cite>Broken Idols of the English Reformation</cite> (Cambridge, 2016).</li>
                    <li> - G. E. Bentley, jr., 'Images of the Word: Separately Published English Bible Illustrations 1539-1830', Studies in Bibliography, 47 (1994), 103-128..</li>
                    <li> - Tara Hamling & Jonathan Willis, 'From Rejection to Reconciliation: Protestantism and the Image in Early Modern England', <cite>Journal of British Studies</cite>, 62 (2023): 932-63.</li>
                    <li> - Tara Hamling & Richard Williams, <cite>Art Re-formed: reassessing the Impact of the Reformation on the visual arts</cite> (Newcastle, 2007).</li>
                    <li> - Adam Morton, 'A Product of Confession or Corruption? The Common Weales Canker Worms (c.1625) and the Progress of Sin in early modern England', in <cite>Illustrated Religious Texts in the North of Europe 1500-1800</cite> ed. Feiker Dietz, Adam Morton et al (Farnham, 2014), pp. 135-66.</li>
                    <li> - Adam Morton, 'Images & the Senses in post-Reformation England', <cite>Reformation</cite>, 20 (2015): 77-100.</li>
                    <li> - Alexandra Walsham, 'Eating the Forbidden Fruit: Pottery and Protestant Theology in early modern England', <cite>Journal of Early Modern History</cite>, 24 (2020): 63-83.</li>
                    <li> - Alexandra Walsham, 'Domesticating the Reformation: Material Culture, Memory, and Confessional Identity in early modern England', <cite>Renaissance Quarterly</cite>, 69 (2016): 566-616.</li>
                    <li> - Alexandra Walsham, 'Idols in the Frontispiece? Illustrating Religious Texts in the age of iconoclasm' in in <cite>Illustrated Religious Texts in the North of Europe 1500-1800</cite> ed. Feiker Dietz, Adam Morton et al (Farnham, 2014), pp. 21-53.</li>
                </ul>

                <h3 class="mt-2 text-1xl font-bold italic tracking-tight text-gray-900"> John Foxe</h3>
                <ul>
                    <li> - Margaret Aston and Elizabeth Ingram, 'The Iconography of the Acts and Monuments', in <cite>John Foxe and the English Reformation</cite>, ed. David Loades (Aldershot, 1997), pp. 66-142.</li>
                    <li> - Thomas Betteridge, 'Truth and History in Foxe's Acts and Monuments', in <cite>John Foxe and his World</cite>, ed. Christopher Highley and J.N. King (Aldershot, 2002), pp. 145-59.</li>
                    <li> - Elizabeth Evenden & Thomas Freeman, <cite>Religion & the Book in Early Modern England: The Making of John Foxe's 'Book of Martyrs'</cite> (Cambridge, 2011).</li>
                    <li> - Elizabeth Evenden & Thomas Freeman, 'Print, Profit and Propaganda: The Elizabethan Privy Council and the 1570 edition of Foxe's “Book of Martyrs”', <cite>English Historical Review</cite> 119 (2004), 1288-1307.</li>
                    <li> - J.N. King, <cite>Foxe's Book of Martyrs and Early Modern Print Culture</cite> (Cambridge, 2006).</li>
                    <li> - Ruth Luborsky, 'The Illustrations: their Pattern and Plan', in <cite>John Foxe: An Historical Perspective</cite>, ed. David Loades (Aldershot, 1999), pp. 67-84.</li>
                </ul>

                <h3 class="mt-2 text-1xl font-bold italic tracking-tight text-gray-900"> Society, Culture & the History of the Book</h3>
                <ul>
                    <li> - Alexander Globe, Peter Stent c. 1642-1665, <cite>London Printseller: A Catalogue Raisonné of His Engraved Prints and Books With An Historical and Bibliographical Introduction</cite> (Vancouver, 1985).</li>
                    <li> - Michael Hunter, 'Illustrated Books', in Adam Smyth, ed., Oxford Handbook of the History of the Book in Early Modern England (2023), pp. 285-309.</li>
                    <li> - Mark Jenner, 'Plague on the Page: Lord Have Mercy Upon us in early modern London', <cite>Seventeenth Century</cite>, 27 (2012): 255-86.</li>
                    <li> - Christopher Marsh, 'A Woodcut and its Wanderings in Seventeenth-century England', <cite>Huntington Library Quarterly</cite>, 79 (2016): 245-62.</li>
                    <li> - Christopher Marsh, 'Best-Selling Ballads and their pictures in seventeenth-century England', <cite>Past & Present</cite>, 233 (2016): 53-99.</li>
                    <li> - J.A. Knapp, <cite>Illustrating the Past in Early Modern England: the Representation of History in Printed Books</cite> (Aldershot, 2003).</li>
                    <li> - Joseph Monteyne, <cite>The Printed Image in Early Modern London: Urban Space, Visual Representation and Social Exchange </cite>(Aldershot, 2007).</li>
                </ul>

                <p class="py-4">Each of the works here shows how images can be used to enrich our understanding of early modern Britain. Globe's study reads the prints sold by one print seller - Peter Stent - against the society and culture of the period. Monteyne's book uses images as a way into how people in early modern London conceived of their city as it transformed in the late-seventeenth century: it is an important example of how to write cultural history using visual sources. Jenner's article considers the visual culture of plague, and Marsh's use images in cheap print as a way into everyday ideas in the period.</p>

                <h3 class="mt-2 text-1xl font-bold italic tracking-tight text-gray-900"> Science & Cartography</h3>

                <ul>
                    <li>- Meghan Doherty, <cite>Engraving Accuracy in Early Modern England</cite> (Amsterdam University Press, 2022).</li>
                    <li>- Meghan C. Doherty, 'Discovering the “True Form”: Hooke's Micrographia and the Visual Vocabulary of Engraved Portraits', Notes & Records, 66 (2012), 211-234.</li>
                    <li>- Felicity Henderson, Sachiko Kusukawa, and Alexander Marr (eds.), 'Curiously Drawn: Early Modern Science as a Visual Pursuit', special issue of <cite>Huntington Library Quarterly</cite>, 78/2 (2015),</li>
                    <li>- Michael Hunter, <cite>The Image of Restoration Science</cite> (Routledge, 2017).</li>
                    <li>- Sachiko Kusukawa (ed.), 'Making Visible: The Visual and Graphic Practices of the Early Royal Society', special issue of <cite>Perspectives on Science</cite>, 27 (2019). </li>
                    <li>- Pamela MacKenzie, 'Nehemiah Grew, the illustrator', <cite>Notes and Records: The Royal Society Journal of the History of Science</cite>, 78 (2022), 81-113.</li>
                    <li>- Victor Morgan, 'The Cartographic Image of “The Country” in Early Modern England', <cite>Transactions of the Royal Historical Society</cite>, 5th ser., 29 (1979), 129-154.</li>
                    <li>- William Ravenhill (ed.), <cite>Christopher Saxton's Sixteenth-Century Maps: The Counties of England & Wales</cite> (Shrewsbury: Chatsworth Library, 1992).</li>
                    <li>- R. A. Skelton, <cite>County Atlases of the British Isles 1579 - 1703</cite> (London: Carta Press, 1970).</li>
                    <li>- Sarah Tyacke and John Huddy, <cite>Christopher Saxton and Tudor Map-making</cite> (London: British Library, 1980).</li>
                    <li>- Katherine S. Van Eerde, <cite>John Ogilby and the Taste of His Times</cite> (Folkestone: Dawson, 1976).</li>
                </ul>
            </div>
            </div>
            <div class="text-center pb-8">
                <button type="button" aria-label="research" @click="viewResearchPage" class="bg-slate-500 hover:bg-slate-600 text-white font-bold py-2 px-4 mt-4 mb-4 rounded focus:outline-none focus:ring focus:ring-white inline-flex items-center w-40">Back to Research 
                </button>
            </div>
        </section> 
      <SimpleFooter />
    </div> 
</template>

<script>
   
import HomeBanner from '../components/HomeBanner.vue';
import NavMenu from '../components/NavMenu.vue';
import SimpleFooter from '../components/SimpleFooter.vue';
import HomeLink from '../components/HomeLink.vue';

export default {
  data() {
      return {};
  },
  components: { 
    HomeLink,
    HomeBanner, 
    NavMenu,
    SimpleFooter
  },
  methods: {
    viewResearchPage(){
        this.$router.push({name: 'Research'});
    }
    }
}
</script>