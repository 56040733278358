<template>
    <AccordionPanel v-if="currentPage===0"></AccordionPanel>
    <AccordionPanelDG v-if="currentPage===1"></AccordionPanelDG>
    <AccordionPanelGL v-if="currentPage===2"></AccordionPanelGL>
    <AccordionPaneLR v-if="currentPage===3"></AccordionPaneLR>
    <AccordionPanelSY v-if="currentPage===4"></AccordionPanelSY>
               
    <nav aria-label="Page navigation example" class="py-8">
        <ul class="-space-x-px text-base h-10 pagination">
            <li class="pagination-item">
                <button type="button" @click="onClickPage(0)" @keydown.enter="onClickPage(0)" class="flex items-center justify-center px-6 h-16 leading-tight text-gray-500 bg-stone-100 text-gray-700 border border-gray-300 hover:bg-gray-100 hover:text-gray-700">A-D</button>
            </li>
            <li class="pagination-item">
                <button type="button" @click="onClickPage(1)" @keydown.enter="onClickPage(1)" class="flex items-center justify-center px-6 h-16 leading-tight text-gray-500 bg-stone-100 text-gray-700 border border-gray-300 hover:bg-gray-100 hover:text-gray-700">D-G</button>
            </li>
            <li class="pagination-item"> 
                <button type="button" @click="onClickPage(2)" @keydown.enter="onClickPage(2)" class="flex items-center justify-center px-6 h-16 leading-tight text-gray-500 bg-stone-100 text-gray-700 border border-gray-300 hover:bg-gray-100 hover:text-gray-700"> G-L</button>
            </li>
            <li class="pagination-item">
                <button type="button" @click="onClickPage(3)" @keydown.enter="onClickPage(3)" class="flex items-center justify-center px-6 h-16 leading-tight text-gray-500 bg-stone-100 text-gray-700 border border-gray-300 hover:bg-gray-100 hover:text-gray-700">L-R</button>
            </li>
            <li class="pagination-item">
                <button type="button" @click="onClickPage(4)" @keydown.enter="onClickPage(4)" class="flex items-center justify-center px-6 h-16 leading-tight text-gray-500 bg-stone-100 text-gray-700 border border-gray-300 hover:bg-gray-100 hover:text-gray-700">S-Z</button>
            </li>
        </ul> 
    </nav>
</template>

<script>

import AccordionPanel from '@/components/AccordionPanel.vue';
import AccordionPanelDG from '@/components/AccordionPanelDG.vue';
import AccordionPanelGL from '@/components/AccordionPanelGL.vue';
import AccordionPaneLR from '@/components/AccordionPanelLR.vue';
import AccordionPanelSY from '@/components/AccordionPanelSY.vue';

export default {
    components: { 
      AccordionPanel,
      AccordionPanelDG,
      AccordionPanelGL,
      AccordionPaneLR,
      AccordionPanelSY
      },
    data() {
          return {
             currentPage : 0
          };
    },
    methods: {
        onClickPage(page) {
        switch(page)  {
            case 0:
            this.currentPage = 0;
            break;
            case 1:
            this.currentPage = 1;
            break;
            case 2:
            this.currentPage = 2;
            break;
            case 3:
            this.currentPage = 3;
            break;
            case 4:
            this.currentPage = 4;
            break;
        }
    },
    isPageActive(page) {
      return this.currentPage === page;
    },
  }
};
</script>