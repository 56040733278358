<template>
    <div class="container container max-w-10xl m-auto flex flex-wrap flex-col md:flex-row justify-start bg-stone-200">
        
        <section class="w-full lg:w-1/2 p-0">
            <div class="flex flex-col lg:flex-row overflow-hidden h-auto border-t-2 border-b-2 border-l-2 border-r-2 sm:border-b-2 sm:border-r-2 md:border-b-2 md:border-r-2 lg:border-r-0 border-slate-500 bg-stone-200">

                <section class="p-4 flex flex-col justify-between leading-normal">
                    <div class="flex items-center mb-3">

                        <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-slate-500 text-white flex-shrink-0">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
                        </svg>
                        </div>
                        <h2 class="text-grey-600 dark:text-white text-3xl font-medium pb-5 pt-5">About </h2> 
                       
                    </div>
                    <div class="flex flex-col justify-between flex-grow">
                    <p class="leading-relaxed text-base text-black dark:text-gray-300 py-4 text-left text-lg">
                        This website is a digital archive of images held at the British Museum and includes works by the period's leading printmakers.
                        </p>
                        <button type="button" @click="viewAboutPage"  @keydown.enter="viewAboutPage"  aria-label="about" class="bg-slate-500 hover:bg-slate-600 text-white font-bold py-2 px-4 mt-4 mb-4 rounded focus:outline-none focus:ring focus:ring-white inline-flex items-center w-40">Learn More
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                <path d="M5 12h14M12 5l7 7-7 7"></path>
                            </svg>
                        </button>
                    </div>
                </section>
            </div>
        </section>

        <section class="w-full lg:w-1/2 p-0">
            <div class="flex flex-col lg:flex-row overflow-hidden h-auto border-t-0 border-b-2 border-l-2 border-r-2 sm:border-l-2 lg:border-t-2 border-slate-500 bg-stone-200">

                <section class="p-4 flex flex-col justify-between leading-normal">
                    <div class="flex items-center mb-3">

                        <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-slate-500 text-white flex-shrink-0">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 0 1-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0 1 12 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
                            </svg>
                        </div>
                        <h2 class="text-grey-600 dark:text-white text-3xl font-medium pb-5 pt-5">Search</h2> 

                    </div>
                    <div class="flex flex-col justify-between flex-grow">
                        <p class="leading-relaxed text-base text-black dark:text-gray-300 py-4 text-left text-lg">
                            The images in the archive are organised by a BPI number. Search by BPI or Producer/Printmaker, Person, Subject and Technique.
                        </p>
                        <button type="button" @click="viewSearchPage" @keydown.enter="viewSearchPage"  aria-label="search" class="bg-slate-500 hover:bg-slate-600 text-white font-bold py-2 px-4 mt-4 mb-4 rounded focus:outline-none focus:ring focus:ring-white inline-flex items-center w-40">Learn More
                            <svg afill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                            </svg>    
                        </button>
                    </div>    
                </section>
            </div>
        </section>

        <section class="w-full lg:w-1/2 p-0">
            <div class="flex flex-col lg:flex-row overflow-hidden h-auto border-t-0 border-b-2 border-l-2 border-r-2 sm:border-b-2 md:border-b-2 md:border-r-2 lg:border-r-0 border-slate-500 bg-stone-200">

                <section class="p-4 flex flex-col justify-between leading-normal">
                    <div class="flex items-center mb-3">

                        <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-slate-500 text-white flex-shrink-0">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z" />
                            </svg>
                        </div>
                        <h2 class="text-grey-600 dark:text-white text-3xl font-medium pb-5 pt-5">Resources</h2> 

                    </div>
                    <div class="flex flex-col justify-between flex-grow">
                        <p class="leading-relaxed text-base text-black dark:text-gray-300 py-4 text-left text-lg">
                           This section includes a short historiography, printmaker and illustrator directories, references, technique and genre information. </p>
                        <button type="button" @click="viewResourcesPage" @keydown.enter="viewResourcesPage"  aria-label="resources" class="bg-slate-500 hover:bg-slate-600 text-white font-bold py-2 px-4 mt-4 mb-4 rounded focus:outline-none focus:ring focus:ring-white inline-flex items-center w-40">Learn More
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                            </svg>
                        </button>   
                    </div>  
                </section>
            </div>
        </section>

        <section class="w-full lg:w-1/2 p-0">
            <div class="flex flex-col lg:flex-row overflow-hidden h-auto border-t-0 border-b-2 border-l-2 border-r-2 sm:border-l-2 md:border-l-2 lg:border-b-2 border-slate-500 bg-stone-200">

                <section class="p-4 flex flex-col justify-between leading-normal">
                    <div class="flex items-center mb-3">

                        <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-slate-500 text-white flex-shrink-0">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25" />
                            </svg>
                        </div>
                        <h2 class="text-grey-600 dark:text-white text-3xl font-medium pb-5 pt-5">Research</h2> 

                    </div>
                    <div class="flex flex-col justify-between flex-grow">
                        <p class="leading-relaxed text-base text-black dark:text-gray-300 py-4 text-left text-lg">
                            This site hopes to support students, researchers and others who have an interest in British historical images, printmakers and illustators.
                        </p>
                        <button type="button" @click="viewResearchPage" @keydown.enter="viewResearchPage" aria-label="research" class="bg-slate-500 hover:bg-slate-600 text-white font-bold py-2 px-4 mt-4 mb-4 rounded focus:outline-none focus:ring focus:ring-white inline-flex items-center w-40">Learn More
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                            </svg>

                        </button>
                    </div>    
                </section>
            </div>
        </section>    
    </div>
</template>

<script>

export default {
      methods: {
        viewAboutPage() {
        this.$router.push('/about')
        },
        viewResearchPage() {
        this.$router.push('/research')
        },
        viewResourcesPage() {
        this.$router.push('/resources')
        },
        viewSearchPage() {
        this.$router.push('/search')
        },
      }
  }


</script>
