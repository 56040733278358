<template>
 <div>
        <h3 class="py-6 text-3xl">Tree of Man's Life</h3>
        <h4 class="py-2 text-xl">No.10 May 2007 </h4>
    </div>
    <div class='relative text-left overflow-y-auto'>
      <p class="py-2"> <strong>Copyright of the Trustees of the British Museum.</strong> </p>
      <p class="pt-2 pb-4"> <strong>British Museum 1847,0723.10 [Sat 771]. Dimensions of original: 496 mm x 364 mm.</strong></p> 
        <span class="float-left pr-6 pb-2"><img :src="thumbnail" width="200"/> <button type="button" @click="getlargerImage()" class="underline font-bold" >View larger image</button></span> 

        
        <p class="pb-2">During the winter of 1637/8 there appeared in English print-shops at least five large and important 'emblematic'<sup>i</sup> sheets which attempted to encapsulate all human knowledge or all human understanding of the Creator and His creation in a single definitive and comprehensive 'unified field', bearing such alarming titles as <span class="italic">The Order of the Universe, A Laurell of Metaphysicke</span> and <span class="italic">An Artificiall Table of Moral Philosophy</span><sup>ii</sup>.  The appearance of the last is not known, but the first is a diagram of text arranged around the “Philosopher's Head” (its alternative title), and the second is an elaborately pictorial scene, of the same type as <span class="italic">An Artificiall Description of Logick</span>, while a further sheet entitled <span class="italic">The Theater of Nature</span> would have been somewhere in between—perhaps we might term it a pictorialised schema. </p>

        <p class="pb-2">
        What unites all five, however—and in addition, the splendidly pictorial <span class="italic">Tree of Mans Life</span>, engraved by Goddard the following year<sup>iii</sup> - is that a certain Richard Dey is credited with authorship of the texts. He styles himself a Cambridge BA, and so is presumably enrolled amongst the alumni of that university.<sup>iv</sup> The most promising candidate is the Richard Day [sic], a scholar from Eton, who was admitted to King's College in 1622, and graduated BA 1625-6, MA in 1629, and BD in 1637, the year of the earliest-known print signed by Dey. He was a fellow of Kings until 1644, and vicar of Prescot, Lancashire, in 1642, where he died in 1650. He followed his father of the same name at Kings (matriculated 1590), and came from a family of theologians, being the grandson of the William Day (1529-1596), who was successively, Provost of Eton, Dean of Windsor, and Bishop of Winchester. That said, it is odd—if he is, indeed, our man—that he styles himself <span class="italic">in art bacc</span>: on <span class="italic">An Artificiall Description of Logick</span> (late 1637), <span class="italic">in Art: Bacca: Cantabri</span>. on <span class="italic">A Laurel of Metaphisick</span>, entered in the Stationers Registers on 16th January 1638, and as <span class="italic">Batch: in arts</span> on <span class="italic">The Tree of Mans Life</span> (not before 1639), seeing that we know that the above Richard Day was already both MA and Bachelor of Divinity by these dates. 
        </p>

        <p class="pb-2">
        Most of these sheets for which Dey provided text—or, rather, translations—are English versions of prints first issued on the continent, yet again testifying to the currency and availability of foreign models to artists in England. By 1659 at least four of the sheets were available from Robert Walton: <span class="italic">The Philosophers Head, The Laurel of Metaphysic, An Artificial Description of Logic</span> and <span class="italic">The Tree of Mans Life</span>.
        </p>

        <p class="pb-2">
        The present sheet, entitled in full, <span class="italic">The Tree of Mans Life Or an Emblem declareing the like, and unlike, or various condition of all men in their estate of Creation, birth, life, death, buriall, resurrection, and last Judgment, with pyous observations out of the Scriptures upon the severall branches</span> is signed by the engraver John Goddard, and <span class="italic">By Ri Dey Batch: in arts</span>. It has recently been discovered that Goddard was not made free of the Merchant Taylors Company until 1639, which (it is therefore assumed) becomes the earliest possible date for the publication of this print.<sup>v</sup> If this reasoning holds, then it is unlikely to have been occasioned by the wedding in 1634 of Susanna Vernatti, to whom it is fulsomely dedicated: <span class="italic">To the right worshipfull and virtueous Lady the Lady Susanna Vernatti; R:D: wisheth encrease of the best blessings in this life, and fruition of æternall joyand felicitie in the life to come, humbly dedicating his endeavours, and desyreing favourable acceptance of his best services</span>.
        </p>

        <p class="pb-2"> It is a close copy of the <span class="italic">Arbor Hvmanae Vitae</span> issued [in Antwerp] by Theodoor Galle (d.1633) and doubtless engraved by him too; the credit for its design, however, is given to Nicolaus Christophorus Radziuilus (d.1616). Dey has translated all the Latin inscriptions into English and - as with the contemporary <span class="italic">Artificiall Description of Logick</span> - Goddard has altered it somewhat so as to be acceptable to Protestant eyes, by replacing Galle's Christ in Majesty adored by Mary and the Apostles by the name CHRIST irradiated, and 'airbrushed out' St. Peter's keys.</p>

        <p class="pb-2">The British Museum impression of the sheet - the only other known impression is in the Beinecke Library of Yale University—bears the imprint, <span class="italic">Are to be sould by Ro: Walton at the Globe and Compass in St. Paules church yard betweane the two north dores</span>, but traces of an earlier imprint beneath it are plainly visible, and Griffiths not unreasonably suggests that publisher might have been Thomas Hinde who is known to have published the earlier state of Dey's <span class="italic">Order of the Universe</span> (above), and his <span class="italic">Artificial Description of Logic</span><sup>vi</sup>. 
        </p>

        <p class="pb-2">
        The composition itself takes the form of a massive tree, on the roots and branches of which are inscribed Biblical texts. At the crown of the tree is the irradiated name of Christ. The trunk of the tree divides to accommodate two inset scenes, Dives and Lazarus (B) below, and Death with his scythe, a funeral and burial behind him (D), above. Two further scenes are inset bottom left—a woman with a baby on her knee—and bottom right—a woman breast-feeding a swaddled infant in a bedchamber.<sup>vii</sup>     
        </p>

        <p class="pb-2">
        Six stages of the tree are labelled A-F from the bottom upwards; they are keyed to six lines printed at the bottom of the sheet: 
        </p>

        <p class="pb-2">
        A <span class="italic">Wee are all borne alike, and equall being in our infancie, contented with milke</span><br>
        B<span class="italic"> Wee live unlike, and after milke not satisfyed with whole kingdomes, and now a dayes there is no end of riches and pleasures</span> <br>
        C <span class="italic">Wee dye alike, the royall septer haveing noe more prerogatives then the criples crutch</span><br>
        D <span class="italic">Though wee be buryed unlike, and covered with purple, or cheaper cloth, yet wee are all alike wormes meate</span><br>
        E <span class="italic">Wee shall arise alike, at the last day: stripte of all the lying vanities of fortune</span><br>
        F <span class="italic">Wee shall be rewarded unlike, either from Lazarus, or the rich Gluttons table with blessed or cursed estate forever </span>
        </p>

        <h4 class="py-2 text-xl font-bold">Footnotes</h4> 
        <ul class="list-[lower-roman] list-inside">
          <li class="py-2"><cite>The French Renaissance in Prints from the Bibliothèque Nationale de France</cite> catalogue (Los Angeles, 1994) refers to them as "the hieroglyphic plates that were the invention of Martin Meurisse", p. 432, re cat. no. 174.   </li>
          <li class="py-2">These have now been discussed by Susanne Berger in JWCI LXXVI (2013), 203-30</li>
          <li class="py-2">For the suggested date of this sheet, see below.  </li>
          <li class="py-2">J. Venn (ed.), <cite>Alumni Catabrigienses</cite> (Cambridge, 1922), vol. 2., s.n. </li>
          <li class="py-2">A. Griffiths, 'The Print in Stuart Britain' Revisited', <cite>Print Quarterly</cite>, 17 (2000), 115-22, on p. 118. </li>
          <li class="py-2">A. Griffiths, <cite>The Print in Stuart Britain 1603-1689</cite> (London, 1998), cat. no.93. </li>
          <li class="py-2">A.M.Hind, <cite>Engraving in England in the Sixteenth and Seventeenth Centuries</cite> (3 vols., Cambridge, 1952-64), vol. 3, p. 339; F.G. Stephens, <cite>Catalogue of Prints and Drawings in the British Museum</cite>, vol. 1 (London, 1870), pp. 425-6.  </li>
        </ul> 
  </div>   
</template>

<script>
    export default {
        data() {
          return { 
            imagePath : 'https://britishprintedimages.blob.core.windows.net/input-images/printsinfocus/',
            thumbnail : '',
            largeImage : ''
          }
        },
        methods: {
          getlargerImage() {
                let currentImagePath = this.imagePath + 'Tree of Mans Life.png' 
                window.open(currentImagePath, "_blank");
            },
        },
        created() {
          this.thumbnail = this.imagePath  + '26.jpg' 
        }
    }
  
  </script>
