<template>
   <div>
        <h3 class="py-6 text-3xl">The Common Weales Canker Wormes, or the Locusts Both of Church and States</h3>
        <h4 class="py-2 text-xl">No.24 October/November 2008</h4>
    </div>
    <div class='relative text-left overflow-y-auto'>
      <p class="py-2"> <strong>Copyright Brotherton Library, University of Leeds. </strong> </p>
      <p class="pt-2 pb-4"> <strong>Dimensions of original: 320 mm x 376 mm.</strong></p> 
      <p>
        <span class="float-left pr-6 pb-2"><img :src="thumbnail" width="300"/> <a href="#" @click="getlargerImage()" class="underline font-bold" >View larger image</a></span></p>   
        <p class="pb-2">
        This superb late Jacobean single-sheet print survives in two impressions and was first published by Alexander Globe in his exemplary catalogue raisonné, <span class="italic">Peter Stent, London Printseller</span> c. 1642-65 (Vancouver, 1985), where it is cat. no. 504.<sup>i</sup>  Globe reproduced his impression from the Ashmolean Museum's Sutherland Collection, but was unaware that a second impression is preserved in the Brotherton Library of Leeds University, by whose kind permission the present plate is reproduced. This impression is also exceptional in bearing the manuscript licence of Sir Roger L'Estrange dated October 28th 1672, presumably when being licensed to Stent's successor, John Overton, and one of six sheets now known to have been licensed by L'Estrange on that day. Why Stent (active c.1642-1665) and his successor Overton should wish to reissue it in the different political circumstances obtaining in the mid and later century is not obvious, though there is perhaps no need to look for any particular reason behind the publication of an anti-Catholic sheet in post-Reformation England. Despite its topical origins in the particular historical milieu of the late Jacobean era, as an estates satire which lampoons various occupations its appeal is perennial—and its graphic impact also makes it an attractive publishing venture.</p>
        
        <p class="py-2">Generically, it is an example of the topos I term <span class="italic">Death and the Four/Five Alls</span> of which various examples are known,<sup>ii</sup> but it introduces a decidedly more satirical note. It is a complex composition. Arranged in a circle round a grotesque female devil standing in a hell-mouth, ten human types (each provided with an inscribed banderole and an inset emblem) describe their relations with the previous persons. Each character performs his (usually negative) office for all the preceding characters, while the Devil reveals that ultimately they are all his agents, and that he will use the chain composed of human fingers, ears and tongues that links them, to drag them all into hell. Each of the ten types is further labelled with a letter which is keyed to the corresponding quatrain below the image portion of the sheet.</p>

        <p class="py-2">Globe suggested the sheet was engraved 'c. 1650' because he evidently associated the <span class="italic">Common Weal</span> of the title with the <span class="italic">Commonwealth</span> era, i.e. the 'Republican' period between the execution of Charles I in 1649 and the Restoration of Charles II in 1660—<span class="italic">even Homer nods</span>! As we have noted above, the print was clearly still in circulation at this period, but I suggest it was first issued a generation earlier. Certainly the costumes are <span class="italic">mpossible</span>i for the 1650s, but there is no detail that would be out of place in the 1620s. The costume historian, Prof. Ribiera, has kindly informed me that she would date the costume c.1625, that of the tailor being—appropriately—in the latest datable fashion on the sheet.</p> 
        
        <p class="py-2">There are further clues to be had in the accompanying text—which allots a quatrain to each character—and in their accompanying 'emblems'. Although the ten characters represented are for the most part 'generic', I suggest that those I here refer to as the Jesuit, labelled G, and the Ambassador, labelled I, were intended by the designer to be identified with two known individuals, notorious in Jacobean England. The verse given to the Ambassador clearly implies Spain's imperial ambitions, and that the figure in question is working cunningly ('foxe-like') for Spain—again, quite inappropriate to the international <span class="italic">Realpolitik</span> of c.1650, but precisely relevant to that of the 1620s: I find I cannot resist the suggestion that the man who boasts of deceiving the previous ten is none other than the Spanish ambassador, Gondomar. Gondomar was the target both of Middleton's satirical play <span class="italic">A Game at Chess</span> (1625), and of Scott's <span class="italic">The Second Part of Vox Populi, or Gondomar appearing in the likenes of Matchiavell</span> (1624), where he appears on the engraved title-page.</p>
       
        <p class="py-2"> The inset 'emblems' are certainly worthy of consideration in their own right—and apparently unknown to emblem-scholars. Though the published emblem-book had only been available to an English readership for some forty years at the time I suggest this print was issued—since Whitney's selection of 1586, followed up by translations of Paradin (1591), La Perrière (1606,1614) and Peacham's <span class="italic">Minerva Brittana</span> (1612)—recent scholarship has supplied ample evidence of the familiarity of English writers with the emblem genre already in Elizabethan times.<sup>iii</sup> Each emblem here consists of an image in a rectangular frame and a Latin motto, transcribed below. The 'seducing' Jesuit (?) holds a book and rosary, but his civilian disguise is visible beneath the otherwise all-enveloping cloak and cowl, his verses openly proclaim his intention to 'bring in Popery… By cunning plotts, devised for Romish use', and significantly, his emblem shows a man setting light to a trail of gunpowder which leads to what looks like a castle—surely an allusion to the Gunpowder Plot, especially when combined with the other Catholic monk, the confessor who absolves all the others. I find I cannot resist identifying him as the Jesuit, Stephen Garnet, the Gunpowder Plotters' confessor. </p>
     
        <p class="py-2">Extraordinarily - references to specific artworks being rare in this era -- two texts published in London in the early 1620s refer to our motif, one of them in great detail. On October 26 1623 at <span class="italic">Pauls Crosse</span> Thomas Adams preached a sermon entitled <span class="italic">The barren tree</span>, at the end of which he alludes to what he calls our <span class="italic">satyre</span> thus:</p>
      
        <p class="py-2"><span class="italic">It was a smart Inuention of him, that hauing placed the Emperour, and the Pope, reconciled, in their Maiestick Thrones, he brought the States </span>[i.e. estates] <span class="italic">of the world before them. First comes a Counseller of State, with this Motto</span>, I aduise you two: <span class="italic">then a Courtier</span>, I Flatter you three: <span class="italic">then a Husbandman</span>, I feed you foure: <span class="italic">then a Merchant</span>, I Coussen you fiue: <span class="italic">then a Lawyer</span>, I Robbe you sixe: <span class="italic">then a Souldier</span>, I Fight for you seuen: <span class="italic">then a Physician</span>, I Kill you eight: <span class="italic">Lastly, a Priest</span>, I Absolue you all nine. (p.55).</p>
       
        <p class="py-2">James Mabbe's translation of Aleman's <span class="italic">The Rogue or the life of Guzman de Alfarache</span> was first published in 1622 and was made partly from the original Spanish edition, and partly from an Italian translation by Barezzi.<sup>iv</sup> On p.251 of the Second Part, a marginal note signals <span class="italic">A merry tale deliuered in the Pulpit by a Preacher</span>. The <span class="italic">tale</span> in question does not appear in the Spanish original - and as yet I have been unable to see the <span class="italic">Vita del picaro Gusmano d'Alfarace</span> (Venice, 1615) - but if it is Mabbe's addition, it seems the preacher he was referring to cannot have been Adams, and in fact his account is much more detailed, and offers striking similarities with <span class="italic">The Common Weales Canker Wormes</span>: </p>

        <p class="py-2"><span class="italic">"Hearken to that, which was deliuered vnto me by a famous Preacher, who discoursing vpon this subiect of rich men, amongst many other things, I remember he told vs at that time of a certaine picture, the vpper and middle part of which square, or peece, was taken vp by a great rich man, which was there portrayed forth to the life: On his right hand, stood a roaring boy, to fight for him, if need were; and on his left, a foole, to make his Worship merry, and to put him out of his dumps: both which were expressed in such a posture, as fitted best with them. Vnder this rich man, was drawne a country Clowne; vnder the Clowne, a Merchant; vnder the Merchant, a Counsellor at Law; vnder the Counsellor, a Confessor; vnder the Confessor, a Physitian; and vnder the Physitian, the Deuill, with a long chaine, whose linkes were made of womens tongues, which kept a horrible ratling. And he farther added, that the rich man had written ouer his head</span>; These two I keepe:   [i.e. <span class="italic">roaring boy & foole</span>]</p>

        <p class="py-2"><span class="italic">The Clownes word was</span>; I maintaine these three:  </p>

        <p class="py-2"><span class="italic">The Merchants motto</span>; I rob these foure: </p>

        <p class="py-2"><span class="italic">The Counsellor[at Law]s posie</span>; I pill these fiue: </p>

        <p class="py-2"><span class="italic">The Confessors</span>; I absolue these six: </p>

        <p class="py-2"><span class="italic">The Physitians</span>; I kill these seuen: :</p>

        <p class="py-2"><span class="italic">And the Deuill had a labell comming out of his mouth, which said</span>; I carry away all these eight with me to Hell."</p>

        <p class="py-2">It will be seen how very closely this agrees with our print, the principal difference being that Mabbe enumerates only eight estates, where the print has twelve.</p>

        <p class="py-2">The <span class="italic">inuention</span> as described by Adams was subsequently plagiarised wholesale, appearing almost verbatim in books by Grove (1651), Gove (1652), Spencer (1658), Hibbert (1662), Winstanley (1680), and Brown (1690) - though Gove did at least have the grace to give his source.</p>

        <p class="py-2">For fuller detail about all the other characters depicted and their associations see my essay in <span class="italic">Printed Images in Early Modern Britain: Essays in Interpretation</span> (Burlington, 2010), edited by Michael Hunter, which I hope sufficiently illustrates the anti-Jesuit fervour abroad in the 1620s to provide a convincingly appropriate context for my redating of <span class="italic">The Common Weals Canker Wormes</span> to c.1625, alongside the two identifications I have suggested here.</p>

        <p class="py-2"><span class="font-bold">Description of the characters with their emblems, with transcription of the verses relating to each</span></p>

        <p class="py-2">
        <span class="font-bold">A<br>
        Character<br></span>
        <span class="italic">Wealthy Man 'of the World'<br></span>
        I maintaine these too<br>
        <span class="font-bold">Emblem</span><br>
        Man ?embracing world<br>
        <span class="italic">SPERNO SUPERNA<br></span>
        [I scorn/despise the celestial/heavenly]<br>
        <span class="italic">
        Since I have found what others seeke with care<br>
        To take my pleasure heere, why should I spare<br>
        Ile keepe my champion hee my health shall quaffe<br>
        While my Buffoone shall make my worshipp laffe</span>
        </p>

        <p class="py-2">
        <span class="font-bold">B<br>
        Character<br></span>
        Man/Labourer/Peasant-Farmer digging<br>
        <span class="italic">I feed these three<br></span>
        <span class="font-bold">Emblem</span><br>
        Man at corner of ?thatched haystack/house<br>
        <span class="italic">COR LAETUM FACIT HORREA PLENA MIHI<br></span>
        [Full barns make my heart happy]<br>
        <span class="italic">
        It is my labour and unwearied paines<br>
        That common wealth and humane life maintaines<br>
        My sweat feeds you wherfore Ile rate it deare<br>
        Or hoard my graine upp for a scarcer yeare</span>
        </p>

        <p class="py-2">
        <span class="font-bold">C<br>
        Character<br></span>
        Merchant<br>
        <span class="italic">I cosen these foure<br></span>
        <span class="font-bold">Emblem</span><br>
        Scales and measuring- or tally-stick<br>
        <span class="italic">LIBRIS ET LABRIS LUCROR FURTIUIS<br></span>
        [I gain by fraudulent scales and ?measures]<br>
        <span class="italic">
        Cloathes must bee had and other wares you knowe<br>
        And you your moneys must wth mee bestowe<br>
        Ile ease your purses with a trick of skill<br>
        While mine wth waights & measures false I fill</span>
        </p>

        <p class="py-2">
        <span class="font-bold">D<br>
        Character<br></span>
        Lawyer<br>
        <span class="italic">I fleece these five<br></span>
        <span class="font-bold">Emblem</span><br>
        Fox and goose<br>
        <span class="italic">LEGEM PONE<br></span>
        [Pay up!/Let's see your money!]<br>
        <span class="italic">
        What lett fall such an advantageous cause,<br>
        And beare such wronges why tis against the lawes,<br>
        Ile prosecute it & uppon my troth,<br>
        Ile never leaue it till I have fleecd you bothe [i.e. both parties to the case]</span>
        </p>

        <p class="py-2">
        <span class="font-bold">E<br>
        Character<br></span>
        Physician<br>
        <span class="italic">I kill these six<br></span>
        <span class="font-bold">Emblem</span><br>
        Large purse from which smaller purses hang<br>
        <span class="italic">
        BONUS ODOR LUCRI EX RE QUALIBET<br></span>
        [The smell of money is good however acquired]<br>
        <span class="italic">
        Fye fye take phisicke pray bee ruld by mee<br>
        I in your water signes of danger see<br>
        Lett mee prescribe y'a potion what care I<br>
        So I thrive by't whether you live or dye</span>
        </p>

        <p class="py-2">
        <span class="font-bold">F<br>
        Character<br></span>
        Tailor<br>
        <span class="italic">I shape these seaven<br></span>
        <span class="font-bold">Emblem</span><br>
        (Aesop's) Ass in a lion's skin<br>
        <span class="italic">
        QUIS ME NOUIT<br></span>
        [Who recognised me]<br>
        <span class="italic">
        My Arte transformes your persons to more shapes<br>
        Then Proteus for y'are All but french mens Apes<br>
        Ile fitt your pride & make you seeme with ease<br>
        Prodigious or ridiculous when I please</span>
        </p>

        <p class="py-2">
        <span class="font-bold">G<br>
        Character<br></span>
        Jesuit (disguised)<br>
        <span class="italic">I seduce these eight<br></span>
        <span class="font-bold">Emblem</span><br>
        Man lighting powder trail to detonate castle<br>
        <span class="italic">
        QUAE A MINIMIS INCIPIUNT IN MAIORA PRORUNT<br></span>
        [Small things overthrow big things]<br>
        <span class="italic">
        By cunning plotts, devised for Romish use<br>
        Ile make Truth stagger & the Church seduce<br>
        By consequence Ile negatives imply<br>
        And intricately bring in Popery.</span>
        </p>

        <p class="py-2">
        <span class="font-bold">H<br>
        Character<br></span>
        ?Hermaphrodite/Spy<br>
        <span class="italic">I betray these nine<br></span>
        <span class="font-bold">Emblem</span><br>
        Flying demon with face and breasts of woman, wings and tail<sup>v</sup> <br> 
        <span class="italic">FRONTI NULLA FIDES (Juvenal)<br></span>
        [Put no faith in the face, i.e. in appearances]<br>
        <span class="italic">
        And so well usherd Ile make ready way<br>
        To lett the foe in and the state betray<br>
        Soe weake and so disjoynd how easy ist<br>
        Toverunne a land not able to resist</span>
        </p>

        <p class="py-2">
        <span class="font-bold">I<br>
        Character<br></span>
        Ambassador<br>
        <span class="italic">I deseave these tenne<br></span>
        <span class="font-bold">Emblem</span><br>
        Fox measuring off the world with compasses<br>
        <span class="italic">
        QUIS NOSTRA IMPERIO FINES IMPONERE POSSIT<br></span>
        [Who can impose a limit on our empire]<br>
        <span class="italic">
        With sly protexts & foxe: like falacy<br>
        Ile compasse Earth Spaines mighty Monarchy,<br>
        And or by force or fraud Ile trample downe,<br>
        All opposits that hinder our renowne</span>
        </p>

        <p class="py-2">
        <span class="font-bold">K<br>
        Character<br></span>
        Catholic Priest/Friar/Monk<br>
        <span class="italic">I absolve these eleaven<br></span>
        <span class="font-bold">Emblem</span><br>
        Switch, scourge and purse<br>
        <span class="italic">
        QUI NON HABET IN AERE LUAT IN CORPORE<br></span>
        [Who has not the money will suffer corporal punishment]<br>
        <span class="italic">
        Noe paying soules in Purgatory stay<br>
        Our praying songs soone send ym thence away<br>
        But silly soules they frying must endure<br>
        Which have no coyne theire pardon to procure</span>
        </p>

        <p class="py-2">
        <span class="font-bold">
        [Centre, standing in large monstrous mouth]<br>
        Character</span><br>
        Devil<br>
        <span class="italic">
        I draw these twelve to hell<br>
        See heere a crew of crafty mates well mett<br>
        Link'd wth a chaine of tongues & eares all sett<br>
        A worke by mee who thus the world doe gull<br>
        And thus these soules at length to hell doe pull<br>
        Thus they that gull themselues are gulld as fast<br>
        And Sathan cheats the cheating world at last.</span>
        </p>

        <h4 class="py-2 text-xl font-bold">Footnotes</h4> 
        <ul class="list-[lower-roman] list-inside">
          <li class="py-2">Since the original publication of the present commentary, a full-length treatment appeared as <cite>The Common Weales Canker Wormes, or the Locusts Both of Chvrch and States</cite>' -  Emblematic Identities in a Late Jacobean Print', in <cite>Printed Images in Early Modern Britain: Essays in Interpretation</cite>, ed. M. Hunter, (Burlington, VT, 2010), 193-214.  </li>
          <li class="py-2">E.g. an early seventeenth-century carved stone sign in Ghent museum (M. de Meyer, <cite>De Volks- en Kinderprent in de Nederlanden</cite> (Antwerp & Amsterdam, 1962), pp. 396-9) or an Italian sheet engraved by Giuseppe Maria Mitelli issued in 1691 entitled, <cite>Vno la fa all' altro e il diavolo a tvtti</cite> [Each does it to the other and the devil to all], reproduced as fig. 9-22 in D. Kunzle, <cite>The Early Comic Strip</cite> (Berkeley, 1973), p. 288: Milan, Bertarelli Collection cart. M. 3-37. </li>
          <li class="py-2">See M. Bath,<cite>Speaking Pictures</cite> (London, 1994). </li>
          <li class="py-2">info from ODNB s.n. <cite>Mabbe</cite>. </li>
          <li class="py-2">I see now that the Janus-headed figure labelled H on the print, and which I refer to as the Hermaphrodite, derives appropriately enough from an engraving of <cite>Mendacium</cite> attributed to Enea Vico made in the 1540s. Others from this so-called 'emblematic' series were copied in a Scottish armorial manuscript now in Edinburgh [M. Bath, “Emblems from Enea Vico in a Scottish Armorial Manuscript” in ROSC 21 (2009), 132-  
         140] to which I add a further and early example of such copying in a manuscript of the household expenses of Princess Elizabeth dated 1552, which includes drawings of <cite>Tempus, Dolor</cite> and <cite>Natura</cite> with their accompanying mottoes. The present whereabouts of this manuscript seem not to be known. I owe my knowledge of it to the description (and facsimile of <cite>Natura</cite>) in T. Astle, “Extract account of the Household Expences [sic] of the Princess Elizabeth, A.D. 1553” in <cite>The Antiquarian Repetory</cite> 1 (1807), 64-67, and <cite>Camden Miscellany</cite> 2 (1853).</li>
        </ul> 
        
    </div>
</template>

<script>
    export default {
        data() {
          return { 
            imagePath : 'https://britishprintedimages.blob.core.windows.net/input-images/printsinfocus/',
            thumbnail : '',
            largeImage : ''
          }
        },
        methods: {
          getlargerImage() {
                let currentImagePath = this.imagePath + 'commonwealescankerwormes.jpg' 
                window.open(currentImagePath, "_blank");
            },
        },
        created() {
          this.thumbnail = this.imagePath  + '4.jpg' 
        }
    }
  
  </script>