import { defineStore } from 'pinia'
import { useLocalStorage } from "@vueuse/core"

export const useImageDataStore = defineStore('Images', {
    state: () => { 
        return {
            imageDetails: useLocalStorage('imageDetails', [])
        }
     },
    getters: {
        getImageDetails(state) {
            return state.imageDetails;
        }
    },
    actions: {
        addToImageDetails(obj) {
            this.imageDetails.push(obj);
        },
        clearImageDetails(){
           this.imageDetails = []; 
        }
    }
})