<template>
    <div>
        <section class="bg-white dark:bg-gray-900">
          <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700 bg-white px-6 pt-12 pb-6 lg:px-8">
            <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">British Printed Images</h1>
            <p class="mt-6 text-xl leading-8 text-left">British Printed Images to 1700 is a gateway to the visual culture of the Tudor and Stuart period. In its database of prints and book illustrations you will find political satires, portraits, maps, landscapes, architectural drawings, playing cards, and titlepages of books, thousands of images that show us how early modern people understood their society and their world.</p>
            <div class="mt-10 text-left">
              <p>The database includes works by the period's leading printmakers, including Francis Barlow, Abraham Blooteling, Francis Clein, Thomas Cockson, William Faithorne, Wenceslaus Hollar, Marcellus Laroon, William Marshall, John Smith, and Robert White. It contains depictions of some of the period's main events (the Gunpowder Plot, Civil Wars, Anglo-Dutch Wars, the Restoration, Revolution of 1688, and Nine Years' War), representations of its ideas and beliefs (political protest, religion, mythology, and fables), and its understanding of the natural world (anatomy, science, animals, plants, and flowers). There are images of people (from royalty to ordinary folk), places (from palaces to alehouses), and activities (from eating and drinking to washing and shaving). The prints record the incidental details of early modern life, the clothes, objects, and wares that defined people's place in their society.</p>
              <p class="py-4">In addition to the database, this website contains <router-link :to="{  name: 'Resources' }" class="text-blue-600 dark:text-blue-500 hover:underline">resources</router-link> to enhance users' understanding of printed images and inspire further <router-link :to="{  name: 'Research' }" class="text-blue-600 dark:text-blue-500 hover:underline">research</router-link> in the field. These resources include guides on using images in undergraduate and post-graduate <router-link :to="{  name: 'Dissertations' }" class="text-blue-600 dark:text-blue-500 hover:underline">dissertations</router-link>, overviews of current research, and introductions to the different <router-link :to="{  name: 'Genres' }" class="text-blue-600 dark:text-blue-500 hover:underline">genres</router-link> of single sheet prints and the various <router-link :to="{  name: 'Techniques' }" class="text-blue-600 dark:text-blue-500 hover:underline">techniques</router-link> of printmaking. <router-link :to="{  name: 'Prints' }" class="text-blue-600 dark:text-blue-500 hover:underline">'Prints in Focus'</router-link> unpicks individual prints, contextualising them in the society and culture of early modern Britain</p>
            </div>
          </div>
      </section>
      <section class="bg-white dark:bg-gray-900">
        <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700 bg-white px-6 py-4 lg:px-8">     
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl text-left">Acknowledgments</h2>
            <div class="mt-4 text-left">
              <p class="py-4">The original British Printed Images to 1700 project ran between 2006 to 2009 and was funded by the Arts and Humanities Research Council (AHRC) under its Resource Enhancement scheme. The project was led by Michael Hunter (Birkbeck College, University of London) and included Stephen Pigney, Katherine Hunt, and Mary Wills as research assistants. The team worked closely with the Department of Prints and Drawings at the British Museum and the Department of Word and Image at the Victoria & Albert Museum. Software engineering was completed by the following members of Centre for Computing in the Humanities at King's College London (now called the King's Digital Lab).       </p>
              <p class="py-4">The website and database were updated, redesigned, and relaunched in 2024 as part of a project on visual culture in early modern England led by <a class="text-blue-600 dark:text-blue-500 hover:underline" href="https://www.ncl.ac.uk/hca/people/profile/adammorton.html" target="_blank">Adam Morton</a> (Newcastle University) and funded by the AHRC under its Research Development and Engagement Scheme. Software and database design was undertaken by Research Software Engineering, Newcastle University.</p>
            </div>
          </div>
      </section> 
      <section class="bg-white dark:bg-gray-900"> 
        <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700 bg-white px-6 py-4 lg:px-8">     
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl text-left">The Corpus</h2>
            <div class="mt-4 text-left">
              <p class="py-4">The database contains almost 11,000 prints and book illustrations (that number includes multiple states and impressions of the same print). Much of the corpus is held in the Department of Prints and Drawings at the British Museum, with some additional material held in the Victoria and Albert Museum. Additional prints and book illustrations will be added in the future. The prints have been catalogued using terms derived and adapted from Iconclass and the Library of Congress Subject Headings. They can be searched according to Printmaker, Subject, Person, or Technique.  </p>
              <p class="py-4">The date 1700 is an approximate cut-off point for the corpus. Many political prints produced during the reign of Queen Anne (1702-14) are included as are those produced by printmakers whose careers spanned the late-seventeenth and early-eighteenth centuries. For example, John Smith, one of the most important printmakers of the Restoration, continued to be active until his death in the early 1740s. 'British' print is also an approximate term. Most of the prints in the corpus were produced in Britain, but many were made by foreign-born artists. Prints made on the continent but depicting events in Britain (such as Dutch satires on the Anglo-Dutch wars or Revolution of 1688) are also included.</p>
              <p class="py-4">
                Despite its size, the database is not a complete record of the period's printed imagery. Prints are dispersed in collections across the world, and no one repository can hope to achieve completion. Printed ballads are largely absent from the corpus (see the <a class="text-blue-600 dark:text-blue-500 hover:underline" href="http://ballads.bodleian.ox.ac.uk/" target="_blank">Bodleian Library's Broadside Ballad</a> project for access to that material), as are most of the early modern period's book illustrations (though a catalogue of those produced between 1604-1640 is available). Finally, although many of Hollar's works are in this database, there are absences in the corpus. The University of Toronto's <a class="text-blue-600 dark:text-blue-500 hover:underline" href="https://hollar.library.utoronto.ca/" target="_blank">Wenceslaus Hollar Digital Collection</a> is an excellent resource for those wishing to look further. 
              </p>
            </div>
          </div>  
      </section> 
      <section class="bg-white dark:bg-gray-900 pb-6">
        <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700 bg-white px-6 py-4 lg:px-8">     
            <h3 class="mt-2 text-1xl font-bold tracking-tight text-gray-900 sm:text-xl text-left">Copyright</h3>
            <div class="mt-4 max-w-6xl text-left">
              <p class="py-4">All images in the database are subject to copyright. They cannot be reproduced for commercial purposes without the permission of the museums, libraries, and other repositories which hold them. 
              Most of the prints in the database are held in the British Museum. Details of that museum's copyright, permissions, and creative commons licenses may be found <a class="text-blue-600 dark:text-blue-500 hover:underline" href="https://www.britishmuseum.org/terms-use/copyright-and-permissions" target="_blank">here</a>.
              </p>  
            </div>
          </div>     
      </section>  
    </div>
  </template>
    
  <!-- TODO add links -->

  <script>
   
  export default {
    data() {
        return {
        };
    },
    components: { 
     
    },
    methods: {
      followLink(str){
        this.$router.push(str);
      }
  }
}
  </script>