<template>
    <div class="relative container mx-auto px-20 bg-gray-200">
      <HomeLink />
      <HomeBanner />
      <NavMenu />
   
      <section class="bg-white dark:bg-gray-900">
          <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700 bg-white px-6 pt-12 pb-2 lg:px-8">
            <h1 class="mt-2 mb-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">History</h1>
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl text-left">16th Century</h2>
            <p class="mt-6 text-xl leading-8 text-left">The production of printed images in Europe began in the late Middle Ages, slightly predating the introduction of moveable type which revolutionised book production. The technique was first used to produce such items as single-sheet devotional images, and a facsimile of one of these that is in our corpus (bpi1008). Woodcut book illustrations also appeared from an early date. Caxton's edition of Chaucer's <span class="italic">Canterbury Tales</span> (1477) and in books produced by Wynkyn de Worde (d.1534/5) were notable examples. </p>
            <div class="mt-10 text-left">
              <p>Important developments occurred in the 1530s and 1540s. Hans Holbein (d.1543) introduced a new genre into England. His illustrative titlepage for the Coverdale Bible (1535) became established the form for titlepages across the 16th and 17th centuries, in which printed images summarised the content or argument of a book in pictorial form. This period also saw the first production of engravings made in metal. Thomas Geminus (1510-62) pioneered this practice in the plates he produced for Vesalius' <span class="italic">De humani corporis fabrica</span>, an anatomical book published in London in 1545. Plates from this book are in the BPI corpus, including a striking image of a flayed man (bpi1038).  </p>
              <p class="py-4">
                In the Elizabethan period book illustrations developed further, largely because of the printshop of John Day. Day's output included several heavily illustrated texts, including Stephen Bateman's <span class="italic">A Christall Glasse of Christian Reformation</span> (1569), William Cunningham's <span class="italic">The cosmographical glasse</span> (1559), Jan van der Noot, <span class="italic">Theatre of Worldlings</span> (1569). The most significant was John Foxe's  <span class="italic">Actes and Monuments</span> (first published in 1563), often called ‘The Book of Martyrs', which used images in an unprecedented scale. This used illustrations on an unprecedented scale and included scenes of martyrdom that became famous in English history. Some of those images are included in the database (bpi5083-5119). Cartographic prints were also developed in this period, most famously in Saxton's <span class="italic">Atlas of England and Wales</span> (1579), and the Armada charts produced by Augustine Ryther were also significant (bpi8978-8989). Portraits were the other principal type of printed image produced in this period.
              </p>
            </div>
          </div>
      </section>
      <section class="bg-white dark:bg-gray-900">
          <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700 bg-white px-6 pt-12 pb-2 lg:px-8">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl text-left">Early-17th Century</h2>
            <p class="mt-6 text-left">Most of the artists who made printed images in 16th-century England were foreigners: Holbein, Geminus, and Jodocus Hondius, a Protestant fleeing persecution, being the most prominent. The importance of immigrants to British printmaking continued in the early-17th century, with notable engravers like Francis Delaram and the de Passe family establishing themselves in the reigns of James VI/I and Charles I. There were native-born artists active in this period. William Rogers' portrait of Elizabeth I (1589) was the first print signed by an English engraver (bpi708) and Renold Elstracke (1570-1625) and John Payne (1607-47) made important contributions in the subsequent generation.  </p>
            <div class="mt-4 text-left">
              <p>There was an upsurge in the woodcut trade in the Jacobean period, and engravings also became increasingly common. These years saw a growing number of specialist dealers in prints, such as the partners Sudbury and Humble, and their rivals, Compton and Henry Holland. These men dealt in prints produced in England, but also commissioned work overseas for the English market, including <span class="italic">Herωologia </span>(1620), a volume of portraits of leading Englishmen. </p>
              <p class="py-4">
                Portraits continued to dominate printmaking. Portraits of the Stuart royal family and members of their courts were popular. Charles I on horseback by Elstrack (1612) is an interesting example(bpi59). The head of the figure was adapted in subsequent versions (1616 and 1625) as the prince's appearance changed. Other genres also flourished at this time. Engraved titlepages became increasingly common, while new genres were introduced, including botanical prints derived from Dutch models, sets of allegorical figures, political prints, and satires of popular vices. Prints offer a glimpse into events and places, such as the engravings of the triumphal arches that lined the streets of the City of London for the Coronation of James I, or the etching of the gardens of Wilton House by their designer, Isaac de Caus (bpi3909-20).
              </p>
            </div>
          </div>
      </section> 
      <section class="bg-white dark:bg-gray-900">
          <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700 bg-white px-6 pt-12 pb-6 lg:px-8">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl text-left">Mid-17th Century</h2>
            <p class="mt-4 text-left">Print production burgeoned in the mid-17th century. Two figures were central to its development: Wenceslaus Hollar and Peter Stent. Hollar (1607-77) entered the service of the Earl of Arundel during the latter's European tour in the 1630s and spent much of the rest of his life in England. Hollar was both talented and prolific. He produced prints in virtually every genre, topography (including the famous Long View of London), maps, portraits, allegories, satires, and natural histories. The BPI does not offer a comprehensive series of Hollar's work, but he is well represented in its corpus. </p>
            <div class="mt-4 text-left">
              <p>Peter Stent (d.1665) was a print-seller who mass-produced prints from 1642. The outbreak of the Civil War led to an unprecedented demand for topical engravings. Stent produced engravings of subjects linked to the War, including portraits of military figures, maps of battles, and political satires. But as an advertisement he printed in 1654 shows, he also offered prints on a wide variety of subjects: portraits, maps, copy books, and series of prints of flowers and animals. The advertisement (the first to survive for printed images) hints at how buoyant the market for prints had become by the mid-century. Stent employed artists to meet the demand for prints of all kinds, including Hollar and prolific English engravers like Richard Gaywood (1644-80). </p>
              <p class="py-4">
                The 1650s saw other innovative enterprises in printmaking. John Ogilby (1600-76) produced the first of a series of books illustrated with lavish engravings, including editions of Aesop (bpi8121-44) and Virgil. And Sir William Dugdale (1605-86) pioneered the production of engravings of medieval cathedrals, abbeys, and churches to illustrate the first volume of the great <span class="italic">Monasticon Anglicanum</span> (1655) that he produced with Roger Dodsworth. 
              </p>
            </div>
          </div>
      </section>
      <section class="bg-white dark:bg-gray-900">
          <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700 bg-white px-6 pt-12 pb-6 lg:px-8">
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl text-left">Late-17th Century</h2>
            <p class="mt-4 text-left">The Restoration consolidated the innovations of the revolutionary period. Stent and his successor, John Overton, continued to sell a wide range of prints, as a 1662 advertisement of his wares from 1662 demonstrates. The fact that rival dealers Thomas Jenner and Robert Walton now entered the market showed that demand for prints was growing. Ogilby also continued to thrive in this period, as did another talented engraver of birds and animals, Francis Barlow (1626-1704), who produced lavish sets of natural history scenes. Barlow was one of the most important printmakers of the century. </p>
            <div class="mt-4 text-left">
              <p>Demand for engraved portraits also grew rapidly. Engravers like William Faithorne (1616-91) and Robert White (1645-1703) were leading producers. A new technique, the mezzotint, was principally used for portraiture after its introduction in the 1670s and contributed to the availability of and demand for prints. By the 1680s, Isaac Beckett (1653-1719) was the leading mezzotint artist. He was succeeded in this role by John Smith (1652-1742), who produced mezzotint portraits well into the 18th century. Both Beckett and Smith are well represented in the BPI database. </p>
              <p class="py-4">
                New genres also emerged in this period. Scientific books like Robert Hooke's Micrographia (1665) with its detailed engravings of substances and creatures under the microscope, including the highly magnified flea, were notable.  Sets of architectural engravings like David Loggan's Oxonia Illustrata (1675) and its Cambridge equivalent were equally innovative, Marcellus Laroon's Cries of London series (1687) depicted the lives of ordinary people. Political satires became more widespread in this period, particularly after the Popish Plot and Succession Crisis (1678-82) and Revolution of 1688, with prints like Roger L'Estrange's The Committee (1680) demonstrating a growing sophistication in political commentary. In the reign of William and Mary, Dutch satires of British politics circulated, often produced by Romeyn de Hooghe (1645-1708), who is well represented in the database. Graphic satire acquired a potency which anticipated the age of Gillray a century later.
              </p>
            </div>
          </div>

          <div class="text-center">
            <button type="button" @click="viewResourcesPage()" @keydown.enter="viewResourcesPage()" class="bg-slate-500 hover:bg-slate-600 text-white font-bold py-2 px-4 mt-4 mb-4 rounded focus:outline-none focus:ring focus:ring-white inline-flex items-center w-40">Back to Resources 
            </button>
          </div>
      </section>
      <SimpleFooter />
    </div>
  </template>
    
    <script>
   
    import HomeBanner from '../components/HomeBanner.vue';
    import NavMenu from '../components/NavMenu.vue';
    import SimpleFooter from '../components/SimpleFooter.vue';
    import HomeLink from '../components/HomeLink.vue';
  
    export default {
      data() {
          return {};
      },
      components: { 
        HomeLink,
        HomeBanner, 
        NavMenu,
        SimpleFooter
      },
      methods: {
        viewResourcesPage() {
        this.$router.push('/resources')
        },
      }
  }
    </script>