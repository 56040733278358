<template>

<div  id="accordion-collapse" data-accordion="collapse">
    <h2 id="accordion-collapse-heading-1">
        <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-700 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-stone-400 hover:bg-stone-200 dark:hover:bg-stone-800 gap-3" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
        <span class="text-2xl">Savage, John  </span>
        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
        </svg>
        </button>
    </h2>
    <div id="accordion-collapse-body-1" class="hidden" aria-labelledby="accordion-collapse-heading-1">
        <div class="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900 text-left overflow-y-auto"> 
            
            <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                (active 1683-1700) Little is known about Savage's life, virtually the only relevant piece of information being Vertue's comment that 'he was a French man'. The earliest works by him that have been noted are plates to the Philosophical Transactions from 1683 onwards. In 1689 he took over Isaac Beckett's shop from his widow, and his career can be followed through dated works until 1700. He is named as engraver on two plates from the Cryes of London (after Laroon, published by Tempest) but stylistically it is evident that he engraved them all (see Griffiths, Print in Stuart Britain, p. 260).
                </p>
        </div>
    </div>
    <h2 id="accordion-collapse-heading-2">
        <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-2" aria-expanded="false" aria-controls="accordion-collapse-body-2">
        <span class="text-2xl">Somer, Jan van</span>
        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
        </svg>
        </button>
    </h2>
    <div id="accordion-collapse-body-2" class="hidden" aria-labelledby="accordion-collapse-heading-2">
        <div class="p-5 border border-b-0 border-gray-200 dark:border-gray-700 text-left">
            <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                (active 1660-after 1687) and Paul van (active 1670-94) Both of these men were apparently of French origin, but their careers are obscure. Jan was responsible for various mezzotints, both portraits and subject plates, while Paul produced both etchings and mezzotints.
            </p>
        </div>
        </div>
        <h2 id="accordion-collapse-heading-3">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-3" aria-expanded="false" aria-controls="accordion-collapse-body-3">
            <span class="text-2xl">Stoop, Dirk  </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
            <div id="accordion-collapse-body-3" class="hidden" aria-labelledby="accordion-collapse-heading-3">
                <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                    <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                        (c. 1618-86) Stoop hailed from Utrecht, where he published etchings of horsemanship and other subjects, before moving to Portugal in or before 1661. While there, he became associated with Catherine of Braganza, and he came to London with her in 1662, remaining there for a few years and producing various etchings including some for Ogilby's edition of Aesop's Fables (1665).
                    </p>
                </div>
            </div>
        <h2 id="accordion-collapse-heading-4">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-4" aria-expanded="false" aria-controls="accordion-collapse-body-4">
            <span class="text-2xl">Sturt, John  </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-4" class="hidden" aria-labelledby="accordion-collapse-heading-4">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (1658-1703) Sturt was apprenticed to Robert White in 1674. Later he became known as an engraver of calligraphy, and his output also included engraved title-pages and prints of architectural and naval subjects. In 1697 he founded a drawing school in St Paul's churchyard in conjunction with Bernard Lens II.
                </p>
            </div>
        </div>
        <h2 id="accordion-collapse-heading-5">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-5" aria-expanded="false" aria-controls="accordion-collapse-body-5">
            <span class="text-2xl">Valck, Gerard</span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-5" class="hidden" aria-labelledby="accordion-collapse-heading-5">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (1651/2-1726) Said by Vertue to have been a servant to Abraham Blooteling, whose sister he married, Valck apparently came to London from Amsterdam with Blooteling at the behest of David Loggan in the early 1670s. His output in England included both engravings and mezzotints. Later, Valck returned to Amsterdam, where he entered a publishing partnership with Pieter Schenk, specialising in maps and globes and taking over the old Hondius shop.
                </p>  
            </div>
        </div>  
        <h2 id="accordion-collapse-heading-6">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-6" aria-expanded="false" aria-controls="accordion-collapse-body-6">
            <span class="text-2xl"> Vandrebanc, Peter </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-6" class="hidden" aria-labelledby="accordion-collapse-heading-6">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (1649-97) A French engraver who came to England with the portrait painter, Henri Gascar, in the early 1670s with a view to engraving his portraits. Vertue tells us that his large and refined portrait prints were much admired, and he later produced engravings of Verrio's decorative work at Hampton Court and the illustrations to Jean Tijou's New Booke of Drawings of ironwork (1694), though he appears to have died in reduced circumstances.
                </p>  
            </div>
        </div>  
        <h2 id="accordion-collapse-heading-7">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-7" aria-expanded="false" aria-controls="accordion-collapse-body-7">
            <span class="text-2xl">Vaughan, Robert </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-7" class="hidden" aria-labelledby="accordion-collapse-heading-7">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (c. 1600- before 1663-4) A Welshman by origin (various of his works have Welsh connections), Vaughan was one of the most prolific engravers in England in the Caroline period. An active royalist in the Civil War period who also had antiquarian interests, he engraved plates for Elias Ashmole's Theatrum Chemicum Britannicum (1652). William Vaughan (fl. 1653-78) was apparently his son; he worked for John Field and engraved title-pages for several editions of the Bible.
                </p>  
            </div>
        </div>  
        <h2 id="accordion-collapse-heading-8">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-8" aria-expanded="false" aria-controls="accordion-collapse-body-8">
            <span class="text-2xl"> Voerst, Robert van   </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-8" class="hidden" aria-labelledby="accordion-collapse-heading-8">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (1597-1636) A pupil of Crispijn de Passe, the Dutch engraver Robert van Voerst had arrived in London by 1627, probably on de Passe's recommendation. He was responsible for various royal and other portraits and contributed to van Dyck's Iconography, including a portrait of himself.
                </p>  
            </div>
        </div>  
        <h2 id="accordion-collapse-heading-9">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-9" aria-expanded="false" aria-controls="accordion-collapse-body-9">
            <span class="text-2xl"> Vorsterman, Lucas  </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-9" class="hidden" aria-labelledby="accordion-collapse-heading-9">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (1595-1675) Vorsterman moved to London from Antwerp, after falling out with Rubens, by whom he had formerly been employed; the date of his arrival is uncertain, but he was certainly there by 1623 and he remained there until 1629, when he returned to Antwerp. He retained his English links after his return to Antwerp, executing work there for the Earl of Arundel; he also contributed to van Dyck's Iconography.
                </p>  
            </div>
        </div> 
       
        <h2 id="accordion-collapse-heading-10">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-10" aria-expanded="false" aria-controls="accordion-collapse-body-10">
            <span class="text-2xl">White, Robert</span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-10" class="hidden" aria-labelledby="accordion-collapse-heading-10">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (1645-1703) The foremost pupil of David Loggan and his successor as the chief line-engraver to the print trade. White was a prolific engraver who specialised particularly in the production of portraits, many of them intended as frontispieces for books. He also produced architectural and other prints. A number of the drawings from which his portrait engravings were made survive. In the early 1680s he briefly experimented with mezzotint publishing.
                </p>  
            </div>
        </div>   
        <h2 id="accordion-collapse-heading-11">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-11" aria-expanded="false" aria-controls="accordion-collapse-body-11">
            <span class="text-2xl">Winstanley, Henry </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-11" class="hidden" aria-labelledby="accordion-collapse-heading-11">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (1644-1703) Projector and owner of a house at Littlebury, Essex, where he displayed mechanical curiosities. He produced plates of Audley End and other country houses, and set of geographical playing cards.
                </p>  
            </div>
        </div> 
        <h2 id="accordion-collapse-heading-12">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-12" aria-expanded="false" aria-controls="accordion-collapse-body-12">
            <span class="text-2xl">Yeates, Nicholas </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-12" class="hidden" aria-labelledby="accordion-collapse-heading-12">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (fl. 1669-86) Engraver of various prints, some of them after Barlow. He collaborated with another little-known engraver, Jacob Collins, on various plates, including one of the Javanese ambassador in 1682.ß
                </p>  
            </div>
        </div>     
    </div>

</template>
<script setup>

  import { initFlowbite } from 'flowbite';

  // ensures that the prop data is received by the accordion
  setTimeout(() => {
        initFlowbite();
  }, 500);

  const init = async () => {};
  init();
  
</script>

<style>
div[data-panel-id] {
	border-bottom: 1px !important;
}
</style>

