<template>
   <section class="bg-white dark:bg-gray-900">
        <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700 bg-white px-6 pt-12 pb-2 lg:px-8">
            <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Resources</h1>
        </div>
    </section>

<div class="flex flex-wrap justify-center bg-white py-10">

     <!-- card 1 -->

     <section class="p-4 max-w-xl">
        <div class="flex rounded-lg h-full dark:bg-gray-800 bg-stone-200 p-8 flex-col">
            <div class="flex items-center mb-3">
                <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full dark:bg-slate-600 bg-slate-600 text-white flex-shrink-0">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5" /></svg>
                </div>
                <h2 class="text-black dark:text-white text-3xl font-medium">History</h2>
            </div>
            <div class="flex flex-col justify-between flex-grow">
                <p class="leading-relaxed text-base text-black dark:text-gray-300 py-4 text-left text-lg">
                    Learn about the historiography of British Printed Images, from the early 16th Century to the late 17th Century.
                </p>
                <a href="#" @click="goToHistory()" @keydown.enter="goToHistory()" class="mt-3 text-black dark:text-white hover:text-blue-600 inline-flex items-center">Learn More
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                </a>
            </div>
        </div>
    </section>

    <!-- card 2 -->
    <section class="p-4 max-w-xl">
        <div class="flex rounded-lg h-full dark:bg-gray-800 bg-stone-200 p-8 flex-col">
            <div class="flex items-center mb-3">
                <div
                    class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full dark:bg-slate-600 bg-slate-600 text-white flex-shrink-0">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12a7.5 7.5 0 0015 0m-15 0a7.5 7.5 0 1115 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077l1.41-.513m14.095-5.13l1.41-.513M5.106 17.785l1.15-.964m11.49-9.642l1.149-.964M7.501 19.795l.75-1.3m7.5-12.99l.75-1.3m-6.063 16.658l.26-1.477m2.605-14.772l.26-1.477m0 17.726l-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205L12 12m6.894 5.785l-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864l-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495" />
                </svg>

                </div>
                <h2 class="text-black dark:text-white text-3xl font-medium">Techniques</h2>
            </div>
            <div class="flex flex-col justify-between flex-grow">
                <p class="leading-relaxed text-base text-black dark:text-gray-300 py-4 text-left text-lg">
                Learn about the main printmaking techniques of the late 18th Century.
                </p>
                <a href="#" @click="goToTechniques()" @keydown.enter="goToTechniques()" class="mt-3 text-black dark:text-white hover:text-blue-600 inline-flex items-center">Learn More
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                </a>
            </div>
        </div>
    </section>

      <!-- card 3 -->
      <section class="p-4 max-w-xl">
        <div class="flex rounded-lg h-full dark:bg-gray-800 bg-stone-200 p-8 flex-col">
            <div class="flex items-center mb-3">
                <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full dark:bg-slate-600 bg-slate-600 text-white flex-shrink-0">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                    </svg>

                </div>
                <h2 class="text-black dark:text-white text-3xl font-medium">Genres</h2>
            </div>
            <div class="flex flex-col justify-between flex-grow">
                <p class="leading-relaxed text-base text-black dark:text-gray-300 py-4 text-left text-lg">
                    Browse printmaker genres such as: satorical prints, book illustrations and sets of allegories.
                </p>
                <a href="#" @click="goToGenres()" @keydown.enter="goToGenres()" class="mt-3 text-black dark:text-white hover:text-blue-600 inline-flex items-center">Learn More
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                </a>
            </div>
        </div>
    </section>

     <!-- card 4 -->
     <section class="p-4 max-w-xl">
        <div class="flex rounded-lg h-full dark:bg-gray-800 bg-stone-200 p-8 flex-col">
            <div class="flex items-center mb-3">
                <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full dark:bg-slate-600 bg-slate-600 text-white flex-shrink-0">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
                    </svg>

                </div>
                <h2 class="text-black dark:text-white text-3xl font-medium">Printmakers</h2>
            </div>
            <div class="flex flex-col justify-between flex-grow">
                <p class="leading-relaxed text-base text-black dark:text-gray-300 py-4 text-left text-lg">
                    Browse our directory of printmakers from the historical period of 1600 to 1768.
                </p>
                <a href="#" @click="goToDirectories()" @keydown.enter="goToDirectories()" class="mt-3 text-black dark:text-white hover:text-blue-600 inline-flex items-center">Learn More
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                </a>
            </div>
        </div>
    </section>

    <!-- card 5 -->
     <section class="p-4 max-w-xl">
        <div class="flex rounded-lg h-full dark:bg-gray-800 bg-stone-200 p-8 flex-col">
            <div class="flex items-center mb-3">
                <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full dark:bg-slate-600 bg-slate-600 text-white flex-shrink-0">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.53 16.122a3 3 0 00-5.78 1.128 2.25 2.25 0 01-2.4 2.245 4.5 4.5 0 008.4-2.245c0-.399-.078-.78-.22-1.128zm0 0a15.998 15.998 0 003.388-1.62m-5.043-.025a15.994 15.994 0 011.622-3.395m3.42 3.42a15.995 15.995 0 004.764-4.648l3.876-5.814a1.151 1.151 0 00-1.597-1.597L14.146 6.32a15.996 15.996 0 00-4.649 4.763m3.42 3.42a6.776 6.776 0 00-3.42-3.42" />
                    </svg>

                </div>
                <h2 class="text-black dark:text-white text-3xl font-medium">British Book Illustrations</h2>
            </div>
            <div class="flex flex-col justify-between flex-grow">
                <p class="leading-relaxed text-base text-black dark:text-gray-300 py-4 text-left text-lg">
                    Download alphabetical listing of illustrations in British books published between 1604 and 1640. Research funded by the Bibliographical Society.
                </p>
                <a href="Illustrations.xls" class="mt-3 text-black dark:text-white hover:text-blue-600 inline-flex items-center" download> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15M9 12l3 3m0 0 3-3m-3 3V2.25" />
                    </svg><span class="ml-2 mt-2">Illustrations</span>
                </a>
            </div>
        </div>
    </section> 

     <!-- card 6 -->
     <section class="p-4 max-w-xl">
        <div class="flex rounded-lg h-full dark:bg-gray-800 bg-stone-200 p-8 flex-col">
            <div class="flex items-center mb-3">
                <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full dark:bg-slate-600 bg-slate-600 text-white flex-shrink-0">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                    </svg>

                </div>
                <h2 class="text-black dark:text-white text-3xl font-medium">Publishers and Printsellers</h2>
            </div>
            <div class="flex flex-col justify-between flex-grow">
                <p class="leading-relaxed text-base text-black dark:text-gray-300 py-4 text-left text-lg">
                    Download alphabetical listing of British publishers and printsellers.
                </p>
                <a href="DirectoryPP.docx" class="mt-3 text-black dark:text-white hover:text-blue-600 inline-flex items-center" download>  
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15M9 12l3 3m0 0 3-3m-3 3V2.25" />
                    </svg><span class="ml-2 mt-2">Publishers and Printsellers</span>
                </a>
            </div>
        </div>
    </section> 
</div>

</template>

<script>

export default {
  name: 'ResourcesGrid',
  components: {
  },
  data() {
        return {      
        }
    },
    methods: {
        goToGenres(){
            this.$router.push({name: 'Genres'});
        },
        goToTechniques() {
            this.$router.push({name: 'Techniques'});
        },
        goToDirectories() {
            this.$router.push({name: 'Directories'});
        },
        goToHistory() {
            this.$router.push({name: 'History'});
        }
    }
}
</script>
