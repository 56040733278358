<template>
    <div class="relative container mx-auto px-20 bg-gray-200">
      <HomeLink />
      <HomeBanner />
      <NavMenu />

      <section class="bg-white dark:bg-gray-900">
          <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700 bg-white px-6 pt-12 pb-6 lg:px-8">
            <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Prints in Focus</h1>
            <p class="mt-6 text-xl leading-8 text-left">  The articles in the 'Prints in Focus' section were all written by Malcolm Jones, author of <cite>The Print in early modern England: an historical oversight</cite> (Yale, 2010). The articles were originally published between 2006 and 2009 as a 'Print of the Month' series on the original BPI1700 website. They have been edited and updated with new evidence and information here.</p>   
          </div>
      </section>
      <section class="bg-white dark:bg-gray-900">
          <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700 bg-white px-6 pt-4 pb-6 lg:px-8 text-left">
            <ul>
              <li class="py-2">
              <a  title="All Doe Ride the Asse" href="#" @click="activatePrintsView(1)">All Doe Ride the Asse</a></li>
              <li class="py-2">
              <a  title="Cats Castle Besieged and Stormed by the Rats" href="#" @click="activatePrintsView(2)">Cats Castle Besieged and Stormed by the Rats</a></li>
              <li class="py-2">
              <a  title="Committee; or Popery in Masquerade" href="#" @click="activatePrintsView(3)">Committee; or Popery in Masquerade</a></li>
              <li class="py-2">
              <a  title="Common Weales Canker Wormes" href="#" @click="activatePrintsView(4)">Common Weales Canker Wormes</a></li>
              <li class="py-2">
              <a  title="Cully Flaug'd" href="#" @click="activatePrintsView(5)">Cully Flaug'd</a></li>
              <li class="py-2">
              <a  title="Doctor Panurgus" href="#" @click="activatePrintsView(6)">Doctor Panurgus</a></li>
              <li class="py-2">
              <a  title="Embleme of Englands Distraction" href="#" @click="activatePrintsView(7)">Embleme of Englands Distractions</a></li>
              <li class="py-2">
              <a  title="England's Miraculous Preservation Emblematically Described" href="#" @click="activatePrintsView(8)">England's Miraculous Preservation Emblematically Described</a></li>
              <li class="py-2">
              <a  title="Funeral Obsequies of Sir All-in-New-Fashions" href="#" @click="activatePrintsView(9)">Funeral Obsequies of Sir All-in-New-Fashions</a></li>
              <li class="py-2">
              <a  title="Hunting after Money" href="#" @click="activatePrintsView(10)">Hunting after Money</a></li>
              <li class="py-2">
              <a  title="Infallible Mountebank, or Quack Doctor" href="#" @click="activatePrintsView(11)">Infallible Mountebank, or Quack Doctor</a></li>
              <li class="py-2">
              <a  title="Jesuit Displaid" href="#" @click="activatePrintsView(12)">Jesuit Displaid</a></li>
              <li class="py-2">
              <a  title="Lawyer settles a dispute over an oyster" href="#" @click="activatePrintsView(13)">Lawyer settles a dispute over an oyster</a></li>
              <li class="py-2">
              <a  title="Mappe of the Man of Sin" href="#" @click="activatePrintsView(14)">Mappe of the Man of Sin</a></li>
              <li class="py-2">
              <a  title="'May' from the Months" href="#" @click="activatePrintsView(15)">'May' from the Months</a></li>
              <li class="py-2">
              <a  title="New Yeares Guift for Shrews" href="#" @click="activatePrintsView(16)">New Yeares Guift for Shrews</a></li>
              <!-- <li class="py-2">
              <a  title="Pedlar Robbed by Apes" href="#" @click="activatePrintsView(17)">Pedlar Robbed by Apes</a></li>
              <li class="py-2">
              <a  title="Powder Treason" href="#" @click="activatePrintsView(18)">Powder Treason</a></li> -->
              <li class="py-2">
              <a  title="Revells of Christendome" href="#" @click="activatePrintsView(19)">Revells of Christendome</a></li>
              <li class="py-2">
              <a  title="Submarine" href="#" @click="activatePrintsView(20)">Submarine</a></li>
              <li class="py-2">
              <a  title="Sucklington Faction" href="#" @click="activatePrintsView(21)">Sucklington Faction</a></li>
              <li class="py-2">
              <a  title="Syons Calamity or Englands Miserye Hieroglyphically Delineated" href="#" @click="activatePrintsView(22)">Syons Calamity or Englands Miserye Hieroglyphically Delineated</a></li>
              <li class="py-2">
              <a  title="This Ages Rarity, or the Emblem of a Good Servant Explained" href="#" @click="activatePrintsView(23)">This Ages Rarity, or the Emblem of a Good Servant Explained</a></li>
              <li class="py-2">
              <a  title="This Costly Fish Catcht" href="#" @click="activatePrintsView(24)">This Costly Fish Catcht</a></li>
              <li class="py-2">
              <a  title="Tittle-Tattle: or the Several Branches of Gossipping" href="#" @click="activatePrintsView(25)">Tittle-Tattle: or the Several Branches of Gossipping</a></li>
              <li class="py-2">
              <a  title="Tree of Man's Life" href="#" @click="activatePrintsView(26)">Tree of Man's Life</a></li>
              <li class="py-2">
              <a  title="Winchester Wedding" href="#" @click="activatePrintsView(27)">Winchester Wedding</a></li>
              <li class="py-2">
              <a  title="Woman and the Men of the Four Elements" href="#" @click="activatePrintsView(28)">Woman and the Men of the Four Elements</a></li>
              <li class="py-2">
              <a  title="Yale Center 'Panorama'" href="#" @click="activatePrintsView(29)">Yale Center 'Panorama'</a></li>
            </ul>
          </div>

          <button type="button" @click="backToHomePage()" @keydown.enter="backToHomePage()" class="bg-slate-500 hover:bg-slate-600 text-white font-bold py-2 px-4 mt-4 mb-4 rounded focus:outline-none focus:ring focus:ring-white inline-flex items-center w-40">Back to Home page
          </button>
        </section>
      
      <SimpleFooter />
    </div>
  </template>
    
    <script>
   
    import HomeBanner from '../components/HomeBanner.vue';
    import NavMenu from '../components/NavMenu.vue';
    import SimpleFooter from '../components/SimpleFooter.vue';
    import HomeLink from '../components/HomeLink.vue';
  
    export default {
      data() {
          return { 
          }
        },
      components: { 
        HomeLink,
        HomeBanner, 
        NavMenu,
        SimpleFooter
      },
      methods: {
        activatePrintsView(num){
          this.$router.push({
            name: 'PrintsInFocusView',
            params: {
              id: num,  
            }
          })
        },
        backToHomePage() {
        this.$router.push('/');
        }
      },
      created() {
      
      }
  }
    </script>