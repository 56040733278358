<template>
        
    <div class="relative container mx-auto px-20 bg-gray-200">
        <HomeLink />
        <HomeBanner />
        <NavMenu />
        <section class="bg-white dark:bg-gray-900">
            <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700 bg-white px-6 py-12 pb-6 lg:px-8">
            <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Dissertations</h1>
            <p class="mt-6 text-xl leading-8 text-left">
                Bpi1700 is a good archive for undergraduate and post-graduate dissertations. Much of the material here has not been studied before, and that which has is always open to new interpretations. </p>
            <div class="mt-10 text-left">
                <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900">Designing your project</h2>
                 <p class="py-4">
                    How you use the database depends on the nature of your research project. Are visual materials the main body of primary sources in your dissertation? Or are they a supplementary one, a type of source you are drawing on alongside other types of material? Both are valid types of research projects, but how you draw on the prints in the Bpi1700 may be different in each project.</p>

                 <h3 class="mt-2 text-1xl tracking-tight text-gray-900 italic">Basing a dissertation on prints.</h3>

                 <p class="py-4">
                    There are lots of ways that you might do this. First, it is essential to consider what you want the subject of your research project to be (and  why). Will you focus on:</p>

                <ul>
                    <li> - <span class="font-bold">A period or event?</span> Perhaps you are interested in the role of images at specific moments? Coronations or royal weddings, for example. Or during moments of political crisis? The Exclusion Crisis (1678-82) or the Revolution of 1688 produced many political prints. You may even wish to be more specific and consider one aspect of those sources. The role of Dutch prints in British politics, for example. Searching for Romeyn de Hooghe would be a good place to start.</li>
                    <li> - <span class="font-bold">An artist or publisher?</span> Perhaps you want to focus on the prints made by one engraver or publisher. Will you trace developments? Explore themes in their work? Or, perhaps, place them in an artistic or cultural context? There are plenty of engravers to choose from - the <a class="text-blue-600 dark:text-blue-500 hover:underline" href="/resources/directories" target="_blank"> Printmakers directory</a> is a good place to look. Alternatively, you may want to think about how the work of a painter (Godfrey Kneller or Peter Lely, perhaps) was disseminated in prints. </li>
                    <li> - <span class="font-bold">A theme or topic?</span> Perhaps you are interested in how early modern people understood their world. The subject search will help you here. You will be able to find prints depicting the natural world (plants, flowers, animals, birds, agriculture, and so on). These make up a significant part of the archive. Perhaps you are interested in how ethnicity or nationality was understood in the period? Prints depicting non-Europeans and different European nationalities will be a useful way in thinking about this. </li>
                </ul>
                <p class="py-4">
                    This approach allows you to identify a discrete body of source material quite quickly. This will give your research project definable parameters. The next stage will be to move on to analysing and contextualising the sources. This is the trickier (and more fun) part of the research process, and there are plenty of <a class="text-blue-600 dark:text-blue-500 hover:underline" href="/resources" target="_blank">resources</a> on this website to help. The first step is to establish what it is you want to know? What questions are you asking of the prints?</p>

                <h3 class="mt-2 text-1xl  italic tracking-tight text-gray-900"> Using prints alongside other sources</h3>

                <p class="py-4">
                    The bpi material might also be used to complement other types of primary sources. Frontispieces might help you study to works of a particular author, for example. Religious imagery might shed a new light on the Reformations. Images here might not be the main thrust of your project but using them alongside other sources will be fruitful. A research project on a political controversy might draw on texts (pamphlets or books), ballads, and printed images - weaving a range of sources together in one analysis will provide a richer and fuller sense of the debate.</p>
                <p class="py-4">
                    The question to ask is what visual sources might add to your research project. For example, perhaps your research project considers the history of gender. Visual sources will allow you to think about how masculinity and femininity were understood, represented, and expressed this period. Portraiture is one type of source we can use to study this. How are men and women portrayed? How does this vary by social rank? Were these idealistic depictions and, if so, what might this tell us? Conversely, your project may also consider how gender was used to represent ideas or concepts. How and why were virtues, senses, and emotions depicted in gendered ways? </p>


                <h3 class="mt-2 text-2xl font-bold tracking-tight text-gray-900">Researching prints</h3>

                <p class="py-4">
                    Once you have designed your research project and identified the prints you would like to study the next stage is to think about the questions you will ask of your sources. In one sense, images are no different from other types of sources you use as historians, and you should consider the following:</p>

                <ul>
                    <li> - <span class="font-bold">Intention:</span> Why was the image produced? What was the artist trying to do? Why did people want to buy it or look at it? Does the tone of the image (satirical, polemical, memorial, and so on) help us to answer these questions?</li>
                    <li> - <span class="font-bold">Content:</span> Who and/or what is depicted? Can you unpick the image? </li>
                    <li> - <span class="font-bold">Audience:</span> Who was the image for? How might it have been viewed?  </li>
                    <li> - <span class="font-bold">Context:</span> Was the image part of a genre of prints? Did it conform to iconographic conventions? Was it produced at a specific political or cultural moment? Did it relate to other prints or images, and how did they inform it?</li>
                </ul>

                <p class="py-4">
                    Each entry in the bpi archive contains bibliographic information that will be important in answering these questions. Compiling information on the who, what, and when of a print may help you to answer the why. As such, establishing when a print was made and by who will be essential groundwork for your research. After that, it will be worth considering if other art historians/historians have written about any of your sources. The <a class="text-blue-600 dark:text-blue-500 hover:underline" href="/research" target="_blank">research</a> and <a class="text-blue-600 dark:text-blue-500 hover:underline" href="/resources" target="_blank">resources</a> section of this websites provides a useful list of places to look. </p>

                <h3 class="mt-2 text-2xl font-bold tracking-tight text-gray-900">Other resources</h3>

                <p class="py-4">
                    The <a class="text-blue-600 dark:text-blue-500 hover:underline" href="/prints" target="_blank">Prints in Focus</a> section provides examples of how prints might be used as a way into early modern society and culture. The <a class="text-blue-600 dark:text-blue-500 hover:underline" href="#" target="_blank">blog</a> also includes discussions of how to use images in historical research. </p>

            </div>
          </div>
          <div class="text-center pb-8">
            <button type="button" @click="viewResearchPage()" @keydown.enter="viewResearchPage()" class="bg-slate-500 hover:bg-slate-600 text-white font-bold py-2 px-4 mt-4 mb-4 rounded focus:outline-none focus:ring focus:ring-white inline-flex items-center w-40">Back to Research
            </button>
          </div>
      </section> 
      <SimpleFooter />
    </div>    
</template>

<script>
   
import HomeBanner from '../components/HomeBanner.vue';
import NavMenu from '../components/NavMenu.vue';
import SimpleFooter from '../components/SimpleFooter.vue';
import HomeLink from '../components/HomeLink.vue';

export default {
  data() {
      return {};
  },
  components: { 
    HomeLink,
    HomeBanner, 
    NavMenu,
    SimpleFooter
  },
  methods: {
    viewResearchPage(){
        this.$router.push({name: 'Research'});
    }
    }
}
</script>