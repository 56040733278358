import { defineStore } from 'pinia'
import { useLocalStorage } from "@vueuse/core"

export const useBPIStore = defineStore('BPIs', {
    state: () => { 
        return {
            BPI_array: useLocalStorage('BPI_array', []),
            BPI_list: useLocalStorage('BPI_list', []),
            BPI_index: useLocalStorage('BPI_index', 0),
            BPI_pages: useLocalStorage('BPI_pages', 0) 
        }
     },
    getters: {
      getPageNum(state) {
        return state.BPI_pages.length;
      }
    },
    actions: {
      // BPI_array is a de-duped list of results
      addToBPIArray(array) {
        this.BPI_array.push(array);
        this.flattenBPIArray();
        this.reduceBPIArray();
        this.sortBPIArray();

        const chunk = (arr, size) =>
        Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
          arr.slice(i * size, i * size + size)
        );
        // split into chunks of 20 
        this.BPI_pages = chunk(this.BPI_array, 20);
        this.page_count = this.BPI_pages.length;
      },
      addToBPIList(bpi_list){
        this.BPI_list = bpi_list;
      },
      clearBPI(){
        this.BPI_array = [];
      },
      clearBPIList() {
        this.BPI_list = [];
      },
      clearBPIPages() {
        this.BPI_pages = 0;
      },
      clearBPIIndex() {
        this.BPI_index = 0;
      },
      flattenBPIArray(){
        this.BPI_array = this.BPI_array.flat(1);
      },
      reduceBPIArray(){
        const uniqueBPIArray = new Set(this.BPI_array);
        this.BPI_array = [...uniqueBPIArray];
      },
      sortBPIArray() {

        // sort algorithm here
        this.BPI_array.sort();
      },
      getBPIChunk(i){
        return this.BPI_pages[i];
      },
      getBPIList(){
        return this.BPI_list;
      },
      updateBPIIndex(num) {
        return this.BPI_index = num;
      }  
    }
})