<template>
 <div>
        <h3 class="py-6 text-3xl">Submarine</h3>
        <h4 class="py-2 text-xl">No.17 January/February 2008 </h4>
    </div>
    <div class='relative text-left overflow-y-auto'>
      <p class="py-2"> <strong>Copyright of the Trustees of the British Museum.</strong> </p>
      <p class="pt-2 pb-4"> <strong>British Museum 1842,0806.372. Dimensions of original: 149 mm x 227 mm.</strong></p> 
        <span class="float-left pr-6 pb-2"><img :src="thumbnail" width="200"/> <button type="button" @click="getlargerImage()" class="underline font-bold" >View larger image</button></span> 

        <p class="pb-2">Datable to the time of the First Anglo-Dutch War is a most interesting sheet depicting a type of submarine (really, a semi-submerged ram) built in Rotterdam by the Frenchman De Son in 1653. The English print, <span class="italic">The true and perfect forme of the Strange Ship built at Rotterdam Anno 1653</span>, which though unsigned is attributed to Hollar,<sup>i</sup> clearly copies an original engraved by Frederik de Witt and held uniquely in the Maritime Museum in Rotterdam, entitled <span class="italic">Perfecte Afbeeldinge: van 't Wonderlycke Schip, gemaakt tot Rotterdam</span>. 1653.<sup>ii</sup> Comparison of the texts of the English and Dutch versions is revealing. The original claims that the wonderful ship could reach the East Indies in six weeks, and in a single day get to France and back—on the English sheet this latter claim has been significantly altered to read, '[She] can goe from Rotterdam to London and back againe in one day', and is immediately preceded by the ominous 'the inventor doeth undertake in one day to destroy a honderd Ships'. The message was clear—here is a dastardly secret weapon that the Dutch are about to unleash on English shipping! But the English need not have worried, for, in the event, the vessel never saw action, as De Son was unable to find a clockwork motor powerful enough to drive its internal paddle-wheel.  </p>

        <p class="pb-2">The accompanying text is as follows:</p>

        <p class="pb-2">
        'The true & perfect forme of the Strange Shipp built at Rotterdam Anno 1653[;] the inventor of it doeth undertake in one day to destroy one honderd Ships, can goe from Rotterdam to London and back againe in one day: & in 6 Weekes to goe to the East Indiens, and to run as Swift as a bird can fly[:] no fire, nor Storme or Bullets can hinder her unlesse it please God, Although the Ships meane to bee safe in their havens, it is in vaine. for shee shall come to them in any place, it is impossible for her to bee taken, unlesse by treacherie, and then can not bee governed by any but himselfe, the length is 72, the height is 12 foote, the breadth 8 foote, 1. The middle beame, 2. the two ends with Iron barrs, wherein the Strength of the Ship lieth on both ends alike. 3. the rudder of the Ship, 4. the kiele, 5. Iron boults with scrues, 6. the depth to the middle beame, 7. The wheele that goeth round by which it hath its motion, 8. the Shuttles where they goe in, 9. the Gallery there they walke,' 
        </p>

        <h4 class="py-2 text-xl font-bold">Footnotes</h4> 
        <ul class="list-[lower-roman] list-inside">
          <li class="py-2">A. Griffiths and G. Kesnerova, <cite>Wenceslaus Hollar: Prints and Drawings from the collections of the National Gallery, Prague, and the British Museum</cite> (London, 1983), no. 93</li>
          <li class="py-2">See J.R. Paas, <cite>The German Political Broadsheet</cite>, 1600-1700 (Wiesbaden, 1994), PA453.  </li>
        </ul> 
  </div>   
</template>

<script>
    export default {
        data() {
          return { 
            imagePath : 'https://britishprintedimages.blob.core.windows.net/input-images/printsinfocus/',
            thumbnail : '',
            largeImage : ''
          }
        },
        methods: {
          getlargerImage() {
                let currentImagePath = this.imagePath + 'Submarine.png' 
                window.open(currentImagePath, "_blank");
            },
        },
        created() {
          this.thumbnail = this.imagePath  + '20.jpg' 
        }
    }
  
  </script>
