<template>
   <div class="relative container mx-auto px-20 bg-gray-200">
      <HomeLink />
      <HomeBanner />
      <NavMenu />
      <section class="bg-white dark:bg-gray-900 p-8">
    <div class='text-center'>
         <ImageADRTA v-if="currentImage==1"></ImageADRTA>
         <ImageCCBASBR v-if="currentImage==2"></ImageCCBASBR>
         <ImageCOPIM v-if="currentImage==3"></ImageCOPIM>
         <ImageCWCW v-if="currentImage==4"></ImageCWCW>
         <ImageCF v-if="currentImage==5"></ImageCF>
         <ImageDP v-if="currentImage==6"></ImageDP>
         <ImageEOED v-if="currentImage==7"></ImageEOED>
         <ImageEMPED v-if="currentImage==8"></ImageEMPED>
         <ImageFO v-if="currentImage==9"></ImageFO>
         <ImageHAM v-if="currentImage==10"></ImageHAM>
         <ImageIMQD v-if="currentImage==11"></ImageIMQD>
         <ImageJD v-if="currentImage==12"></ImageJD> 
         <ImageLSDOAO v-if="currentImage==13"></ImageLSDOAO> 
         <ImageMOTMOS v-if="currentImage==14"></ImageMOTMOS>
         <ImageMay v-if="currentImage==15"></ImageMay>
         <ImageNYG v-if="currentImage==16"></ImageNYG>
         <ImageROC v-if="currentImage==19"></ImageROC>
         <ImageSUB v-if="currentImage==20"></ImageSUB>
         <ImageSF v-if="currentImage==21"></ImageSF>
         <ImageSCEMHD v-if="currentImage==22"></ImageSCEMHD>
         <ImageTAR v-if="currentImage==23"></ImageTAR>
         <ImageTCFC v-if="currentImage==24"></ImageTCFC>
         <ImageTT v-if="currentImage==25"></ImageTT>
         <ImageTOML v-if="currentImage==26"></ImageTOML>
         <ImageWW v-if="currentImage==27"></ImageWW>
         <ImageWAMOTFE v-if="currentImage==28"></ImageWAMOTFE>
         <ImageYCP v-if="currentImage==29"></ImageYCP>

    </div>
    </section>
    <div class="text-center">
      <button type="button" @click="backToPrintsInFocus()" @keyboard.enter="backToPrintsInFocus()" class="bg-slate-500 hover:bg-slate-600 text-white font-bold py-2 px-4 mt-4 mb-4 rounded focus:outline-none focus:ring focus:ring-white inline-flex items-center w-40">Back to Prints in Focus
      </button>
     </div>
    <SimpleFooter />
  </div>
</template>

<script>
import HomeBanner from '../components/HomeBanner.vue';
import NavMenu from '../components/NavMenu.vue';
import SimpleFooter from '../components/SimpleFooter.vue';
import HomeLink from '../components/HomeLink.vue';
import ImageADRTA from '../components/printsinfocus/ImageADRTA.vue';
import ImageCCBASBR from '../components/printsinfocus/ImageCCBASBR.vue';
import ImageCF from '../components/printsinfocus/ImageCF.vue';
import ImageCOPIM from '../components/printsinfocus/ImageCOPIM.vue';
import ImageCWCW from '../components/printsinfocus/ImageCWCW.vue';
import ImageDP from '../components/printsinfocus/ImageDP.vue';
import ImageEOED from '../components/printsinfocus/ImageEOED.vue';
import ImageEMPED from '../components/printsinfocus/ImageEMPED.vue';
import ImageFO from '../components/printsinfocus/ImageFO.vue';
import ImageHAM from '../components/printsinfocus/ImageHAM.vue';
import ImageIMQD from '../components/printsinfocus/ImageIMQD.vue';
import ImageJD from '../components/printsinfocus/ImageJD.vue';
import ImageLSDOAO from '../components/printsinfocus/ImageLSDOAO.vue';
import ImageMay from '../components/printsinfocus/ImageMay.vue';
import ImageNYG from '../components/printsinfocus/ImageNYG.vue';
import ImageMOTMOS from '../components/printsinfocus/ImageMOTMOS.vue';
import ImageROC from '../components/printsinfocus/ImageROC.vue';
import ImageSUB from '../components/printsinfocus/ImageSUB.vue';
import ImageSF from '../components/printsinfocus/ImageSF.vue';
import ImageSCEMHD from '../components/printsinfocus/ImageSCEMHD.vue';
import ImageTAR from '../components/printsinfocus/ImageTAR.vue';
import ImageTCFC from '../components/printsinfocus/ImageTCFC.vue';
import ImageTT from '../components/printsinfocus/ImageTT.vue';
import ImageTOML from '../components/printsinfocus/ImageTOML.vue';
import ImageWW from '../components/printsinfocus/ImageWW.vue';
import ImageWAMOTFE from '../components/printsinfocus/ImageWAMOTFE.vue';
import ImageYCP from '../components/printsinfocus/ImageYCP.vue';

export default {
    name: "PrintsInFocusView",
    components: {
      ImageADRTA,
      ImageCCBASBR,
      ImageCF,
      ImageCOPIM,
      ImageCWCW,
      ImageDP,
      ImageEOED,
      ImageEMPED,
      ImageFO,
      ImageHAM,
      ImageIMQD,
      ImageJD,
      ImageLSDOAO,
      ImageMay,
      ImageMOTMOS,
      ImageNYG,
      ImageROC,
      ImageSUB,
      ImageSF,
      ImageSCEMHD,
      ImageTAR,
      ImageTCFC,
      ImageTT,
      ImageTOML,
      ImageWW,
      ImageWAMOTFE,
      ImageYCP,
      HomeLink,
      HomeBanner, 
      NavMenu,
      SimpleFooter
    },
    data() {
      return { 
       currentImage : 0
      }
    },
    methods: {
        backToPrintsInFocus() {
        this.$router.push('/prints');
      }
    },
    created() {
        this.currentImage = this.$route.params.id;
    },
  }
    
  </script>