<template>
 <div>
        <h3 class="py-6 text-3xl">'The Embleme of Englands distractions as also of her attained, and further expected Freedome, & Happines' (1658), traditionally known as 'Cromwell between Two Pillars'</h3>
        <h4 class="py-2 text-xl">No.1  August 2006</h4>
    </div>
    <div class='relative text-left overflow-y-auto'>
      <p class="py-2"> <strong>Copyright of the Trustees of the British Museum.</strong> </p>
      <p class="pt-2 pb-4"> <strong>British Museum 1848,0911.242. Second state. Dimensions of original: 562 mm x 422 mm.</strong></p> 
        <span class="float-left pr-6 pb-2"><img :src="thumbnail" width="200"/> <button type="button" @click="getlargerImage()" class="underline font-bold" >View larger image</button></span> 

        <p class="pb-2">Shortly before the death of the Lord Protector, Oliver Cromwell, William Faithorne (c.1620-91) engraved a portrait of Cromwell standing in Glory, after a drawing by Francis Barlow (d. 1704), and against a background of emblems. It is a superb large sheet entitled, <span class="italic">The Embleme of Englands Distractions As also of her attained, and further expected Freedome, & Happiness Per H M 1658</span>. </p>

        <p class="pb-2">Oliver stands holding a sword piercing three crowns upraised in one hand, an open book in the other, and tramples with one foot between the bare breasts of the prostrate Whore of Babylon, who pours the contents of her 'cup of abominations' over a hydra-headed serpent labelled <span class="italic">Error</span> and <span class="italic">Faction</span>, which his other foot pins to the ground. Above his head are the dove bearing the olive branch of peace (which also puns on his name), and a glory, indicating divine approval. He stands, himself a pillar of the State, like a latter-day Hercules between two columns, on one of which allegorical figures representing England, Ireland and Scotland offer him laurel wreaths, while the other is composed of the fundamentals of English civil society, Magna Carta, the Rule of Law, etc. </p>

        <p class="pb-2">Three Old Testament vignettes are placed above him: Noah's ark safely arriving through wind and wave (here steering a middle course between rocks anachronistically but Classically identified as Scylla and Charybdis!) to the top of Ararat on which the sun beams down', an example of the popular metaphor of the Ship of State:<sup>i</sup>   Oliver has steered the Ship safely through the trials and tribulations (of Civil War), till it has now come to rest in the sunny uplands of divine favour. Somewhat more puzzling, however,' even sinister' is the third scene, the Sacrifice of Isaac, with Jacob his sword poised about to decapitate the boy - surely this cannot allude to the beheading of Charles I some nine years earlier? The summit on which the terrible deed is about to be done is labelled <span class="italic">Moria</span> - unless this Greek name (famously and punningly used by Sir Thomas More in his <span class="italic">Encomium Moriae</span> or Praise of Folly) was instead intended not as a place-name, but as an allusion to the folly of such an act?</p>

        <p class="pb-2">The bottom of the sheet is filled with several emblematic scenes of peace and prosperity (left), and machinations against the state (right). A shepherd pipes to his sheep beneath another punning olive tree, labelled <span class="italic">Oliva Pacis</span> ['the olive of peace', but also, 'Oliver's peace'], and the Isaian prophecy, <span class="italic">They shall beat their Speares into Pruneing-hooks And their Swords into Plow-shears</span>, is illustrated by the peaceful occupations of cutting grape-bunches from an unconvincing, tree-like vine with a pruning-hook, and a man using a horse-drawn plough. Perhaps most interesting, however, is the last of these peaceful emblems, the war-helmet which has become used as a hive by bees; already 150 years old by this date, it first appears in England in Geoffrey Whitney's <span class="italic">A Choice of Emblemes</span> (1586), but there derives from Andrea Alciati's original emblem-book of 1531. Closer in date to Faithorne's print of Cromwell, it had also appeared in George Wither's <span class="italic">Collection of Emblemes</span> (1635). </p>

        <p class="pb-2">In the bottom righthand corner of the sheet two bonneted Jesuits are depicted, one carrying a dark lantern, a man with bellows trying to set light to barrels of gunpowder, and Samson's trick of a pair of foxes yoked by the tails about to fire a cornfield', some of which imagery recurs a few years later in another print entitled <span class="italic">Pyrotechnica Loyalana, Ignatian fireworks</span> (1667). A gallows with noose is labelled <span class="italic">Proditorum finis funis</span> [The rope is the end of traitors]. The gunpowder is placed within a cavern of the rock on which the righthand pillar featuring the representations of England, Scotland and Ireland stands, and is also attacked by a number of men (one with a dog's head) wielding pickaxes' literal attempts to undermine the state, presumably. The final scene which remains mysterious to me, is of three rustics, one with pitchfork, approaching a small copse at the foot of the same rock.<sup>ii</sup>   </p>

        <p class="pb-2">Ironically, in the fifth state of the plate (1690), the head was changed to that of William of Orange, while the head of Queen Mary tops the lefthand pillar. </p>

        <p class="pb-2">For an authoritative discussion of the various states of this engraving, see Antony Griffiths, <span class="italic">The Print in Stuart Britain</span>, 1603-89 (London, 1998), cat. no. 115. Griffiths notes that 'It has been suggested that “H.M.” [in the title] might be the publisher Humphrey Moseley' (op. cit., p. 176). </p>


        <h4 class="py-2 text-xl font-bold">Footnotes</h4> 
        <ul class="list-[lower-roman] list-inside">
          <li class="py-2">The outside of the lid of the so-called Armada or Heneage jewel made some time in the second half of the 1580s similarly depicts the ark of the English Church on a stormy sea surrounded by the inscription SAEVAS TRANQVILLA PER VNDAS [calm through the savage waves] in allusion to Elizabeth's safeguarding of the church, and it was a device also used by James I on medals: see A.G. Somers Cocks (ed.), <cite>Princely Magnificence. Court Jewels of the Renaissance</cite>, 1500-1630 (London, 1980), cat. nos. 38 and 117.  </li>
          <li class="py-2">I have not been able to see Robert R. Wark, <cite>Early British Drawings in the Huntington Collection</cite> (San Marino, 1969), pp. 15-17, who perhaps is able to explicate this curious image.</li>
        </ul> 
  </div>   
</template>

<script>
    export default {
        data() {
          return { 
            imagePath : 'https://britishprintedimages.blob.core.windows.net/input-images/printsinfocus/',
            thumbnail : '',
            largeImage : ''
          }
        },
        methods: {
          getlargerImage() {
                let currentImagePath = this.imagePath + 'Embleme of Englands.png' 
                window.open(currentImagePath, "_blank");
            },
        },
        created() {
          this.thumbnail = this.imagePath  + '7.jpg' 
        }
    }
  
  </script>