<template>
     <section class="bg-white dark:bg-gray-900">
          <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700 bg-white px-6 py-12 pb-6 lg:px-8">
            <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">British Printed Images Research</h1>

            <div class="flex flex-wrap justify-center bg-white py-10">

              <!-- card 1 -->
              <div class="p-4 max-w-xl">
                <div class="flex rounded-lg h-full dark:bg-gray-800 bg-stone-200 p-8 flex-col">
                    <div class="flex items-center mb-3">
                        <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full dark:bg-slate-600 bg-slate-600 text-white flex-shrink-0">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                            </svg>

                        </div>
                        <h2 class="text-black dark:text-white text-3xl font-medium">Studying</h2>
                        
                    </div>
                    <div class="flex flex-col justify-between flex-grow">
                        <p class="leading-relaxed text-base text-black dark:text-gray-300 py-4 text-left text-lg">
                            A guide to resources that will help you begin research into British prints in the period 1500-1700.
                        </p>
                        <a href="#" @click="viewStudyPage" @keydown.enter="viewStudyPage" class="mt-3 text-black dark:text-white hover:text-blue-600 inline-flex items-center">Learn More
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                <path d="M5 12h14M12 5l7 7-7 7"></path>
                            </svg>
                        </a>
                    </div>
                </div>
              </div>

              <!-- card 2 -->
              <div class="p-4 max-w-xl">
                <div class="flex rounded-lg h-full dark:bg-gray-800 bg-stone-200 p-8 flex-col">
                    <div class="flex items-center mb-3">
                        <div
                            class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full dark:bg-slate-600 bg-slate-600 text-white flex-shrink-0">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12a7.5 7.5 0 0015 0m-15 0a7.5 7.5 0 1115 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077l1.41-.513m14.095-5.13l1.41-.513M5.106 17.785l1.15-.964m11.49-9.642l1.149-.964M7.501 19.795l.75-1.3m7.5-12.99l.75-1.3m-6.063 16.658l.26-1.477m2.605-14.772l.26-1.477m0 17.726l-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205L12 12m6.894 5.785l-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864l-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495" />
                        </svg>

                        </div>
                        <h2 class="text-black dark:text-white text-3xl font-medium">Scholarship</h2>
                    </div>
                    <div class="flex flex-col justify-between flex-grow">
                        <p class="leading-relaxed text-base text-black dark:text-gray-300 py-4 text-left text-lg">
                            A guide to academic research into British prints in the period 1500-1700.
                        </p>
                        <a href="#" @click="viewScholarshipPage" @keydown.enter="viewScholarshipPage" class="mt-3 text-black dark:text-white hover:text-blue-600 inline-flex items-center">Learn More
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                <path d="M5 12h14M12 5l7 7-7 7"></path>
                            </svg>
                        </a>
                    </div>
                </div>
              </div>
               <!-- card 3 -->

               <div class="p-4 max-w-xl">
                <div class="flex rounded-lg h-full dark:bg-gray-800 bg-stone-200 p-8 flex-col">
                    <div class="flex items-center mb-3">
                        <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full dark:bg-slate-600 bg-slate-600 text-white flex-shrink-0">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5" />
              </svg>

                      </div>
                      <h2 class="text-black dark:text-white text-3xl font-medium">Dissertations</h2>
                  </div>
                  <div class="flex flex-col justify-between flex-grow">
                      <p class="leading-relaxed text-base text-black dark:text-gray-300 py-4 text-left text-lg">
                        Discover tips on how to use prints in your UG/PG dissertation.
                      </p>
                      <a href="#" @click="viewDissertationsPage" @keydown.enter="viewDissertationsPage" class="mt-3 text-black dark:text-white hover:text-blue-600 inline-flex items-center">Learn More
                          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                              stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                              <path d="M5 12h14M12 5l7 7-7 7"></path>
                          </svg>
                      </a>
                  </div>
              </div>
            </div>

          </div>
        </div>
      </section>  
    
</template>

<script>
   

export default {
  data() {
      return {};
  },
  methods: {
    viewStudyPage(){
        this.$router.push({name: 'Study'});
    },
    viewScholarshipPage() {
        this.$router.push({name: 'Scholarship'});
    },
    viewDissertationsPage() {
        this.$router.push({name: 'Dissertations'});
    }
  }
}
</script>