<template>
  <div>
         <h3 class="py-6 text-3xl">The Hunting after Money</h3>
         <h4 class="py-2 text-xl">No.15 November 2007 </h4>
     </div>
     <div class='relative text-left overflow-y-auto'>
       <p class="py-2"> <strong>Copyright of the Trustees of the British Museum.</strong> </p>
       <p class="pt-2 pb-4"> <strong>British Museum 1850,1109.15 [BM Sat 802]. Dimensions of original: 235 mm x 328 mm</strong></p> 
         <span class="float-left pr-6 pb-2"><img :src="thumbnail" width="300"/> <button type="button" @click="getlargerImage()" class="underline font-bold" >View larger image</button></span> 
      
 
         <p class="pb-2">We do not know whether the present image, which is signed by the engraver Thomas Cross (active 1644-82), was accompanied by any text when it was originally published, presumably in the seventeenth century (stylistically, it looks as if it must belong to the earlier part of Cross's career). As things stand, the earliest extant impression is entitled <span class="italic">The Hunting after Money</span> and bears the imprint, <span class="italic">London: Printed and Sold by Edward Midwinter, at the Star in Pye-Corner near West-Smithfield</span>.<sup>i</sup>  It is undated, but one copy of the two impressions known is priced and dated in Narcissus Luttrell's hand, <span class="italic">2d. 27. August. 1709</span>. Copies of both impressions are at Harvard, one of them—fittingly—in the Baker Library of that University's Graduate School of Business, and it is one of these which is accompanied by a set of verses which are transcribed here, which do not appear in the British Museum version.<sup>ii</sup>   </p>
 
         <p class="pb-2">Thanks to the Literature Online database [LION] I am able to say that approximately one fifth of the verse lines of <span class="italic">The Hunting after Money</span> are taken from Robert Gould's <span class="italic">The corruption of the times by money, a satyr</span>, published in 1693. The lines so used are numbered in the margin against the transcription below. The composer of the verses accompanying our image seems not to have looked at it too carefully, however, for though he writes, <span class="italic">The Poet's Fancy gave it wings to fly, And ev'ry one is Hunting it full cry</span>, which fully accords with the engraving, he then adds <span class="italic">Whether 'thas Legs or no, I cannot say</span>—which is odd seeing that the coin has clearly been given legs by the artist. But he must at least have had a fairly good description to work on, for he writes that <span class="italic">The Smooth-Tongu'd Flatterer drives on amain, Thro' Hazard, and thro' Rapin</span>, hunts for Gain—and Flattery's dogs are indeed so labelled on the sheet—and that <span class="italic">The Frugal Man hunts it with Pain and Toil… But he's too Honest, for you'll always find, That Diligence, and Industry come far behind</span>, again including two of the names of <span class="italic">Frugallity's</span> dogs. </p>
 
         <p class="pb-2">Cross was a prolific engraver of portraits and book-illustrations, though is generally agreed to be a decidedly second-rate artist, and is only known to have engraved one other broadside, the same plate being re-used as the frontispiece to <span class="italic">Symptomes of Growth and Decay in Godliness</span> (1673).</p>
 
         <p class="pb-2">The present engraving depicts an Allegorical Hunt<sup>iii</sup>  in which a winged coin on legs flees from a party hunting it on foot who are labelled, <span class="italic">Frugallity, Flattery, Prodigallity</span> and <span class="italic">Covetousnes</span>, and whose dogs, are named, respectively, <span class="italic">Dilligence, Industry and Labour; Rapin</span> and <span class="italic">Hazard</span>; [<span class="italic">Prodigallity</span> needs no dog, of course, as he is still adequately provided with coin], and <span class="italic">Deceit</span> and <span class="italic">Usury</span>. <span class="italic">Frugallity</span> is so very, proverbially, frugal that he carries his shoes over his shoulder for fear of wearing them out by walking on them,<sup>iv</sup> and <span class="italic">Prodigallity</span> merely tosses coins into the air while trampling on the sword and scales labelled <span class="italic">Justice</span>. </p>
 
         <p class="pb-2">Once again, however—as so often in the history of early modern English prints—this striking allegory is not an original conception, but is copied from a late sixteenth-century engraving, <span class="italic">Currite, nam pretio…</span>, by Hendrick Goltzius, issued in the Netherlands by Philips Galle (d.1612). The Goltzius original was discussed by Ilja Veldman, who notes that the (wingless) coin on legs bears the image of a crowned woman holding a cornucopia while seated on an out-of-kilter world-orb.<sup>v</sup>  The legend inscribed around the coin's perimeter refers to Ecclesiastes 10.19: <span class="italic">Money answereth all things</span>. Cross's winged coin on legs, however, bears a laureate bust facing to the right and a legend which is illegible.<sup>vi</sup>  </p>
 
         <p class="pb-2">The major difference in Cross's adaptation of the scene, however, is that his <span class="italic">Prodigallity</span> is a man walking, not a woman riding, and his <span class="italic">Flattery</span> is similarly walking, whereas in the Goltzius print, <span class="italic">Adulator</span> in cockerel-headed cap kneels before <span class="italic">Prodigalitas</span>, and holds a foxtail duster behind his back (a symbolic detail which was probably quite obscure to Cross).<sup>vii</sup> Cross's <span class="italic">Flattery</span>, though interestingly he retains the cockerel-headed hat [i.e. the coxcomb's cock's comb], is not noticeably flattering anyone in particular! And Goltzius's <span class="italic">Prodigalitas</span> rides down the personification of <span class="italic">Iustitia</span>, whereas Cross's <span class="italic">Prodigallity</span> merely treads on her sword and scales. The character labelled <span class="italic">Frugallity</span> on the English sheet is presumably Cross's translation of Goltzius's label <span class="italic">Parsimonia</span>, and yet that name properly belongs to one of this hunter's dogs. All the other dogs on the Goltzius sheet are named, so it seems certain that this name belongs to the dog, not the man, whose name is surely <span class="italic"></span>Victus et Amictus [Food & Clothing], the label which appears just above his head. Goltzius's <span class="italic">Auaritia</span>, like all such Latin personifications is grammatically, and here, it would seem, humanly, feminine, though Cross's <span class="italic">Covetousnes</span>, about to let slip his dogs <span class="italic">Usury</span> and <span class="italic">Deceit</span>, is a luxuriously-bearded man.</p>
 
         <p class="pb-2">According to Veldman, 'The title of the [Goltzius]print is boldly satirical, <span class="italic">Run as best you may, for money alone matters, money confers honour, money procures friendship, heaven itself may be bought with riches</span>', which she identifies as a conflation of quotations from Ovid's <span class="italic">Fasti</span> and the <span class="italic">Argonautica</span> of Valerius Flaccus, while 'The lines of French and Dutch verse beneath the print explain that each pursuer will always have reasons of his own for chasing after the money. It becomes clear, however, that the poor devil striving to achieve wealth through honest toil will always come off worst.' The writer of the verses to our engraving rehearses the same complaint: <span class="italic">The Frugal Man hunts it with Pain and Toil… But he's too Honest, for you'll always find, That Diligence, and Industry come far behind; He Labours hard, but Knaves outstrip him far</span>.</p>
 
         <p class="pb-2">There is a French print on the same theme, roughly contemporary with ours, entitled <span class="italic">La Chasse de 'Mon Oye'</span> [The hunt after 'my goose']. It depicts a number of people chasing a goose [<span class="italic">oye</span>], punning on the word for money [<span class="italic">monoye</span>, older form of modern French, monnaie]. There is an impression in the Douce print collection now in the Ashmolean Museum, Oxford; it bears the imprints, <span class="italic">A Paris chez P. Mariette rue S. Iacques a lesperance and A Paris chez P. Ferdinand, au Fauxbourg S. Germain, r.d. Seine</span>, the latter apparently over an earlier imprint.</p>
 
         <p class="pb-2">
         TRANSCRIPTION
         </p>

         <p class="pb-2"> 
         When the bright World was fram'd, and all Things plac'd,<br>
         In perfect Order, that before lay wast:<br>
         The Sea in Bounds, the Earth its Teeming knew,<br>
         And all her Fruitful Product kindly grew:<br>
         The World was Happy, no Corruption found,<br>
         But did with ev'ry useful Thing abound;<br>
         Till Satan, who lay Lurking still to find,<br>
         Some Way to prey on, and seduce Mankind,<br>
         Invented Money to corrupt the Mind.<br>
         <br> 
         The Bait was swallow'd, Money had the Name,<br>
         Then Theft and Rapin soon in Fashion came.<br>
         Friend did his Friend, Neighbour his Neighbour cheat,<br>
         And all the Wold [sic] grew Knavery and Deceit:<br>
         No Vice there was, but Money wou'd procure;<br>
         Cut Throats, or turn the honest Damsel Whore.<br>
         Money] MONEY, of all grand Evils sure the worst,<br>
         Without it Discontent, and with it Curst;<br>
         <br> 
         For where one hoards it to a Happy Use,<br>
         Ten Thousand do the Blessing on't abuse:<br>
         Strange Chams<sup>viii</sup> that Men should such a Value place,<br>
         To be in Love with Caesar's Royal Face;<br>
         Else where's the Intrinsick Value to be seen,<br>
         Unless to purchase ev'ry kind of Sin.<br>
         <br>
         What Guilt, what Mischief, Cruelty and Rage?<br>
         Has powerful Gold produc'd in ev'ry Age!<br>
         Rob, Trick and Cheat, do ev'ry Thing that's ill;<br>
         Be Rich and be as Wicked as you will.<br>
         Take but a View of all that you can see,<br>
         Of each Religion, Calling and Degree;<br>
         Lords, Lacqueys, Juglers, Courtiers, Knaves and Fools,<br>
         Punks, Players, Pimps, and Bawds, withal the Shoal<br>
         Of busie Tradesmen that Encompass Pauls:<br>
         Mark to what Centre all their Actions tend,<br>
         And see if Money's not their only End.<sup>ix</sup><br>
         <br>
         The Poet's Fancy gave it wings to fly,<br>
         And ev'ry one is Hunting it full cry:<br>
         Whether 'thas Legs or no, I cannot say,<br>
         But this I'm sure 'twill run like Dirt away.<br>
         <br>
         Covetious [sic] ] See, with Pains, each gaping Wretch pursues<br>
         The glitt'ring Bait, fearing the Prize to lose:<br>
         The Miser with Extortion and Deceit,<br>
         Hunts after Gain, and hopes to hoard more yet;<br>
         Half starves his Carcass to encrease his Store,<br>
         And in the midst of his Plenty Poor;<br>
         And tho' by Fraud he has purchas'd an Estate,<br>
         Labours for more, and does but beg in State.<br>
         All Worldly Int'rests does to Heav'n prefer,<br>
         And to his crowded Bags makes all his Prayer;<br>
         As tho' his Mony would his Crimes defray,<br>
         Or bribe his Jury on the Judgment Day:<br>
         Then will he feel, and feeling rue, how vain<br>
         It was to trust in Curst Ill gotten Gain.<br>
         When Death's grim Summons he must strait obey,<br>
         Nor bear the Wealth he so admires away.<br>
         <br>
         Flattery] The Smooth-Tongu'd Flatterer drives on amain,<br>
         Thro' Hazard, and thro' Rapin, hunts for Gain;<br>
         He's next in Scent, for wicked Ways thrives best,<br>
         A subtle Knave will soonest feather his Nest,<br>
         By Bribes and Flattery, tho' he Thousands gets,<br>
         Yet scorns in all his Pride to pay his Debts:<br>
         Haughty, yet condescends to crush the Poor,<br>
         To cram his Belly, or to pay his Whore.<sup>x</sup><br>
         <br> 
         Frugality] The Frugal Man hunts it with Pain and Toil,<br>
         And hopes to get a Share too of the Spoil;<br>
         But he's too Honest, for you'll always find,<br>
         That Diligence, and Industry come far behind;<br>
         He Labours hard, but Knaves outstrip him far;<br>
         Villains and Fool, still Fortunes Fav'rites are.<br>
         A modest Man shall be Carrest,<br>
         But still be Poor, and by the World Opprest.<br>
         <br>
         Prodigal] But here comes one that contradicts 'em all,<br>
         That Golden Ass, that Spenthrift Prodigal!<br>
         Preposterous Fop! That thinks it an abuse<br>
         To put his Money to its proper Use;<br>
         See how he deals it out, as he comes on,<br>
         With both Hands too, as tho' 'twould ne'er be gone;<sup>xi</sup> <br>
         Money is Dirt he cries, he never stands on't,<br>
         And which he is resolv'd to Wash his hands on:<br>
         To Plays, and Taverns, daily he resorts,<br>
         And squanders the Estate his Father got;<br>
         His Taylor, Mercer, Sempstress, and his Whore,<br>
         Help in the End to make the Coxcomb Poor;<br>
         Besides his Coach, his Horses, and his Slaves,<sup>xii</sup> <br>
         His Bawds and Pimps, and daily flattering Knaves.<br>
         <br>
         What English Land, or Indian Mine can last,<br>
         When the vain Prodigal does spend so fast?<br>
         Rich tho' he be, when so to Vice inclin'd,<br>
         He blazes like a Candle in the Wind;<sup>xiii</sup><br>
         And gratifying all his loose Desires,<br>
         Is melted down, and in a Snuft expires:<br>
         Tho' Wealth and Power do in the front appear,<br>
         Want, and a Goal,<sup>xiv</sup> do still bring up the Rear:<br>
         A Prison is the inevitable Lot,<br>
         Of an extravant<sup>xv</sup> and headless Sot.<br>
         <br>
         Oh Money! Money! Thou strange tempting Thing,<br>
         The glit[t]ering, Source, whence all our Evils spring;<br>
         Had we ne'er known thy Name, we'd Happy been,<br>
         Nor ever known so many Ways to Sin.<br>
         With use of Money, use of Friend began,<br>
         And then 'twas first that Man did ruin Man:<br>
         Whilst Poverties, the only Blessing sent<br>
         From Heaven, if attended with Content.<sup>xvi</sup> <br>  
         An Honest Mind is to be valued more<br>
         Than the Rich Miser with his glittering Store.
         </p>
 
         <h4 class="py-2 text-xl font-bold">Footnotes</h4> 
         <ul class="list-[lower-roman] list-inside">
           <li class="py-2">Though unaware of the text-accompanied version, Stephens entitled the engraving, <cite>The Chase after Money</cite>. See F.G.Stephens, <cite>Catalogue of Prints and Drawings in the British Museum: Satirical and Personal Subjects</cite>, vol. 1 (London, 1870), p. 443 (BM Sat 802).  </li>
           <li class="py-2">I have not seen <cite>A hue and cry after mony catch it if you can</cite> ('[London, ?1715]': ESTC N37962), with verses opening, <cite>O what a strang [sic] confused world is here</cite>, uniquely in The National Archives [SP. 46/170], though it perhaps uses the same plate. </li>
           <li class="py-2">For the earlier medieval history of such allegorical hunts, see M. Thiebaux, <cite>The Stag of Love</cite> (New York, 1974). </li>
           <li class="py-2">Carrying one's shoes to save shoe-leather is a traditional, absurd niggardliness motif—cf. “Townsfolk were not surprised to discover he had been seen walking barefoot on Ronalds Moor, to save shoe leather!” from “The Bradford Miser [John Turner]” in M. Campbell, <cite>Curious Tales of Old West Yorkshire</cite> (Wilmslow, 1999). </li>
           <li class="py-2">I.M. Veldman, 'Images of Labor and Diligence in Sixteenth-Century Netherlandish Prints', <cite>Simiolus</cite>, 21 (1992), pp. 227-64, esp. pp. 253-4. </li>
           <li class="py-2">This also baffled Barrie Cook of the British Museum's Dept. of Coins and Medals who noted that 'The only laureate head coinage available around this time was the gold laurel (the pound coin) of James I's third coinage (1619-25)… This would be a fairly good match, and the coin-would still have been available, if not particularly common, in the 1650s. The only problem is that it was a left-facing bust…' (pers. comm., 10 April 2000.) </li>
           <li class="py-2">I discuss the flattery connotations of the foxtail in M. Jones, 'Fiddlers on the Roof and Friars with Foxtails', in W. Mieder (ed.), <cite>The Netherlandish Proverbs. An International Symposium on the Pieter Brueg(h)els</cite> (Burlington, 2004), pp. 163-94.  </li>
           <li class="py-2">Sic, perhaps for Chans = Chance, or Charms.</li>
           <li class="py-2">The last 5 lines in this stanza are taken from Robert Gould, <cite>The corruption of the times by money, a satyr</cite> (London, 1693), lines 48-52.</li>
           <li class="py-2">Last 2 lines of stanza from Gould, <cite>Corruption</cite>, lines 619-20. </li>
           <li class="py-2">Lines 3-4 of stanza from Gould, <cite>Corruption</cite>, lines 557-8 and lines 5-6 from lines 560-1. </li>
           <li class="py-2">This line from Gould, <cite>Corruption</cite>, line 573. </li>
           <li class="py-2">First 4 lines of stanza from Gould, <cite>Corruption</cite>, lines 577-80.</li>
           <li class="py-2">Sic: i.e. Gaol, Jail. </li>
           <li class="py-2">Sic: i.e. heedless. The last 5 lines of this stanza are from Gould, <cite>Corruption</cite>, lines 581-5. </li>
           <li class="py-2">Lines 5-6 of stanza from Gould, <cite>Corruption</cite>, lines 26-7 and lines 7-8 from lines 662-3.</li>
         </ul> 

   </div>   
 </template>
 
 <script>
     export default {
         data() {
           return { 
             imagePath : 'https://britishprintedimages.blob.core.windows.net/input-images/printsinfocus/',
             thumbnail : '',
             largeImage : ''
           }
         },
         methods: {
           getlargerImage() {
                 let currentImagePath = this.imagePath + 'Hunt after money.png'; 
                 window.open(currentImagePath, "_blank");
             },
         },
         created() {
           this.thumbnail = this.imagePath  + '10.jpg' 
         }
     }
   
   </script>
 
 