<template>
    <div class="relative container mx-auto px-20 bg-gray-200">
      <HomeLink />
      <HomeBanner />
      <NavMenu />
   
      <section class="bg-white dark:bg-gray-900">
          <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700 bg-white pt-12 pb-6 lg:px-8">
            <h1 class="mt-2 mb-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Techniques</h1>
           
            <span class="float-right pl-6"><img src="@/assets/thm_engravingetching.jpg" ></span>
            <p class="py-4 text-left">Printing is the transferral of ink from a prepared surface (block, plate, or stone) onto paper or other material. There are three main printing processes: </p>

   
            <div class="text-left indent-4 py-6">
              <ul>
              <li><span class="font-bold">Relief printing</span> - ink is applied to the raised part of the surface (i.e. that part which stands in relief from the rest).</li>
              <li><span class="font-bold">Intaglio</span> - (from the Italian for 'incision' or 'engraving'). Ink is applied to grooves cut into the surface. </li>
              <li><span class="font-bold">Planographic</span> - ink is applied to the surface itself (the lithograph being the prime example). Not known in the early modern period.</li>
            </ul>
        
            </div>
            <p class="py-4 text-left">Four printing techniques were used in the 16th and 17th centuries: <span class="italic">woodcut</span> (relief printing), <span class="italic">engraving</span>, <span class="italic">etching</span>, and <span class="italic">mezzotint</span> (intaglio printing).</p>
          </div>
       </section>
      <section class="bg-white dark:bg-gray-900">
        <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700 bg-white px-6 py-4 lg:px-8">     
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl text-left">Woodcut</h2>
            <div class="mt-4 text-left">
              <span class="float-left pr-6"><img src="@/assets/thm_woodcutting.jpg" ></span>
              <p class="py-4">Invented in China in the 9th century and the oldest of the four techniques. Woodblocks were used in Europe from the 13th century, with the first prints on paper appearing in the late-14th. </p>
              <p class="py-4">Blocks were made from soft wood around an inch thick. The design would be drawn on it (or on paper glued to the surface). A woodcutter would cut away the areas around the lines of the design before applying ink to the remaining raised area using a dabber. The image was transferred to paper by stamping it by hand, by rubbing the back of paper laid on top of the block, or, from the 1450s onwards, by use of a press. Because the presses were also used for movable type, it was possible to print images and letterpress text together on a single page of a book. This was not possible in the intaglio processes. Despite this advantage, woodcuts declined in the 17th century relative to intaglio prints that could produce finer and more precise images. </p>
              <p class="py-4">Distinguishing a woodcut from one of the other techniques is straightforward. A woodcut will lack a platemark (the mark around the edges of intaglio prints); lines are more likely to be uneven when compared to intaglio prints; and by it is possible to see that lines have been achieved by cutting around them.</p>
              <span class="float-right pl-6"><img src="@/assets/thm_turnerplate.jpg" ></span>
          
            </div>
          </div>
      </section> 
      <section class="bg-white dark:bg-gray-900"> 
        <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700 bg-white px-6 py-4 lg:px-8">     
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl text-left">Engraving</h2>
            <div class="mt-4 text-left">
              <span class="float-left pr-6"><img src="@/assets/thm_engravingtools.jpg" ></span>
              <p class="py-4">Engraved prints date from the 15th century. Engraving involved cutting a design into a copper plate using a burin, a small steel rod with a sharpened V-shaped point. The completed design consisted of a series of incised lines on the copper plate.  </p>
              <p class="py-4">After warming the plate, ink was applied and worked into the lines. Ink on the plate's surface area was then carefully wiped off and the plate was then placed face up in an intaglio press. A sheet of dampened paper was placed on top of it and cloths placed on top of both to even out the pressure when the plate passed through the press's rollers. The pressure of the rollers forced the paper into the plate's grooves, dragging out the ink. The paper was then hung up to dry and the plate re-inked for a further impression. The pressure of the press caused the platemark, the indented line where the edge of the plate was pushed into the paper.   </p>
              <p class="py-4">Engravings can be distinguished from woodcuts by inspecting the ink, which is usually slightly raised on the paper because of the paper dragging the ink up when passed through the press. Engravings can be identified by the steadiness of the lines, and the fine points at the beginning of lines as the burin is first pushed into the plate and at the end as it is lifted from the plate. </p>
            </div>
          </div>  
      </section> 
      <section class="bg-white dark:bg-gray-900 pb-6">
        <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700 bg-white px-6 py-4 lg:px-8">     
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl text-left">Etching</h2>
            <div class="mt-4 max-w-6xl text-left">
              <span class="float-right pr-6"><img src="@/assets/thm_pouringaquafortis.jpg" ></span> 
              <p class="py-4">Etched prints date from the early 16th century. Etching involved cutting lines into a copper print using acid (rather than a burin, as in engraving). The entire plate was covered evenly with a melted acid-resistant wax ground. After this had cooled and hardened, the etcher used a needle to draw lines through the ground and expose the copper underneath (this was a less laborious than engraving directly into copper). The plate was then immersed in acid which ate into those parts of the plate exposed by the etched lines (which became deeper the longer the plate was immersed). The ground was then removed, and impressions were made from the plate using an intaglio press in the same way as engravings. </p>  
              <p class="py-4">Etchings can be distinguished from engravings by the relative unevenness of their lines caused by the acid not biting evenly. Engraved lines are more formal than etched lines, which often resemble the greater freedom of drawing.</p> 
             
            </div>
          </div>     
      </section>
      <section class="bg-white dark:bg-gray-900 pb-6">
        <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700 bg-white px-6 py-4 lg:px-8">     
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl text-left">Mezzotint</h2>
            <div class="mt-4 max-w-6xl text-left">
              <span class="float-left pr-6"><img src="@/assets/thm_greatexecutioner.jpg" ></span>
              <p class="py-4">Mezzotints date from the mid-17th century. The technique is credited to a German soldier, Ludwig von Siegen, in 1642. The first mezzotints were produced by Prince Rupert in the 1650s. The technique was widely known by the 1660s. </p>  
              <p class="py-4">Unlike engraving and etching, in mezzotint the engraver worked from dark to light. First a rocker (a blade with teeth) was used to roughen the plate. A plate inked in this rough state it would print a rich black. The engraver created the design by smoothing out parts of the plate with a scraper/burnisher. The smoother the area, the less ink it held, and tones could be created: light burnishing of an area printed dark grey, heavy scraping light grey. Inking the plate was a complex task. The printing process that followed was the same as for engravings/etchings. Because they were highly worked mezzotint plates became worn by press more quickly than engravings or etchings, and it was not uncommon for the printmaker to rework the plate during the printing process. </p>
              <p class="py-4">Mezzotints are distinguishable from the other engravings, etching, and woodcuts because of their absence of lines and their tonal quality. The different techniques in the intaglio process could be combined on a single plate: mezzotints often had areas of engraved detail, and many engravers first etched background detail onto a plate before continuing to engrave the design.</p>  
            </div>
          </div>     
      </section>
      <section class="bg-white dark:bg-gray-900 pb-6">
        <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700 bg-white px-6 py-4 lg:px-8">     
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl text-left">Printing, states and impressions</h2>
            <div class="mt-4 max-w-6xl text-left">
              <span class="float-right pr-6"><img src="@/assets/thm_bosseprinting.jpg" ></span> 
              <p class="py-4">The printing matrix (block/plate) could be re-used numerous times, either to create more impressions, or, after reworking, different states.  </p>  
              <p class="py-4">Blocks or plates had almost limitless states. These were the result of some alteration, whether minor reworking or significant re-engraving: indeed, an entire metal plate could be smoothed down and re-used for a completely different image. Woodblocks were more durable than metal plates. Repeated printings eventually wore down copper plates which printmakers had to rework during the process to maintain the image's quality. This applied particularly to mezzotints. A different state of a print often resulted from this reworking of the plate. </p>  
              <p class="py-4">Proofs were another common example of a different state. Several proofs could be made before the final published state was reached, each a way of checking the progress of the design. The most common proofs are prints before lettering was added. Lettering was often added to a print by a specialist lettering engraver and could be extensive, including mottos, scriptural references, a title, explanatory verses, and details of a print's designer (or the artist after whom the design was taken), engraver, and publisher. Latin terms identified the different roles:  </p>  
            </div>
            <div class="text-left indent-4 pt-8 t">
              <ul>
              <li><span class="font-bold">'fecit'</span> - (often abbreviated to 'fec' or 'fe') or 'sculpsit' ('sculp' or 'sc') denoted the engraver or printmaker.</li>
              <li><span class="font-bold">'invenit'</span> - ('inv' or 'in') or 'pinxit' ('pinx') denoted the designer or artist. </li>
              <li><span class="font-bold">'excudit'</span> -	and ('exc' or 'ex') indicated the publisher. </li>
            </ul>
            </div>
          </div>  
          <div class="text-center">
            <button type="button" aria-label="resources" @click="viewResourcesPage()" @keydown.enter="viewResourcesPage()" class="bg-slate-500 hover:bg-slate-600 text-white font-bold py-2 px-4 mt-4 mb-4 rounded focus:outline-none focus:shadow-outline inline-flex items-center w-40">Back to Resources </button>
          </div>
              
      </section>

      <SimpleFooter />
    </div>
  </template>
    
    <script>
   
    import HomeBanner from '../components/HomeBanner.vue';
    import NavMenu from '../components/NavMenu.vue';
    import SimpleFooter from '../components/SimpleFooter.vue';
    import HomeLink from '../components/HomeLink.vue';
  
    export default {
      data() {
          return {};
      },
      components: { 
        HomeLink,
        HomeBanner, 
        NavMenu,
        SimpleFooter
      },
      methods: {
        viewResourcesPage() {
        this.$router.push('/resources')
        },
      }
  }
    </script>