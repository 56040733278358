<template>
 <div>
        <h3 class="py-6 text-3xl">Tittle-Tattle; Or, the several Branches of Gossipping</h3>
        <h4 class="py-2 text-xl">No.5 December 2006 </h4>
    </div>
    <div class='relative text-left overflow-y-auto'>
      <p class="py-2"> <strong>Copyright of the Trustees of the British Museum.</strong> </p>
      <p class="pt-2 pb-4"> <strong>British Museum 1973,U.216. Dimensions of original: 406 mm x 510 mm. </strong></p> 
        <span class="float-left pr-6 pb-2"><img :src="thumbnail" width="200"/> <button type="button" @click="getlargerImage()" class="underline font-bold" >View larger image</button></span> 

        <p class="pb-2">Like the Middle Ages, the Early Modern era subscribed to the traditional stereotype of the gossiping woman, the subject of the present <span class="italic">Tittle-Tattle; Or, the several Branches of Gossipping</span>, but Tittle-Tattle is also a prime example of a late (probably mid-eighteenth-century) impression of a print which, to judge from the costume depicted, must have been first issued c.1600, when it was called <span class="italic">The Severall Places Where you May hear News</span> [STC 19974] - the form in which Samuel Pepys knew it, and which is preserved, in an impression thought to have been printed c.1640, together with the rest of this far-sighted collector's legacy in the Library of Magdalene College, Cambridge. </p>

        <p class="pb-2">
          The history of this misogynist image is predictably long. By 1560 at the latest, a broadside print had evolved in France, the earliest version of which is entitled <span class="italic">Le Caquet<sup>i</sup> des femmes</span> [The Cackling of Women] which depicts a townscape in which women gossip at the bakery, the bath-house, the laundry, and even in church.
        </p>

        <p class="pb-2">
          The English version, <span class="italic">The Severall Places Where you May hear News</span>, is certainly derived from this French original, though far from slavishly, English scenes being occasionally substituted; e.g. the French watermill is replaced by an English market, with wool being weighed and women selling fruit and vegetables, an English ale-house is introduced, and a couple of French beggars are replaced by two women carrying a tub of washing slung from a cowlstaff over their shoulders. In our present sheet, the venues for gossiping are labelled <span class="italic">At the Childbed, At the Market, At the Hottehovse</span> [i.e. Baths], <span class="italic">At the Bakehovse, At the Conditte</span> [i.e. well-head], <span class="italic">At the Ælle Hovs, At the River</span>, and even <span class="italic">At the Chvrch</span> - though it is noticeable that the English artist has silently expunged the recording-demon present in the French original, and replaced the man kneeling before the altar while the priest elevates the host with a minister preaching from a pulpit.
        </p>

        <p class="pb-2">
          As the bilingual lexicographer Randle Cotgrave was to point out - with admirable (and anachronistic) 'gender-blindness' - in his <span class="italic">A Dictionarie of the French and English Tongues</span> (1611), at <span class="italic">four</span> [oven]:
        </p>

        <p class="pb-2"> <span class="italic">C'est au four, & au moulin, ou l'on sçait des nouvelles: Pro</span> [verb]. <span class="italic">For while the bread bakes, and the corne grinds, people have some leisure to tell how the world goes.</span></p>

        <p class="pb-2">The contemporary English poet Richard West (fl.1606-19) must surely have had this very sheet in mind when he described the <span class="italic">tatling Gossips</span> thus in <span class="italic">The Court of Conscience, or Dick Whippers Sessions</span> (1607)
        </p>

        <p class="pb-2 italic">
        You that at Conduicts, and such other places,<br>
        The ale-house, bake-house, or the washing block<br>
        Meet daily, talking with your brazen faces,<br>
        Of peoples matters which concerne you not...<sup>ii</sup>  
        </p>

        <p class="pb-2">
          A version of the same composition was etched by Hollar c.1628, and provided with numbers which relate to the verses of a German broadside entitled <span class="italic">Schaw Platz</span>.
        </p>

        <p class="pb-2">
          A 17-century impression of this print was collected by Samuel Pepys, when the woodblock was much less worn, but at that date the title was “The Severall Places Where you May hear News”. The British Museum's eighteenth-century impression of the sheet - now entitled <span class="italic">Tittle-Tattle; Or, the several Branches of Gossipping </span>- adds an extra ninth quatrain to the verses below the sheet, the previous verses being the same as in the earlier impression (but for trivial variants), though arranged in a slightly different order. They read as follows:
        </p>

        <p class="pb-2">
        At Child-bed when the Gossips meet,<br>
        Fine Stories we are told:<br>
        And if they get a Cup too much,<br>
        Their Tongues they cannot hold.<br>
        At Market when good Houswives meet,<br>
        Their Market being done,<br>
        Together they will crack a Pot,<br>
        Before they can get Home.<br>
        The Bake-house is a Place you know<br>
        Where Maids a Story hold,<br>
        And if<sup>iii</sup> their Mistresses will prate,<br>
        They must<sup>iv</sup> not be control'd.<br>
        At Alehouse you see how jovial they be<br>
        With every one her Noggin:<sup>v</sup>  <br>
        For till the Skull and the Belly be full,<br>
        None of them will be jogging <sup>vi</sup> <br>
        To Church fine Ladies do resort,<br>
        New Fashions for to spy:<br>
        And others go to Church sometimes<br>
        To shew their Bravery.<sup>vii</sup> <br> 
        The Hot-house makes a rough Skin smooth<br>
        And doth it beautify:<br>
        Fine Gossips use it every Week,<br>
        Their Skins to purify.<br>
        At the Conduit striving for their Turn,<br>
        The Quarrell it grows great,<br>
        That up in Arms they are at last,<br>
        and one another beat.<br>
        Washing at the River's Side<br>
        Good Housewives take Delight:<br>
        But scolding Sluts care not to work,<br>
        Like wrangling Queens<sup>viii</sup> they fight.<br>
        Then Gossips all a Warning take,<br>
        Pray cease your Tongue to rattle:<br>
        Go knit, and Sew, and Brew, and Bake,<br>
        And leave off TITTLE-TATTLE. 
        </p>

        <h4 class="py-2 text-xl font-bold">Footnotes</h4> 
        <ul class="list-[lower-roman] list-inside">
          <li class="py-2"> From a linguistic perspective, I note the metaphorical use in the French title of a term originally appropriate only to <span class="italic">hens</span> - part of the enduring metaphorics which compares human sexual relations to those of the cock and hen [cf. <span class="italic">hen-pecked, hen party, cock of the walk</span>...]. For more on this metaphorics, see my <cite>The Secret Middle Ages</cite> (Stroud, 2002), pp. 49-51, etc.  </li>
          <li class="py-2">Sig. F. </li>
          <li class="py-2"><span class="italic">recte</span> of.  </li>
          <li class="py-2"><span class="italic">must</span>: will. </li>
          <li class="py-2"><span class="italic">noggin</span>: small drinking-vessel, mug, cup. </li>
          <li class="py-2"><span class="italic">jogging</span>: moving on.</li>
          <li class="py-2"><span class="italic">bravery</span>: finery, fine clothes</li>
          <li class="py-2">i.e. <span class="italic">queans</span>: bold, impudent, or ill-behaved women. </li>
        </ul> 
  </div>   
</template>

<script>
    export default {
        data() {
          return { 
            imagePath : 'https://britishprintedimages.blob.core.windows.net/input-images/printsinfocus/',
            thumbnail : '',
            largeImage : ''
          }
        },
        methods: {
          getlargerImage() {
                let currentImagePath = this.imagePath + 'Tittle Tattle.png' 
                window.open(currentImagePath, "_blank");
            },
        },
        created() {
          this.thumbnail = this.imagePath  + '25.jpg' 
        }
    }
  
  </script>
