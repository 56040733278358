<template>
    <div>
        <h3 class="py-6 text-3xl">The Cully Flaug'd</h3>
        <h4 class="py-2 text-xl">No.16 December 2007</h4>
    </div>
    <div class='relative text-left overflow-y-auto'>
      <p class="py-2"> <strong>Copyright of the Trustees of the British Museum.</strong> </p>
      <p class="pt-2 pb-4"> <strong>British Museum 1881,0611.320. Dimensions of original: 246 mm x 196 mm</strong></p> 
        <span class="float-left pr-6 pb-2"><img :src="thumbnail" width="300"/> <a href="#" @click="getlargerImage()" class="underline font-bold" >View larger image</a></span>    
     <p class="pb-2">
      Over an upturned 'dining-room' chair, we see an elderly bespectacled man, his posterior exposed, looking back over his shoulder at a young woman who is in the act of beating him with a switch. She is holding her dress up at the front and his gaze is fixed on her (presumably) exposed crotch.</p>
        
      <p class="py-2">The accompanying caption reads, </p>

      <p class="py-2 italic">
      What Drudgery's here, what Bridewell-like Correction!<br>
      To bring an Old Man, to an Insurrection.<br>
      Firk<sup>i</sup> on Fair Lady [,] Flaug the Fumblers Thighs [,]<br>
      Without such Conjuring th' Devil will not rise
      </p>

      <p class="py-2">It is signed <span class="italic">MLauron pinx</span>, i.e. 'M[arcellus] Laroon painted' (the canvas which this print copies). Laroon died in 1702 and is best known for the drawings of Metropolitan tradesmen and women that John Savage engraved for Pierce Tempest's 1688 edition of <span class="italic">The Cryes of London</span>. As well as paintings and etchings, he is known to have produced at least five mezzotints in the early 1680s.<sup>ii</sup>  It is possible that he engraved the present print himself, or it might have been engraved by John Smith (who engraved others after paintings by Laroon), who may also have been the publisher. </p> 

      <p class="py-2">Laroon was best-known in his own time—as now—for his Dutch-style genre scenes of 'low life', a fact that led to a disapproving assessment of his work by the appropriately-named Vertue: 'His thoughts in his pictures shew him to be a Man of levity, of loose conversation & morals suteable to his birth & education, being low & spurious.'<sup>iii</sup> But it is time we addressed ourselves to the subject of this print. Quite what is going on exactly, in this apparently uniquely surviving impression of a late seventeenth-century English mezzotint? Is it intentionally pornographic? I feel certain it is, but also that it is not without a hint of satire. And what of the title and caption—<span class="italic">The Cully Flaug'd</span> and <span class="italic">…Flaug the Fumblers Thighs?</span>
      </p>

      <p class="py-2">
        There is no doubt that 'flaug' is merely a phonetic variant of 'flog', as we now spell the word in Standard English, but, surprisingly, at the date this print was produced, the verb was still very new to the language—OED's first citation is from Coles's <span class="italic">English Dictionary</span> (1676)<sup>iv</sup>  where it is defined as to whip, but marked as a cant word. Published in 1680, Tell-Troth's <span class="italic">Knavery of Astrology</span> claims that 'of late years there's a neat <span class="italic">Invention</span>, called <span class="italic">Flogging</span>, invented on purpose to pleasure <span class="italic">Old Fumblers</span>.'
      </p>

      <p class="py-2">
          The caption - far from being the hackwork such labels are often considered—is a masterpiece of condensed wit. Consider the word <span class="italic">Insurrection</span> alone—the artist shows us a world in which a youngish woman, hardly more than a girl, can beat an elderly man, as if a reversal of the schoolmaster birching the naughty pupil: this is the revolution of the World Turned Upside Down—albeit at his sexual prompting—and given the suggestive import of the final line of the caption it is impossible not to hear <span class="italic">erection</span> in the word too.
        </p>

        <p class="py-2">
          Significantly, the elderly man in our print is also termed a <span class="italic">Fumbler</span>, defined in B.E's <span class="italic">New Dictionary of the Canting Crew</span> (1699) as 'an unperforming Husband, one that is insufficient', echoing the alternative title of <span class="italic">The Contented Cuckold</span> ballad (1686), 'the fortunate fumbler… he being insufficient to perform'. In the 1678 edition of Ray's <span class="italic">A Collection of English Proverbs</span> the expression, 'He is free of Fumbler's Hall', is glossed, 'Spoken of a man that cannot get his wife with child'. The <span class="italic">fumbler</span>, then, is certainly an infertile man, and often an impotent one too. The caption to our print with its innuendo couched in terms of conjuring up the Devil clearly suggests that the elderly man is unable to achieve an erection without being thrashed in this manner.
        </p>

        <p class="py-2">
          OED's citation at 'flauging'—curiously given a separate entry in the Dictionary—and its unnecessarily cautious definition, ? = 'flogging', has at least the merit of being highly apposite to our print: it is from the opening scene of D'Urfey's <span class="italic">Injured Princess</span> (1682): 'Ask him if he knows where we may find a sound Wench: he's a flauging old Whipster, I warrant him' (with 'whipster' being defined as 'one addicted to whipping or flogging'). Of even greater assistance to us here, however, is Gordon Williams' <span class="italic">Dictionary of Sexual Language and Imagery in Shakespearean and Stuart Literature</span> (London, 1994) which gives a separate entry to 'flogging cully', where it is defined as a 'whore's client who desires whipping', a term first found in Head's <span class="italic">Canting Academy </span>(1673), and it is from Williams that I take most of my remaining examples. B.E.'s definition of 'flogging' in his <span class="italic">New Dictionary of the Canting Crew</span> (1699) reads, 'a Naked Woman's whipping (with Rods) an Old (usually)… Lecher.' In London Spy II (1698) Ned Ward describes an 'Old Leacher' as being 'one of that <span class="italic">Classis</span> in the <span class="italic">Black-School</span> of <span class="italic">Sodomy</span>, who are call'd… <span class="italic">Flogging Cullies</span>. This <span class="italic">Unnatural Beast</span> gives Money to…<span class="italic">Strumpets</span>… and they down with his Breeches and Scourge his Privities till they have laid his <span class="italic">Leachery</span>.'
        </p>

        <p class="py-2">Though Ward described this penchant for flagellation as a 'new Vice', it had, in fact, already been described in an epigram, <span class="italic">In Francum</span>, published a century earlier in the late 1590s by Sir John Davies:</p>

        <p class="py-2 italic">
        When Francus comes to sollace with his whoore<br>
        He sends for rods and strips himselfe stark naked:<br>
        For his lust sleepes, and will not rise before,<br>
        By whipping of the wench it be awaked.
        </p>

        <p class="py-2">And what of the scene of this little drama? It hardly seems correct to call it a 'domestic interior'; it is much too sparsely furnished for that: perhaps it is rather a room in what contemporaries referred to as a 'flogging school', i.e. a brothel specialising in flagellation. In his <span class="italic">London Terrae Filius</span> (1707) Ned Ward describes a whore 'who shall handle a <span class="italic">Birch-Rod</span> with e'er a Pedagogue in <span class="italic">Christendom…</span> Mistress of a <span class="italic"></span>Flogging-School, where a parcel of Old <span class="italic">Fanatical Goats</span> go to be Whip'd into their Leachery.'</p>

        <p class="py-2">The elderly gentleman's hat lies on the table beside a jug of wine and glass and—surely significant—a couple of coins. He is presumably paying for this particular specialist service, confirming that we are in a brothel chamber. But is the hat of any particular type that allows us to identify his profession? Is he a clergyman, for example? A schoolmaster? One can almost see the tabloid headlines! </p>

        <p class="py-2">This impression appears to be a unique survivor and such subject-matter is always prey to later censorship by destruction, however popular in its own time, as late seventeenth-century auction catalogues from which I take the following lot descriptions would seems to suggest [Wing nos. in brackets]: an <span class="italic">old Cully flog'd by Larroon</span> (13th Jan 1692, lot 161) [C7662], and a <span class="italic">Flogging-piece after Laroon</span> (15th Sept 1690, lot 187) [C5130], and, though unattributed, <span class="italic">The Cully well flogged</span> (30th April 1690, lot 707) [C5126], and a <span class="italic">Woman flogging a Cully </span>(Easter 1691, lot 63) [G1750].</p>

        <p class="py-2">If we can agree to label this print by that contentious term 'pornographic', then it is one of the earliest such to survive from England. Inasmuch as the post-Restoration era enjoyed an apparently greater freedom when it came to publishing 'literary' erotica, it appears to have enjoyed a somewhat more relaxed attitude to visual erotica too—and yet, and yet… Given the peculiar vulnerability of this category of art—visual as well as literary—we should exercise the greatest caution when attempting to assess the range and availability, quantity and quality of early modern 'erotic' art.</p>

        <p class="py-2">In <span class="italic">Histriomastix</span> (1633) one of the many evils the Puritan William Prynne inveighed against were 'obscene pictures'— not to mention, lascivious songs, bonfires, grand Christmases, long hair and laughter.<sup>v</sup>  Puritans like Prynne had time (and censorship) on their side; in the sense in which we understand the term in the early twenty-first century, it is arguable that only one other example of visual pornography of native manufacture from this period has survived (see below).</p>  

        <p class="py-2">Throughout the period with which we are concerned, there would seem to have been relatively little 'homegrown' material, so—as until relatively recent times—those interested in acquiring images of a pornographic nature turned naturally to the Continent. In Vanbrugh's <span class="italic">The Country Wife</span> (first staged in 1675), having just returned from France, Horner significantly claims, 'I have brought over not so much as a Bawdy Picture, new Postures nor the second part of the Escholle des Filles'.<sup>vi</sup>  In the very same year a group of Oxford undergraduates was discovered clandestinely trying to run off copies of these same <span class="italic">Postures</span> on the Clarendon Press at night.<sup>vii</sup>  The six <span class="italic">French Postures</span> advertised for sale in Jenner's 1662 list are intriguing but their appearance unknown, and perhaps quite innocent—unlike the <span class="italic">10 Drawings of several postures</span> offered at the sale of John Dunstall's library in 1693.</p>

        <p class="py-2">As for Horner's <span class="italic">Escholle des Filles</span>, originally published in Paris in 1655 (and unillustrated), it was read in the original by Samuel Pepys in 1668 and then burnt, 'that it might not be among my books to my shame'. The illustrated English translation, published anonymously as <span class="italic">The School of Venus</span> in 1680, survives uniquely in the Bayerische Staatsbibliothek in Munich. Opposite the title-page is an etched frontispiece entitled <span class="italic">The Schoole of Venus</span> which depicts a modestly-dressed woman standing behind a booth selling dildoes, while two potential buyers appear to be discussing the relative merits of those on offer and a third woman (a maid?) makes as if to tear her hair out in horror!<sup>viii</sup>  Even more extraordinary is the survival of no fewer than twelve mezzotint illustrations accompanying the two Dialogues of <span class="italic">The School of Venus</span>, the crudity of which tends to confirm an English publication for the edition,<sup>ix</sup> but which also constitute surely the closest we shall ever come to an early modern English version of Aretine's <span class="italic">Postures</span>. </p>

        <h4 class="py-2 text-xl font-bold">Footnotes</h4> 
        <ul class="list-[lower-roman] list-inside">
          <li class="py-2">OED sense 4: 'To beat, whip, lash, trounce, drub', citing <cite>British Apollo</cite> No. 9. 3/1 (1708), <cite>Who Bound up a tingling Rod, and firk'd his Tail</cite>, and Ainsworth's <cite>Latin Dictionary</cite> (1736) in which <span class="italic">To firk</span> is glossed <span class="italic">flagello</span>. </li>
          <li class="py-2">A. Griffiths, <cite>The Print in Stuart Britain</cite>, 1603-1689 (London, 1998), pp. 258-60.</li>
          <li class="py-2"><cite>Vertue Notebooks</cite>, vol. 1, Walpole Society, 18 (Oxford, 1930), p. 147.</li>
          <li class="py-2">But see the antedating provided by the term <span class="italic">flogging cully</span> cited below.</li>
          <li class="py-2">Cited in Roger Thompson, <cite>Unfit for Modest Ears: a Study of Pornographic, Obscene and Bawdy Books Written or Published in England in the Second Half of the Seventeenth Century</cite> (London and Basingstoke, 1979), pp. 176-7</li>
          <li class="py-2">  I am not certain what Horner means by the 'second part'—either some recently published work capitalising on the fame of <span class="italic">L'Escholle</span> or maybe, as the work is divided into two dialogues, a separate edition of the second dialogue? See also Thompson, op. cit., pp. 21ff., who in 1979 was unaware of the unique survival in München.</li>
          <li class="py-2">E.M. Thompson (ed.), <cite>The Letters of Humphrey Prideaux</cite>, Camden Society Publications, N.S. 15 (1875), pp. 30-1.</li>
          <li class="py-2">This reference to the unique surviving copy I owe to the kindness of my student, Molly Carter.</li>
          <li class="py-2">The full set are reproduced in his reprinting of the unique copy now in Munich in B.K. Mudge, <cite>When Flesh Becomes Word</cite> (Oxford, 2004), pp. 17-46. They are relatively crude and highly unlikely to be the work of an engraver as accomplished as Laroon or even Luttrell.</li>
        </ul> 
     </div>   
</template>

<script>
    export default {
        data() {
          return { 
            imagePath : 'https://britishprintedimages.blob.core.windows.net/input-images/printsinfocus/',
            thumbnail : '',
            largeImage : ''
          }
        },
        methods: {
          getlargerImage() {
                let currentImagePath = this.imagePath + 'Cully Flaugd.png' 
                window.open(currentImagePath, "_blank");
            },
        },
        created() {
          this.thumbnail = this.imagePath  + '5.jpg' 
        }
    }
  
  </script>