<template>
    <div class="relative container mx-auto px-20 bg-gray-200">
      <HomeLink />  
      <HomeBanner />
      <NavMenu />
      <ResourcesGrid />
      <SimpleFooter />
    </div>
  </template>
    
    <script>
   
    import HomeBanner from '@/components/HomeBanner.vue';
    import NavMenu from '@/components/NavMenu.vue';
    import ResourcesGrid from '@/components/ResourcesGrid.vue';
    import SimpleFooter from '@/components/SimpleFooter.vue';
    import HomeLink from '@/components/HomeLink.vue';
  
    export default {
      data() {
          return {};
      },
      components: { 
        HomeLink,
        HomeBanner, 
        NavMenu,
        ResourcesGrid,
        SimpleFooter
      },
      methods: {
       
      }
  }
    </script>