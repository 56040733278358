<template>
    <section class="flex" v-show="imageData">
      
        <div class="xl:flex-none h-full">
          <div v-if="imageData.jpgNum"><img class="h-48 w-full object-cover md:h-full md:w-48" :src="imagePath + '/'+ imageData.jpgNum" >
            <h4 class="font-bold p-4">BPI Number {{  bpiNumber  }}</h4>
            <div class="p-2" v-show="largerImageAvailable">
              <a href="#" @click="getlargerImage()" class="underline font-bold" >View larger image </a> <br>(opens in new tab)
            </div>
          </div> 
        </div>
        <div class="flex-auto text-left">
         
        <div class="tab ml-8">
            <nav class="flex flex-wrap pb-4 " aria-label="Tabs">

            <a href="#" id="desc" class="tablinks text-gray-500 hover:text-gray-900 rounded-md px-3 py-2 sm:text-md md:text-lg font-medium bg-gray-200" @click="goToTab('Description', 'desc')" @keydown.enter="goToTab('Description', 'desc')">Description</a>
            <a href="#" id="prod" class="tablinks text-gray-500 hover:text-gray-900 rounded-md px-3 py-2 sm:text-md md:text-lg font-medium" @click="goToTab('Production','prod')" @keydown.enter="goToTab('Production','prod')">Production</a>
            <a href="#" id="sub" class="tablinks text-gray-500 hover:text-gray-900 rounded-md px-3 py-2 sm:text-md md:text-lg font-medium" @click="goToTab('Subjects','sub')" @keydown.enter="goToTab('Subjects','sub')">Subjects</a>
            <a href="#" id="com" class="tablinks text-gray-500 hover:text-gray-900 rounded-md px-3 py-2 sm:text-md md:text-lg font-medium" @click="goToTab('Comments','com')" @keydown.enter="goToTab('Comments','com')">Comments</a>
            </nav>

            <div id="Description" class="tabcontent overflow-y-auto h-96 inline-block ">
              <h4 class="font-bold p-2">Description</h4>
              <div class="p-2">{{ imageData.description }}</div>
              <h4 class="font-bold p-2">Inscription</h4>
              <div class="p-2">{{ imageData.inscription }}</div>
              <h4 class="font-bold p-2">Dimensions</h4>
              <div class="p-2">{{ imageDimensions }}</div>  
            </div>
            <div id="Production" class="tabcontent overflow-y-scroll h-96 ">
              <h4 class="font-bold p-2">Producers</h4>
              <div class="p-2">
                <ul>
                    <li v-for="item in producerInfo" :key="item.id" > {{ item.description  }} {{ item.person }} </li> 
                </ul>
              </div>
              <h4 class="font-bold p-2">Production Place</h4>
              <div class="p-2">  {{ imageData.producerPlace }}</div>
              <h4 class="font-bold p-2">Schools</h4> 
              <div class="p-2"> 
                <ul>
                    <li v-for="item in producerSchools" :key="item.id" > {{ item.school  }} </li> 
                </ul>
              </div>
              <h4 class="font-bold p-2">Copyright</h4>
              <div class="p-2"> &#169; Copyright The Trustees of The British Museum </div>
            </div>
            <div id="Subjects" class="tabcontent overflow-y-scroll h-96">
              <h4 class="font-bold p-2">Associated names</h4>
              <div class="p-2"> 
                <ul>
                    <li v-for="item in imageAssocNames" :key="item.id" > {{ item.desc }} {{ item.name  }} </li> 
                </ul>
              </div>

              <h4 class="font-bold p-2">Subjects</h4>
              <div class="p-2">
                <ul>
                  <li v-for="item in imageSubjects" :key="item.id" > {{ item.subject  }} </li> 
                </ul>
              </div>
            </div>
            <div id="Comments" class="tabcontent overflow-y-scroll h-96">
        
              <h4 class="font-bold p-2">Comments</h4>
              <div class="p-2"> {{ imageComments }}</div>
              <h4 class="font-bold p-2">Bibliographic references</h4>
              <div class="p-2">
                <ul>
                    <li v-for="item in biblioRef" :key="item.id" class="pt-2"> <strong>{{ item.ref  }} </strong> : {{ item.desc }}</li> 
                </ul>
              </div>
            </div>
        </div>
        </div>
                    
      </section>    
  </template>
     
  
  <script>
  
  const path = process.env.VUE_APP_ROOT_API;
  import axios from 'axios';

  export default {
    name: "ImagesModal",
    props: {
            bpiNumber : {
                type: Number,
                default: 0
            }
          },
          data() {
            return { 
              imagePath : 'https://britishprintedimages.blob.core.windows.net/input-images/web',
              largerImagePath : 'https://britishprintedimages.blob.core.windows.net/input-images/1024',
              imageData : {},
              imageLocation: {},
              inscription : '',
              imageDimensions : '',
              producerPlace: '',
              imageComments : '',
              imageAssocNames : {},
              producerInfo: [],
              producerSchools: [],
              imageSubjects: [],
              biblioRef: [],
              i : 0,
              largerImageAvailable : false,
              path: process.env.VUE_APP_ROOT_API
            }
          },
          methods: {
            getImage() {
                this.imageData = JSON.parse(localStorage.getItem(this.bpiNumber));
            },
            getlargerImage() {
                let currentImagePath = this.largerImagePath + '/' + this.imageData.jpgNum
                window.open(currentImagePath, "_blank");
            },
            goToTab(tabname, tablink) {
              let tabcontent, tablinks;
              tabcontent = document.getElementsByClassName("tabcontent");
              // hide all the tabcontent 
              for (let i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
              }
              tablinks = document.getElementsByClassName("tablinks");
              for (let i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" bg-gray-200", "");
              } 
              // expand the tab section and add the link background colour
              document.getElementById(tabname).style.display = "block";
              document.getElementById(tablink).className += " bg-gray-200";
            },
            getImageSchools() {
             // use axios to call a stored procedure in the API, passing the BPI_catKey as id
              axios
                .get(path + "image_schools/" + this.bpiNumber)
                .then((response) => {
                  if(response.data.recordset === undefined || response.data.recordset.length === 0){
                    console.log('No schools data');
                  } 
                  else {
                    for(let i = 0; i < response.data.recordset.length; i++) {
                      let productionSchool = response.data.recordset[i].bm_school !== undefined ? response.data.recordset[i].bm_school: "No data available";
                      let prodObj = {
                        'id' : i,
                        'school' :  productionSchool 
                      }
                      this.producerSchools[i] = Object.assign(prodObj);
                    }
                  }  
                })
                .catch(function (err){
                console.log(err);
                })
            },
            getImageSubjects() {

             // use axios to call a stored procedure in the API, passing the BPI_catKey as id
              axios
                .get(path + "image_subjects/" + this.bpiNumber)
                .then((response) => {
                  let i;
                  if(response.data.recordset === undefined || response.data.recordset.length === 0){
                    console.log('No subject data');
                  } 
                  else {
                    for(i = 0; i < response.data.recordset.length; i++) {
                      let imageSubjectHeading = response.data.recordset[i].heading !== undefined ? response.data.recordset[i].heading: "No data available";
                      let prodObj = {
                        'id' : i,
                        'subject' :  imageSubjectHeading 
                      }
                      this.imageSubjects[i]= Object.assign(prodObj);
                    }
                  }  
                })
                .catch(function (err){
                console.log(err);
                })
            },
           getBiblioRef() {
             // use axios to call a stored procedure in the API, passing the BPI_catKey as id
              axios
                .get(path + "biblio_ref/" + this.bpiNumber)
                .then((response) => {
                  if(response.data.recordset === undefined || response.data.recordset.length === 0){
                    console.log('No bibliographic references');
                  } 
                  else {
                    for(let i = 0; i < response.data.recordset.length; i++) {
                      let reference = response.data.recordset[i].bm_bib_xref !== undefined ? response.data.recordset[i].bm_bib_xref: "No data available";
                      let description = response.data.recordset[i].description !== undefined ? response.data.recordset[i].description: "No data available";
                      let prodObj = {
                        'id' : i,
                        'ref' :  reference,
                        'desc' : description 
                      }
                      this.biblioRef[i]= Object.assign(prodObj);
                    }
                  }  
                })
                .catch(function (err){
                console.log(err);
                })
            },
            getImageDimensions() {
             // use axios to call a stored procedure in the API, passing the BPI_catKey as id
              axios
                .get(path + "image_dimensions/" + this.bpiNumber)
                .then((response) => {
                  if(response.data.recordset === undefined || response.data.recordset.length === 0){
                    console.log('No dimension data');
                  } 
                  else { 
                    let height =  response.data.recordset[0].mus_dimension + ' ' + response.data.recordset[0].mus_dimension_value + response.data.recordset[0].mus_dimension_measurement_unit
                    let width =  response.data.recordset[1].mus_dimension + ' ' + response.data.recordset[1].mus_dimension_value + response.data.recordset[1].mus_dimension_measurement_unit;
                    this.imageDimensions = height + ' ' + width;
                  }  
                })
                .catch(function (err){
                console.log(err);
                })
            },
            getImageLocation() {
              axios
                .get(path + "image_location/" + this.bpiNumber)
                .then((response) => {
                  if(response.data.recordset === undefined || response.data.recordset.length === 0){
                    console.log('No location data');
                  } 
                  else { 
                    let collection = response.data.recordset[0].collection !== undefined ? response.data.recordset[0].collection: "No data available";
                    let bmRefNo =  response.data.recordset[0].bm_calc_reg_no_expr !== undefined ? response.data.recordset[0].bm_calc_reg_no_expr: "No data available";
                    let note = response.data.recordset[0].mus_acquisition_note !== undefined ? response.data.recordset[0].mus_acquisition_note: "No data available";

                    this.imageLocation = {
                      collection : collection,
                      ref : bmRefNo,
                      note : note
                    }
                  }  
                })
                .catch(function (err){
                console.log(err);
                })
            },
            getImageComments(){
                axios
                  .get(path + "image_comments/" + this.bpiNumber)
                  .then((response) => {
                    if(response.data.recordset === undefined || response.data.recordset.length === 0){
                      console.log('No image comments');
                    } 
                    else {
                      this.imageComments = response.data.recordset[0].mus_comments !== null || response.data.recordset[0].mus_comments !== '' ? response.data.recordset[0].mus_comments  : "Unavailable";
                      this.imageComments = this.filterHTML(this.imageComments);
                    }
                  })
                  .catch(function (err){
                  console.log(err);
                  })
            }, 
            getImageProducers(){
              // call for an array of image producers
              axios
                .get(path + "image_producers/" + this.bpiNumber)
                .then((response) => {
                  if(response.data.recordset === undefined || response.data.recordset.length === 0){
                    console.log('No producer data');
                  } 
                  else {
                    for(let i = 0; i < response.data.recordset.length; i++) {
                      let productionDesc = response.data.recordset[i].al_production_person_assDesc !== undefined ? response.data.recordset[i].al_production_person_assDesc : "No data available";
                      let productionPerson = response.data.recordset[i].mus_object_production_person !== undefined ? response.data.recordset[i].mus_object_production_person : "No data available";
                      let prodObj = {
                        'id' : i,
                        'description' :  productionDesc,
                        'person' : productionPerson
                      }
                      this.producerInfo[i] = Object.assign(prodObj); 
                    }
                  }  
                })
                .catch(function (err){
                console.log(err);
                })
            },
            getImageAssocNames(){
              this.imageAssocNames = {};
              // call for an array of image producers
              axios
                .get(path + "image_assoc_names/" + this.bpiNumber)
                .then((response) => {
                  if(response.data.recordset === undefined || response.data.recordset.length === 0){
                    console.log('No associated name data');
                  } 
                  else {
                  for(let i = 0; i < response.data.recordset.length; i++) {
                      let name = response.data.recordset[i].bm_as_name !== undefined ? response.data.recordset[i].bm_as_name: "No data available";
                      let desc = response.data.recordset[i].al_as_name_assDesc !== undefined ? response.data.recordset[i].al_as_name_assDesc: "No data available";
                      let assocObj = {
                        'id' : i,
                        'name' :  name,
                        'desc' : desc
                      }
                      let match = false;

                      // always assign the first result
                      if(Object.keys(this.imageAssocNames).length === 0){
                          // assign value
                          this.imageAssocNames[i] = Object.assign(assocObj); 
                      }
                      else {
                        // check for duplicate names and descriptions
                        let itemArray = Object.values(this.imageAssocNames);
                        itemArray.forEach(item => {
                            if(assocObj.name === item.name && assocObj.desc === item.desc ){
                              match = true;
                            }    
                        })
                        if(!match)
                        {
                          this.imageAssocNames[i] = Object.assign(assocObj); 
                        }
                      }
                  }
                }      
                })
                .catch(function (err){
                console.log(err);
                })     
            },
            async checkForLargerImage(){
                let url = this.largerImagePath + '/'+ this.imageData.jpgNum
                const response = await fetch(url, {
                method: "HEAD", 
                mode: "no-cors", // no-cors, *cors, same-origin
                cache: "no-cache",
                credentials: "same-origin", 
                headers: {
                  "Content-Type": "application/json",
                },
                redirect: "follow",  
                referrerPolicy: "no-referrer",
              });
              this.largerImageAvailable = true;
              return response;
            },
            filterHTML(str) {
              if ((str===null) || (str===''))
                  return false;
              else
                  str = str.toString();
                  
              // Regular expression to identify HTML tags in
              // the input string. Replacing the identified
              // HTML tag with a null string.
              return str.replace( /(<([^>]+)>)/ig, '');
            }
          },
          created() {
            this.getImage();
            this.getImageProducers();
            this.getImageComments();
            this.getImageSchools();
            this.getImageSubjects();
            this.getBiblioRef();
            this.getImageDimensions();
            this.getImageAssocNames();
            this.getImageLocation();
            this.checkForLargerImage();
      }
    }
  

</script>

<style>

/* Style the tab */
.tab {
  overflow: hidden;
}

/* Style the tab content */
.tabcontent {
  display: none;
}

</style>


