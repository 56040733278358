<template>

<div  id="accordion-collapse" data-accordion="collapse">
    <h2 id="accordion-collapse-heading-1">
        <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-700 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-stone-400 hover:bg-stone-200 dark:hover:bg-stone-800 gap-3" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
        <span class="text-2xl">Dudley, Thomas </span>
        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
        </svg>
        </button>
    </h2>
    <div id="accordion-collapse-body-1" class="hidden" aria-labelledby="accordion-collapse-heading-1">
        <div class="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900 text-left overflow-y-auto"> 
            
            <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                (fl. 1678-9) An etcher, Dudley signed himself on one of his plates as 'quondam dicipulus W: Hollar' ('a former pupil of Wenceslaus Hollar'). His work included twenty-six etchings on the life of Aesop, after Barlow drawings, which appeared in the 1687 second edition of the Fables of Aesop. In 1679 he went to Lisbon where he continued etching. Quite likely a Catholic, there is no evidence he ever returned to England.
                </p>
        </div>
    </div>
    <h2 id="accordion-collapse-heading-2">
        <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-2" aria-expanded="false" aria-controls="accordion-collapse-body-2">
        <span class="text-2xl">Dunstall, John</span>
        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
        </svg>
        </button>
    </h2>
    <div id="accordion-collapse-body-2" class="hidden" aria-labelledby="accordion-collapse-heading-2">
        <div class="p-5 border border-b-0 border-gray-200 dark:border-gray-700 text-left">
            <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                (d. 1693) Probably a native of Chichester, where his father of the same name was a stationer, Dunstall was employed by Peter Stent in the years around 1660 and thereafter set himself up as a drawing master, producing many plates to illustrate a treatise on ‘The Art of Delineation' which was never published and survives in the British Library.
            </p>
    
        </div>
        </div>
        <h2 id="accordion-collapse-heading-3">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-3" aria-expanded="false" aria-controls="accordion-collapse-body-3">
            <span class="text-2xl">Elder, William </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
            <div id="accordion-collapse-body-3" class="hidden" aria-labelledby="accordion-collapse-heading-3">
                <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">

                    <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                        (fl. 1680-1701) Originally from Scotland, Elder worked in London principally as an engraver of portraits, usually after older engravings, for booksellers. There is also evidence that he was interested in calligraphic engraving.
                    </p>
                
                </div>
            </div>
        <h2 id="accordion-collapse-heading-4">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-4" aria-expanded="false" aria-controls="accordion-collapse-body-4">
            <span class="text-2xl">Elstrack, Renold</span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-4" class="hidden" aria-labelledby="accordion-collapse-heading-4">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (1570-last recorded 1625) ‘The most prolific and competent of the few native-born engravers working in London during the reign of James I' (Griffiths, Print in Stuart Britain, p. 45). Elstrack was the son of a glazier who had emigrated to England from the province of Liège in the mid-Tudor period. He was probably a pupil of William Rogers, with whom he collaborated in his earliest known work, the maps illustrating Linschoten's Voyages (1598). Thereafter he produced a wide range of book illustrations and other prints, including the satire, All doe Ride the Asse.
                </p>
            </div>
        </div>
        <h2 id="accordion-collapse-heading-5">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-5" aria-expanded="false" aria-controls="accordion-collapse-body-5">
            <span class="text-2xl">English, Josias</span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-5" class="hidden" aria-labelledby="accordion-collapse-heading-5">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (active 1649-56) English seems to have been a collaborator of Francis Clein at the Mortlake tapestry factory, and his few known etchings are after designs by Clein. According to Vertue, he died in 1718, but no work is known by him for half a century prior to that.
                </p>  
            </div>
        </div>  
        <h2 id="accordion-collapse-heading-6">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-6" aria-expanded="false" aria-controls="accordion-collapse-body-6">
            <span class="text-2xl">Faithorne, William </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-6" class="hidden" aria-labelledby="accordion-collapse-heading-6">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                   (c. 1620-91) Seen by Horace Walpole as the founder of the English school of engraving, Faithorne was said by John Bagford to have been trained as an engraver by John Payne. While serving in the royalist army he was taken prisoner at the siege of Basing House in 1645, and, after being taken to London, he subsequently commuted his imprisonment for banishment, going to Paris where he worked for several years. By 1652 he had returned to England where he set up a successful business which he continued for the rest of his life, though increasingly his activity was as a dealer rather than an engraver. At his best, Faithorne could produce very sensitive engraved portraits; however, he was increasingly overshadowed by younger artists from the 1660s onwards. In 1662, he published an English translation of Abraham Bosse's treatise on engraving.

                </p>  
            </div>
        </div>  
        <h2 id="accordion-collapse-heading-7">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-7" aria-expanded="false" aria-controls="accordion-collapse-body-7">
            <span class="text-2xl">Faithorne, William, the Younger  </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-7" class="hidden" aria-labelledby="accordion-collapse-heading-7">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (c. 1670-1703), son of the preceding, was apprenticed to his father in 1683 and is known only as an engraver of mezzotints. Although a fine engraver, he appears to have been an incompetent businessman, and never freed himself from working for publishers. Indeed, his father briefly left retirement to publish one of his son's plates.
                </p>  
            </div>
        </div>  
        <h2 id="accordion-collapse-heading-8">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-8" aria-expanded="false" aria-controls="accordion-collapse-body-8">
            <span class="text-2xl">Fillian, John  </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-8" class="hidden" aria-labelledby="accordion-collapse-heading-8">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (fl. 1658-80) Son of William Fillian, a tailor of Covent Garden. In 1650 he was apprenticed to Edward Cooke, a goldsmith, for eight years. He engraved a portrait of William Faithorne, and presumably transferred to him at some point.
                </p>  
            </div>
        </div>  
        <h2 id="accordion-collapse-heading-9">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-9" aria-expanded="false" aria-controls="accordion-collapse-body-9">
            <span class="text-2xl">Fuller, Isaac </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-9" class="hidden" aria-labelledby="accordion-collapse-heading-9">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (?1606-72) Fuller is said to have studied in France, returning to England during the Interregnum and becoming a leading decorative artist in the Restoration period, though none of his designs now survive. In 1654 he produced an etched drawing book for Peter Stent, the earliest British drawing book to survive.
                </p>  
            </div>
        </div> 
       
        <h2 id="accordion-collapse-heading-10">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-10" aria-expanded="false" aria-controls="accordion-collapse-body-10">
            <span class="text-2xl">Gammon, James </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-10" class="hidden" aria-labelledby="accordion-collapse-heading-10">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (fl. 1653-70) Apprenticed to William Faithorne in 1653, Gammon is known for engraving poor copies of better-known prints. His plates were mostly intended to be used as frontispieces to books rather than as single-sheet prints.
                </p>  
            </div>
        </div>   
        <h2 id="accordion-collapse-heading-11">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-11" aria-expanded="false" aria-controls="accordion-collapse-body-11">
            <span class="text-2xl">Gaywood, Richard </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-11" class="hidden" aria-labelledby="accordion-collapse-heading-11">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (fl. 1644-88) The most prolific etcher working in the period from the Civil War to the Restoration, Gaywood is in personal terms a shadowy figure, to whom only scattered documentary references survive. Described by George Vertue as a follower of Wenceslaus Hollar, he certainly copied many prints by Hollar and others. He also produced a large number of other prints, mainly portraits, many of them for the publisher, Peter Stent.
                </p>  
            </div>
        </div> 
        <h2 id="accordion-collapse-heading-12">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-12" aria-expanded="false" aria-controls="accordion-collapse-body-12">
            <span class="text-2xl">Geminus, Thomas</span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-12" class="hidden" aria-labelledby="accordion-collapse-heading-12">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (active c. 1540-63) Geminus was probably of Flemish origin; he was active in England from some point in the 1540s to early in the reign of Elizabeth. From 1547 onwards he is known to have received a salary from the Privy Purse; he is described in that connection as a surgeon, while he was also a printer, established in the Blackfriars by 1555. His best known work is his Compendios totius Anatomie Delineatio (1545), which was plagiarised from the work of Vesalius. He was also responsible for a set of ornament prints, and for various maps.
                </p>  
            </div>
        </div>     
        <h2 id="accordion-collapse-heading-13">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-13" aria-expanded="false" aria-controls="accordion-collapse-body-13">
            <span class="text-2xl">Gifford, George </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-13" class="hidden" aria-labelledby="accordion-collapse-heading-13">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (fl. 1632-5) Gifford is known principally for his portrait of Hugh Latimer, used as the frontispiece to an edition of Latimer's sermons in 1635. Among few other works, an engraving of St Peter, part of a set of the four apostles, survives.
                </p>  
            </div>
        </div>     

        <h2 id="accordion-collapse-heading-14">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-14" aria-expanded="false" aria-controls="accordion-collapse-body-14">
            <span class="text-2xl">Glover, George</span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-14" class="hidden" aria-labelledby="accordion-collapse-heading-14">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (active 1634-52) All that is known about Glover is derived from his engravings, which included portraits (often intended as frontispieces to books), title-pages and other book illustrations. He also produced sets of engravings of the liberal arts and the senses.
                </p>  
            </div>
        </div>   
        
        <h2 id="accordion-collapse-heading-15">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-15" aria-expanded="false" aria-controls="accordion-collapse-body-15">
            <span class="text-2xl">Goddard, John</span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-15" class="hidden" aria-labelledby="accordion-collapse-heading-15">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (active 1645-53) Goddard was evidently linked to various writing masters, producing a portrait of Martin Billingsley in 1651. He also produced various other portraits and book illustrations, including engraved title-pages, along with a series of the seven deadly sins and two religious broadsides: A Tree of Man's Life and God in Essence, a Summe or Body of Divinitie Real.
                </p>  
            </div>
        </div>      

    </div>

</template>
<script setup>

  import { initFlowbite } from 'flowbite';

  // ensures that the prop data is received by the accordion
  setTimeout(() => {
        initFlowbite();
  }, 500);

  const init = async () => {};
  init();
  
</script>

<style>
div[data-panel-id] {
	border-bottom: 1px !important;
}
</style>

