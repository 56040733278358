<template>

<div  id="accordion-collapse" data-accordion="collapse">
    <h2 id="accordion-collapse-heading-1">
        <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-700 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-stone-400 hover:bg-stone-200 dark:hover:bg-stone-800 gap-3" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
        <span class="text-2xl">Loggan, David </span>
        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
        </svg>
        </button>
    </h2>
    <div id="accordion-collapse-body-1" class="hidden" aria-labelledby="accordion-collapse-heading-1">
        <div class="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900 text-left overflow-y-auto"> 
            
            <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                (1634-92) Although his family came from Oxfordshire, Loggan was born in Gdansk and was trained under Wilhelm Hondius and Crispijn de Passe the younger. In the 1650s, he settled in London, specialising in portraits drawn in black lead which he often subsequently engraved. In the 1660s he moved to Oxford where he was appointed 'public sculptor' and was responsible for producing Oxonia Illustrata (1675). He returned to London in 1675, producing a companion volume on Cambridge over the next decade and a half. In his later years, his business was affected by the rise of mezzotint, with which he briefly experimented himself, but he is mainly known for his sensitive portrait engravings.
                </p>
        </div>
    </div>
    <h2 id="accordion-collapse-heading-2">
        <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-2" aria-expanded="false" aria-controls="accordion-collapse-body-2">
        <span class="text-2xl">Lombart, Pierre (or Peter)</span>
        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
        </svg>
        </button>
    </h2>
    <div id="accordion-collapse-body-2" class="hidden" aria-labelledby="accordion-collapse-heading-2">
        <div class="p-5 border border-b-0 border-gray-200 dark:border-gray-700 text-left">
            <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                (1613-82) Lombart was a Frenchman who arrived in England during the Interregnum (the first dated print that he produced in this country is an engraved title-page of 1650). He remained there until 1660 or shortly thereafter, when he returned to Paris, remaining there for the rest of his life. In addition to fine portrait prints of Cromwell and others, and a series of portraits of 'Countesse' after van Dyck, Lombart produced various illustrations for publications by John Ogilby.
            </p>
        </div>
        </div>
        <h2 id="accordion-collapse-heading-3">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-3" aria-expanded="false" aria-controls="accordion-collapse-body-3">
            <span class="text-2xl">Luttrell, Edward </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
            <div id="accordion-collapse-body-3" class="hidden" aria-labelledby="accordion-collapse-heading-3">
                <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">

                    <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                        (documented 1680-1737) From the same Devonshire family as the collector Narcissus Luttrell (1657-1732), Edward Luttrell abandoned an intended legal career for art, writing a treatise on drawing, painting and limning which has never been published, and developing a technique of using pastels on copper plates grounded for mezzotint. A number of examples of his work survive, although he never became leading producer of mezzotints.
                    </p>
                
                </div>
            </div>
        <h2 id="accordion-collapse-heading-4">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-4" aria-expanded="false" aria-controls="accordion-collapse-body-4">
            <span class="text-2xl">Marmion, Edmond </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-4" class="hidden" aria-labelledby="accordion-collapse-heading-4">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (active 1650-3) Marmion was responsible for a series of etchings of the five senses, and one etched portrait by him is also known. In addition, he produced the drawings for the illustrations to the 1656 edition of Francis Quarles' Argalus and Parthenia, which were executed by other artists, probably including Richard Gaywood.
                </p>
            </div>
        </div>
        <h2 id="accordion-collapse-heading-5">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-5" aria-expanded="false" aria-controls="accordion-collapse-body-5">
            <span class="text-2xl">Marshall, William</span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-5" class="hidden" aria-labelledby="accordion-collapse-heading-5">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (active 1617-48) Marshall was the most prolific engraver of the Caroline period, though little is known about him other than from the prints that he produced. Half his output was of portraits and most of the rest was of engraved title-pages, though he also produced two sets of allegorical figures and few miscellaneous items.
                </p>  
            </div>
        </div>  
        <h2 id="accordion-collapse-heading-6">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-6" aria-expanded="false" aria-controls="accordion-collapse-body-6">
            <span class="text-2xl">Oliver, Peter </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-6" class="hidden" aria-labelledby="accordion-collapse-heading-6">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (c. 1589-1647) Oliver was the eldest son of the Elizabethan miniaturist, Isaac Oliver, and himself specialised in miniature copies of famous paintings, a number of which were owned by Charles I. He also made a few etchings.
                </p>  
            </div>
        </div>  
        <h2 id="accordion-collapse-heading-7">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-7" aria-expanded="false" aria-controls="accordion-collapse-body-7">
            <span class="text-2xl">Passe, Crispijn de, the Elder </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-7" class="hidden" aria-labelledby="accordion-collapse-heading-7">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (1564-1637) Born in Arnemuiden in Zeeland, Crispijn de Passe the Elder moved successively to Antwerp, Aachen, Cologne and Utrecht, where he was based from 1611 until his death and where he was joined in his later years by his son Crispijn the Younger (c. 1594-1670). Crispijn the Elder was involved in joint projects with various publishers based in London, often providing plates with inscriptions in various languages including English.
                </p>  
            </div>
        </div>  
        <h2 id="accordion-collapse-heading-8">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-8" aria-expanded="false" aria-controls="accordion-collapse-body-8">
            <span class="text-2xl">Passe, Simon de  </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-8" class="hidden" aria-labelledby="accordion-collapse-heading-8">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (c. 1595-1647) The second son of Crispijn de Passe the Elder, Simon came to London in 1616, remaining there until 1621, when he returned to Utrecht, subsequently moving to Copenhagen, where he became official engraver to Christian IV of Denmark. In England, he mainly produced portrait engravings, enhancing their impact by placing the bust of the sitter in an oval frame surrounded by lavish decoration, an important development in European portrait engraving as a whole.
                </p>  
            </div>
        </div>  
        <h2 id="accordion-collapse-heading-9">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-9" aria-expanded="false" aria-controls="accordion-collapse-body-9">
            <span class="text-2xl">Passe, Willem de </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-9" class="hidden" aria-labelledby="accordion-collapse-heading-9">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (1597/8-?1636/7) The third son of Crispijn de Passe the Elder, Willem was initially associated with the English market when he and his sister Magdalena engraved the plates for Henry Holland's Herωologia between 1618 and 1620. In 1621, he moved to London, where he remained active until 1625, although he did not die until 1636/7. During the period of his activity, he was responsible for various engraved portraits of the royal family and of royal favourites.
                </p>  
            </div>
        </div> 
       
        <h2 id="accordion-collapse-heading-10">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-10" aria-expanded="false" aria-controls="accordion-collapse-body-10">
            <span class="text-2xl">Payne, John</span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-10" class="hidden" aria-labelledby="accordion-collapse-heading-10">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (active 1620-39) Payne was probably trained by Simon de Passe, and George Vertue considered him 'the first native that distinguishd himself by his Excellent burin', though his chances of becoming official engraver to Charles I were thwarted by 'his irregular way of life' which also brought about his early death. His prints date from between 1620 and 1639, most of them engraved title-pages and book illustrations, though he was also responsible the various books of plants and animals and for a huge print of The Sovereign of the Seas.
                </p>  
            </div>
        </div>   
        <h2 id="accordion-collapse-heading-11">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-11" aria-expanded="false" aria-controls="accordion-collapse-body-11">
            <span class="text-2xl">Place, Francis </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-11" class="hidden" aria-labelledby="accordion-collapse-heading-11">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (1647-1728) A member of a gentry family from County Durham, Francis Place discovered his artistic talents while a law student in London. From the mid-1670s he spent increasing amounts of time in York, where he formed part of a circle of virtuosi, but he divided his time between there and London, and he was responsible for a number of prints, including a set of imaginary landscapes and a few mezzotints. He also produced many topographical drawings made on tours undertaken from the 1670s to the end of his life.
                </p>  
            </div>
        </div> 
        <h2 id="accordion-collapse-heading-12">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-12" aria-expanded="false" aria-controls="accordion-collapse-body-12">
            <span class="text-2xl">Robinson, Robert </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-12" class="hidden" aria-labelledby="accordion-collapse-heading-12">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (active 1674-d. 1706) Robinson was a member of the Painter-Stainers' Company, and his primary activity was as a decorative artist, specialising in exotic landscape interiors of which examples survive at Sir John Cass's School in the City of London and Carshalton House, Surrey. In addition, he produced mezzotints, usually also of architectural caprices and other imaginative compositions.
                </p>  
            </div>
        </div>     
        <h2 id="accordion-collapse-heading-13">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-13" aria-expanded="false" aria-controls="accordion-collapse-body-13">
            <span class="text-2xl">Rogers, William</span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-13" class="hidden" aria-labelledby="accordion-collapse-heading-13">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (active c. 1589-1604) 'The greatest of the English engravers in the Tudor period' (Hind, Engraving in England, vol. 1, p. 258), Rogers produced a number of important portraits of royalty and others as well as various engraved book illustrations, including the illustrations to Hugh Broughton's Concent of Scripture (1590). His engraved title-pages include that for the 1600 edition of Camden's Britannia. His Armada portrait of Elizabeth I, Eliza triumphans, is the earliest signed and dated engraving by an Englishman.
                </p>  
            </div>
        </div>     

        <h2 id="accordion-collapse-heading-14">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-14" aria-expanded="false" aria-controls="accordion-collapse-body-14">
            <span class="text-2xl"> Rupert, Prince </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-14" class="hidden" aria-labelledby="accordion-collapse-heading-14">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (1619-82) Nephew of Charles I and a military and naval commander in the Civil War, Rupert's first etchings date from 1636, and he took a more sustained interest in the technique during the Interregnum. In 1654, he was shown a novel method of preparing a plate by a military officer, Ludwig von Siegen, and Rupert refined the technique and thus laid the foundation for mezzotint.
                </p>  
            </div>
        </div>   
        
        <h2 id="accordion-collapse-heading-15">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-15" aria-expanded="false" aria-controls="accordion-collapse-body-15">
            <span class="text-2xl">Ryther, Augustine </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-15" class="hidden" aria-labelledby="accordion-collapse-heading-15">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (active c. 1576-95) Ryther is said to have hailed from Leeds, where he was probably acquainted with Christopher Saxton from his youth, and he engraved various maps for Saxton and others. His most important work was the set of plates illustrating the defeat of the Spanish Armada, two copies of which survive bound with copies of Saxton's Atlas of England and Wales, with which they may have been issued.
                </p>  
            </div>
        </div>      
    </div>
    
</template>
<script setup>

  import { initFlowbite } from 'flowbite';

  // ensures that the prop data is received by the accordion
  setTimeout(() => {
        initFlowbite();
  }, 500);

  const init = async () => {};
  init();
  
</script>

<style>
div[data-panel-id] {
	border-bottom: 1px !important;
}
</style>

