<template>
    <transition name="modal">
      <div class="modal-mask">
        <div id='modalContainer' tabindex="-1" aria-modal="true" aria-label="Contributor information modal" role="dialog">
          <div class='modal-header relative text-center'>
            <h3 class="w-5/6 pt-6 lg:text-3xl md:text-xl sm:text-md">Contributors</h3>
            <span class="absolute top-5 right-10">
              <button type="button" aria-label="close" class="p-2 lg:text-lg sm:text-xs md:text-sm bg-slate-200 rounded" @click="closeHandler()" @keydown.enter="closeHandler()">Close</button>
            </span>
          </div>
          <div class='modal-body text-left overflow-y-scroll h-96'>
            <p class="py-2">This website has been created by Dr Adam Morton, Senior Lecturer in History, School of History, Classics and Archeology, Newcastle University in conjunction with the Newcastle University Research Software Engineering (RSE) team.</p>
            <p class="py-2 font-bold">Acknowledgements</p>
            <p class="py-2">The bulk of the material included in the project's database comes from the collection of the Department of Prints and Drawings at the British Museum, which has provided many resources and facilities. Many of the images in the 'Print in Focus' section of the website have kindly been provided by Malcolm Jones of the University of Sheffield.</p>
            <p class="py-2">Technical assistance in the transfer of data from a previous version of the website has been provided from King's Digital Laboratory at Kings College London.</p>
            </div>
        </div>
      </div>
    </transition>
  </template>
  
<script>

  export default {
      name: "ContributorModal",
      components: {
      },
      data() {
        return{
       
        }
      },
      methods: {
        closeHandler() {
            this.$emit('close');   
        }
      }
  }
  
  </script>
  
  <style scoped>
  
    .modal-mask {
      position: fixed;
      z-index: 9998;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .5);
      display: table;
      transition: opacity .3s ease;
    }
  
    #modalContainer {
      position: absolute;
      z-index: 100;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 50%;
      height: 50%;
      background: #FFF;
      border-radius: 25px;
    }
  
  
    .modal-body {
      padding: 50px;
    }
  
    .modal-body p {
      padding: 5px;
    }

  
  </style>
  