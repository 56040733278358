<template>
  <div>
        <h3 class="py-6 text-3xl">The Committee; or Popery in Masquerade</h3>
        <h4 class="py-2 text-xl">No, 19.  April 2008 </h4>
    </div>
   
    <div class='relative text-left overflow-y-auto'>
      <p class="py-2"> <strong>Copyright of the Trustees of the British Museum.</strong> </p>
      <p class="pt-2 pb-4"> <strong>British Museum 1849,0315.82. Dimensions of original: 554 mm x 417 mm (sheet); 297 mm x 415 mm (plate)</strong></p> 
       <p>
        <span class="float-left pr-6 pb-2"><img :src="thumbnail" width="250"/> <a href="#" @click="getlargerImage()" class="underline font-bold" >View larger image</a></span></p>    
        <p class="pb-2">
        Antony Griffiths has hailed the present print—which was 'answered' for the Whig faction six months later by Stephen College's <span class="italic">Strange's Case Strangely Altered</span> —as constituting 'a landmark in the history of English satire, being the first occasion that both parties conducted a political controversy in visual form'.<sup>i</sup></p>
        
        <p class="py-2"><span class="italic">The Committee; or Popery in Masquerade</span>, by an unknown engraver, is a most accomplished print <span class="italic">satyr</span>—as the accompanying text interestingly terms it—issued in April 1680, which <span class="italic">inter alia</span> holds up the various nonconformist sects for ridicule. Here representatives of the various denominations of the earlier Commonwealth era—a naked Adamite, a Ranter, a Quaker, an Anabaptist, a Presbyterian, an Independent, [Lodowick] <span class="italic">Mugleton</span> himself, and [James] <span class="italic">Nailor</span>—are depicted as constituting a committee listening to petitions from a dog, a horse, a man and a woman. In the lefthand corner, the various victims of the Civil War appear in chains, while in the righthand corner, a Church of England parson is forced to vomit forth his living—a detail which may well owe something to <a href="">Doctor Panurgus</a>, which Sir Roger L'Estrange, who conceived the design for the present print and penned its text, had accorded his <span class="italic">imprimatur</span> eight years earlier.<sup>ii</sup>  At this period, L'Estrange was the most effective of the Tory propagandists, and the message of the print as a whole is to imply that the present day Puritan faction is plotting to seize Parliament and subvert the Government, which will lead to a second Civil War. When criticised for his part in the design, however, L'Estrange declared that the print was <span class="italic">a piece historical and only recommended by way of caution</span>.<sup>iii</sup>  The subtitle alludes to the present era of Popish Plot(s) and the verses describe how such misinformation is to be  </p>

        <p class="py-2 italic">
        …the Pulpits, and the Presses<br>
        Must ring of Popery, Grievances, Addresses,<br>
        Plots of all Sorts, Invasions, Massacres,<br> 
        Troops under Ground, Plague-Plaisters, Cavaliers…
        </p>

        <p class="py-2"><span class="italic">The Committee; or Popery in Masquerade</span> is extremely detailed visually, and also accompanied by 178 lines of verse commentary; it would require an essay-length commentary to do it justice.<sup>iv</sup>  Instead, I shall concentrate here on the petitioners or 'sectaries' themselves, whose alleged infamous behaviour is clearly intended to discredit all such schismatics.</p> 
        <p class="py-2">The petitioners before the Committee are, in fact, two animal/human couples: a dog named <span class="italic">Swash</span> and the <span class="italic">Elders Mayd</span>; and a horse and man, jointly labelled <span class="italic">The Colchester Wedding</span>. The verse to which the latter figures are keyed makes it clear that the man is a Quaker and the horse a mare, and it is clear that bestiality is implied</p>
        <p class="py-2">
          In a remarkable and amusing burlesque sermon, <span class="italic">The Exaltation of Christmas Pye</span> (1659), by one P.C. - allegedly <span class="italic">Dr. of Divinity and Midwifery</span> - as the parodic preacher is exemplifying the various types of <span class="italic">conjunction copulative</span>, he cannot resist referring, in passing, to what are evidently these same two pairs:
        </p>

        <p class="py-2 italic">
        your praeternatural Conjunction Copulative, as when an Elders maid lyes<br>
        with a Mastiff or as when a Quaker buggereth a Mare.
        </p>

        <p class="py-2">The former alleged act is the subject of Sir John Birkenhead's <span class="italic">The four-legg'd elder, or, A horrible relation of a dog and an elders maid to the tune of The lady's fall</span> (1647), reprinted three years before the present print. The elder is a Presbyterian - so his mastiff (which is, indeed, named Swash here) is counted one too - and his maid, Jane, was apparently born in Colchester.</p>

        <p class="py-2">Twelve years later Birkenhead was also the author of <span class="italic">The Four'legg'd Quaker To the Tune of the Dog and Elder's Maid, Or, the Lady's Fall </span>(1659), which concerns another Colchester resident, one Ralph Green, and an act that appears to have occurred six years earlier. This broadside is also illustrated with four individual woodcuts: a man with tongue out, advancing towards a horse, a centaur wearing a hat and holding a sword in one hand and the hilt only of another in the other hand, and a devil also wearing a hat advancing towards the latter holding a bridle.<sup>v</sup>  But Sir John could not help referring readers to his earlier composition in the accompanying verse text:</p>

        <p class="py-2 italic">
        But though 'twas foul 'tween Swash and Jane,<br>
        Yet this is ten times worse,<br>
        For then a Dog did play the Man,<br>
        But Man now play'd the Horse.
        </p>

        <p class="py-2">The same year Sir John Denham too was moved to publish an unillustrated broadside, <span class="italic">A relation of a Quaker that to the Shame of his Profession Attempted to Bugger a Mare near Colchester </span>(1659).<sup>vi</sup>
         </p>
        <p class="py-2"> 
          <span class="italic">The Committee</span> evidently achieved sufficient notoriety to make it worthwhile for some anonymous publisher to issue a somewhat inferior copy: this is BM Satires 1081. 
        </p>
        <h4 class="py-2 text-xl font-bold">Footnotes</h4> 
        <ul class="list-[lower-roman] list-inside">
          <li class="py-2">Antony Griffiths, <cite>The Print in Stuart Britain</cite> (London, 1998), p. 287. </li>
          <li class="py-2">It is pleasing to be able to add a sixth item to the list of five — all licensed to John Overton on 28th October 1672 — given by Antony Griffiths (op. cit., p. 148), viz. a second impression of <cite>The Common Weales Canker Worms</cite> (Globe knew only of the earlier Stent impression in the Sutherland Collection), in what I produce evidence to show in my forthcoming book is its third state. </li>
          <li class="py-2">L'Estrange, <cite>Answer to a whole Litter of Libels </cite>(1680), cited in F.G. Stephens, <cite>Catalogue of Prints and Drawings in the British Museum: Satirical and Personal Subjects</cite>, vol. 1 (1320-1689) (London, 1870), p. 627.</li>
          <li class="py-2">See Stephens, op. cit., pp. 623-7. For modern accounts of it see Tim Harris, <cite>London Crowds in the Reign of Charles II</cite> (Cambridge, 1987), pp. 139-40, Joseph Monteyne, <cite>The Printed Image in Early Modern London </cite>(Aldershot, 2007), pp. 173-4, and Helen Pierce, 'The Devil's Bloodhound: Roger L'Estrange Caricatured', in Michael Hunter (ed.), <cite>Printed Images in Early Modern Britain: Essays in Interpretation</cite> (Ashgate, 2010).  </li>
          <li class="py-2">Reproduced in Barry Reay, 'Popular hostility towards Quakers in mid-seventeenth - century England', <cite>Social History</cite>, 5 (1980), pp. 387-407 (p. 395). </li>
          <li class="py-2">Text edited in J. Wardroper, <cite>Lovers, Rakes and Rogues</cite> (London, 1995), pp. 216-18. It also appears in Denham's collected verse, i.e. <cite>Poems and translations with the Sophy</cite> (1668), under the title <cite>News from Colchester: or a Proper New Ballad of Certain Carnal Passages betwixt a Quaker and a Colt at Horsly near Colchester in Essex.</cite> </li>
        </ul> 
    </div>
</template>

<script>
    export default {
        data() {
          return { 
            imagePath : 'https://britishprintedimages.blob.core.windows.net/input-images/printsinfocus/',
            thumbnail : '',
            largeImage : ''
          }
        },
        methods: {
          getlargerImage() {
                let currentImagePath = this.imagePath + 'Committee.png' 
                window.open(currentImagePath, "_blank");
            },
        },
        created() {
          this.thumbnail = this.imagePath  + '3.jpg' 
        }
    }
  
  </script>