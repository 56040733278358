<template>
 <div>
        <h3 class="py-6 text-3xl">This Costly Fish Catcht In's Weil All These Desire To Tast As Wel As Feele. at All times in Season.</h3>
        <h4 class="py-2 text-xl">No.7  February 2007 </h4>
    </div>
    <div class='relative text-left overflow-y-auto'>
      <p class="py-2"> <strong>Copyright of the Ashmolean Museum, Oxford.</strong> </p>
      <p class="pt-2 pb-4"> <strong>Ashmolean Museum, Douce Prints W.1.2 (413). Dimensions of original: 292 mm x 415 mm </strong></p> 
        <span class="float-left pr-6 pb-2"><img :src="thumbnail" width="200"/> <button type="button" @click="getlargerImage()" class="underline font-bold" >View larger image</button></span> 

        <p class="pb-2">While the Department of Prints and Drawings of the British Museum is undoubtedly the single most significant repository of English single sheet prints of the period, there are several other important collections both in Britain and abroad, knowledge of which is essential for a proper overview of the genre, and we begin our sampling of these with the present print from the Ashmolean Museum, Oxford.  </p>

        <p class="pb-2">
        It formed part of the collection of Francis Douce (1757-1834), former Keeper of Manuscripts in the British Museum, who left the bulk of his personal collection to the Bodleian Library, but most of whose thousands of English and continental single sheets were recently transferred to the Ashmolean. Douce began assembling his print collection in order to provide authentic, contemporary illustrations for the socio-historical works he compiled, most notably his <span class="italic">Illustrations of Shakespeare, and of ancient manners: with dissertations on the clowns and fools of Shakespeare; on the collection of popular tales entitled Gesta Romanorum; and on the English Morris Dance</span>, first published in 1807. Though this enormous collection still awaits adequate cataloguing, already a number of significant unique English print items have been discovered within it.
        </p>

        <p class="pb-2">
        The present sheet - one of several English prints which survive uniquely in the Douce Collection -  is a very close copy in reverse of an untitled engraving by the unidentified C.I., published in Amsterdam by Hugo Allard the Elder c.1650.<sup>i</sup>  Our English sheet is entitled <span class="italic">This Costly Fish Catcht In's Weil/ All These Desire To Tast As Wel As Feele. at All times in Season.</span> A weil is a wicker trap for catching fish, especially eels,<sup>ii</sup> and in the Dutch original the naked woman in the weel is referred to as an eel or little eel. 
        </p>

        <p class="pb-2">
        On the bank of a river we see a naked young woman caught in what looks like a long, hooped net. It appears from the text that the fisherman who is pulling on the ropes attached to the net has just found this strange 'fish' in his net. Behind him - forming a sort of queue - are a monk, a gallant/Cavalier, a lawyer and an old man on crutches - again it appears from the text that each is eager to acquire the fisherman's catch. Standing in the fisherman's boat beside the net is another rather dumpy and fully-clothed young woman offering the fisherman a knife and urging him to cut the rope with it and throw his catch back, in order that the men should turn their attention to her - thus, implicit criticism of female jealousy is added to that of male lasciviousness.
        </p> 

        <p class="pb-2">
        The unidentified Dutch engraver, C.I., has expanded somewhat the design of a very popular plate in the influential <span class="italic">Pugillus Facetiarum</span> (Strasbourg, 1608, 1618, 1643, etc.) which shows a man (the fisherman) pulling the foot of the woman in the eel-pot, while a soldier, a monk (or priest?) and an old man on crutches look on. 
        </p>

        <p class="pb-2"> The verses under the image present the reactions of the various figures to their surprising catch. The full text is as follows:</p>

        <p class="pb-2">
        A [Fisherman, Peter]<br>
        <br> 
        Poter [<span class="italic">sic</span>: i.e., Peter] in's Weil by chance did light<br>
        Vpon a Mayd with Belly white.<br>
        Hee drew full hard, and layd about,<br>
        With all his force, to get her out<br>
        This Fish was such a goodly sight,<br>
        Hee longed for it, with delight.
        </p>

        <p class="pb-2">
        B [Monk, Father Steven]<br>
        <br> 
        Father Steven who stood by<br>
        His hands out from's sleeves, did cry,<br>
        Pull hard Peeter this present,<br>
        Let mee have to my Co[n]vent.<br>
        Doe so go, and make all even,<br>
        All thy sins shall be forgiven.
        </p>

        <p class="pb-2">
        C [Soldier, Robert the Cavalier]<br>
        <br> 
        Young Robert, to get such spoil,<br>
        His blood within his veins did boyl.<br>
        With gold that he had filld his purse,<br>
        All for this he will disburse.<br>
        Peter said he you are Old,<br>
        Heeres coin, go Drink, keep out ye cold.
        </p>

        <p class="pb-2">
        D [Doll]<br>
        <br> 
        Doll heard what each man said,<br>
        She vext, and was half dismaid,<br>
        Hold, she cried tis time I think<br>
        To cut the cord; let her sink.<br>
        You all strive to set her free,<br>
        Not one of you will pull for mee.
        </p>

        <p class="pb-2">
        E [Lawyer]<br>
        <br> 
        The learned Lawer he intends,<br>
        And for her, pleads, thus, my friends.<br>
        Bee not so earnest, for this chere,<br>
        In time may cost you all full deare.<br>
        It hath cost me pounds and pence,<br>
        Tis costly fare, with great expence.
        </p>

        <p class="pb-2">
        F [Old Man, Grandsire Hop]<br>
        <br> 
        Grandsire Hop<sup>iii</sup> said see you mee,<br>
        That haue scars<sup>iv</sup> one limb left free.<br>
        With Crutches I can hardly creep,<br>
        And yet I cannot chuse but peep.<br>
        The pox and gout wring mee sore,<br>
        Yet can I not give it ore.<sup>v</sup>  
        </p>

        <h4 class="py-2 text-xl font-bold">Footnotes</h4> 
        <ul class="list-[lower-roman] list-inside">
        <li class="py-2">There is an impression in the British Museum,  1875, 0508. 17 - incipit Pieter ving lest, in zen fuyk       </li>
        <li class="py-2">OED s.v. weel</li>
        <li class="py-2">Probably not a satirical name, cf. OED s.v. hop, from Defoe, Memoirs of a Cavalier (1724), p. 235, Away he hops with his crutch.  </li>
        <li class="py-2">scars = scarce.  </li>
        <li class="py-2">i.e., “Yet can I not give it o'er”, i.e. “Yet can I not give it over”, i.e. “Yet I can't give it up”. </li>
        </ul> 
  </div>   
</template>

<script>
    export default {
        data() {
          return { 
            imagePath : 'https://britishprintedimages.blob.core.windows.net/input-images/printsinfocus/',
            thumbnail : '',
            largeImage : ''
          }
        },
        methods: {
          getlargerImage() {
                let currentImagePath = this.imagePath + 'This Costly Fish.jpg' 
                window.open(currentImagePath, "_blank");
            },
        },
        created() {
          this.thumbnail = this.imagePath  + '24.jpg' 
        }
    }
  
  </script>
