<template>
    <transition name="modal">
      <div class="modal-mask">
        <div id='modalContainer' tabindex="-1" aria-modal="true" aria-label="Accessibility information modal" role="dialog">
          <div class='modal-header relative text-center'>
            <h3 class="w-5/6 pt-6 lg:text-3xl md:text-xl sm:text-md">Accessibility Features</h3>
            <span class="absolute top-5 right-10">
              <button type="button" aria-label="close" class="p-2 lg:text-lg sm:text-xs md:text-sm bg-slate-200 rounded" @click="closeHandler()" @keydown.enter="closeHandler()">Close</button>
            </span>
          </div>
          <div class='modal-body text-left overflow-y-scroll h-96'>
            <p class="py-2">This website has been checked manually for accessibility and by automated testing tools. It makes use of aria roles, labels and semantic html tags. </p>
            <p class="py-2">If you discover any issues with navigating or accessing resources on the site, please email : <a class="text-blue-600 dark:text-blue-500 hover:underline" href="mailto:bpi1700@newcastle.ac.uk">bpi1700@newcastle.ac.uk</a>, stating which browser you are using and be specific about the problems encountered. </p>
            </div>
        </div>
      </div>
    </transition>
  </template>
  
<script>

  export default {
      name: "AccessibilityModal",
      components: {
      },
      data() {
        return{
       
        }
      },
      methods: {
        closeHandler() {
            this.$emit('close');   
        }
      }
  }
  
  </script>
  
  <style scoped>
  
    .modal-mask {
      position: fixed;
      z-index: 9998;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .5);
      display: table;
      transition: opacity .3s ease;
    }
  
    #modalContainer {
      position: absolute;
      z-index: 100;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 50%;
      height: 50%;
      background: #FFF;
      border-radius: 25px;
    }
  
    .modal-body {
      padding: 50px;
    }
  
    .modal-body p {
      padding: 5px;
    }

  </style>
  