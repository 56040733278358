<template>
<div class="bg-slate-300 p-8 mb-10">
        <nav aria-label="Page navigation">

          <ul class="inline-flex -space-x-px text-base h-10 pagination">
            <li class="pagination-item">
              <button type="button" @click="onClickFirstPage" @keydown.enter="onClickFirstPage" :disabled="isInFirstPage" class="flex items-center justify-center px-6 h-16 leading-tight text-gray-500 bg-stone-100 text-gray-700 border border-gray-300 hover:bg-gray-100 hover:text-gray-700">First</button>
            </li>
            <li class="pagination-item">
              <button type="button" @click="onClickPreviousPage" @keydown.enter="onClickPreviousPage" :disabled="isInFirstPage" class="flex items-center justify-center px-6 h-16 leading-tight text-gray-500 bg-stone-100 text-gray-700 border border-gray-300 hover:bg-gray-100 hover:text-gray-700">Previous</button>
            </li>
            <li class="pagination-item"> 
              <button type="button" class="flex items-center justify-center px-6 h-16 leading-tight text-gray-500 bg-stone-100 text-gray-700 border border-gray-300 hover:bg-gray-100 hover:text-gray-700"> Page {{ showPageNumber }} of {{ totalPages }}</button></li>
            <li class="pagination-item">
              <button type="button" @click="onClickNextPage" @keydown.enter="onClickNextPage" :disabled="isInLastPage" class="flex items-center justify-center px-6 h-16 leading-tight text-gray-500 bg-stone-100 text-gray-700 border border-gray-300 hover:bg-gray-100 hover:text-gray-700">Next</button>
            </li>
            <li class="pagination-item">
              <button type="button" @click="onClickLastPage" @keydown.enter="onClickLastPage" :disabled="isInLastPage" class="flex items-center justify-center px-6 h-16 leading-tight text-gray-500 bg-stone-100 text-gray-700 border border-gray-300 hover:bg-gray-100 hover:text-gray-700">Last</button>
            </li>
          </ul> 
        </nav>
    </div> 
</template>

<script>

import { useBPIStore } from '@/store/BPIs'

export default {
  props: {
    totalPages: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true,
      default : 20
    },
    currentPage: {
      type: Number,
      required: true
    }
  },
  computed: {
    isInFirstPage() {
      return this.currentPage === 0;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages-1;
    },
    showPageNumber() {
      return this.currentPage+1;
    }
  },
  methods: {
    onClickFirstPage() {
      const bpiStore = useBPIStore();
      bpiStore.updateBPIIndex(0);
      this.$emit('pageChanged', 0, 'first');
    },
    onClickPreviousPage() {
      const bpiStore = useBPIStore();
      let newIndex =   bpiStore.BPI_index -20
      bpiStore.updateBPIIndex(newIndex);
      this.$emit('pageChanged', this.currentPage -1, 'back');
    },
    onClickNextPage() {
      const bpiStore = useBPIStore();
      let newIndex = bpiStore.BPI_index +20;
      bpiStore.updateBPIIndex(newIndex);
      this.$emit('pageChanged', this.currentPage, 'forward');
    },
    onClickLastPage() {
      const bpiStore = useBPIStore();
      let lastPage = bpiStore.getPageNum;
      console.log('last page is ' + lastPage);
      let newIndex = (lastPage-1)  * 20;
      bpiStore.updateBPIIndex(newIndex);
      this.$emit('pageChanged', this.totalPages-1, 'last');
    },
    isPageActive(page) {
      return this.currentPage === page;
    },
  }
};
</script>

<style>
.pagination {
  list-style-type: none;
}

.pagination-item {
  display: inline-block;
}

.active {
  background-color: '#AAAAAA';
  color: #ffffff;
}


</style>