<template>

  <div class="relative container mx-auto px-20 bg-gray-200">
      <HomeLink />  
      <HomeBanner />
      <NavMenu />

        <section class="bg-white dark:bg-gray-900 py-10">

          <h2 class="text-grey-600 dark:text-white text-3xl font-medium pb-5 pt-5">404 - Page not found</h2> 

          <p class="py-6">Sorry, the page you were hoping to find doesn't live at this url. </p>
      </section>
     
      <SimpleFooter />
    </div>
  </template>
    
  <!-- TODO add links -->

  <script>

  import HomeBanner from '@/components/HomeBanner.vue';
  import NavMenu from '@/components/NavMenu.vue';
  import SimpleFooter from '@/components/SimpleFooter.vue';
  import HomeLink from '@/components/HomeLink.vue';
   
  export default {
    data() {
        return {};
    },
    components: { 
      HomeLink,
      HomeBanner, 
      NavMenu,
      SimpleFooter
    },
    methods: {
      viewHomePage() {
      this.$router.push('/')
      },
    }
}
  </script>