<template>
 <div>
        <h3 class="py-6 text-3xl">The Funeral Obsequies of Sir. All-in-New-Fashions</h3>
        <h4 class="py-2 text-xl">No.13 September 2007 </h4>
    </div>
    <div class='relative text-left overflow-y-auto'>
      <p class="py-2"> <strong>Copyright of the Ashmolean Museum.</strong> </p>
      <p class="pt-2 pb-4"> <strong>Ashmolean Museum, Oxford, Douce Prints, Portfolio 138, no. 89. Dimensions of original: 209 mm x 303 mm.</strong></p> 
        <span class="float-left pr-6 pb-2"><img :src="thumbnail" width="300"/> <button type="button" @click="getlargerImage()" class="underline font-bold" >View larger image</button></span> 

        <p class="pb-2"><span class="italic">The Funeral Obseques [sic] of Sir. All-in-New-Fashions</span>, survives uniquely in the print-collection of Francis Douce (1757-1834) in the Ashmolean Museum, Oxford. It depicts a mock-funeral procession and is a fairly straightforward satire of extravagant male fashion issued c.1630. The imprint on the sheet reads, <span class="italic">Are to be Sould by Thomas Geele at the Dagger in Lumbard Street</span>. Little is known about the print-seller Thomas Geele,<sup>i</sup>  but as F.P. Wilson was the first to point out, he is known to have sold from the same address a suite of prints engraved by Robert Vaughan of the <span class="italic">Twelve Months of the Year</span> illustrated by couples in national dress, which now survive uniquely as bound into the Huntington Library copy of Breton's <span class="italic">Fantasticks</span> which was published in 1626. In 1630, still at the same address, his name appears on the second reprint of the <span class="italic">Baziliώlogia</span>, first issued in 1618, which contains over 30 prints of English monarchs. </p>

        <p class="pb-2">The satire may be simple enough, but the print is not quite what it seems, for it is an extremely close copy (in reverse) of <span class="italic">Trauer-Gesang/ Vber den all zu früe tödtlichen abgang/ Alla Modo Monsier</span> [Song of Mourning on the all too early deathly departure of <span class="italic">A la Mode Monsieur]</span>, or else (in the same direction) of <span class="italic">Vnuersehner Hochbetriebter Doch Lächerlicher Todfall</span>… [Unexpected … yet ridiculous death …], both dated 1629, the former explicitly on April Fools' Day.<sup>ii</sup>  It is also entirely typical that no fewer than six impressions of the former sheet survive in German repositories, whereas the English print survives uniquely in the Ashmolean Museum, Oxford. </p>

        <p class="pb-2">Harms assumes that the gallant's <span class="italic">Junckfraw, who ligt 6 woche[n]n zu bett</span> [literally, 'lies 6 weeks in bed'], must be lying in <span class="italic">Wochenbett</span>, i.e. childbed, and thus will eventually be delivered of an infant who will continue his father's fashion-victimhood. Be that as it may, the translator clearly understood her bedbound condition differently, Englishing it, <span class="italic">His wench 6 weekes through Greife doth keepe Her Bedd</span>.</p>

        <p class="pb-2">A long procession of mourners issues from beneath the upper room in which the grieving wench keeps her bed. Though dressed identically, and sporting the same pointed beard, the last few to emerge are labelled: <span class="italic">His Launderesses and their maydes</span>; <span class="italic">Spurrier</span> [maker of spurs]; <span class="italic">Fendsor</span>  [i.e. fencer = fencing instructor]; <span class="italic">Cutler; Musissioner</span> [musician]; <span class="italic">Taylor;Taylor; Gouldsmith</span>; clearly making a point, though not quite as heavy-handedly as the German original, in which no fewer than a dozen mourners are identified as tailors, in fact, every other mourner of those named. There are other—perhaps telling—minor differences in the tradesmen the English engraver has chosen to include or omit: if we can accept that his <span class="italic">Haberdasher</span> renders the German <span class="italic">Kramer</span>, it is interesting that he has translated the general <span class="italic">Kauffman as Wine Merchant</span>. But he has omitted five other tradesmen altogether: the <span class="italic">Dantzmeister</span> [dancing-instructor], <span class="italic">Bereitter</span> [riding-instructor], <span class="italic">Schwertfeger </span>[sword-maker], but also—more interesting from our point of view—the <span class="italic">Drucker</span> [printer] and <span class="italic">Kupferstecher</span> [engraver]. </p>

        <p class="pb-2">The German original is but one of a rash of prints appearing in 1629 satirising the adoption of French costume by fashionable German males. The French fashions being satirised may be seen, for example, in Abraham Bosse's <span class="italic">Le Jardin de la Noblesse Française</span> issued in Paris in 1629, a suite of 18 fashion-plates depicting French cavaliers dressed in the height of fashion. Antony Griffiths has shown how John Goddard curiously selected seven of this set to copy and issued them—perhaps in the later 1630s—as <span class="italic">The Seaven deadly Sins</span>.<sup>iii</sup>  </p>

        <p class="pb-2">The verses below our English funeral-procession claim—somewhat vaguely—that his <span class="italic">Long Coate, Capeles Cloake, Ruffes</span>, [and] <span class="italic">fallinge Bands</span> are <span class="italic">all fashions Lately brought from foreyne Lands</span>. But the image itself may serve as something of a cautionary tale for us—if we did not know its origin, it would be very tempting to use it as an illustration of English male fashion c.1630 and, indeed, too often historians have used such images irresponsibly, knowing nothing of their origins.</p>

        <p class="pb-2">For an unequivocally native English satire of male fashion we must wait until the engraved <span class="italic">Picture of an English antick, with a list of his ridiculous habits, and apish gestures</span> issued in 1646, in which the fashionable Cavalier beau is accompanied by a caption which enumerates no fewer than twenty-six details of his attire and accoutrements.</p>

        <p class="pb-2"><span class="italic">The Funeral Obseques of Sir All-in-New-Fashions</span> is not the only mock-funeral to appear as a print. When <span class="italic"></span>'The Twelve Years Truce' between Spain and the Netherlands failed in 1621, the breakdown was satirised in a sheet entitled <span class="italic">The Funerall of the Netherlands Peace</span> (1621), printed in the Netherlands from the plate etched by Claes Jansz. Visscher for the original Dutch version, <span class="italic">Testament van't Bestand, Treves Endt,</span> but with English letterpress verses added. Thus the Dutch title Treves Endt—which cannot have been immediately meaningful to the English viewer—still actually heads the sheet. Again this affords us a rare glimpse into the technique of printing bilingual versions of a print for consumption both at home and abroad (a German version also exists, copying the Visscher plate in reverse with German verse and labels). This funeral procession wends its way towards a mausoleum, the coffin of Truce, covered in a pall inscribed with her name, being borne on the shoulders of twelve bearers, one for each year of the duration of the truce. The text makes clear that it is the fault of the Spanish and their Catholic supporters in the Netherlands that the Twelve Year Truce has broken down.</p>

        <p class="pb-2">A third and later mock-funeral print is <span class="italic">The Funeral of the Low Church or the Whig's last Will and Testament</span> published in 1710.<sup>iv</sup>  </p>

        <p class="pb-2">A fully diplomatic transcription of the verses on the English sheet follows:</p>

        <p class="pb-2">
        THE FVNERAL OBSEQVES OF Sr. ALL=IN NEW FASHIONS:
        </p>
        <p class="pb-2">
        <span class="font-bold">1</span><br>
        <span class="italic">Gallantes; Sr. All in new Fashions is Dead;<br>
        Behold His hearse, Carried in Solemne Sorte:<br>
        His Wench 6 Weekes (through griefe) hath kept her Bedd.<br>
        Those men who Liud by him are all amorte;</span><sup>v</sup> <br>
        <span class="italic">Liud by him said I; noe, I am mistooke,<br>
        He Liud by them: his names in each mans Booke.</span><sup>vi</sup> <br>
        <span class="font-bold">2</span><br>
        <span class="italic">His Breeches, Dublet, Buffe coate,</span><sup>vii</sup> <span class="italic">Hatt and Fether;<br>
        his Spurres, Bootes, Garters, Gloues, Sword and Dagger;<br>
        instead of Flagges; are carried all to geather;<br>
        and other toyes, where with he used to swagger,<br>
        As Long Coate, Capeles Cloake,</span><sup>viii</sup> <span class="italic">Ruffes, fallinge Bands<br>
        all fashions Lately brought from forreyne Lands.</span><br>
        <span class="font-bold">3</span><br>
        <span class="italic">IF you Desire to see these thinges Agayne;<br>
        (for Fashions [ ; ] Ebb and flow as doth the Tyde)<br>
        Then make repayre, to Hounsditch, or Long Lane;<br>
        And there they Hange as Trophies of his Pride.<br>
        His Credit (with His Lyfe) being ended Here<br>
        tis thought hes gone, in Hell to Domynere.</span><sup>ix</sup> 
        </p>

        <h4 class="py-2 text-xl font-bold">Footnotes</h4> 
        <ul class="list-[lower-roman] list-inside">
          <li class="py-2">F.P. Wilson, 'The Funeral Obsequies of Sir All-in-New-Fashion', <cite>Shakespeare Survey</cite>, 11 (1958), pp. 98-9. </li>
          <li class="py-2">W. Harms, <cite>Deutsche Illustrierte Flugblätter der 16 und 17 Jahrhunderts</cite> (Tubingen, 1985- [in progress]), vol. 1, p. 131. </li>
          <li class="py-2">Antony Griffiths, <cite>The Print in Stuart Britain</cite> 1603-89 (London, 1998), no.63. </li>
          <li class="py-2">British Museum Satires no. 1531, reproduced as fig. 90 in Timothy Clayton, <cite>The English Print 1688-1802</cite>  (New Haven and London, 1997).</li>
          <li class="py-2">dejected</li>
          <li class="py-2">i.e., his name appears in their account books, as their bills have not been paid by him</li>
          <li class="py-2">a stout coat of leather. </li>
          <li class="py-2">a cloak without a cap/hood.</li>
          <li class="py-2">domineer.</li>
        </ul> 
  </div>   
</template>

<script>
    export default {
        data() {
          return { 
            imagePath : 'https://britishprintedimages.blob.core.windows.net/input-images/printsinfocus/',
            thumbnail : '',
            largeImage : ''
          }
        },
        methods: {
          getlargerImage() {
                let currentImagePath = this.imagePath + 'funeralobseques.jpg' 
                window.open(currentImagePath, "_blank");
            },
        },
        created() {
          this.thumbnail = this.imagePath  + '9.jpg' 
        }
    }
  
  </script>