<template>
    <transition name="modal">
      <div class="modal-mask">
        <div id='modalContainer' tabindex="-1" aria-modal="true" aria-label="Privacy information modal" role="dialog">
          <div class='modal-header relative text-center'>
            <h3 class="w-5/6 pt-6 lg:text-3xl md:text-xl sm:text-md">Privacy and Terms of Use</h3>
            <span class="absolute top-5 right-10">
              <button type="button" aria-label="close" class="p-2 lg:text-lg sm:text-xs md:text-sm bg-slate-200 rounded" @click="closeHandler()" @keydown.enter="closeHandler()">Close</button>
            </span>
          </div>
          <div class='modal-body text-left overflow-y-scroll h-96'>
            <p class="py-2">This site uses Google analytics to track user information, but you can disable the use of marketing cookies using the pop-up provided.</p>
            <p class="py-2"><img class="float-left py-2 pr-5" :src="privacyImage" alt="privacy image and settings link" width="50">You can review and change your privacy settings at any time by clicking on the image shown, (located bottom left of your main screen).</p>
          </div>
        </div>
      </div>
    </transition>
  </template>
  
<script>

import privacyImage from "../assets/privacy.png";

  export default {
      name: "PrivacyModal",
      components: {
      },
      data() {
        return{
          privacyImage
        }
      },
      methods: {
        closeHandler() {
            this.$emit('close');   
        }
      }
  }
  
  </script>
  
  <style scoped>
  
    .modal-mask {
      position: fixed;
      z-index: 9998;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .5);
      display: table;
      transition: opacity .3s ease;
    }
  
    #modalContainer {
      position: absolute;
      z-index: 100;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 50%;
      height: 50%;
      background: #FFF;
      border-radius: 25px;
    }
    
    .modal-body {
      padding: 50px;
    }
  
    .modal-body p {
      padding: 5px;
    }

  </style>
  