<template>
    <div class="relative container mx-auto px-20 bg-gray-200">
      <HomeLink />
      <HomeBanner />
      <NavMenu />
   
      <section class="bg-white dark:bg-gray-900">
          <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700 bg-white px-6 pt-12 pb-6 lg:px-8">
            <h1 class="mt-2  mb-6  text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Genres</h1>
            <h2 class="mt-4 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl text-left">Portraits</h2>
            <p class="mt-6 text-xl leading-8 text-left">Portraits were the commonest type of print. Tudor and early-Stuart portraits are mainly of royalty and nobility, though the compilation <span class="italic">Herωologia</span> included portraits of leading Englishmen in various walks of life. During the Civil Wars and the Restoration there was a demand for portraits of public figures for which print-sellers like Peter Stent and John Overton catered. The introduction of mezzotint printing at the Restoration was an important development for portraiture, the genre of print for which it was principally used. John Smith was the leading mezzotiner for nearly half a century until his death in 1743.</p>
            <div class="mt-10 text-left">
              <p>The variety of portrait prints suggests that they served a range of purposes. Large, single-sheet prints were intended as memorials of the person depicted. It seems likely that these were often hung up on the walls of houses, probably coated with varnish, though by definition those treated in this way have not survived. Smaller plates were often inserted in books or albums. Surviving examples include those of Samuel Pepys, preserved at Magdalene College, Cambridge, and that kept by the Somerset landowner and M.P., Sir Philip Sydenham, held in the Department of Prints and Drawings at the British Museum.</p>
            </div>
          </div>
      </section>
      <section class="bg-white dark:bg-gray-900">
        <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700 bg-white px-6 py-4 lg:px-8">     
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl text-left">Books of flowers, birds and animals</h2>
            <div class="mt-4 text-left">
              <p class="py-4">A popular genre from the early 17th century onwards, these books derived from Dutch exemplars. Many of the earliest examples were copied from originals published by Crispijn de Passe and others, including most of those produced by John Payne, who worked between 1620 and 1640. The books were popular partly for the information about natural phenomena that they conveyed, but also as pattern books for embroidery. Such books took on a new lease of life when the slightly stilted images purveyed by the likes of Payne were replaced by more naturalistic ones in the works produced by Francis Barlow and his successors. The genre survived well into the 18th century.  </p>
            </div>
          </div>
      </section> 
      <section class="bg-white dark:bg-gray-900"> 
        <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700 bg-white px-6 py-4 lg:px-8">     
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl text-left">Sets of allegories</h2>
            <div class="mt-4 text-left">
              <p class="py-4">Prints of this type became popular in the mid-17th century. They usually depicted human figures as representations of allegorical subjects like the 12 months, the 4 seasons, the 5 senses, the 7 deadly sins, the 7 liberal arts, or the 12 sybils. The database includes sets of the 4 seasons etched by Hollar (bpi105-08), the 7 deadly sins by John Goddard (bpi256-62), and the 7 liberal arts (bpi652-59) and the 4 senses by Francis Clein (bpi663-68). Anthony Griffiths, <span class="italic">The Print in Stuart Britain</span> (1998), pp. 307-13, provides a list of allegorical series produced in the period. </p>
            </div>
          </div>  
      </section> 
      <section class="bg-white dark:bg-gray-900 pb-6">
        <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700 bg-white px-6 py-4 lg:px-8">     
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl text-left">Genre prints</h2>
            <div class="mt-4 max-w-6xl text-left">
              <p class="py-4">These prints depicted a theme or topic from classical mythology or evoked a mood or milieu. Mid-17th century examples including Richard Gaywood's striking evocation of Democritus and Heraclitus (bpi1099). Mezzotints of a similar type were produced later in the century, for instance one by Isaac Beckett showing Cupid and Psyche, the former lying naked on a bed while the latter looks down on him (bpi1446). Robert Robinson specialised in such mezzotints, evoking classical scenes like 'The ruind Temple of Apollo'. Francis Place's engravings of imaginary landscapes produced are also good examples.</p>  
            </div>
          </div>     
      </section>
      <section class="bg-white dark:bg-gray-900 pb-6">
        <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700 bg-white px-6 py-4 lg:px-8">     
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl text-left">Satirical prints</h2>
            <div class="mt-4 max-w-6xl text-left">
              <p class="">Graphic satires could be political or more general. <span class="italic">Dr Panurgus</span>, an elaborate satire on fashionable folly and on the way in which doctors exploited their patients, is typical. Another good example <span class="italic">A New Year's Gift for Shrews</span> (c.1630), in which a nagging wife is chased off by the devil. Satire claimed to be reforming, exposing vice to mockery to cast it out of society.</p>  
              <p class="py-4">Political satires became prominent from the 1620s onwards, and ideological commentaries on current events were produced during the Civil Wars and after the Succession Crisis (1678-83) and Revolution of 1688, when graphic satire became a mainstay of political debate. <span class="italic">The Double Deliverance</span> (1621), an anti-Catholic satire on James VI/I's attempt to secure a Spanish marriage for Prince Charles that was copied in subsequent satires across the century, is an important example (bpi43). Equally significant are Sir Roger L'Estrange's <span class="italic">The Committee</span> (1680), a searing attack on Whig and Non-conformist Protestants during the Succession Crisis, and the range of satires produced by Romeyn de Hooghe after the Revolution of 1688 (bpi8528-39). </p>
            </div>
          </div>     
      </section>
      <section class="bg-white dark:bg-gray-900 pb-6">
        <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700 bg-white px-6 py-4 lg:px-8">     
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl text-left">Playing cards</h2>
            <div class="mt-4 max-w-6xl text-left">
              <p class="py-4">Packs of pictorial playing cards are intriguing - they treated serious topics in a playful way. What made them appealing, and what purpose did their often complex imagery serve? A deck of cards showing the maps of English counties dates from c. 1590, and another combining maps and mnemonic devices from c. 1605. Pictorial playing cards really came into vogue in the late-17th century, however. Many were political. Decks depicting the 'Knaveries of the Rump', Popish Plot, and the 1688 Revolution are typical. Others are didactic or recreational, including fortune-telling cards, educational tools, and maps of the countries. </p>  
            </div>
          </div>     
      </section>
      <section class="bg-white dark:bg-gray-900 pb-6">
        <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700 bg-white px-6 py-4 lg:px-8">     
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl text-left">Book illustrations</h2>
            <div class="mt-4 max-w-6xl text-left">
              <p class="py-4">Printed images featured prominently in books. They ranged from simple woodblock scenes and diagrams to series of engraved plates. Woodcuts were easier to use for book illustration than engravings because they could be printed in the middle of a typeset page and could be reused far more times. The earliest woodcut illustrations include those in William Caxton's edition of Chaucer's <span class="italic">Canterbury Tales</span> (1483) and woodcuts were used for cheap publications throughout our period. </p>  
              <p class="py-4">Engraved images had to be printed separately on a different press. Despite being more difficult to produce, they became increasingly prevalent. Thomas Geminus pioneered their use in his illustrations to Vesalius (1545). By the 1630s engraved plates were produced for works like Ovid's <span class="italic">Metamorphoses</span>, and in the 1650s and 1660s series of plates were included John Ogilby's editions of Aesop, Homer, and Virgil. Engraved illustrations were also included in scientific books. Robert Hooke's <span class="italic">Micrographia</span> (1665), which shared the findings of the microscope with the English public, is a classic example of their importance. </p>  
              <p class="py-4">Also notable are the antiquarian book illustrations produced by Hollar and others for Sir William Dugdale, in his <span class="italic">Monasticon Anglicanum</span> (1655) and <span class="italic">History of St Paul's Cathedral</span> (1658). The architectural engravings which appeared in David Loggan's lavish <span class="italic">Oxonia Illustrata</span> (1675), and its sequel <span class="italic"></span>Cantabrigia Illustrata (1690) were equally innovative. Similar works followed in the years around 1700. </p>  
            </div>
          </div>     
      </section>
      <section class="bg-white dark:bg-gray-900 pb-6">
        <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700 bg-white px-6 py-4 lg:px-8">     
            <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl text-left">Title pages</h2>
            <div class="mt-4 max-w-6xl text-left">
              <p class="py-4">Titlepages of books were among the period's most typical printed images. In the 16th-century they were predominantly woodcuts, but from c.1600 they were often engraved plates. Titlepages are often elaborate compositions which sought to encapsulate the theme of a work by emblematic or illustrative means. William Marshall's titlepage to the 1641 edition of Richard Brathwait's <span class="italic">English Gentleman and English Gentlewoman</span> is a good example (bpi1652). A series of emblems showing the attributes of male and female members of the landed class and adding up to a visual exposition of gentility. Other significant examples include the titlepage to John Foxe's <span class="italic">Actes and Monuments</span> (1563), which depicts a central theme of the martyrology, the battle of the Churches of Christ and Antichrist across history, and that of Thomas Hobbes's <span class="italic">Leviathan</span> (1651), which presents the central argument of that work, the need for a sovereign to overcome all other powers in a commonwealth. </p>  
            </div>
          </div>
          <div class="text-center">
            <a href="#" @click="viewResourcesPage" @keydown.enter="viewResourcesPage()" class="bg-slate-500 hover:bg-slate-600 text-white font-bold py-2 px-4 mt-4 mb-4 rounded focus:outline-none focus:shadow-outline inline-flex items-center w-40">Back to Resources </a>
          </div>
                   
      </section>

      <SimpleFooter />
    </div>
  </template>
    
    <script>
   
    import HomeBanner from '../components/HomeBanner.vue';
    import NavMenu from '../components/NavMenu.vue';
    import SimpleFooter from '../components/SimpleFooter.vue';
    import HomeLink from '../components/HomeLink.vue';
  
    export default {
      data() {
          return {};
      },
      components: { 
        HomeLink,
        HomeBanner, 
        NavMenu,
        SimpleFooter
      },
      methods: {
        viewResourcesPage() {
        this.$router.push('/resources')
        },
      }
  }
    </script>