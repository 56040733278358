<template>
<footer class="bg-slate-600">

    <!-- <div class="pt-16 pb-2 flex flex-wrap justify-center space-x-14 sm:space-x-8 "> -->
    <div class="pt-16 pb-2 mb-6 columns-3 sm:flex-wrap md:flex-wrap lg:flex lg:justify-evenly lg:space-x-12 ">
      <div class="md:mb-6 sm:mb-4 sm:ml-2"><img :src="nclLogo" alt="Newcastle University logo" width="250"></div>
      <div class="md:mb-6 sm:mb-4" ><img :src="ukri" alt="Arts and Humanities Research Council" width="250"></div> 
      <div class="md:mb-6 sm:mb-4 sm:mr-2"><img :src="bmLogo" alt="British Museum Logo" width="300"></div>
    </div>


  <div class="mx-auto max-w-7xl overflow-hidden px-3 py-5 sm:py-6 lg:px-4">
    <nav class="mb-6 columns-2 sm:flex-wrap md:flex-wrap lg:flex lg:justify-center lg:space-x-12 " aria-label="Footer links">
      <div class="pb-6 ">
        <a href="#" aria-label="accessibility" @click="activateAccessibilityModal()" @keydown.enter="activateAccessibilityModal()" class="text-lg leading-6 text-gray-100 hover:text-gray-900">Accessibility</a>
      </div>
      <div class="pb-6">
        <a href="#" aria-label="contributors" @click="activateContributorModal()" @keydown.enter="activateContributorModal()" class="text-lg leading s-6 text-gray-100 hover:text-gray-900">Contributors</a>
      </div>
      <div class="pb-6">
        <a href="#" aria-label="privacy" @click="activatePrivacyModal()" @keydown.enter="activatePrivacyModal()" class="text-lg leading-6 text-gray-100 hover:text-gray-900">Privacy and Terms of Use </a>
      </div>
    </nav>
    <p class="mt-10 mb-2 text-centre leading-5 text-gray-100">Email : <a href="mailto:bpi1700@newcastle.ac.uk">bpi1700@newcastle.ac.uk</a></p>
    <p class="text-gray-100">&copy; 2023 Newcastle Univerity</p>
  </div>
</footer>

  <AccessibilityModal v-if="showAccessibilityModal" @close="closeAccessibilityModal()"></AccessibilityModal>
  <ContributorModal v-if="showContributorModal" @close="closeContributorModal()"></ContributorModal>
  <PrivacyModal v-if="showPrivacyModal" @close="closePrivacyModal()"></PrivacyModal>
  
  </template>
  
  <script>

import nclLogo from  "../assets/ncl-logo2.png";
import bmLogo from "../assets/british-museum-logo.svg";
import ukri from "../assets/ukri-ahrc-square-logo.png";
import PrivacyModal from "../components/PrivacyModal.vue";
import ContributorModal from "../components/ContributorModal.vue";
import AccessibilityModal from "../components/AccessibilityModal.vue";
 

export default {
  name: 'SimpleFooter',
  components: {
    AccessibilityModal,
    ContributorModal,
    PrivacyModal
  },
  data() {
        return { 
             nclLogo,
             bmLogo,
             ukri,
             showPrivacyModal : false ,
             showContributorModal : false,
             showAccessibilityModal : false 
        }
    },
    methods: {
        activateAccessibilityModal(){
          this.showAccessibilityModal = true;
        },
        closeAccessibilityModal(){
          this.showAccessibilityModal = false;
        },
        activateContributorModal(){
          this.showContributorModal = true;
        },
        closeContributorModal(){
          this.showContributorModal = false;
        },
        activatePrivacyModal(){
          this.showPrivacyModal = true;
        },
        closePrivacyModal(){
          this.showPrivacyModal = false;
        }      
    }
}

 </script>  