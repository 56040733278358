<template>
    <div class="relative container mx-auto px-20 bg-gray-200">
      <HomeLink />  
      <HomeBanner />
      <NavMenu />

      <section class="bg-white dark:bg-gray-900">
          <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700 bg-white px-6 pt-12 pb-2 lg:px-8">
            <h1 class="mt-2 mb-12 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"> The Printmakers Directory</h1>

          <AccordionNav></AccordionNav>

          </div>
      </section>
      <SimpleFooter />
    </div>
  </template>
    
    <script>
   
    import HomeBanner from '@/components/HomeBanner.vue';
    import NavMenu from '@/components/NavMenu.vue';
    import SimpleFooter from '@/components/SimpleFooter.vue';
    import HomeLink from '@/components/HomeLink.vue';
    import AccordionNav from '@/components/AccordionNav.vue';
   
    export default {
      data() {
          return {};
      },
      components: { 
        HomeLink,
        HomeBanner, 
        NavMenu,
        AccordionNav,
        SimpleFooter
      },
      methods: {
       
      }
  }
    </script>