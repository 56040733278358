<template>

<div class="container container max-w-10xl m-auto flex flex-wrap flex-col md:flex-row justify-start bg-slate-500">

      <section class="w-full lg:w-1/2 p-0 bg-stone-200">
            <div class="flex flex-col lg:flex-row overflow-hidden h-auto border-t-0 border-r-2 border-l-2 border-b-2 sm:border-r-2 lg:border-t-2 min-h-[250px] border-slate-500">

                <div class="p-4 flex flex-col justify-between leading-normal">
                    <div class="flex items-center mb-3">

                        <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-slate-500 text-white flex-shrink-0">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
                          </svg>
                        </div>
                        <h2 class="text-grey-600 dark:text-white text-3xl font-medium pb-5 pt-5 mr-2">Producer/Printmakers</h2> 
                        <svg data-tooltip-target="tooltip-producer" data-tooltip-trigger="hover" data-tooltip-style="light" data-tooltip-placement="right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 text-slate-500">
                          <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 0 1 .67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 1 1-.671-1.34l.041-.022ZM12 9a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" />
                        </svg>
                        <div id="tooltip-producer" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 tooltip w-96">
                          Search for designers (e.g. Barlow), engravers (e.g. Hollar), and publishers. You can also search for painters whose work was subsequently engraved (e.g. Knollys or Lely).
                            <div class="tooltip-arrow" data-popper-arrow></div>
                        </div>
                    </div>
                    <div class="flex-1 text-left">
                      <form role="form" id="producer_search_form" aria-label="Producer search form">
                        <div class="mb-2">
                          <label for="producerSearchItem"
                            class="text-md font-bold tracking-tight text-gray-900 sm:text-xl text-left mb-6 mt-4 pb-2">Search Producer/Printmakers</label>
                          
                          </div>
                        <input type="text" id="producerSearchItem" v-model="producer_search_item"
                          class="block overflow-auto w-48 sm:w-96 border-0 py-2.5 pl-5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 placeholder:text-xs focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                          placeholder="Please enter your producer..." />
                        <div class="mt-2">
                          <input type="submit" @click="handleSearchRequests($event,'producer')" @keydown.enter="handleSearchRequests($event,'producer')"
                            class="bg-slate-500 hover:bg-slate-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-white" value="Submit" />
                            <span class="ml-4"><input @change="checkboxToggle" type="checkbox" id="producerDateFilter" class="" v-model="producerDateCheckbox"/> <label for="producerDateFilter" class="text-md">Apply <strong>Date Filter</strong></label></span>  
                            <span class="pl-4 ßmt-6 text-red-800" v-if="invalidProducerText" >Invalid characters entered</span>
                        </div>
                        <div class="mt-6 text-red-800" v-for="error of v$.producer_search_item.$errors" :key="error.$uid" >
                            <div>{{ error.$message }}</div>
                        </div>  
                      </form>
                  </div> 
                </div>
            </div>
        </section>
        
        <section class="w-full lg:w-1/2 p-0 bg-stone-200">
            <div class="flex flex-col lg:flex-row overflow-hidden h-auto border-t-0 border-r-2 border-l-2 border-b-2 sm:border-t-0 lg:border-l-0 lg:border-t-2 min-h-[250px] border-slate-500">

                <div class="p-4 flex flex-col justify-between leading-normal">
                    <div class="flex items-center mb-3">

                        <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-slate-500 text-white flex-shrink-0">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25" />
                            </svg>
                        </div>
                        <h2 class="text-grey-600 dark:text-white text-3xl font-medium pb-5 pt-5 mr-2">Subject</h2> 
                        <svg data-tooltip-target="tooltip-subject" data-tooltip-trigger="hover" data-tooltip-style="light" data-tooltip-placement="right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 text-slate-500">
                          <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 0 1 .67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 1 1-.671-1.34l.041-.022ZM12 9a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" />
                        </svg>  
                        <div id="tooltip-subject" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 tooltip w-96">
                          Use keywords to search the corpus. 'Subject' refers to objects depicted (e.g. scythes, spades, chairs), clothing (e.g. coats, boots, doublets, wigs), activity (e.g. fighting), the body and anatomy (e.g. foot, buttocks), ethnicity/nationality (e.g Asians, Germans), crime and punishment (e.g executions, chains, thieves), travel (e.g. walking, ships, roads), Leisure (e.g. theatres, smoking, toys), architecture (e.g. castles, niches, obelisks), music (e.g harps, lutes), and terms association with the military and war (e.g. medals, massacres, cavalry, spears).
                            <div class="tooltip-arrow" data-popper-arrow></div>
                        </div>  
                    </div>
                    <div class="flex-1 text-left">
                    <form role="form" id="subject_search_form" aria-label="Subject search form">
                      <div class="mb-2">
                        <label for="subjectSearchItem"
                          class="text-md font-bold tracking-tight text-gray-900 sm:text-xl text-left mb-6 mt-4 pb-2">Search by Subject</label>
                        </div>
                      <input type="text" name="subject-search-item" id="subjectSearchItem" v-model="subject_search_item"
                        class="block w-48 sm:w-96 border-0 py-2.5 pl-5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 placeholder:text-xs focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 invalid:[&:not(:placeholder-shown):not(:focus)]:border-red-500"
                        placeholder="Please enter your subject..." />
                      <div class="mt-2">
                        <input type="submit" @click="handleSearchRequests($event,'subject')" @keydown.enter="handleSearchRequests($event,'subject')"
                          class="bg-slate-500 hover:bg-slate-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-white" value="Submit" />
                          <span class="pl-4 ßmt-6 text-red-800" v-if="invalidSubjectText" >Invalid characters entered</span>
                      </div>
                      <div class="mt-6 text-red-800" v-for="error of v$.subject_search_item.$errors" :key="error.$uid" >
                          <div>{{ error.$message }}</div>
                      </div>  
                    </form>
                  </div>
                    
                </div>
            </div>
          </section>

          <section class="w-full lg:w-1/2 p-0 bg-stone-200">
            <div class="flex flex-col lg:flex-row overflow-hidden h-auto border-t-0 border-r-2 border-l-2 border-b-2 sm:border-r-2 lg:border-t-0 min-h-[250px] border-slate-500 ">

                <div class="p-4 flex flex-col justify-between leading-normal"> 
                    <div class="flex items-center mb-3">
                        <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-slate-500 text-white flex-shrink-0">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                          </svg>
                        </div>
                        <h2 class="text-grey-600 dark:text-white text-3xl font-medium pb-5 pt-5 mr-2">Person</h2> 
                        <svg data-tooltip-target="tooltip-person" data-tooltip-trigger="hover" data-tooltip-style="light" data-tooltip-placement="right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 text-slate-500">
                          <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 0 1 .67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 1 1-.671-1.34l.041-.022ZM12 9a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" />
                        </svg>
                        <div id="tooltip-person" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 tooltip w-96">
                          Search for contemporary figures depicted in a print or associated with it. This may be a monarch (e.g. Elizabeth I), noble (e.g. Essex), or a political figure (e.g Titus Oates or Henry Sacheverell). 'Person' also allows you to search for mythical figures (e.g. Ceres) or personifications (e.g. Peace, Africa).
                            <div class="tooltip-arrow" data-popper-arrow></div>
                        </div>
                    </div>
                    <div class="flex-1 text-left">
                    <form role="form" id="person_search_form" aria-label="Person search form">
                      <div class="mb-2">
                        <label for="personSearchItem"
                          class="text-md font-bold tracking-tight text-gray-900 sm:text-xl text-left mb-6 mt-4 pb-2">Search
                          by Person</label>
                      </div>
                      <input type="text" id="personSearchItem" v-model="person_search_item"
                        class="block w-48 sm:w-96 border-0 py-2.5 pl-5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 placeholder:text-xs focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 invalid:[&:not(:placeholder-shown):not(:focus)]:border-red-500"
                        placeholder="Please enter your person..." />
                      <div class="mt-2">
                        <input type="submit" @click="handleSearchRequests($event,'person')" @keydown.enter="handleSearchRequests($event,'person')"
                          class="bg-slate-500 hover:bg-slate-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-white" value="Submit" /> 
                          <span class="pl-4 ßmt-6 text-red-800" v-if="invalidPersonText" >Invalid characters entered</span>
                     </div>
                     <ul class="error mt-6 pl-4 text-red-800" v-for="error of v$.person_search_item.$errors" :key="error.$uid">
                              <li>{{ error.$message }}</li>
                          </ul>
                    </form>
                  </div>    
                    
                 </div> 
            </div>
          </section>
          <section class="w-full lg:w-1/2 p-0 bg-stone-200">
            <div class="flex flex-col lg:flex-row overflow-hidden h-auto border-t-0 border-r-2 border-b-2 border-l-2 lg:border-l-0 min-h-[250px] border-slate-500">

                <div class="p-4 flex flex-col justify-between leading-normal">
                    <div class="flex items-center mb-3">

                        <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-slate-500 text-white flex-shrink-0">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
                        </svg>
                        </div>
                        <h2 class="text-grey-600 dark:text-white text-3xl font-medium pb-5 pt-5 mr-2">Technique</h2>
                        <svg data-tooltip-target="tooltip-technique" data-tooltip-trigger="hover" data-tooltip-style="light" data-tooltip-placement="right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 text-slate-500">
                          <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 0 1 .67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 1 1-.671-1.34l.041-.022ZM12 9a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" />
                        </svg>
                        <div id="tooltip-technique" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 tooltip w-96">
                          Use the scroll down menu to select prints made by a particular technique or prints that have been coloured. Given that most prints in the period are engravings or mezzotints those techniques are not search options.
                            <div class="tooltip-arrow" data-popper-arrow></div>
                        </div>
                    </div>
                    <div class="flex-1 text-left">
                      <form role="form" aria-label="Technique search form">
                        <label for="technique"
                          class="text-md font-bold tracking-tight text-gray-900 sm:text-xl text-left mb-8 mt-4 pb-4">Search by Technique</label>
                            <select v-model="technique" id="technique" class="block appearance-none bg-white border-0 hover:border-gray-500 px-4 py-3 pr-8 mt-2 mb-2 rounded shadow leading-tight focus:outline-none focus:ring focus:ring-white">
                              <option v-for="(item, index) in techniqueList" :value="index" :key="index" > {{ item }}</option>
                            </select>
                            <input type="submit" @click="handleSearchRequests($event,'technique')" @keydown.enter="handleSearchRequests($event,'technique')"
                            class="bg-slate-500 hover:bg-slate-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-white" value="Submit" />
                      </form>
                  </div>
                    
                </div>
            </div>
          </section>
    
          <div class="text-left bg-stone-100 p-8 border-t-0 border-b-0 border-l-2 border-r-2 border-slate-500">
            <div>Using the <strong>Date Filter</strong> allows you to complete a more targeted search. Select
              the Date Filter checkbox, move the sliders to the desired range and then type into one of the search boxes and click submit. NB: Subject and person searches are not affected by the Date Filter
            </div>

            <!-- date slider -->
            <div v-show="showDateFilter" class="text-left bg-stone-100 mt-4 w-full">
              <h3 class="text-2xl pb-4 font-bold">Filter by Date</h3>

              <p class="text-md mb-4 pb-6">You can choose to restrict the number of search results returned by selecting a
                date range. Please move the slider handles to the desired points on the date scale.</p>

              <Slider class="mt-12" v-model="dateSlider.value" v-bind="dateSlider" :min="dateSlider.min"
                :max="dateSlider.max"> </Slider>
              <div class="text-right pt-4">
                <button type="button" @click="resetDateSlider"
                  class="bg-slate-500 hover:bg-slate-600 text-white font-bold py-2 px-4 mt-4 rounded focus:outline-none focus:ring focus:ring-white">
                  Reset slider
                </button>
              </div>  
             
            </div>
             <!-- clear search button -->
             <div class="text-left">
                <button type="button" @click="clearSearches" class="bg-blue-600 hover:bg-blue-700 text-white font-bold mt-4 py-2 px-4 rounded focus:outline-none focus:ring focus:ring-white">Clear searches</button>
              </div>
        </div>
      
         <!-- results/no results links-->
        <div class="pl-8">
          <div class="pb-6 text-white"
            v-if="resultsFound === true && resultShow === true">
            <span class="ml-4 pl-4 justify-center relative w-full">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
              </svg>
              <a href="#" @click="viewResultsPage" class="text-xl absolute top-4 left-5 w-52 pt-2">VIEW RESULTS PAGE</a></span>
          </div>

          <div class="pb-6 text-white"
            v-if="resultsFound === false && resultShow === true">
            <span class="ml-4 pl-4 justify-center relative w-full">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
              </svg>
              <a href='#' class="text-xl absolute top-4 left-5 w-52 pt-2">NO RESULTS FOUND</a></span>
          </div>

          <!-- spinner -->
          <div v-if="spinnerShow" class="pt-4 pb-4">
            <FwbSpinner v-if="spinnerShow" size="10" color="blue" />
          </div>
        </div>
 <!-- end container -->
</div> 
</template>

<script>
const path = process.env.VUE_APP_ROOT_API

import { initFlowbite } from 'flowbite';

import Slider from '@vueform/slider'
import axios from 'axios'
import router from '../router'
import * as dataService from '../services/dataService'
import { FwbSpinner } from 'flowbite-vue'
import { useVuelidate } from '@vuelidate/core'
import { useBPIStore } from '@/store/BPIs'
import { useImageDataStore } from '@/store/imagedata'
import { required, minLength, maxLength } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: { 
    Slider,
    FwbSpinner
  }, 
  data: () => ({
    dateSlider: {
      value: [1600, 1700],
      min: 1550,
      max: 1815
    },
    // _item vars match validations
    subject_search_item: '',
    person_search_item: '',
    producer_search_item: '',
    producer_name: '',
    technique: '',
    all_images: [],
    techniqueList: {
        'drypoint': 'Drypoint',
        'handcoloured': 'Hand-coloured',
        'letterpress': 'Letterpress', 
        'rubbing': 'Rubbing',
        'woodcut': 'Woodcut',
        'stipple' : 'Stipple'
    },
    showDateFilter: false,
    spinnerShow: false,
    resultShow: false,
    resultsFound: false,
    producerDateCheckbox: null,
    invalidPersonText : false,
    invalidSubjectText : false,
    invalidProducerText : false,
  }), 
  validations () {
    // matches v-model variables
    return {
      subject_search_item: { required, minlengthValue: minLength(3), maxLengthValue: maxLength(50) }, 
      person_search_item: { required, minLengthValue: minLength(3), maxLengthValue: maxLength(50) },
      producer_search_item: { required, minLengthValue: minLength(3), maxLengthValue: maxLength(50) }
    }
  },
  methods: {
    viewResultsPage() {
      router.push('/resultsViewPagination')
    },
    viewProducerPage() {
      router.push({
        name: 'Producer',
        params: {
          producerName: this.producer_name
        }
      })
    },
    setShow() {
      setTimeout(() => {
        this.resultShow = true;
        this.spinnerShow = false;
      }, 3000);
    },
    clearSearches() {
      localStorage.clear();
      const bpiStore = useBPIStore();
      const imageDataStore = useImageDataStore();
      bpiStore.clearBPI();
      bpiStore.clearBPIList();
      bpiStore.clearBPIPages();
      imageDataStore.clearImageDetails();
      this.data_bpis = [];
      this.subject_search_item = '';
      this.person_search_item = '';
      this.producer_search_item = '';
      this.search_techniques = [];
      this.resultShow = false;
      this.resultsFound = false;
      this.showDateFilter = false;
      this.producerDateCheckbox = null;
      this.invalidSubjectText = false;
      this.invalidPersonText = false;
      this.invalidProducerText = false;
      this.v$.$reset();
    },
    async handleSearchRequests(event, type) {
      event.preventDefault();

      // use pinia store
      const bpiStore = useBPIStore();
      const imageDataStore = useImageDataStore();
      let characterCheckResult = false;
      let result = false;
      // clear out any previous data in store
      bpiStore.clearBPI();
      imageDataStore.clearImageDetails();

      if(type === 'person'){
          result = await this.v$.person_search_item.$validate();
          characterCheckResult = this.characterCheck(this.person_search_item);
        if (!result || !characterCheckResult) {
          // show error message
          if(!characterCheckResult){
            this.invalidPersonText = true;
          }
          return;
        }
      }
      else if (type === 'subject'){
        result = await this.v$.subject_search_item.$validate();
        characterCheckResult = this.characterCheck(this.subject_search_item);
        if (!result || !characterCheckResult) {
          // show error message
          if(!characterCheckResult){
            this.invalidSubjectText = true;
          }
          return;  
        }
      }
      else if (type === 'producer'){
        result = await this.v$.producer_search_item.$validate();
        characterCheckResult = this.characterCheck(this.producer_search_item);
        if (!result || !characterCheckResult) {
          // show error message
          if(!characterCheckResult){
            this.invalidProducerText = true;
          }
          return;  
        }
      }
      // display changes
      this.removeMessage();
      this.resultShow = false;
      this.spinnerShow = true;

      // try to get results then stop the spinner
      try {
         await this.getImageResults(type); 
        }
        catch (err) {
          console.log('Error fetching data:', err)
        }
        finally {
          // add a timeout so that data has a chance to load into localstorage, even when network calls are done.
          this.setShow()
        }
    },
    async getImageResults(type){
        if (type === 'person'){
            await this.getImages('person',this.person_search_item),
            await this.getImages('label',this.person_search_item)
        } 
        else if (type === 'subject'){
            await this.getImages('subject', this.subject_search_item);
            await this.getImages('subject_index', this.subject_search_item);
            await this.getImages('event', this.subject_search_item);
        }
        else if (type === 'producer'){
          if (this.showDateFilter === true) {
            await this.getImagesByDate('producer',this.producer_search_item, this.dateSlider.value);
          }
          else {
            await this.getImages('producer',this.producer_search_item);
          }
        }
        else if (type === 'technique'){
          await this.getImages('technique',this.technique);
        }
    },
    checkboxToggle() {
      if (this.showDateFilter == false) {
        this.showDateFilter = true;
      }
      else if (this.showDateFilter == true) {
        this.showDateFilter = false;
      }
    },
    resetDateSlider() {
      this.dateSlider.value = [1600, 1700]
    },

    // API functions
    async getImages(search_type, search_item) {
      try {
        let api = '';

        if(search_type === 'subject'){
          api = 'image_subject_search/';
        }
        else if (search_type === 'subject_index'){
          api = 'image_subject_search_by_index/';
        } 
        else if (search_type === 'event'){
          api = 'image_event_search/';
        } 
        else if (search_type === 'person'){
          api = 'image_person_search/';
        } 
        else if (search_type === 'label'){
          api = 'image_label_search/';
        } 
        else if (search_type === 'producer'){
          api = 'image_producer_search/';
        } 
        else if (search_type === 'technique'){
          api = 'image_technique_search/';
        } 
        // call the api
        const response = await axios.get(
          path + api + search_item
        )
        if(response !== null && response !== undefined){
          await this.handleResponse(response, search_item);
        }
      } catch (err) {
          console.log('Error fetching data:', err)
      }
    },
    async getImagesByDate(search_type, search_item, sliderValues) {
      try {
        let api = '';
        if (search_type === 'producer'){
          api = 'image_producer_search_date/';
        } 
        // call the api
        const response = await axios.get(
          path + api + search_item + '/' + sliderValues[0] + '/' + sliderValues[1]
        )

        if(response !== null && response !== undefined){
          await this.handleResponse(response, search_item);
        }
      } catch (err) {
          console.log('Error fetching data:', err)
      }
    },
    async handleResponse(response, search_item) {

      let data_bpis = [];
      let search_data = [];

      if (!response.data.recordset || response.data.recordset.length === 0) {
        console.log('No ' + search_item + ' data');
      } 
      else {
        search_data = response.data.recordset;
       
        search_data.forEach((object) => {
          if (object.BPI_catKey !== null) {
            data_bpis.push(object.BPI_catKey)
          }
        })
        // TODO put in pinia action
        if (data_bpis.length !== 0) {
          await this.callDataService(data_bpis)
          this.resultsFound = true;
        }
      } 
    },
    removeMessage() {
      this.inProdList = false;
    },
    removeDuplicates(array){
      let unique = [];
      array.forEach(element => {
        if(!unique.includes(element)){
          unique.push(element)
        }
      });
      return unique;
    },
    characterCheck(search_item){
      // allow spaces and apostrophes as valid characters
      const custRegex = /^[a-zA-Z\s']*$/;
      if(custRegex.test(search_item)){
         return true;
      }
      return false;
    },
    // handle writes to localstorage and pinia
    async callDataService(data_bpis){
      // use pinia store
      const bpiStore = useBPIStore();
      // add all BPIs to pinia store
      bpiStore.addToBPIArray(data_bpis); 
      bpiStore.updateBPIIndex(0);
      const myFirstChunk = bpiStore.getBPIChunk(0);
      const values = myFirstChunk.toString();
      // set bpi list of 20 results into pinia
      bpiStore.addToBPIList(values);

      // this section is for pagination 
      myFirstChunk.forEach(async bpi_num => {
        if (bpi_num !== null) {
          await dataService.getAllImageDetails(bpi_num); 
        }
      })

      bpiStore.BPI_array.forEach(async bpi_num => {
        if (bpi_num !== null) {
          await dataService.getAllImageDetails(bpi_num); 
        }
      })
    }
  },
  mounted() {
    initFlowbite()
  }
}
</script>

<style src="@vueform/slider/themes/default.css"></style>

<style>
.slider-connect,
.slider-tooltip {
  background-color: slategray;
  border-color: slategray;
}
</style>