<template>
 <div>
        <h3 class="py-6 text-3xl"> A Jesuit Displaid</h3>
        <h4 class="py-2 text-xl">No.9 April 2007 </h4>
    </div>
    <div class='relative text-left overflow-y-auto'>
      <p class="py-2"> <strong>Copyright of the Trustees of the British Museum.</strong> </p>
      <p class="pt-2 pb-4"> <strong>British Museum 1849,1001.11. Dimensions of original: 448 mm x 308 mm</strong></p> 
        <span class="float-left pr-6 pb-2"><img :src="thumbnail" width="300"/> <button type="button" @click="getlargerImage()" class="underline font-bold" >View larger image</button></span> 

        <p class="pb-2">A print that may be considered truly popular is <span class="italic">A Jesuit Displaid</span> of c.1680, bearing the imprint of Arthur Tooker. It is a composite portrait bust in the manner of Arcimboldo, the verse text below explaining, for example, that the Jesuit's ear-ring is <span class="italic">Faux's Lanthorne</span>, and the various tools are those appropriate to the various occupations a Jesuit would be obliged to adopt for the purpose of disguising his true mission; as the caption puts it, </p>

        <p class="pb-2 italic">
        Gardner, Groome, Cooke, he's everything to all,<br>
        So his Laborious Zeale may dam- a Soul.
        </p>

        <p class="pb-2">On the other hand, the pair of winged horns above his head <span class="italic">denote hoe [sic] he committs Swift Lusts and Rapine in Adulterous sheetes</span> — a slur more usually directed in the past at the representatives of the mendicant orders, but that, perhaps, is implicit in the verse-writer's reference to him as <span class="italic">This wandring Father</span>.</p>

        <p class="pb-2">The Jesuits were a popular bugbear. As early as the 1620s at least two engravings depict them sitting in council, plotting round a table, and in another Print in Focus we will note the <span class="italic">Ignatians' conclave</span> in the hell-scene of the probably contemporary <span class="italic">Powder Treason</span> sheet. One of the <span class="italic"></span>Three Grand Enimies to Church and State portrayed in the engraved frontispiece to Sheppard's <span class="italic">The Times Displayed in Six Sestyads</span> (1646) is <span class="italic">Jesuitecall Pollicie</span>, whose caption opens, <span class="italic">Till Antechrist shall ruind bee Great combustions wee must see...</span> The engraved frontispiece to <span class="italic">The Plots of Jesuites…</span> (1653) continues this theme, showing the pope, cardinal and priests also seated round a table plotting, as the title goes on to say, <span class="italic">How to bring England to the Romane Religion without tumult</span>. One of the three Jesuit tracts included in it is by Robert Parsons (d. 1610), who notoriously encouraged Philip II of Spain to invade and convert England. It is a pity that what was evidently a broadside satire listed in Stent's 1662 advertisement as <span class="italic">Cornelius of Dort brings Parsons to confession</span> has not come down to us, but <span class="italic">The Jesuits Loyalty</span> (1677) includes portraits of both Parsons and Garnet.</p>

        <p class="pb-2">A book issued in 1641 entitled <span class="italic">The rat-trap: or, The Jesuites taken in their owne Net, &c. Discovered in this yeare of Jubilee, or Deliverance from the Romish faction</span>, includes a woodcut of a <span class="italic">Jesuiticall Romist</span> who <span class="italic">with a rusty dagger, stabbed Justice Heywood, as he was going to the parliament house to deliver up a catalog of divers papists and Jesuites names…</span> and this is presumably the incident responsible for the inclusion of the word <span class="italic"></span>Daggers that issues from the mouth of the Jesuit in Vicars' <span class="italic">November the 5, 1605. The Qvintessence of Cruelty</span>. Similarly, <span class="italic">The Solemn Mock procession</span> that took place in London on November 17th 1679 included <span class="italic">Six Jesuits with bloody daggers</span> (see further below).</p>

        <p class="pb-2">A more significant and compendious image is the engraved frontispiece to <span class="italic">Pyrotechnica Loyalana, Ignatian fireworks</span> (1667), which suggests that the Jesuits were responsible for the previous year's Great Fire of London (depicted at the top left of the composition), and two men, one of them a Jesuit, are shown taking flammable materials into a London house—opposite this scene, the familiar image of Fawkes about to enter the cellars of Parliament. An enthroned Pope fans the flames of the burning city with bellows, while a Jesuit priest with the legend <span class="italic">ab igne natus</span> [born of the fire] written on his back, punning on Loyola's forename, releases yoked foxes with firebrands tied to their tails (Samson's stratagem against the Philistines as recorded in Judges 15.4), and by pointing, links the house-burners with a man labelled Hubert (a simple-minded French Protestant watch-maker who falsely confessed to starting the fire), who is receiving a fire-bomb from another Jesuit priest with the initials P.H. (see further below). The designer may have taken some of this imagery from Faithorne's far more accomplished print of Cromwell issued nine years previously. [SEE <router-link :to="`/printsInFocusView/7`" class="text-blue-600 dark:text-blue-500 hover:underline">Emblem of England's Distractions</router-link>].
        </p>

        <p class="pb-2">That the Jesuits were indeed responsible for the Great Fire of London, and were trying to do the same again, was one of the allegations made by Titus Oates at the time of the spurious Popish Plot in 1678. The first of the twelve constituent scenes of <span class="italic">The popish Damnable Plot against our Religion and Liberties (December 1680) describes the Burning of London, which hath been proved undeniably by Dr. Oates… to be contrived and carried on by the Papists</span>. Indeed, the myth was preserved into modern times, by the inscription on the Monument commemorating the Fire. <span class="italic">The Happy Instruments of Englands Preservation</span> (April 1681) shows the Pope in conclave with four cardinals and eight Jesuits recognisable by their distinctive bonnets, one of whom holds a commission inscribed to <span class="italic">fire the City</span>, another—reprising Garnet and <span class="italic">The Pope's Pardon</span>—holds up a dagger, on his scroll the words, <span class="italic">The daggers consecrated and heres my Pardon</span>.</p>

        <p class="pb-2">And London is shown burning in various anti-Catholic sheets, e.g. <span class="italic">The Catholick Gamesters or A Dubble Match of Bowleing </span>(February 1680) engraved by Stephen College, which also includes six bonneted Jesuits (all identified by name) above the caption, <span class="italic">London Burnt by Popish preists</span> [sic] <span class="italic">and Jesuits Sep: 2 1666</span>. Two carry torches while two hurl fire-bombs at the burning buildings. One of them is labelled <span class="italic">Harcourt</span>—there were several contemporary English Jesuits who used this name, and it may be that he is identical with the mysterious P.H. of the earlier <span class="italic">Pyrotechnica Loyalana</span>, the P. being perhaps the abbreviation of a title, such as Provincial or Procurator, rather than a forename.<sup>i</sup>  </p>

        <p class="pb-2">On the anniversary of Queen Elizabeth's death in the years 1679-81 the staunchly anti-Catholic Whig party organised huge 'solemn mock processions' which paraded their way rowdily through the City. The 1681 crowd smashed the windows of a tavern that was still unwisely trading at the sign of The Pope's Head.<sup>ii</sup>  An establishment of the same name in Cornhill had prudently changed its name to The Bishop's Head as long ago as 1542, and another in Southwark to The King's Head in the same year!<sup>iii</sup>  The prose <span class="italic">Explanation</span> to the 1680 print opens:</p>

        <p class="pb-2"><span class="italic">You must first know the occasion of this Mock Procession to have been, that the Pope, Fryars, and their Abettors here in England, contrived the Lamentable Burning of London; some Protestant Gentlemen, partly in a thankful Commemoration of their Deliverance, and partly to raise a just Abhorrency of such Popish practicers, do now bring these Incendiaries in Effigie to the Fire they have better deserved.</span>  </p>

        <p class="pb-2">One of the pageant floats included four Jesuits holding daggers, though, as we have noted, the previous year's procession had featured <span class="italic">Six Jesuits with Bloody Daggers</span>.  </p>

        <h4 class="py-2 text-xl font-bold">Footnotes</h4> 
        <ul class="list-[lower-roman] list-inside">
          <li class="py-2">William Waring, for example, who was known in England as Harcourt, one of the Jesuits hanged at Tyburn on 20th June 1679, thanks to Oates's allegations, was Procurator of the English province by 1671 [ODNB]. He has a walk-on post-mortem role as one of the <cite>late Sainted Traytors</cite>, in <cite>The dreadful apparition; or, the pope haunted with ghosts</cite> (1680), a broadside headed by an impressive engraving. </li>
          <li class="py-2">S. Williams, 'The Pope-burning processions of 1679, 1680 and 1681', <cite>Journal of the Warburg and Courtauld Institutes</cite>, 21 (1958), 104-17, on p. 105.  </li>
          <li class="py-2">B. Lillywhite, <cite>London Signs</cite> (London, 1972), p. 416, items 1870 and 11581, respectively.  </li>
        </ul> 
  </div>   
</template>

<script>
    export default {
        data() {
          return { 
            imagePath : 'https://britishprintedimages.blob.core.windows.net/input-images/printsinfocus/',
            thumbnail : '',
            largeImage : ''
          }
        },
        methods: {
          getlargerImage() {
                let currentImagePath = this.imagePath + 'Jesuit.png' 
                window.open(currentImagePath, "_blank");
            },
        },
        created() {
          this.thumbnail = this.imagePath  + '12.jpg' 
        }
    }
  
  </script>