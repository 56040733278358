<template>
 <div>
        <h3 class="py-6 text-3xl"> The Infallible Mountebank, or Quack Doctor</h3>
        <h4 class="py-2 text-xl">No.23 September 2008 </h4>
    </div>
    <div class='relative text-left overflow-y-auto'>
      <p class="py-2"> <strong>Copyright of the Trustees of the British Museum.</strong> </p>
      <p class="pt-2 pb-4"> <strong>British Museum, Department of Prints & Drawings, 1850, 1109.3. Dimensions of original: 327 mm x 232 mm.</strong></p> 
        <span class="float-left pr-6 pb-2"><img :src="thumbnail" width="200"/> <button type="button" @click="getlargerImage()" class="underline font-bold" >View larger image</button></span> 

        <p class="pb-2">The mountebank or quack doctor and his assistant would perform an act on a stage in order to sell the wonder-working drugs by which they made their living, before moving on, long before their clients had time to realise they had been duped.<sup>i</sup>  By the seventeenth century, representations of mountebanks had become relatively commonplace on the Continent,<sup>ii</sup>  and in England are to be found in crude broadside ballad woodcuts such as <span class="italic">The Skilful Doctor; Or, The Compleat Mountebank</span> (1685, 1686/7), as well as in contemporary prints. </p>

        <p class="pb-2">A relatively unproblematic print is that entitled, <span class="italic">The Mountebanck Doctor and his Merry Andrew</span>, engraved in London by the little-known Edward (Le) Davis (active 1671-after 1691), after a painting by Frans Hals. It must therefore date some time after Davis's return from France in 1675, though the only known state bearing Henry Overton's imprint must belong to the earlier part of the eighteenth century (active 1707-51). In the verses the Doctor confesses that, 'tho I in a Velvet Coat Appear / I am not worth one single Groat a Year'.</p>

        <p class="pb-2">Our present <span class="italic">Infallible Mountebank, or Quack Doctor</span><sup>iii</sup>  shows the Doctor on stage holding out a paper (a testimonial letter?) in one hand, and a tiny phial in the other, his chest of drugs and performing monkey at his feet, and his 'merry andrew' dressed like a harlequin appearing from behind a curtain and pointing at him.<sup>iv</sup>  The print is undated, though another simplified woodcut version bears an imprint with the date 1707, <sup>v</sup>  and a clear <span class="italic">terminus post quem</span> is provided by Marcellus Laroon's <span class="italic"></span>Mountabanck from which it plainly derives, published as part of his <span class="italic">Cryes of the City of London Drawne after the Life </span>(1688), engraved by John Savage.<sup>vi</sup>  Laroon's model was said to have been one Hans Buling, a quack of Dutch extraction, apparently, who enjoyed some notoriety in London in the 1670s.</p>

        <p class="pb-2">The text which accompanies the print appears in Motteux's play <span class="italic">Farewel folly: or, the younger the wiser. A comedy as it is acted at the Theatre Royal. With a musical interlude, call'd The mountebank: or, the humours of the fair</span>, published in 1707, though it is known to have been earlier staged at the Drury Lane Theatre in January 1705.<sup>vii</sup>  While 'songs from the shows' would often be printed and issued separately—and the singling out of the 'musical interlude' on the title-page of the play is tantamount to implying such separate publication—the present sheet prints the lyric but no music. Perhaps our print might better be considered as one of the earliest illustrations of a scene as acted in a stage-play. There is at least one (easily missed) detail to support this suggestion—the pair of cuckold's horns the 'zany' holds above his head. </p>

        <p class="pb-2">We learn from the 'Mountebank 's Zany's Speech' in Motteux's play that his Master's name is 'Kill-mob', incidentally, and it is evident from the speech that during the course of his patter he produces various unsavoury relics which testify to the efficacy of the quack's cures, including: </p>

        <p class="pb-2">'this Hide-clearing, wrinkle-smoothing, Snout-adorning Butter of Beauty… his Superexcellent Nostrum … It makes the Lame, See, and the Blind Walk…'Twill do yet more, 'twill make a Woman hold her Tongue… this Precious Stone… He took it out of the Great Mogul's Pimp's Bladder, with the Point of an Oyster Knife... this small Butter-tooth, he wheadl'd out of the Nether Jaw of a Frow, who caught Cold on a Visiting Day, for want of her Stove…'</p>

        <p class="pb-2">'And this Short Excrescence (as like a Horn as ever you saw since you were born) out of the Forehead of one of the Dons of Cuckold's-haven'</p>

        <p class="pb-2">It is this last detail of the zany's <span class="italic">spiel</span> that explains the curious pair of horns fitted with a handle which he brandishes above his head, his pointing at his Master perhaps cheekily insinuating that he not only can cure cuckolds but is himself one too.</p>

        <p class="pb-2">The precise date of our print is of more than 'academic' significance, it is also of the greatest interest to students of traditional drama, in that the doggerel lines below the image in which the mountebank boasts of the many diseases he can cure—many of them non-existent and nonsensical—are practically identical to those used by the Quack-doctor in the English mummers' play, and may thus have important implications for dating the plays. (Full text given below.)  </p>

        <p class="pb-2">It must have been relatively popular, for a surprising number of impressions of the sheet survive, as well as other versions, e.g. with image re-engraved in reverse, or in woodcut.<sup>viii</sup>  </p>

        <p class="pb-2">Another English quack-doctor print is the marvellously-titled <span class="italic">Pharmacopola Circumforaneus, Or The Horse Doctor's Harangue to the Credulous Mob </span>[1714].<sup>ix</sup> It depicts the well-dressed Doctor and his assistant on horseback, handing out papers or packets to an eager crowd, and it too is accompanied by a lengthy, extravagant text. Stephens pointed out that in alluding to 'Signior Hanesio', i.e. Jo. Haines, the anonymous author of the present text closely based his opening paragraph on <span class="italic">Jo. Haine's 2d Letter to Will's Coffee-house</span>, as published in <span class="italic">A Continuation or Second Part of the Letters from the Dead to the Living</span> by Tom Brown, vol. 2 (2nd ed., 1707).<sup>x</sup>  </p>

        <p class="pb-2"><span class="italic">An Apish Mountebanck</span> is how a scene featuring three monkeys in a late seventeenth-century 'panorama' held in the Yale Center for British Art is labelled. While one of the apes sits on a bench, another appears to be examining his foot, while a third holds what is presumably a urine-flask. In fact, the group derives from a similar group in a mid-seventeenth-century Dutch <span class="italic">singerie</span> of a barber-surgeon's shop engraved by Coryn Boel (d. 1668) after David Teniers the Younger.<sup>xi</sup> </p>

        <p class="pb-2">Before leaving the subject of quack doctors and their nostrums, we should note the real-life early seventeenth-century practitioner, Humphrey Bromley of Shrewsbury, whose German advertisement survives uniquely in the collection of Strasbourg University Library. His advertisement is headed by a woodcut image of a creature intended to be a hippopotamus—as that was the secret ingredient in the panacea he was touting about—and the text claims he has satisfied customers even amongst the crowned heads of Europe. Co-incidentally, we hear of him again in Norwich in 1616, when he is recorded as having been granted</p>

        <p class="pb-2">'libertie to shewe in some howse within this Citty A strange Child with two heades And that by the space of two dayes and no more. But he ys forbidden to sound any Drumme or vse any other meanes to drawe company then onely the hangynge upp of the picture of the said Child.'<sup>xii</sup> </p>

        <p class="pb-2">'A strange Child with two heades' was presumably what we should now term conjoined (formerly, 'Siamese') twins, and presumably the unfortunate children were still alive, providing for 'Dr.' Bromley a lucrative income. The 'picture of the said Child' which the city authorities permitted him to hang up to advertise his show would presumably have been a poster-size version of the unfortunate children. Prints of conjoined twins are a subject in their own right, but not one we have space for here.</p>

        <p class="pb-2 font-bold">
        The Infallible Mountebank, or Quack Doctor (transcription)
        </p>

        <p class="pb-2">
        SEE SIRS, see here!<br>
        a Doctor rare,<br>
        who travels much at home!<br>
        Here take my Bills,<sup>xiii</sup> <br>
        I cure all ills,<br>
        past, present, and to come;<br>
        The Cramp, the Stitch,<br>
        The Squirt, the Itch,<br>
        the Gout, the Stone the Pox;<br>
        The Mulligrubs,<sup>xiv</sup> <br>
        The Bonny Scrubs,<sup>xv</sup> <br>
        and all Pandora's Box;<br>
        Thousands I've Dissected,<br>
        Thousands new erected,<sup>xvi</sup> <br> 
        and such Cures effected,<br>
        as none e're can tell.<br>
        Let the Palsie shake ye,<br>
        Let the Chollick rack ye,<br>
        Let the Crinkums<sup>xvii</sup> break ye,<br>
        Let the Murrain<sup>xviii</sup> take you;<br>
        take this and you are well.<br>
        Come wits so keen,<br>
        Devour'd with Spleen;<br>
        come Beaus who sprain'd your Backs,<br>
        Great Belly'd Maids,<sup>xix</sup> <br> 
        Old Founder'd Jades,<br>
        and Pepper'd Vizard Cracks.<sup>xx</sup> <br> 
        I soon remove,<br>
        The Pains of Love,<br>
        and cure the Love-Sick Maid;<br>
        The Hot, the Cold,<br>
        The Young, the Old,<br>
        the Living, and the Dead.<br>
        I clear the Lass,<br>
        With Wainscot Face,<sup>xxi</sup> <br>
        and from Pimginets<sup>xxii</sup> free;<br>
        Plump Ladies Red,<br>
        Like Saracen's Head,<br>
        with Toaping Rattafia.<sup>xxiii</sup> <br>
        This with a Firk,<sup>xxiv</sup> <br> 
        will do your Work,<br>
        and Scour you o're and o're,<br>
        Read, Judge and Try,<br>
        And if you Die,<br>
        never believe me more. 
        </p>


        <h4 class="py-2 text-xl font-bold">Footnotes</h4> 
        <ul class="list-[lower-roman] list-inside">
          <li class="py-2">See R.Porter, <cite>Quacks, Fakers and Charlatans in English Medicine</cite> (London, 2000). </li>
          <li class="py-2">M.A. Katritzky, 'Marketing Medicine: the Image of the Early Modern Mountebank', <cite>Renaissance Studies</cite>, 15 (2001), pp. 121-53. </li>
          <li class="py-2">Impressions known to me include British Museum, Department of Prints & Drawings, 1850, 1109.3, and British Library, C.121.g 9 (211).  </li>
          <li class="py-2">Interestingly, this design was used as the model for an English delftware plate bearing the date 1750, but present whereabouts unknown. Now known only from a nineteenth-century engraving by I. R. Cruikshank: see F. G. Stephens, <cite>Catalogue of Prints and Drawings in the British Museum: Satirical and Personal Subjects</cite>, vol. 1 (1320-1689) (London, 1870), p. 584 (no. 1033). </li>
          <li class="py-2">British Library Harley 5931 (175). It bears the imprint, 'London: Printed for H. Hills, in Black-Fryers, near the Water-side. 1707.' </li>
          <li class="py-2">See S. Shesgreen, <cite>The Criers and Hawkers of London</cite> (Aldershot, 1990), p. 204 (no. 65). </li>
          <li class="py-2">Information c/o Ian Chandler, as found on www.folkplay.info the essential web-site for serious discussion of mummers' plays maintained by Peter Millington </li>
          <li class="py-2">An example of the former is British Museum, Dept. Prints & Drawings, 1868,0808.3274, and of the latter, Oxford, Bodleian Library, Firth b.21 (61) verso. </li>
          <li class="py-2">Three versions of this print are known to me, but only that in the Library of Congress, Washington, bears the title. The British Museum impression, 1868, 0808.13211, no. 1406 in F. G. Stephens, <cite>Catalogue of Prints and Drawings in the British Museum: Satirical and Personal Subjects</cite>, vol. 2 (1689-1733) (London, 1873), pp. 152-3, has recently been redated from Stephens' 'c.1700' to c.1765, though the plate is worn and may well have originated in the early eighteenth century. The third impression is now in the Ashmolean Museum, Oxford, Douce Prints E.1.4 (236): this is reproduced as fig. 16 in Katrizky, art. cit. (n. 2). A later <cite>Pharmacopola Circumforaneus</cite> with the alternative title, <cite>The High German Doctor's Harangue</cite> has the same text, but it appears beneath a different image of the 1730s/40s: British Museum, Department of Prints & Drawings, 1898, 0520.157.</li>
          <li class="py-2"> Stephens, loc. cit. (n. 9). </li>
          <li class="py-2">Hollstein 42. The series also exists copied in reverse by an anonymous engraver, an impression of his barber-surgeon's shop is in the collection of the Wellcome Institute Library, ref. no. ICV No 12166.</li>
          <li class="py-2">D. Galloway (ed.), Norwich 1540-1642 (Toronto, 1984), p. 146. For discussion of the significance of such births, see D. Cressy, <cite>Alice Bowker's Cat</cite> (Oxford, 2000), pp. 29-50, and A. Walsham, <cite>Providence in Early Modern England</cite> (Oxford, 1999), pp. 194-203.  </li>
          <li class="py-2">'Bills' = fliers, but perhaps an error for 'Pills'</li>
          <li class="py-2">'A state of depression of spirits; a fit of megrims or spleen' [OED]. </li>
          <li class="py-2">the itch [OED s.v. 'scrub']. </li>
          <li class="py-2">cured of impotence? At this point in the play-text appears the line, 'Spoilt and long neglected'—it seems to have been omitted from the print versions by accident.</li>
          <li class="py-2">in the plural, a name for the venereal disease [OED]. </li>
          <li class="py-2">plague. </li>
          <li class="py-2">i.e. pregnant, so no longer 'maids'. </li>
          <li class="py-2">pocky-faced whores.</li>
          <li class="py-2">with face hardened or coloured like old wainscot. </li>
          <li class="py-2">pimples.</li>
          <li class="py-2">with 'swigging ratafia, a sort of Cherry Brandy made with Peach and Apricock Stones'. </li>
          <li class="py-2">thrust or 'kick'. </li>
        </ul> 


  </div>   
</template>

<script>
    export default {
        data() {
          return { 
            imagePath : 'https://britishprintedimages.blob.core.windows.net/input-images/printsinfocus/',
            thumbnail : '',
            largeImage : ''
          }
        },
        methods: {
          getlargerImage() {
                let currentImagePath = this.imagePath + 'Infallible mountebank.png' 
                window.open(currentImagePath, "_blank");
            },
        },
        created() {
          this.thumbnail = this.imagePath  + '11.jpg' 
        }
    }
  
  </script>