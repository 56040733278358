<template>
    <transition name="modal">
      <div class="modal-mask">
        <div id='modalContainer' tabindex="-1" aria-modal="true" aria-label="Image display modal" role="dialog">
          <div class='modal-header relative text-center'>
            <span class="fixed top-5 right-5">
              <button type="button"  aria-label="close" class="p-2 lg:text-lg sm:text-xs md:text-sm bg-slate-200 rounded focus:outline-none focus:ring focus:ring-white" @click="closeHandler()" @keydown.enter="closeHandler()">Close</button></span>
            <h3 class="w-5/6 pt-6 lg:text-3xl md:text-xl sm:text-md"> {{ imageData.title }} </h3>
          </div>
          <div class='modal-body'>
          <TabDetails :bpi-number = "bpinumber" />
          </div>
        </div>
      </div>
    </transition>
  </template>
  
<script>

  import TabDetails from './TabDetails.vue';

    export default {
        name: "ImagesModal",
        props: {
          bpinumber : {
              type: Number,
              default: 0
          }
        },
        components: {
          TabDetails
        },
        data() {
          return { 
            imagePath : 'https://britishprintedimages.blob.core.windows.net/input-images/web',
            imageData : {},
            BPINum : this.bpinumber
          }
        },
        methods: {
          closeHandler() {
              this.$emit('close');   
          },
            getImage() {
                this.imageData = JSON.parse(localStorage.getItem(this.BPINum));
            } 
        },
        created() {
          this.getImage();
    }
  }
  </script>
  
  <style scoped>
  
    .modal-mask {
      position: fixed;
      z-index: 9998;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .5);
      display: table;
      transition: opacity .3s ease;
    }
  
    #modalContainer {
      position: absolute;
      z-index: 100;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 85%;
      height: 85%;
      background: #FFF;
      border-radius: 25px;
    }
  
  
    .modal-body {
      padding: 50px;
      min-height: 300px;
    }
  
    .modal-body p {
      padding: 5px;
    }
  
    .modal-footer{
      position: fixed;
      width: 100%;
      bottom: 0;
    }
  
  
  </style>
  