<template>
    <div class="absolute top-0 right-0">

        <button  aria-label="clear and return to search" class="border rounded bg-slate-500 text-white p-4 text-sm md:text-md lg:text-xl hover:bg-slate-450" @click="clearResults"  @keydown.enter="clearResults">Return to search</button>
   
      </div>
</template>

<script>

import { useImageDataStore } from '@/store/imagedata'
import { useBPIStore } from '@/store/BPIs'
  
export default {
  data() {
      return {};
  },
  components: { 
  },
  methods: {
    clearResults() {
      const imageDataStore = useImageDataStore();
      imageDataStore.clearImageDetails();
      const bpiStore = useBPIStore();
      bpiStore.clearBPI();
      bpiStore.clearBPIList();
      bpiStore.clearBPIPages();
      bpiStore.clearBPIIndex();
      localStorage.clear();
      this.$router.push({name: 'Search'});
    },
  }
}

</script>
