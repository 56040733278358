<template>

<div  id="accordion-collapse" data-accordion="collapse">
    <h2 id="accordion-collapse-heading-1">
        <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-700 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-stone-400 hover:bg-stone-200 dark:hover:bg-stone-800 gap-3" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
        <span class="text-2xl">Gribelin, Simon </span>
        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
        </svg>
        </button>
    </h2>
    <div id="accordion-collapse-body-1" class="hidden" aria-labelledby="accordion-collapse-heading-1">
        <div class="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900 text-left overflow-y-auto"> 
            
            <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                (1661-1733) Gribelin was a Huguenot exile from France who divided his time between printmaking and decorative engraving on silver objects, often made by his Huguenot co-religionists. His prints include engravings after Old Master drawings; he was also responsible for the illustrations to the second edition of Shaftesbury's Characteristicks (1714). He collaborated with one printseller, his fellow Frenchman Paul van Somer, on two plates of Protestant significance: the Seven Bishops of 1688, and a portrait of the Duke of Schomberg, killed at the Battle of the Boyne in 1690 (the latter they published jointly).
                </p>
        </div>
    </div>
    <h2 id="accordion-collapse-heading-2">
        <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-2" aria-expanded="false" aria-controls="accordion-collapse-body-2">
        <span class="text-2xl">Griffier, Jan</span>
        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
        </svg>
        </button>
    </h2>
    <div id="accordion-collapse-body-2" class="hidden" aria-labelledby="accordion-collapse-heading-2">
        <div class="p-5 border border-b-0 border-gray-200 dark:border-gray-700 text-left">
            <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                (1645-1718) Griffier was trained as a painter in Amsterdam. He became known in London from the late 1660s onwards for his landscapes, particularly of the Thames. In 1695 he returned to the Netherlands but was back in London a decade later. In addition to paintings, Griffier produced mezzotints and etchings, the latter mainly of animals and birds after Francis Barlow.
Hertochs, Abraham (fl. 1626-1672) An engraver of Dutch extraction who produced various engraved title-pages, portraits and other book illustrations, including the frontispiece to Evelyn's Sculptura (1662).
            </p>
    
        </div>
        </div>
        <h2 id="accordion-collapse-heading-3">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-3" aria-expanded="false" aria-controls="accordion-collapse-body-3">
            <span class="text-2xl">Elder, William </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
            <div id="accordion-collapse-body-3" class="hidden" aria-labelledby="accordion-collapse-heading-3">
                <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">

                    <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                        (fl. 1680-1701) Originally from Scotland, Elder worked in London principally as an engraver of portraits, usually after older engravings, for booksellers. There is also evidence that he was interested in calligraphic engraving.
                    </p>
                
                </div>
            </div>
        <h2 id="accordion-collapse-heading-4">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-4" aria-expanded="false" aria-controls="accordion-collapse-body-4">
            <span class="text-2xl">Hole, William</span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-4" class="hidden" aria-labelledby="accordion-collapse-heading-4">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (fl. 1607-24) Hole is known to have been working as an engraver by 1607, and he was dead by 1624, when he was succeeded as Chief Engraver to the Mint. He was responsible for producing engraved title-pages and portraits for works by some of the leading literary figures and musicians of the day. He also engraved Martin Billingley's Pen's Excellencie (1617?). He displays a somewhat French style, close to that of Leonard Gaultier.
                </p>
            </div>
        </div>
        <h2 id="accordion-collapse-heading-5">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-5" aria-expanded="false" aria-controls="accordion-collapse-body-5">
            <span class="text-2xl">Hollar, Wenceslaus</span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-5" class="hidden" aria-labelledby="accordion-collapse-heading-5">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (1607-77) The most talented and prolific printmaker to work in Britain during this period, Hollar was born in Prague but forced into exile by the events of the Thirty Years War. After working for Matthaüs Merian in Frankfurt, under whom his skills as a topographical artist were developed, his career was transformed when he was taken into the service of Thomas Howard, Earl of Arundel, in 1636. He travelled to England with Howard, becoming a member of his household and executing various etchings for him. However, in 1644 he was forced into exile in Antwerp, where he remained for several years (though many of the works that he executed were for the English market). He returned to England in 1652 and thereafter was involved in a variety of publishing projects, not least those initiated by Sir William Dugdale and John Ogilby. He also produced various remarkable views and maps of London and Windsor, while in the 1670s he produced a set of views of the English colony at Tangier.
                </p>  
            </div>
        </div>  
        <h2 id="accordion-collapse-heading-6">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-6" aria-expanded="false" aria-controls="accordion-collapse-body-6">
            <span class="text-2xl">Hondius, Jodocus </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-6" class="hidden" aria-labelledby="accordion-collapse-heading-6">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (1563-1612/13) Hondius hailed from Ghent, but as a Protestant he took refuge in England in about 1583-4, where he continued to practice the skills as an engraver that he had initially developed in Flanders; he also devoted himself to the construction of geographical globes and scientific instruments. In 1592 or 1593 he left England, moving to Amsterdam, where he spent the remainder of his life. The engravings that he produced while in England were particularly maps, though he also produced portraits, notably of such seafarers as Sir Francis Drake.

                </p>  
            </div>
        </div>  
        <h2 id="accordion-collapse-heading-7">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-7" aria-expanded="false" aria-controls="accordion-collapse-body-7">
            <span class="text-2xl">Hove, Frederick van </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-7" class="hidden" aria-labelledby="accordion-collapse-heading-7">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (c. 1628-98) A Dutchman who worked in Antwerp before moving to London in the 1660s, where he produced many engraved title-pages as well as at least sixty portraits for the booksellers.
                </p>  
            </div>
        </div>  
        <h2 id="accordion-collapse-heading-8">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-8" aria-expanded="false" aria-controls="accordion-collapse-body-8">
            <span class="text-2xl">Jode, Arnold de </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-8" class="hidden" aria-labelledby="accordion-collapse-heading-8">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (c. 1638-67) Arnold de Jode came from a long established family of engravers in Antwerp, and he evidently arrived in England in the mid 1660s, working for the publishers Richard Tompson and Alexander Browne prior to his death within a couple years of his arrival in this country. De Jode was responsible for various of the plates in Browne's Ars Pictoria, published in 1669 although the plates had evidently earlier been issued as a drawing book.
                </p>  
            </div>
        </div>  
        <h2 id="accordion-collapse-heading-9">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-9" aria-expanded="false" aria-controls="accordion-collapse-body-9">
            <span class="text-2xl">Johnson, Laurence </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-9" class="hidden" aria-labelledby="accordion-collapse-heading-9">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (fl. 1603) One of the earliest native printmakers in England, he is known for the title-page and twenty-six portraits to Richard Knolles, The Generall Historie of the Turkes (1603), as well as a very rare portrait of James I.
                </p>  
            </div>
        </div> 
        <h2 id="accordion-collapse-heading-10">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-10" aria-expanded="false" aria-controls="accordion-collapse-body-10">
            <span class="text-2xl">King, Daniel</span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-10" class="hidden" aria-labelledby="accordion-collapse-heading-10">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (c. 1616-61) King came from Chester and one of his publications was a book on Chester, The Vale-Royall of England (1656). In the 1650s he was associated with Sir William Dugdale and Wenceslaus Hollar in producing the plates that were used in Dugdale's Monasticon Anglicanum, which King also published separately in his Cathedrall and Conventuall Churches of England and Wales.
                </p>  
            </div>
        </div>   
        <h2 id="accordion-collapse-heading-11">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-11" aria-expanded="false" aria-controls="accordion-collapse-body-11">
            <span class="text-2xl">Kip, William </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-11" class="hidden" aria-labelledby="accordion-collapse-heading-11">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (in London 1588-1618) Kip was a goldsmith who came from Utrecht. His engravings are mainly cartographic, though he was also responsible for the plates illustrating Stephen Harrison's Arches of Triumph (1604).
                </p>  
            </div>
        </div> 
        <h2 id="accordion-collapse-heading-12">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-12" aria-expanded="false" aria-controls="accordion-collapse-body-12">
            <span class="text-2xl">Lanier, Nicholas</span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-12" class="hidden" aria-labelledby="accordion-collapse-heading-12">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (1588-1666) A member of a musical dynasty, Nicholas Lanier was also a miniaturist and art collector, who in the 1620s was sent by Charles I to Italy to supervise the acquisition of the art collection of the dukes of Mantua. He executed a few etchings after Old Masters in his later years.
                </p>  
            </div>
        </div>     
        <h2 id="accordion-collapse-heading-13">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-13" aria-expanded="false" aria-controls="accordion-collapse-body-13">
            <span class="text-2xl">Laroon, Marcellus </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-13" class="hidden" aria-labelledby="accordion-collapse-heading-13">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (1648/9 or 1653-1702) Three generations of artists named Marcellus Laroon came from this French family which settled in England after the Restoration. No work is known by the eldest, and his son is usually called 'Old Laroon' to differentiate him from his son (1679-1772), whose activity fell mainly in the 18th century. After a period when he worked in York, 'Old Laroon' moved to London in the 1670s, his address being 4 Bow Street, Covent Garden. He is best known for his drawings of The Cryes of London, which were engraved by John Savage (active 1683-1700) and published by Pierce Tempest in 1688, though he was also notable for his slightly risqué genre scenes in mezzotint..
                </p>  
            </div>
        </div>     
        <h2 id="accordion-collapse-heading-14">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-14" aria-expanded="false" aria-controls="accordion-collapse-body-14">
            <span class="text-2xl">Lens, Bernard, II</span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-14" class="hidden" aria-labelledby="accordion-collapse-heading-14">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (1659-1725) The Lens family were artists of Dutch origin, and three successive members were named Bernard, namely the father (1659-108), who we learn from George Vertue was an enamel painter (and also wrote religious tracts), his son (1659-1725), who specialised in mezzotints and was appointed drawing master at Christ's Hospital in 1705, and his son (1682-1740), a topographical draughtsman, miniaturist and drawing master who was limner to George I and II. This has led to some confusion between the output of Bernard Lens II and Bernard Lens III, though a large corpus of mezzotints of various sizes, especially of genre subjects, can definitely be attributed to Bernard Lens II.
                </p>  
            </div>
        </div>   
        <h2 id="accordion-collapse-heading-15">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-15" aria-expanded="false" aria-controls="accordion-collapse-body-15">
            <span class="text-2xl">Lodge, William</span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-15" class="hidden" aria-labelledby="accordion-collapse-heading-15">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (1649-89) Lodge hailed from a family of wealthy cloth merchants from Leeds and was educated at Jesus College, Cambridge, and Lincoln's Inn. He took up artistic activities while at Cambridge and in 1670 travelled to Italy, publishing The Painter's Voyage to Italy, illustrated with his own etchings, in 1679. In the 1670s, Lodge belonged to the circle of virtuosi at York, and he was a close friend of Francis Place. His etchings include plates illustrating Martin Lister's scientific papers as well as various topographical views.
                </p>  
            </div>
        </div>      
    </div>

</template>
<script setup>

  import { initFlowbite } from 'flowbite';

  // ensures that the prop data is received by the accordion
  setTimeout(() => {
        initFlowbite();
  }, 500);

  const init = async () => {};
  init();
  
</script>

<style>
div[data-panel-id] {
	border-bottom: 1px !important;
}
</style>

