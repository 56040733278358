<template>

    <div class="relative container mx-auto px-20 bg-gray-200">
        <HomeLink />
        <HomeBanner />
        <NavMenu />
        <section class="bg-white dark:bg-gray-900">
          <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700 bg-white px-6 py-12 pb-6 lg:px-8">
            <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Studying British Printed Images</h1>
            <p class="mt-6 text-xl leading-8 text-left">
                Where should you begin when studying printed images produced in early modern Britain? This guide below is divided into two sections: catalogues and scholarship. </p>
            <div class="mt-10 text-left">
            
                <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900"> Catalogues</h2>
                 <p class="py-4">
                    The catalogues listed below are the best way into the source material. They provide the bibliographic information and descriptions of prints that are the foundation of research. They tend to focus on the holdings of the British Museum and need to be complemented by research into other repositories.</p>
                    <h3 class="mt-2 text-1xl font-bold italic tracking-tight text-gray-900">Political satires</h3>
                    <ul>
                        <li>-	F.G. Stephens, <cite>Catalogue of Prints and Drawings in the British Museum: Satirical and Personal Subjects</cite>, vol. 1 (1320-1689) (London, 1870), and vol. 2 (1689-1733) (London, 1873).</li>
                    </ul> 
                    <h3 class="mt-2 text-1xl font-bold italic tracking-tight text-gray-900">Portraits</h3>
                    <ul>
                        <li> - Freeman O' Donoghue, <cite>Catalogue of Engraved British Portraits Preserved in the Department of Prints and Drawings in the British Museum </cite>(4 vols., London, 1908-14), with 2 further volumes ed. H.M. Hake (London, 1922-5).</li>
                    </ul>
                    <h3 class="mt-2 text-1xl font-bold italic tracking-tight text-gray-900">Engraving</h3>
                    <ul>
                        <li> - Arthur M. Hind, <cite>Engraving in England in the 16th and 17th Centuries</cite>. Volume I (1952) covers the Tudor period, II (1955) the reign of James VI/I, and III (1964) the reign of Charles I.</li>
                        <li> - Sir Sydney Colvin, <cite>Early Engraving and Engravers in England</cite> (London, 1905).</li>
                    </ul> 
                    <h3 class="mt-2 text-1xl font-bold italic tracking-tight text-gray-900">Mezzotints</h3>
                    <ul>
                        <li> - John Chaloner Smith, <cite>British Mezzotinto Portraits; Being a Descriptive Catalogue of these Engravings, from the Introduction of the Art to the Early Part of the Present Century</cite> (4 vols., London, 1883).</li>
                        <li> - C.E. Russell, <cite>English Mezzotint Portraits and their States</cite> (2 vols., London, 1926).</li>
                    </ul>
                    <h3 class="mt-2 text-1xl font-bold italic tracking-tight text-gray-900">Playing Cards</h3>
                    <ul>
                        <li> - W. H. Willshire, <cite>A History of Playing and Other Cards in the British Museum</cite> (London, 1876).</li>
                    </ul>
                    <h3 class="mt-2 text-1xl font-bold italic tracking-tight text-gray-900">Titlepages.</h3>
                    <ul>
                        <li> - Margery Corbett and Ronald Lightbown, <cite>The Comely Frontispiece: the Emblematic Title-page in England</cite>, 1550-1650 (London, 1979).</li>
                        <li> - A.F. Johnson, <cite>A Catalogue of Engraved and Etched English Title-pages</cite> (London, 1934).</li>
                    </ul>
                    <h3 class="mt-2 text-1xl font-bold italic tracking-tight text-gray-900">Book Illustrations</h3>
                    <ul>
                        <li>  -	Ruth Samson Luborsky & Elizabeth M Ingram, <cite>A Guide to English Illustrated Books</cite>, 1536-1603 (Temple, Arizona, 1998). A catalogue of all book illustrations in this period.</li>
                        <li> -	For the period 1604-40, see <a href="Illustrations.xls" class="mt-3 text-blue-600  dark:text-white hover:text-blue-600 inline-flex items-center" download>Illustrations</a>  </li>
                    </ul>
                  
                    <h3 class="mt-2 text-1xl font-bold italic tracking-tight text-gray-900">Specific printmakers/publishers</h3>
                    <ul>
                        <li> - Carol Blackett-Ord and Simon Turner, 'Early mezzotints: prints published by Richard Tompson and Alexander Browne', <cite>The Walpole Society</cite>, 70 (2008), pp. 1-206.</li>
                        <li> - Katherine S. van Eerde, <cite>John Ogilby and the Taste of his Times</cite> (Folkestone, 1976).</li>
                        <li> - Louis Fagan, <cite>Descriptive Catalogue of the Engraved Works of William Faithorne </cite>(London, 1888).</li>
                        <li> - Alexander Globe, Peter Stent c. 1642-1665, <cite>London Printseller: A Catalogue Raisonné of His Engraved Prints and Books With An Historical and Bibliographical Introduction</cite> (Vancouver, 1985).</li>
                        <li> - Henry M. Hake, 'Some contemporary records relating to Francis Place, with a catalogue of his engraved work', <cite>The Walpole Society</cite>, 10 (1902).</li>
                        <li> - A. M. Hind, <cite>Wenceslaus Hollar and his Views of London and Windsor in the Seventeenth Century</cite> (London, 1922).</li>
                        <li> - Richard Pennington, <cite>A Descriptive Catalogue of the Etched Work of Wenceslaus Hollar </cite>(1607-1677) (Cambridge, 1982).</li>
                    </ul>
            
            </div>
          </div>
          <div class="text-center pb-8">
            <button type="button" aria-label="research" @click="viewResearchPage()" @keydown.enter="viewResearchPage()" class="bg-slate-500 hover:bg-slate-600 text-white font-bold py-2 px-4 mt-4 mb-4 rounded focus:outline-none focus:ring focus:ring-white inline-flex items-center w-40">Back to Research
            </button>
          </div>
      </section> 
      <SimpleFooter />
     </div> 
</template>

<script>
   
import HomeBanner from '../components/HomeBanner.vue';
import NavMenu from '../components/NavMenu.vue';
import SimpleFooter from '../components/SimpleFooter.vue';
import HomeLink from '../components/HomeLink.vue';

export default {
  data() {
      return {};
  },
  components: { 
    HomeLink,
    HomeBanner, 
    NavMenu,
    SimpleFooter
  },
  methods: {
    viewResearchPage(){
        this.$router.push({name: 'Research'});
    }
    }
}
</script>