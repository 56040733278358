<template>
 <div>
        <h3 class="py-6 text-3xl">A New yeares guift for shrews’</h3>
        <h4 class="py-2 text-xl">No.2 September 2006 </h4>
    </div>
    <div class='relative text-left overflow-y-auto'>
      <p class="py-2"> <strong>Copyright of the Trustees of the British Museum.</strong> </p>
      <p class="pt-2 pb-4"> <strong>British Museum 1850,1109.10. Dimensions of original: 189 mm x 206 mm.</strong></p> 
        <span class="float-left pr-6 pb-2"><img :src="thumbnail" width="300"/> <button type="button" @click="getlargerImage()" class="underline font-bold" >View larger image</button></span> 

        <p class="pb-4 mt-12"><span class="italic">A New yeares guift for shrews</span> is a diminutive sheet engraved c.1630 and signed by the relatively unknown Thomas Cecill, who seems to have been active between 1625 and 1640. It bears the imprint of the even less known Edward Lee, whose only other issue is a large equestrian portrait of Charles I. </p>

        <p class="pb-2">In a series of unified scenes, it depicts the traditional nagging wife eventually beaten by her husband, and ultimately chased off by the devil. It is accompanied by the following rhyme of a traditional type, reminiscent of the ditty about <span class="italic">Solomon Grundy</span>, who was <span class="italic">Born on Monday</span>, and then on successive days christened, married, taken fatally ill and <span class="italic">Buried on Sunday</span>:</p>

        <p class="pb-2 italic">
        Who marieth a wife uppon a Moneday,
        If she will not be good uppon a Tewesday,
        Lett him go to the wood uppon a Wensday,
        And cutt him a cudgell uppon the Thursday,
        And pay her soundly uppon a Fryday;
        And<sup>i</sup>she mend not, the Divil take her a Saterday   
        Then may he eate his meate in peace on the Sonday.
        </p>
 
        <p class="pb-2">In the final scene the husband is shown happily enjoying a jug of ale at an ale-bench outside 'The Swan' - to judge from the inn-sign. Another interesting detail of the contemporary street-scene is the latticed box attached to the tavern wall: at <span class="italic">lattice</span>, the <span class="italic"></span>Oxford English Dictionary explains: <span class="italic">A window of lattice-work (usually painted red), or a pattern on the shutter or wall imitating this, formerly a common mark of an alehouse or inn</span>. The <span class="italic">Dictionary</span> illustrates this usage with a quotation from Richard Harvey's <span class="italic">Plaine Percevall</span> (1590), and one not inappropriate to our print: <span class="italic">As they which determine upon an Ale bench whether the passenger that passeth by the lettise be a Saint or a Divell</span>.</p>

        <p class="pb-2">It was clearly popular, for it appears in Peter Stent's advertisements of 1654 and 1662, and is still listed in his successor John Overton's catalogue of 1673,<sup>ii</sup> yet only the unique impression in the British Museum's Department of Prints & Drawings survives - testimony to the great rarity of such popular material.  </p>

        <p class="pb-2">David Kunzle was the first to point out the likely dependence of <span class="italic">A New yeares guift</span> on a contemporary German sheet, <span class="italic">Ein köstlich gutes bewertes Recept vor die Männer so böse Weiber haben</span> [A precious and valuable prescription for men who have such evil wives], though in the German sheet the process of correction takes nine days; he drives into the wood on Monday, cuts sticks on Tuesday, beats her on Wednesday, and so on.<sup>iii</sup> The English shrew is shown fleeing before a sturdy devil, his pitchfork raised, as he pursues her - into hell, presumably.</p>


        <h4 class="py-2 text-xl font-bold">Footnotes</h4> 
        <ul class="list-[lower-roman] list-inside">
          <li class="py-2">And: i </li>
          <li class="py-2">Alexander Globe, <cite>Peter Stent, London Printseller</cite>, c. 1642-65 (Vancouver, 1985), p. 123 (no.*448).  </li>
          <li class="py-2">D. Kunzle, <cite>The Early Comic Strip</cite>, c. 1450-1825 (Berkeley and Los Angeles, 1973), pp. 230-1 (fig. 8-9).  </li>
        </ul> 
  </div>   
</template>

<script>
    export default {
        data() {
          return { 
            imagePath : 'https://britishprintedimages.blob.core.windows.net/input-images/printsinfocus/',
            thumbnail : '',
            largeImage : ''
          }
        },
        methods: {
          getlargerImage() {
                let currentImagePath = this.imagePath + 'New Yeares Guift.png' 
                window.open(currentImagePath, "_blank");
            },
        },
        created() {
          this.thumbnail = this.imagePath  + '16.jpg' 
        }
    }
  
  </script>