import axios from "axios";
import { useImageDataStore } from '@/store/imagedata'

//explicit promise, async

const path = process.env.VUE_APP_ROOT_API;

export async function getAllImageDetails(bpi_number){
  try {

    if(bpi_number !== null){
      const response = await axios.get(path + "all_image_details/" + bpi_number);
      let imageData = response.data.recordset;

      if(imageData === undefined || imageData.length === 0){
        console.log('No image data');
      } 
      else {
        const imageLabel = imageData[0].Label !== undefined ? imageData[0].Label  : "";
        const imageOtherLabel = imageData[0].OtherLabel !== undefined ? imageData[0].OtherLabel  : "";
        const imageDescription = imageData[0].Description !== undefined ? imageData[0].Description : "Unavailable";
        const imageInscription = imageData[0].Inscription !== undefined ? imageData[0].Inscription : "Unavailable";
        const imageProducer = imageData[0].Producer !== undefined ? imageData[0].Producer : "Unavailable";
        const imageProdPlace =  imageData[0].ProductionPlace !== undefined ? imageData[0].ProductionPlace : "Unavailable";
        const assocName = imageData[0].AssocName !== undefined ? imageData[0].AssocName : "Unavailable";
        const assocNamePrefix = imageData[0].AssocNamePrefix  !== undefined ? imageData[0].AssocNamePrefix : "Unavailable";
        const jpgNumber = imageData[0].Name  !== undefined ? imageData[0].Name : "Image unavailable";
        const imageTitle = imageData[0].Title  !== undefined ? imageData[0].Title : "";

        // filter out html tags
        const filteredImageDesc = filterHTML(imageDescription);
        const filteredImageIns = filterHTML(imageInscription);

         // set the title to imageLabel as a default
         let title = imageLabel;

         // if main title field is blank try to use another title type field
         if((title == '' || title == null) && (imageOtherLabel == '' || imageOtherLabel == null)){
           title = imageTitle;
         }
         else if ((title == '' || title == null) && (imageTitle == '' || imageTitle == null)) {
           title = imageOtherLabel;
         }

        imageData = {
          id : bpi_number,
          title : title,
          description : filteredImageDesc,
          inscription : filteredImageIns,
          producer : imageProducer,
          producerPlace : imageProdPlace,
          assocName : assocName,
          assocNamePrefix: assocNamePrefix,
          jpgNum : jpgNumber
        }
      } 
    // put the data into pinia, writes to localstorage
    // use pinia store
    const imageDataStore = useImageDataStore();
    if(bpi_number && imageData){

      const bpiNum = parseInt(bpi_number);
      const dataObject = { "bpi_number" : bpiNum,  imageData }
      imageDataStore.addToImageDetails( dataObject );
      //imageDataStore.addToImageDataPerPage( dataObject );
    }
  }}
  catch (err) {
    console.error(err);
  }

} 

// cleans strings of HTML tags
function filterHTML(str) {
if ((str===null) || (str===''))
    return false;
else
    str = str.toString();
    
// Regular expression to identify HTML tags in the input string. Replacing the identified HTML tag with a null string.
return str.replace( /(<([^>]+)>)/ig, '');
}
  