<template>
 <div>
        <h3 class="py-6 text-3xl">The Revells of Christendome</h3>
        <h4 class="py-2 text-xl">No.17 January/February 2008 </h4>
    </div>
    <div class='relative text-left overflow-y-auto'>
      <p class="py-2"> <strong>Copyright of the Trustees of the British Museum.</strong> </p>
      <p class="pt-2 pb-4"> <strong>British Museum 1849,0315.10. Dimensions of original: 290 mm x 368 mm.</strong></p> 
        <span class="float-left pr-6 pb-2"><img :src="thumbnail" width="200"/> <button type="button" @click="getlargerImage()" class="underline font-bold" >View larger image</button></span> 

        <p class="pb-2">The state of international politics in the early Jacobean era was summarised in an undated, cartoon-like print engraved by Thomas Cockson, entitled <span class="italic">The Revells of Christendome</span>. It represents an English view of the settlement between Spain and the States General of Holland, at a time when England and France were negotiating peace between the two parties. F.G. Stephens, who first suggested the plausible date 1609 for the print, noted that 'This Peace [i.e. The Twelve Years Truce] was a severe blow to the Pope, and Maurice [of Nassau, Prince of Orange] was watching the game which promised so much benefit to his country'.<sup>i</sup>    </p>

        <p class="pb-2">Stephens thought <span class="italic">The Revells</span> 'probably of German origin'—a strange attribution, given the Netherlandish context he suggested. In fact, what would appear to be the original model for Cockson's print can now be identified as one bearing the monogram of the Netherlandish engraver Pieter van der Heyden/Petrus a Merica with text in Dutch, which opens <span class="italic">Hola ghy roouers</span>. Harms believes internal evidence suggests a date of issue of between 1598 and 1600,<sup>ii</sup> though I note that in Hind's <span class="italic">History of Engraving</span>, Van der Hetden's <span class="italic">floruit</span> is given as 1550-70, though the British Museum database confirms he was active beyond 1584<sup>iii</sup></p>

        <p class="pb-2">
          Comparison with his source shows that Cockson has retained the likenesses on the Catholic side (except that he has replaced a Merica's cardinal's hat with a Jesuit's bonnet), but substituted clearly recognisable portraits on the Protestant side: James I of England, Henri IV of France (d.1610), Christian IV of Denmark, and <span class="italic">Grave Maurice</span> of Nassau, Prince of Orange. They are depicted seated round a table playing various games of chance (backgammon, cards and dice) against three Catholic monks who have staked—and lost—a monstrance, a chalice and much coin. In a picturesque aside—not in his original—Cockson has added a dog urinating on the foot of one of the monks.
        </p>
 
        <p class="pb-2">How can we be certain of the publication date of this undated print? The portrait of James I is unmistakable, so provides us with a <span class="italic">terminus post quem</span> of 1603. If the seated figure next to him is correctly identified as Henri IV of France, then we have a <span class="italic">terminus ante quem</span> of 1610—in such a context and given the suspected pun on Pax, for the Peace of the Twelve Years Truce, 1609 seems a reasonable guess. The engraving is signed by Cockson whose dates, however, are not helpful (active 1591-1630).</p>

        <p class="pb-2">The bonneted figure behind the Pope is problematic: Stephens implies he is the Cardinal Infante Ferdinand, i.e. the younger son of Philip III of Spain and Margaret of Austria—and he would be an excellent fit, having Austrian, Spanish and Netherlandish connexions—but is, sadly, ruled out of court by chronology, being born in 1609! The verses, however, describe the standing figure beside the Pope as both a cardinal and an Austrian… perhaps some reader can enlighten me as to his identification? </p>

        <p class="pb-2">The English verse styles the monstrance on the table a <span class="italic">pax</span>, for the benefit, I suggest, of the pun on the <span class="italic">Peace</span> or Truce, which is the real subject of the conference at the table. The verse writer has done his best to wring several such puns out of the composition (see below for full transcription of text): e.g. in their card game, <span class="italic">the Monck could show France nothing but the Knave</span>. The verses explicitly state that Henri is playing maw, a game we are no longer familiar with (and was perhaps only known in the British Isles?), but close comparison with the cards of his source, shows that Cockson has made some subtle alterations to his original—for the card shown face-up on the top of the deck on the table is not Van der Heyden's Ten of Hearts, but the five of that suit, and as hearts are trumps, it is the five of trumps, a special card in the game of maw known by the term 'five finger' (see verse). Furthermore, in Cockson's version, the two uppermost cards in the monk's hand, as if he is about to play one of them, are the Knaves of Diamonds and Hearts (in Van der Heyden's original, they are the Seven of Diamonds and King of Hearts), while his opponent, Henri IV, is already flourishing the Ace of Hearts.</p>

        <p class="pb-2">
          Not all the rules of maw have come down to us, though we do have a single sheet of <span class="italic">The Groome-porters lawes at Mawe, to be observed in fulfilling the due orders of the game</span> (c.1600) which prints sixteen rules, though—there being no mention of the <span class="italic">five-finger</span>, for instance—there must surely have been further sheets which have not survived. Two of the extant sixteen <span class="italic">lawes</span>, however, concern the <span class="italic"></span>Ace of Hartes, which clearly played a significant role in the game - whichever suit was trumps, cf. <span class="italic">Like the ace of hearts at Mawe</span> in <span class="italic">History of Pope Joan</span> (1599).<sup>iv</sup> By good fortune, however, we learn from <span class="italic">Greene's Newes from Heaven & Hell</span> (1593) that, <span class="italic">Although the knave of trumpes be the seconde carde at Mawe, yet the five-finger may commaunde both him and all the rest of the pack</span>.
        </p>

        <p class="pb-2">
          If I understand - or, rather, infer - the rules of the game correctly, the three highest value cards in the game were thus — in descending order — Five of Trump Suit, Knave of Trump Suit, Ace of Trump Suit, and thus—in the present context - Five of Hearts, Knave of Hearts, Ace of Hearts. France has cut the pack and 'shown' the Five of Hearts, and as Hearts are trumps, this is the unbeatable <span class="italic">Five Finger</span> — horribly frustrating for the Monk who has what is the second-highest card possible, the Knave of Trumps (Hearts) in his hand! Henry also holds (and shows) the third-highest card, the Ace of Trumps (Hearts).
        </p>

        <p class="pb-2">
          Here is the relevant portion of the verse caption to our sheet:
        </p>

        <p class="pb-2">
          ... France setts gould,<br>
          & shuffles: the Monk cutts: but France (being bould)<br>
          Deales freely: Rubs : and the first card hee showes,<br>
          is the Five Finger, which being tourn'd up, goes<br>
          Cold to the Moncks hart : the next card, France sees<br>
          in his owne hand, is the Ace of hartes, "I Leeze"<br>
          Cryes out the Monck; sayes France, "Show what you have,"<br>
          the Monck could show France nothing but the Knave.
        </p>

        <p class="pb-2">(For full text of the verses, see below.)</p>

        <p class="pb-2">In a reversed copy of the present sheet, dated conjecturally '1626' (by Stephens<sup>v</sup>), and which bears no engraver's signature nor other imprint information, but keeps the urinating dog and the verses (except for necessary changes in the names of the participants as noted below), the cardinal—wearing the characteristic broad-brimmed hat now, not the bonnet—may represent Richelieu (despite the verses which remain unchanged and still mention <span class="italic">Austria</span>), the English king James I (d.1625) is replaced by Charles I, Henri IV of France (d.1610) by Christian IV of Denmark, and Christian IV of Denmark of the earlier version by Gustavus Adolphus, King of Sweden (1611-1632), while the Transylvanian war-leader Bethlen Gabor replaces Maurice of Nassau, Prince of Orange (d.1625). What can we say for certain about the date of this later version? The unmistakable likeness of the youthful crowned Charles I replacing James, provides a <span class="italic">terminus post quem</span> of 1625, and the distinctive profile head of Bethlen Gabor provides the <span class="italic">terminus ante quem</span>, as he died in 1629.</p>

        <p class="pb-2">Curiously, the engraver of this later, reversed image seems to have reverted to the cards of the original van der Heyden version, i.e. the card turned up on the table is certainly the Ten of Hearts, and the cards in the monk's hand are the Five(?) of Diamonds, and a court card—this, of course, makes a nonsense of the details of the game of maw as given below the image, which remain the same as in Cockson's version, but for the substitution of <span class="italic">Denmarke </span>for <span class="italic">France</span> in the relevant stanza.</p>

        <p class="pb-2">Interestingly it is the impression of this later version of the composition (in the Society of Antiquaries collection; another impression is in the Department of Prints & Drawings of the British Museum) which is the subject of what must be one of the earliest journal articles to concern a print, <span class="italic">A Political Caricature, temp. Charles I</span>, in <span class="italic">The Gentleman's Magazine</span> of July 1853. A similarly reversed copy of Van der Heyden's original composition, which I have not seen, was issued in Germany in 1633.<sup>vi</sup></p>

        <p class="pb-2">It seems as though an even later version was published c.1660 - if we can believe the estimate of the cataloguer on whose telegraphic description we are dependent.<sup>vii</sup> It bears the same title but now the verses are disposed in five columns and the revellers presumably updated to reflect the political circumstances of a later seventeenth-century Europe. No imprint is mentioned, so we may assume there is none.  </p>

        <p class="pb-2"><span class="italic font-bold">The Revells of Christendome</span> (1609)</p>

        <p class="pb-2">
        Greate Brittaine with proud Rome at Tables playes,<br>
        Rome looseth every Stake that downe shee layes,<br>
        yett fretts and sweares to win all, tho' shee pawne<br>
        her Relliques. thos shee setts, & thos are drawne.<br>
        The last Stake is the Pax : Greate Brittaines hand<br>
        is drawing that too: the Pope who by doth stand<br>
        With Austria, boath beeing Bettors on Romes side,<br>
        holds fast the Pax: twas gamesters law they cryed<br>
        To Snatch the last Stake up, Brittaine, then swore<br>
        to have the Triple Crowne, Romes Vicar wore,<br>
        The Card'nall quarrells (in defence of Rome)<br>
        and (beeing Arm'd) trobleth all Christendome.<br>
        <br>
        France, sitting not far off, and seeing what hand<sup>viii</sup><br>
        Great Brittaine had, & how Romes losse did stand,<br>
        Hopes to winn something too, Maw is the game<br>
        at which hee playes, & Challengeth at the same<br>
        A Monk, who stakes a Challice : France setts gould,<br>
        & shuffles: the Monk cutts: but France (being bould)<br>
        Deales freely: Rubs : and the first card hee showes,<br>
        is the <span class="italic">Five Finger</span>, which being tourn'd up, goes<br>
        Cold to the Moncks hart : the next card, France sees<br>
        in his owne hand, is the <span class="italic">Ace of hartes</span>, "I Leeze"<br>
        Cryes out the Monck; sayes France, "Show what you have,"<br>
        the Monck could show France nothing but the <span class="italic">Knave</span>.<br>
        <br>
        Rome, thus being fleied by Brittaine and by France,<br>
        and knowing that Gamesters winninges are but chance,<br>
        Ventures to Challenge Denmark : the Dice Come<br>
        to Denmarkes hand; hee throwes & winnes from Rome<br>
        All that he playes for; whilst Graue Maurice standes<br>
        onely to see Faire Play: yett fills his handes<br>
        By Betting against Rome, Bearing away<br>
        so mutch, that Rome no more dare Bett or Play.<br>
        Her Crosses, Crucifixes, Myters, Cowles,<br>
        and all the Netts, shee throwes out to catch Sowles<br>
        Rome now hath lost, Shee, that did all desire,<br>
        is left more bare, then a bald, shaven Frier.<br>
        <br>
        Theis Roiall Gamesters thus with Crownes being stor'de<br>
        and Rome being without Crownes, all roze from Bord:<br>
        The Reuells breake up, and theire leaves they take,<br>
        but (first) Inquiry mongst themselues they make,<br>
        Which of them all, (because All they had won<br>
        and that the Dice on theire sides onely ron)<br>
        Had playd but one false Trick; and fownd at last<br>
        that Rome threw false Dice in, at every cast,<br>
        For this, shee never blushd, but onely swore,<br>
        shee would with Thos 4 Gamesters play no more.<br>
        Whom will shee play with then, yf Dice ron true,<br>
        at her owne Game, Rome will her selfe undoe.” 
        </p>

        <h4 class="py-2 text-xl font-bold">Footnotes</h4> 
        <ul class="list-[lower-roman] list-inside">
          <li class="py-2">F.G.Stephens, <cite>Catalogue of Prints and Drawings in the British Museum: Satirical and Personal Subjects</cite>, vol. 1 (London, 1870), pp. 42-4 (BM Sat. 81). It is perhaps worth noting that in 1613 the Duke of Saxe-Weimar noted in his journal a large number of the pictures and other artworks to be seen in the English royal palaces, including Whitehall, where he recorded the Exploits of Count Maurice engraven on copper: W.B. Rye, <cite>England as seen by foreigners in the days of Elizabeth and James the First</cite> (London, 1865), p. 165 </li>
          <li class="py-2">W.Harms, <cite></cite>Deutsche Illustrierte Flugblätter des 16 und 17 Jahrhundets (Tübingen, 1985 et seq. [in progress]), II. 64 [= G. van Rijn,<cite>Katalogus Atlas Van Stolk</cite> (Amsterdam, 1895), no. 340; F.Muller, <cite>Nederlandsche Historieplatten</cite> (Amsterdam, 1863-82), no. 434]. </li>
          <li class="py-2">A.M. Hind, <cite>A History of Engraving & Etching from the 15th century to the year 1914</cite> (3rd ed., New York, 1963), p. 458. </li>
          <li class="py-2">cit. OED s.v. <span class="italic">heart</span> </li>
          <li class="py-2">Stephens, op. cit, pp. 61-2 (BM Sat. 101) </li>
          <li class="py-2">R.J. Paas, <cite>The German Political Broadsheet 1600-1700. Vol. 6 1633-1648</cite> (Wiesbaden, 2002), P-1920.</li>
          <li class="py-2">Pickering & Chatto catalogue no. 244, p.1732, no. 11606</li>
          <li class="py-2">antedating of this sense (23) in OED. </li>
        </ul> 
  </div>   
</template>

<script>
    export default {
        data() {
          return { 
            imagePath : 'https://britishprintedimages.blob.core.windows.net/input-images/printsinfocus/',
            thumbnail : '',
            largeImage : ''
          }
        },
        methods: {
          getlargerImage() {
                let currentImagePath = this.imagePath + 'Revells of Christendom.png' 
                window.open(currentImagePath, "_blank");
            },
        },
        created() {
          this.thumbnail = this.imagePath  + '19.jpg' 
        }
    }
  
  </script>
