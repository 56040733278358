<template>
  <header class="bg-stone-100 py-12 sm:py-12 mt-0 border border-2">
    <div class="mx-auto max-w-8xl px-6 lg:px-8">
      <div class="mx-auto max-w-6xl lg:mx-0 text-left px-12">

        <h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl ">British Printed Images to 1700</h1>
      
        <div class="pt-4 grid grid-cols-3 grid-gap-2">
          <div class="col-span-2">
              <p class="pt-4 text-lg font-bold">A digital library of prints and book illustrations from early modern Britain</p>
              <p class="pt-4 text-md">Search our database of images to 1700</p>
          </div>
        </div>
        </div>
   
    </div>
  </header>     
</template>


