<template>
 <div>
        <h3 class="py-6 text-3xl">Englands Miraculous Preservation Emblematically Described, Erected for a perpetuall Monument to Posterity (1647)</h3>
        <h4 class="py-2 text-xl">No.25 December 2008</h4>
    </div>
    <div class='relative text-left overflow-y-auto'>
      <p class="py-2"> <strong>Copyright of the Trustees of the British Museum.</strong> </p>
      <p class="pt-2 pb-4"> <strong>British Museum Y,1.17. Dimensions of original: 218 mm x 308 mm (image), 436 mm x 315 mm (printed area).</strong></p> 
        <span class="float-left pr-6 pb-2"><img :src="thumbnail" width="200"/> <button type="button" @click="getlargerImage()" class="underline font-bold" >View larger image</button></span> 

        <p class="pb-2">Perhaps via its use as the characteristic attribute of Hope/Spes—surviving to the present day in the synecdochic use of the figure's anchor on the signs of pubs called the <span class="italic">Hope & Anchor</span> - the ship was a well - established symbol of the church or the state (as we noted previously in <router-link :to="`/printsInFocusView/7`" class="text-blue-600 dark:text-blue-500 hover:underline">The Embleme of Englands Distractions</router-link>, August 2006). The outside of the lid of the so-called Armada or Heneage jewel made some time in the second half of the 1580s, for example,  depicts the ark of the English Church on a stormy sea surrounded by the inscription 'SAEVAS TRANQUILLA PER UNDAS' ('calm through the savage waves') in allusion to Elizabeth's safeguarding of the church, and it was a device also used by James I on medals.<sup>i</sup>   </p>

        <p class="pb-2">In the present sheet, <span class="italic">Englands Miraculous Preservation Emblematically Described, Erected for a perpetuall Monument to Posterity</span> (1647), the state of England following the Civil War is symbolised by a storm-tossed ship, 'preserv'd and almost safe at land'. The ship, 'Englands Ark', is divided into three separate compartments labelled, 'House of Lords', 'House of Commons', and 'Asembly' (i.e. the Westminster Assembly set up to reform the English church along Presbyterian lines in 1643). Inset in six medallions are named Parliamentary generals, while drowning in the sea, powerless to impede the ship's safe passage, are Royalists and their sympathisers, including Charles and his Queen (perhaps intended to be siren-like), a square-capped Archbishop Laud (?), Prince Rupert who slashes in vain at the ship with his sword, and Strafford who fires a gun at it. It is accompanied by a long poem by the otherwise little-known John Lecester. </p>

        <p class="pb-2">A slightly later state with no letterpress, and now entitled <span class="italic">Englands Arke Secured and the Eninyes [sic] to the Parliment and Kingdom Overwhelmed</span>, bearing the imprint of Robert Walton, notes that 'These boystrous waves do represent Malignant foes to land & Parliment', and identifies all the drowning royalists by name; the bishop is now identified as 'Greenvill' and the book he holds up as the Book of Common Prayer, while the siren-like woman is labelled 'L[ady] Aubigny', and the document with pendent seal that she holds, inscribed 'Com: of Aray', i.e. a Commission of Array, empowering her to levy troops on the King's behalf. She is also shown holding the same Commission, bearing the King's seal 'brought from Oxford', in the first frame of a multi-frame print entitled <span class="italic">The Malignants Trecherous and Bloody Plot against the Parliament</span> (1643).</p>

        <p class="pb-2">A similar maritime metaphor using the emblematic image of a ship appears in one of the earliest known prints to have been issued by Robert Walton—Thomason dated his copy 17 February 1648—entitled <span class="italic">The Invisible Weapon, or Truth's Triumph over Errors</span>. The text of this itemises no fewer than thirty-nine such errors and refutes them with quotations from Scripture, the very 'Biblia Sacra' which the Church herself, seated under a canopy amidships, holds aloft, a palm in her other hand. The Church's bark is assailed by a motley collection of tyrants, heresiarchs and sectaries—from a mainstream Protestant point of view, of course—i.e. Nero and Domitian, Arius, the Pope, a Turk, a Shaker, an Arminian, a Socinian, a Familist, an Adamite, a Jesuit, a 'Papist' waving a dark lantern, clearly intended for Guy Fawkes, and a literally two-faced 'Antiscripturist' who brandishes a serpent.<sup>ii</sup> Griffiths believes it to have been executed by the same unidentified engraver who cut <span class="italic">Englands Miraculous Preservation Emblematically Described</span>, noting a variation of that sheet also bearing Walton's imprint and dated by Thomason 21 September 1646,<sup>iii</sup>  and indeed, there seem to be textual links between the two sheets too, the latter ending somewhat inconsequentially with 'An Apostrophe to the Church' which includes the lines, 'Outward enmity shal not hurt a jot, / If thine intestine Errors hurt thee not.' </p>

        <p class="pb-2">In similar vein, when Robert White came to engrave a frontispiece for the second volume of <span class="italic">Nalson's An impartial collection of the great affairs of state</span> (1689), he too had recourse to the image of a storm-tossed ship to symbolise the state of England in 1649. While a thunderbolt from heaven strikes St. Stephen's, Westminster Palace, i.e. the Parliamentary chapel, as a symbolic sign of the divine displeasure, and while citizens fight in the foreground, with an army mustered ominously behind them, sailors throw Charles I, Jonah-like, into the water from a ship, significantly identified in 'The Meaning of the Frontispiece', as 'th' Royal Sovereign'.  </p>

        
        <h4 class="py-2 text-xl font-bold">Footnotes</h4> 
        <ul class="list-[lower-roman] list-inside">
          <li class="py-2">See A.G. Somers Cocks (ed.), <cite>Princely Magnificence: Court Jewels of the Renaissance</cite>, 1500-1630 (London, 1980), cat. nos. 38 and 117. </li>
          <li class="py-2">cf. from Torshell's <cite>Harmony of the Bible</cite> (1647): 'The Majesty of it [the Bible] will triumph over the Attempts of all Anti-Scripturists'. cit. OED, s.v. 'antiscripturist'.</li>
          <li class="py-2">Antony Griffiths, <cite>The Print in Stuart Britain</cite> (London, 1998), p. 159. </li>
        </ul> 
  </div>   
</template>

<script>
    export default {
        data() {
          return { 
            imagePath : 'https://britishprintedimages.blob.core.windows.net/input-images/printsinfocus/',
            thumbnail : '',
            largeImage : ''
          }
        },
        methods: {
          getlargerImage() {
                let currentImagePath = this.imagePath + 'Englands miraculous preservation.png' 
                window.open(currentImagePath, "_blank");
            },
        },
        created() {
          this.thumbnail = this.imagePath  + '8.jpg' 
        }
    }
  
  </script>