<template>
 <div>
        <h3 class="py-6 text-3xl">'May' from the Months, with a prologue on trenchers and other applied prints</h3>
        <h4 class="py-2 text-xl">No.29 May 2008</h4>
    </div>
    <div class='relative text-left overflow-y-auto'>
      <p class="py-2"> <strong>Copyright of the Trustees of the British Museum.</strong> </p>
      <p class="pt-2 pb-4"> <strong>British Museum 1863,0808.95. Dimensions of original: 181 mm x 130 mm (cropped)</strong></p> 
        <span class="float-left pr-6 pb-2"><img :src="thumbnail" width="200"/> <button type="button" @click="getlargerImage()" class="underline font-bold" >View larger image</button></span> 

        <p class="pb-2">John Garret's advertisement of 1680 on his reissue of Droeshout's <span class="italic">Spiritual Warfare</span> includes <span class="italic">12 several Sorts of pleasant Landskips, printed on very Large Paper, for Sashes for Windows: Which also serve (being put into Frames) for delightful Ornaments for Houses</span>, and one set of the <span class="italic">Five Senses</span> John Overton was selling in 1673 is described as printed <span class="italic">in rounds for screens</span>.<sup>i</sup>  In the <span class="italic">London Gazette</span> for 6-19 September 1688, an otherwise unknown Mrs Barrett, whose shop was in Three Leg Court in Old Bedlam, advertised <span class="italic">A large Collection of Copper Plates engraven with great Variety of Statues, and other Curious Ornaments, for Hangings, Curtains, &c. Also variety of Land-skips, and small Figures for Shashes</span>.<sup>ii</sup> </p>

        <p class="pb-2">But already in the earliest known printseller's advertisement—that of Peter Stent issued in 1654—the use to which several of his sets of prints might be put is suggested, e.g.</p>

        <p class="pb-2 italic">
        12 Plates for cheez trenchers<br>
        2 Plates of Signes or Badges for Innes, or Taverns. 
        </p>

        <p class="pb-4">With this last item, which has become <span class="italic">Three plats</span> by the time of the 1662 advertisement, compare, from Arthur Tooker's <span class="italic">A Catalogue of Plates</span> (1675), under <span class="italic">Plates of Mr. Gaywoods Etching, invented by Mr. Barlow and others</span>,</p>

        <p class="pb-2">
          <span class="italic">Two</span> [plates of] <span class="italic">Badges or Choice of Signs for Bars and Rooms</span>. 
        </p>

        <p class="pb-2">In his annotations to Samuel Pepys's collection of prints, Aspital suggested that the three engraved sheets of inn-signs, fifteen per sheet, which unfortunately bear no imprint information, may have been the ones appearing in Stent's advertisements.<sup>iii</sup>  Certainly <span class="italic">The Kings head</span> is that of Charles II and on the same sheet, I note that the final sign—where the printer's name and address might be expected, if there were one—is <span class="italic">The White Horse</span>, which was Stent's address. Unknown to Globe, in Douce Prints Portfolio 139, now in the Ashmolean Museum, Oxford, are two such sheets of signs (one fragmentary) bearing the imprint of Stent's successor, John Overton.<sup>iv</sup> A very similar sheet, but in which the signs are disposed vertically in five rows of three, rather than in three rows of five as in the Pepys sheets, is to be found in the Banks collection in the British Museum.<sup>v</sup>   </p>

        <p class="pb-2">While it might be thought such sheets could have served as designs for sign-painters, the wording of Stent's and Tooker's entries, the latter in particular—<span class="italic"></span>Badges or... Signs for Bars and Rooms—may rather imply that they were to be cut up and used to name rooms or bars within large establishments, and not necessarily those dispensing alcoholic liquors. In Shakespeare's <span class="italic">Henry IV</span> plays (c.1597-8) we learn that the Boar's-Head tavern in East Cheap had rooms named <span class="italic">Half-moon, Pomgarnet</span> and <span class="italic">Dolphin</span>, and in Jonson's <span class="italic">Bartholomew Fair</span> (1614), we hear of an inn-room called <span class="italic">Coney</span>—signs of the <span class="italic">Half-moon</span> and <span class="italic">Dolphin</span> appear on the Banks sheet.</p>

        <p class="pb-4">Presumably cashing in on immediate post-Restoration enthusiasm, Stent's 1662 advertisement also lists </p>

        <p class="pb-2">
          <span class="italic">Small pieces of the King Queen, Princess, Dukes of York, Glocester, Albemarl, Royal Oake, Healing the Kings Evil and such like, 12 plats, for to adorn Tobacco-boxes, much in use.</span>
        </p>

        <p class="pb-2">We hear of just such an image of Charles II used strategically by a former Parliamentarian now turned Royalist in the <span class="italic">Preface</span> to L'Estrange's <span class="italic">Apology</span> (1660):</p>

        <p class="pb-2">
          <span class="italic">Out comes the Spruce Tobacco-Box, with the King's Picture at it, which he Wears, and Kisses, not so much out of Kindnesse, and Devotion, as for a Hint, and Introduction, to his Politiques, now at hand.<sup>vi</sup> </span>
        </p>
        
        <p class="pb-2">By the very end of our period, prints might appear on handkerchiefs too, and the Victoria & Albert Museum currently exhibits one such dated 1707 and printed with a celebration of Marlborough's victories. Another contemporary printed handkerchief has the Twelve Months as its subject.</p>

        
        <p class="pb-2">Tooker's 1675 <span class="italic">Catalogue</span>, under <span class="italic">Several sorts of Plates by divers Authors</span>, also lists</p>

        <p class="pb-2">
          <span class="italic">Sixty Stories of variety of Landskips and Ovals with neat borders, and a variety of Beasts, Birds, Hunting, Trees, Worms, Fruit, Flowers, Fishes, &c. Coloured fit for Cabinets, Dressing-boxes, Powder-boxes, Baskets, Skreens, &c.</span>
        </p>

        <p class="pb-2">In her <span class="italic">Supplement to the Queen-like Closet</span> (1674) Hannah Woolley taught her gentlewomen readers how to adorn cabinets, stands and biscuit-plates with prints or parts thereof—in a significant antedating of the Victorian enthusiasm for <span class="italic">découpage</span>.<sup>vii</sup> </p>

        <p class="pb-2">Walton's advertisement from which we have quoted above, continues with a list of the various craftspeople to whom such prints may be of use as design sources:</p>

        <p class="pb-2">
          <span class="italic">also extraordinary [sic] useful for Goldsmiths, Jewelers, Chasers, Gravers, Painters, Carvers, Embroiderers, Drawers, Needle-women, and all Handicrafts.</span>
        </p>

        <p class="pb-4">And we might add, tilers too, for prints were also copied in tile: indeed, nine tiles hand-painted after printed designs charting incidents in the Popish Plot (which also appeared as pictorial playing-cards) survive in the Victoria & Albert Museum, representing what the Museum's online catalogue describes as 'the start of tin-glazed wall tile production in this country'.<sup>viii</sup>   </p>

        <p class="pb-2">Overton's advertisement of 1673 boasts <span class="italic">Five Hundred New sorts of Birds, Beasts, Fish, Flies, Wormes, Flowers, Fruites, Figures, Histories, Landskips, Ovals, Neately cut in Copper, and neatly coloured, for Gentlewomens works, and he is doing more as time will permit</span>, as well as taking a swipe at his rival, Robert Walton: <span class="italic">Iohn Overton... hath more then ten times the choice and stock that R.W. hath, though he vapors that he is the oldest man</span>.</p>

        <p class="pb-2">Many prints will undoubtedly have been put to such decorative use by the craftspeople mentioned in the advertisements, but a few will have been specifically aimed at younger viewers, like the eighteenth-century 'lotteries', and may have been cut up for use in card and other games, or as an aid to reading.</p>

        <p class="pb-2">Certain classes of print were <span class="italic">designed</span> for applied use: they are mostly small in size as they were intended to decorate small boxes (for pills or tobacco) or other artefacts of specific shape—such as circular 'banqueting' trenchers.</p>

        <p class="pb-2"><span class="italic">Trenchers</span> in this sense are thin wooden roundels, often little bigger than a modern circular drinks coaster or beer-mat, that are believed to have been used for the final course of the early modern banquet, in the Elizabethan and Jacobean era in particular. They are usually decorated on one side only, the side on which fruit, cheese, and sweetmeats are thought to have been served, while the other side (which remained face-down during the meal) is either painted with some pictorial design and verse/motto, or—our present concern—has an engraved print pasted to it.</p>

        <p class="pb-2">Several museums possess either complete sets of such trenchers, or sorry and sometimes lone survivors from sets. In fact, in the earliest advertisement of the stock of the London print-seller Peter Stent, of 1654, we read of <span class="italic">12 Plates for cheez trenchers</span>, and <span class="italic">6 plates of Mr Marshal's for Trenchers</span>. Though some rectangular examples do exist,<sup>ix</sup> trenchers are overwhelmingly circular in form, and as illustrated in the advertisement, the standard number in a set is a dozen, though half-dozens are also encountered. It will thus come as no surprise to learn that the most popular subject found pasted on surviving sets is the Months, but other dozens include the Sibyls (even though conventionally only ten in number), and twelve of Aesop's Fables—one of these three subjects could well be that mentioned in Stent's list. Fortunately, we can be certain of the identity of the half-dozen advertised, as the six circular prints in question engraved by William Marshall, survive uniquely in the Folger Shakespeare Library in Washington.<sup>x</sup>  In fact, they are emblems, and the circular emblem, a format first introduced into England with Wither's Collection of Emblemes of 1635 (originally engraved by Crispin de Passe), was clearly tailor-made for the normally circular trencher.<sup>xi</sup> </p>

        <p class="pb-2">The series of <span class="italic">Months</span> published c.1630 attributed to the engraver Robert Vaughan (after de Passe), from which this month's print comes, must have been designed specifically for pasting to trenchers, for the perimeter inscription to March significantly opens, <span class="italic">As trenchers thinn are made for fruite and cheese</span>... while the present May roundel interestingly styles the month's subject (the usual maying-party out in a boat) <span class="italic">this Emblem</span>, before proceeding to moralise it.<sup>xii</sup>  This particular edition of the Months (and there were several editions extant to judge from surviving trencher sets<sup>xiii</sup> ) was certainly found on one of the earliest sets to be published, in <span class="italic">The Gentleman's Magazine</span> for May 1793, p. 398, though the whereabouts of that particular set is now unknown.</p>

        <p class="pb-2">Clearly these Months prints might be saved in an album—as was the case with our present example of the Collings issue—or cut out, pasted to a trencher, and varnished over. Omission of one or both the two concentric rings of inscription also allows for roundels of various diameters.</p>

        <p class="pb-2">The upper verse outside the roundel in the Months series names the prominent saints' days which fall in the month in question, while the lower verse reflects in general (and usually agricultural) terms on the appropriate Labour of the Month.</p>

        <p class="pb-2">The English perimeter verse to the present month is not unusual for this sort of verse in being capable of being read in a double sense:</p>

        <p class="pb-2 italic">
        Heere by this Emblem may be understood
        That when a young man hath a pype that's good,
        The maydes will listen after it with pleasure
        And keepe theire tunes and times unto his measure
        </p>

        <p class="pb-2">Certainly, the male half of one of the couples in the boat is playing on a pipe, but given the sexual context of maying, and the innuendo in other verses of the series, it is, at least, suggestive.</p>

        <p class="pb-2">The originator of this print series was Crispin de Passe the Elder who engraved The Months (after designs by Maarten de Vos) in Cologne probably in the 1590s. That such Months sets were available prior to the date of the English copies of de Passe's engravings we may be sure from a passing allusion to <span class="italic">Twelve trenchers, upon every one a month... and their posies under 'em</span> in Middleton's <span class="italic">No Wit, no Help Like a Woman's</span>, published in 1612 (II. i. 62ff.), though the actual verses quoted correspond to no known set. They were clearly a very popular series in England, however, though the number of English editions has yet to be properly sorted out. What follows is but 'work in progress'! I am aware of three different sets of vernacular perimeter verses. I quote the three versions for September, as only that month survives as a representative of Type 1:</p>

        <p class="pb-2">
        1<br>
        <span class="italic">September showes you playne of sondry sortes of ffishes<br>
        Give God the praise that filleth thus your dishes.</span><sup>xiv</sup><br>
        2<br>
        <span class="italic">The sea and land yields store of Fishe and fruit, most fishe [sic];<br>
        Eate not to muche September wills, it may thy health ympayr.</span><sup>xv</sup> <br>
        3<br>
        <span class="italic">Amongst all Creatures Woemen still are od<br>
        Of fleshe or fish, they Cheifely love the Cod:<sup>xvi</sup> <br> 
        For if the Goodwife haue it not, noe doubte<br>
        Her Husband shall be seru'd with Carpe and Powte.</span><sup>xvii</sup>
        </p>

        <p class="pb-2">The history of the issue of the Type 3 set can be traced as follows:</p>

        <ul class="list-disc list-inside py-2 mb-2">
          <li>Roger Daniel (c. 1629)—(erasure still visible under next)</li>
          <li>Thomas Johnson (c. 1630)—British Museum Prints & Drawings 166 c.1 (2)</li>
          <li>Matt Collings (1660s)—BM P&D 157* a.18</li>
          <li>Robert Walton (active 1647-87)—Ashmolean, Douce E.2.6 (168)</li>
        </ul>

        <p class="pb-2">It is notable that all three of the dozens known to have been pasted to trenchers, the <span class="italic">Months, Sibyls</span> and <span class="italic">Fables</span>, were being sold by the printseller Thomas Johnson c.1630; he seems to have inherited the stock of Roger Daniel after Daniel had moved from London to Cambridge the previous year, and indeed, an earlier state of the <span class="italic">Sibyls</span> is known with Daniel's address.</p>

        <p class="pb-2">As a postscript, it is perhaps worth noting that the <span class="italic">litterati</span> could not resist a sneer at the quality of the verse inscriptions on trenchers, whether painted or engraved. In his <span class="italic">Art of English Poesy</span> (1589), however, Puttenham is not judgemental, neutrally stating that <span class="italic">We call them</span> [sc. epigrams] <span class="italic">posies, and do paint them nowadays upon the backsides of our trenchers of wood, or use them as devices in arms or in rings</span>. And, indeed, when John Heywood came to compose an epigram on his own <span class="italic">Book of Epigrammes</span>, first published in 1555, the metaphorical resemblance of that work to a trencher seemed apposite: <span class="italic">This booke maie seeme, as it sorteth in sute, A thin trym trencher to serue folke at frute</span>, but by 1597 Joseph Hall is referring dismissively to <span class="italic">hunger-starved trencher poetry</span> (<span class="italic">Satires</span> I. i. 13), and Milton couples such verses with ring-posies:</p>

        <p class="pb-2"><span class="italic">Instead of well siz'd periods, he greets us with a quantity of thumb-ring posies. He has a fortune therefore good/ because he is content with it. This is a piece of sapience not worth the brain of a fruit-trencher.</span><sup>xviii</sup> </p>

        <p class="pb-2">Middleton has one of his characters in <span class="italic">The Old Law</span> (1627) refer sneeringly to <span class="italic">running admonitions Upon cheese-trenchers</span>, and cites as an example of such an <span class="italic">admonition</span>,</p>

        <p class="pb-2">
        <span class="italic">Take heed of whoring, shun it;<br>
        'Tis like a cheese too strong of the runnet</span><sup>xix</sup> <br> 
        (II.i. 126ff.)
        </p>

        <p class="pb-2">which is, admittedly, bathetic! In similarly dismissive vein, the vastly learned Robert Burton in his <span class="italic">Anatomy of Melancholy</span> (1621) sarcastically concludes a long list of entirely serious maxims and adages with, <span class="italic">Look for more in Isocrates, Seneca, Plutarch, Epictetus, etc., and for defect, consult with cheese-trenchers and painted cloths</span>.<sup>xx</sup></p>

        <p class="pb-2">Maybe it is the contempt of such 'canonical' writers that has led to the unaccountable scholarly neglect of the genre which, however, has much to reveal about contemporary <span class="italic">mentalité</span>.<sup>xxi</sup> </p>

        <p class="pb-2">The Type 3 verse for September (above) is a case in point—it records a metaphorical commonplace that seems to have been popular from at least the late sixteenth century, when Nashe referred in his <span class="italic">Prognostication</span> (1591) to <span class="italic">Plentie of poutes to bee had in all places, especiallie in those coastes and Countries where weomen haue not their owne willes</span>. The pun on <span class="italic">carp</span> in the September verse will be apparent, but <span class="italic">pout</span> may not be familiar—OED defines the fishy variety as 'a name applied to several kinds of fish, most commonly to the bib or whiting-pout', and <span class="italic">cod</span> at this period was also a common vernacular term for the scrotum.</p>

        <p class="pb-2">Compare Lyly's <span class="italic">Endimion</span> (1632), III.iii.126ff.:</p>

        <p class="pb-2">
        Epiton: <span class="italic">Why in marrying Dipsas, shall haue euery day… foure [dishes] fish… Crab, Carpe
        Lumpe,</span><sup>xxii</sup> <span class="italic">and Powting.</span><br>
        Samias: <span class="italic">Excellent, for of my word, she is both crabbish, lumpish, and carping.</span>
        </p>

        <p class="pb-4">or this from <span class="italic">The Merry Bell-man's Out-Cryes, or, The City's O yes</span> (1655):</p>

        <p class="pb-4">
          <span class="italic"> and ye shall hear a Juniper Lecter, in Billings-gate Nonsense, and have a messe of maundring Broath,</span><sup>xxiii</sup> <span class="italic">wih a dish of Carps and Powts</span>
        </p>

        <p class="pb-2">By the mid century the jest was being recycled in the popular <span class="italic">Figure of…</span> literature, e.g. in the <span class="italic">Nine sorts of Dishes a lewd wife provides her Husband from The figure of nine</span> (1662):</p>

        <p class="pb-2 italic">
        a messe of Mandring broth<br>
        a wormwood posset<br>
        a Dish of crabbed faces<br>
        a panful of Carp<br>
        a mouthful of pouts… 
        </p>

        <p class="pb-4">or in the <span class="italic">Seven things some women provide for their Husbands from The Figure of Seaven</span> by 'MP' (1686<sup>xxiv</sup>):</p>

        <p class="pb-2 italic">
        1 A mess of maundring broth<br>
        2 a dish of Powts<br>
        3 a handfull of Rue<br>
        4 a Wormwood Posset<br>
        5 a Pan full of Carp…
        </p>


        <h4 class="py-2 text-xl font-bold">Footnotes</h4> 
        <ul class="list-[lower-roman] list-inside">
          <li class="py-2">Alexander Globe, <cite>Peter Stent, London Printseller</cite>, c.1642-65 (Vancouver, 1985), p. 179, item 76 (aXVI).  </li>
          <li class="py-2">i.e. OED <span class="italic">sash</span> 1.b, 'A window-frame covered with paper or linen'. I quote this from the section, 'Advertisements for Prints' of the most useful website, <cite>Early Modern Rambler</cite>, by C.H.L. George: http://newspaperadvertisements.wordpress.com/page/3/ </li>
          <li class="py-2">A.W. Aspital, <cite>Prints and Drawings. Part I: General,</cite> in Robert Latham (ed.), <cite>Catalogue of the Pepys Library</cite>, vol. 3, pt. 1 (Woodbridge, 1980), p. 39, cat. nos. 496-7a.  </li>
          <li class="py-2">Oxford, Ashmolean Museum, Douce Prints Portfolio 139, nos. 961 and 967, <cite>[collored and sold] by Iohn Overton att the white [horse without Newgate]</cite>. In the Dicey/Marshall catalogue of '1764' a 'copper royal' of <cite>The Signs of Taverns</cite> is also listed.</li>
          <li class="py-2">  Information I owe to the kindness of Sheila O'Connell. The registration number is Department of Prints and Drawings, Banks 132.153.  </li>
          <li class="py-2">I owe this reference to Tamsyn Williams, <cite>'Magnetic Figures</cite>: Polemical Prints of the English Revolution', in Lucy Gent & Nigel Llewellyn (eds), <cite>Renaissance Bodies </cite>(London, 1990), pp. 86-110, on p. 110. </li>
          <li class="py-2">B  For a seventeenth-century box so decorated, see A.J.B. Wace, 'Embroidery', <cite>Apollo</cite>, 18 (1933), p. 24, fig.4, cited in Globe, <cite>Peter Stent</cite>, p. 43, n. 57. </li>
          <li class="py-2">Mus. no. 414:823/1-1885. </li>
          <li class="py-2">Three survivors from a set of rectangular trenchers survive in the Strangers' Hall Museum, Norwich [76.94.435; 76.94.435a; 76.94.435b]. Anthony Wells-Cole, <cite>Art and Decoration in Elizabethan and Jacobean England</cite> (New Haven and London, 1997), p. 26, has identified their painted decoration as copying Biblical woodcuts by Virgil Solis.  </li>
          <li class="py-2">Peter M. Daly & Mary V. Silcox, 'William Marshall's Emblems (1650) Rediscovered', <cite>English Literary Renaissance</cite>, 19 (1989), pp. 346-74, supplemented by Michael Bath and Malcolm Jones, 'Emblems and Trencher Decorations: Further Examples', <cite>Emblematica</cite>, 10 (1996), pp. 205-10. </li>
          <li class="py-2">For another example of emblematic designs after those in a printed emblem-book painted on rectangular trenchers, see Michael Bath, 'Emblems from Alciato in Jacobean Trencher Decorations', <cite>Emblematica</cite>, 8 (1994), pp. 359-70. </li>
          <li class="py-2">ESTC S94209 (STC 18051.5); not in Hind; bound copy in British Museum Department of Prints & Drawings 166.c.1 (lacks December).  </li>
          <li class="py-2">With the publishers as listed below.</li>
          <li class="py-2">British Museum, Dept. of MME 95, 6-3, 159. In the first line, <span class="italic">playne</span> is perhaps meant to be <span class="italic">playnte</span></li>
          <li class="py-2">BM MME 1921, 2-16, 34 ['Harington' set]; Salisbury Museum; singletons in Stranger's Hall Museum, Norwich, & Plymouth Hall Museum, Plymouth, Mass. At the end of the first line, <span class="italic">fishe</span> should probably be <span class="italic">fayr</span>. </li>
          <li class="py-2">punning on 'scrotum'. </li>
          <li class="py-2">Print series issued by Johnson, Collings, and Walton; the only known applied example of this edition is the lost Drew set—see <cite>Gentleman's Magazine</cite>, 9 July 1793. See further below.</li>
          <li class="py-2">Milton, <cite>Apollo Smectymnus</cite> (London, 1642), p. 28. </li>
          <li class="py-2">i.e. rennet. </li>
          <li class="py-2">Robert Burton, <cite>The Anatomy of Melancholy</cite>, ed. H. Jackson (London, 1932), pt. 2 sec. 3 mem. 8. Painted cloths are urgently in need of discussion! </li>
          <li class="py-2">I discuss such trencher verse in <cite>'Such pretty things would soon be gone</cite>: the Neglected Genres of Popular Verse 1480-1650', in Michael Hattaway (ed.), <cite>A Companion to English Renaissance Literature and Culture</cite> (Oxford, 2000), pp. 442-63.</li>
          <li class="py-2">  'A spiny-finned fish of a leaden-blue colour and uncouth appearance, Cyclopterus lumpus, characterized by a suctorial disk on its belly with which it adheres to objects with great force (whence its name of lump sucker); the sea-owl.' [OED]</li>
          <li class="py-2">  i.e. grumbling, scolding—not in OED—a significant antedating here of Grose, etc. </li>
          <li class="py-2">The composition may have been at least 30 years earlier, however, if the 'MP' in question was the popular ballad- and hack-writer, Martin Parker (d.1656). </li>
        </ul> 
  </div>   
</template>

<script>
    export default {
        data() {
          return { 
            imagePath : 'https://britishprintedimages.blob.core.windows.net/input-images/printsinfocus/',
            thumbnail : '',
            largeImage : ''
          }
        },
        methods: {
          getlargerImage() {
                let currentImagePath = this.imagePath + 'May.png' 
                window.open(currentImagePath, "_blank");
            },
        },
        created() {
          this.thumbnail = this.imagePath  + '15.jpg' 
        }
    }
  
  </script>