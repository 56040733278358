<template>
    <div>
        <h3 class="py-6 text-3xl">All Doe Ride The Asse</h3>
        <h4 class="py-2 text-xl">No.3 Oct 2006</h4>
    </div>
    <div class='relative text-left overflow-y-auto'>
      <p class="py-2"> <strong>Copyright of the Trustees of the British Museum.</strong> </p>
      <p class="pt-2 pb-4"> <strong>British Museum 1855,0114.189. Dimensions of original: 290 mm x 423 mm.</strong> </p>
        
        <p><span class="float-left pr-6 pb-2"><img :src="thumbnail" width="300"/> <button type="button" @click="getlargerImage()" class="underline font-bold" >View larger image</button></span></p>   
     <p class="pb-2">
        <span class="italic">A picture of the Ridinge of the Asse</span> was licensed to Henry Roberts on 7th March 1607 according to the  <span class="italic">Stationers' Registers</span>, thus providing a very precise date for the fine print previously attributed by A.M. Hind to an 'Anonymous Engraver, c.1600-10',<sup>i</sup> and surviving only in a later impression in the British Museum and the Houghton Library, Harvard, with the address of the late seventeenth-century publisher, John Garrett. Recently Antony Griffiths firmly attributed it to the engraver Renold Elstrack, born c.1570 and last heard of in 1625. He further noted that the printseller Thomas Jenner was advertising it as part of his stock in 1662 as <span class="italic">The Ridinge of the Asse</span>, and that it must have passed, on his death eleven years later, to Garrett (as did most of the rest of Jenner's stock). As Griffiths cogently remarks, 'That only two impressions survive of a print that remained in production for so long is entirely typical of early British printmaking'.<sup>ii</sup> Elstrack is also known as the engraver of at least one other broadside satire, the traditional misogynist <span class="italic">Bulchin and Thingut</span>.<sup>iii</sup></p>
        
        <p class="py-2">Perhaps not altogether surprisingly, given what little we know of Elstrack's family origins, his engraving derives quite closely from an early sixteenth-century German woodcut of which an impression survives in the Douce print collection at the Ashmolean Museum, Oxford, though not the speeches given to the various representatives of society who struggle to ride the ass. <sup>iv</sup>Evidently a fairly well-known motif in Germany, it is found, for example, on one of the biscuit-moulds (dated 1517) belonging to Claus Stalburg the Rich, Mayor of Frankfurt, who catalogued it thus: <span class="italic">Der 16. stain ist das eselryten, und will eyn iglicher der allernest seyn</span> [The 16th stone (biscuit-mould) is Riding the Ass, and everyone wants to be the very next].<sup>v</sup></p>

        <p class="py-2">
        In graphic art it reappears roughly a century later in Peter Rollos' print-book aimed at the <span class="italic">alba amicorum</span> student market, the <span class="italic">Philotheca Corneliana</span> (Berlin, 1619), the plate itself being pasted onto f.101v. of the album of Hans Ludwig Pfinzing von Henfenfelden [ Bamberg, SBB, Msc. Hist, 176]. 
        </p>

        <p class="py-2">
        The German woodcut print is untitled, but the full title of Elstrack's version - <span class="italic">Whilst maskinge in their follies all doe passe/ Though all say nay yet all doe ride the asse -</span> probably accurately reflects the intention of the original composition, i.e. it is a general, rather than a particular, satire, in which, with the exception of the judge (<span class="italic">Mr Justice</span> - though the verse explains that his motives for declining to ride are selfish), all social ranks and types of early Jacobean society (including the prostitute <span class="italic">Dame Punke, Don Pandar, Don Gull and a Gallant</span>) are associated with asinine folly (cf. the similar message of <span class="italic">Doctor Panurgus</span>, which will be discussed next month). All 'ride the ass', that is, everyone is foolish, though they even squabble about this. With this usage, compare the title-page woodcut to <span class="italic">The Fool's Complaint to Gotham College</span> (1643) depicting a fool riding on an ass which says, <span class="italic">The fool rides me</span>. Though seemingly unrecorded elsewhere, Robert Burton appears to use the idiom in precisely the same sense and context in his vastly learned <span class="italic">Anatomy of Melancholy</span> (1621): that they may go <span class="italic">'ride the ass'</span>, and all sail along in the <span class="italic">'ship of fools'</span>.<sup>vi</sup> Burton shows an awareness of pictorial prints throughout his book, referring earlier in passing to the fact that <span class="italic">all the world is mad... it is (which Epicthonius Cosmopolites expressed not many years since in a map) made like a fool's head (with that motto, Caput helleboro dignum).</span><sup>vii</sup> </p>

        <p class="py-2">A late seventeenth - or perhaps early eighteenth - century engraver revived and revised Elstrack's composition to illustrate another sheet entitled, <span class="italic">The ass age, or the world in hieroglyphick. An amusement, agreeably resembling the humour of the present times.</span> Interestingly an earlier state, which must have been pirated, is suggested by the words <span class="italic">Beware of paultry wooden-cutts</span>  after the imprint, and, indeed, a woodcut-illustrated version survives in the Folger Shakespeare Library, entitled <span class="italic">The ass age: or, the fools in fashion: being a comical description of the times</span>, dated 1712. </p> 

      
        <h4 class="py-2 text-xl font-bold">Footnotes</h4> 
        <ul class="list-[lower-roman] list-inside">
          <li class="py-2">A.M. Hind, <cite>Engraving in England in the Sixteenth and Sevententh Centuries </cite>(3 vols. Cambridge, 1952-64), vol. 1, pp. 290-2. </li>
          <li class="py-2">Antony Griffiths, <cite>The Print in Stuart Britain 1603-89 </cite>(London, 1998), pp. 145-6 (no. 90). </li>
          <li class="py-2">For which see M. Jones, <cite>“Bigorne et Chicheface -- suite et fin?”</cite> in ed. T. S. Jones  & D. A. Sprunger, <cite>Marvels, Monsters & Miracles: Studies in the Medieval and Early Modern Imagination in Honor of  John  Block Friedman </cite>(Kalamazoo, 2002), 203-221.</li>
          <li class="py-2">Reproduced on p.299 Malcolm Jones, <cite>The Print in Early Modern England</cite> (New Haven, 2010). </li>
          <li class="py-2">Malcolm Jones, <cite>The Secret Middle Ages</cite> (Stroud, 2002), pp. 7-8. </li>
          <li class="py-2">H. Jackson (ed.), Robert Burton, <cite>The Anatomy of Melancholy</cite>  (London, 1972), p. 72. </li>
          <li class="py-2">Jackson, ed. cit., p. 39. This particular fool's head world-map is, in fact, extant, and was issued in Antwerp some thirty years before Burton wrote, c.1590; there is an impression in the Douce Collection in the Ashmolean Museum, Oxford: Portfolio 142 (92). I reproduce it in M. Jones, 'The English Print, c.1550-c.1650', in M. Hattaway (ed.), <cite>A Companion to English Renaissance Literature</cite> (Oxford, 2000), pp. 352-66. The Dutch print is itself an elaboration of an earlier French woodcut version by Jean de Gourmont II (Paris, c.1575).  </li>
        </ul> 
    </div>
   
</template>

<script>
    export default {
        data() {
          return { 
            imagePath : 'https://britishprintedimages.blob.core.windows.net/input-images/printsinfocus/',
            thumbnail : '',
            largeImage : ''
          }
        },
        methods: {
          getlargerImage() {
                let currentImagePath = this.imagePath + 'All Doe Ride the Ass.png' 
                window.open(currentImagePath, "_blank");
          }
        },
        created() {
          this.thumbnail = this.imagePath  + '1.jpg' 
        }
    }
  
  </script>