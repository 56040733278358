<template>
    <div>
        <h3 class="py-6 text-3xl">The Cats Castle Besieged and Stormed by the Rats</h3>
        <h4 class="py-2 text-xl">No.12  August 2007</h4>
    </div>
    <div class='relative text-left overflow-y-auto'>
      <p class="py-2"> <strong>Copyright of the Trustees of the British Museum.</strong> </p>
      <p class="pt-2 pb-4"> <strong>British Museum 1953,0411.70. Dimensions of original: 217 mm x 295 mm</strong></p> 
      <p>
        <span class="float-left pr-6 pb-2"><img :src="thumbnail" width="300"/> <button type="button" @click="getlargerImage()" class="underline font-bold" >View larger image</button></span></p>   
     <p class="pb-2">
      One particularly venerable constituent reversal of the <span class="italic">monde renversé</span> topos, that of the cats hunted by the rats, early achieved independence as a subject in its own right -- as early as the 12th century mural painting in the church of Purgg in Austria --  in the form of <span class="italic">The Cats Castle Besieged and Stormed by the Rats</span>. As a print, it was popular throughout Europe, from as early as c.1500 in Germany,<sup>i</sup> but the earliest known English example to survive was issued some time after c.1665, “<span class="italic">qrinted</span> [sic] and <span class="italic">Sould by John Overton</span>”, though it does not appear in any of Overton's published catalogues; it is preserved uniquely in the Department of Prints and Drawings at the British Museum. A very closely-related version, but without any imprint, is held in the Library of Congress collection.</p>
        
      <p class="py-2">Without attempting a comprehensive listing, subsequent eighteenth-century versions include the 'wood royal' <span class="italic">Cat's Castle, besieged and stormed by the Rats</span> recorded in Marshall and Dicey's '1764' catalogue;<sup>ii</sup> <span class="italic">Cats castle besieged by the rats</span> issued by John Marshall c.1790 (Bodleian); another of c.1790 by Bowles & Carver, and a version closely related to this, bearing the imprint of G (?) Ash of Fetter Lane, London (British Museum), and yet another example issued by G. Sheppard in the Lewis-Walpole Library, Farmington, Connecticut. </p>

      <p class="py-2">The present Overton sheet dramatically presents the feline fortress centre-stage, with flags flying from its turrets, a bell tower and a cupola with a rat being hanged from a protruding mast. In it stands <span class="italic">Maister Tybet [sic] prince of Cat[s]</span>, who proclaims: </p> 
      
      <p class="py-2 italic">
      My command is to you all<br>
      To kill the Rats both great & smal
      </p>

      <p class="py-2">continuing:</p>

      <p class="py-2">
      <span class="italic">Downe with the Rouges</span> [sic] <span class="italic">defend me now or never<br>
      If they get up we are undon for ever.</span>
      </p>

      <p class="py-2"> However, the castle is the subject of a formidable assault by its rodent adversaries, with ladders set up against its walls, cannons on every side and several phalanxes of attackers advancing, including a group approaching from the bottom left in a boat with the legend: </p>
      
      <p class="py-2">
      <span class="italic">Fight ou</span> [sic] <span class="italic">brave Rats and kill our mortall foes<br>
      We'll fight it out nor Will we feare thair blowes</span>
      </p>

      <p class="py-2">while in the centre a cannon is being fired to cries of:</p>

      <p class="py-2">
      <span class="italic">Your commistion</span> [sic] <span class="italic">is to kill and destroy<br>
      Mischevous Cats that doe your state annoy.</span>
      </p>

      <p class="py-2">Above this is a gallows where a rat is hanging a cat with the legend: <span class="italic">up you goe Mistriss puse</span>, and another cat is having a noose put round her neck. Below are two scenes which appear inset in rectangular frames (though, in fact, only one may properly be so called — close inspection shows that it is the pole of the banner which forms the apparent lefthand margin of the scenes). These apparently inset scenes perhaps adumbrate the comic-strip presentation of later versions; the 'upper scene' shows two rats reading the letter of commission with its pendent seals, while the 'lower' one, showing a band of eight rats armed with halberds and spears held aloft, is captioned,</p>
      
      <p class="py-2">
      <span class="italic">if wee these catcs</span> [sic] <span class="italic">can overcom and kill<br>
      of cheese and Bacon we shall have our fill.</span>
      </p>

      <p class="py-2">This literary humour is reflected in details of the image — the rats' banners displaying cheeses and hams, while the flags flown from the cats' castle bear fish (three fish in the earliest-known German woodcut of c.1500, and also in the late seventeenth-century English version where they appear above the legend <span class="italic">Herrings for ever</span>, while the ensign which flutters from the stern of <span class="italic">The Royal Rat</span> depicts two rats either side of a large circular cheese over the motto, <span class="italic">Cheshire Cheese for ever</span>).</p>

      <p class="py-2">The names of the two battle-leaders on the present sheet perhaps suggest derivation from some French version (<span class="italic">Tybert</span>—spelled correctly in the Library of Congress impression—and <span class="italic">Nigro Musell</span>, perhaps Black Snout, the latter element from Old French <span class="italic">musel</span>, snout), and yet in an early seventeenth-century French version of <span class="italic">La grande & merueilleuse Bataille, d'entre les Chats, & les Rats</span>, which bears the imprint <span class="italic">A Lyon pour Leonard Odet; au coin de rue Ferrandiere</span>, 1610, the prince des Chatz is named <span class="italic">Mitou</span>, while in a later seventeenth-century version, closer in composition to the present example, issued by Pierre Bertrand in Paris and entitled <span class="italic">Le Fort des Chatz assiege par les Ratz</span>, he is named <span class="italic">Raminagrobis </span>(probably the source of the name of La Fontaine's feline hero). It is curious that it is the English sheet rather than the French one which uses the name <span class="italic">Tibert</span> for the cats' leader. Deriving from the name given to the cat in the medieval French beast-epic, the <span class="italic">Roman de Renart</span>, it was certainly familiar to the Elizabethans and lies behind Mercutio's punning reference to <span class="italic">Tybalt</span> as <span class="italic">King of Cats in Romeo & Juliet</span> (III.i.76), and Nashe's contemporary reference to <span class="italic">Tibault...Prince of Cats</span> in <span class="italic">Have with you to Saffron Walden</span> (1596). By the time of the late eighteenth-century English sheets, the protagonists are the less colourfully-named <span class="italic">King Mew</span> and <span class="italic">Prince Squeak</span>.</p>

      <p class="py-2">Though all European versions of the print bear a close generic resemblance, telltale details in the English version suggest it derives immediately (in reverse) from a Dutch model.<sup>iii</sup>  </p>

      <p class="py-2">The originators of the prints have left the siege in the balance—from the images it is not possible to guess the outcome—but the lengthy verse text accompanying the Lyon version, at least, makes it clear that the cats, who are explicitly said to represent great villains <span class="italic">[gros larrons]</span>, successfully defend the castle and the few surviving rats, representing the lesser villains <span class="italic"></span> are forced to flee. The earliest known version similarly leaves the outcome of the siege undecided, but we are given some orientation as to how to read this provocative image from the couplet</p>

      <p class="py-2">
      <span class="italic">disi figur get alle di an<br>
      dy iren obristen under sta[n]</span><br>
      This image refers to all those <br>
      ho are subject to their superiors</p>

      <p class="py-2">Though even this in its ambiguity—tautology, indeed!—gets us no further, though German commentators have noted the popular peasant movements in late fifteenth-century Germany associated with such names as the <span class="italic">Bundschuh</span>, and a little later, <span class="italic">Armer Konrad</span>. At the time our present print was issued, the tragic upheaval of the English Civil War was still fresh in the memory, and it is quite possible that an earlier (lost) version—or, just possibly, the very version without imprint in the Library of Congress—had been issued in the 1630s or 1640s. But while there is some reason to believe that the rats storming the cats' castle was sometimes capable of a political interpretation, it seems that for the majority of viewers most of the time it was a purely humorous image.</p>

      <p class="py-2">The Bowles & Carter version, London, c.1790, has verse captions as follows:</p>

      <p class="py-2 italic">
      As the Cats had been long the Rats' foes<br>
      The latter resolv'd to oppose<br>
      So by Land and by Sea in a throng<br>
      They scratch and they quarrel ding dong<br>
      And over their foes to prevail<br>
      Some fire and some the Walls scale<br>
      Yet how it will end we can't say<br>
      But see the fight out and you may.<br>
      King Mew his commission bestows<br>
      to murder all Rats as his foes.<br>
      Prince Squeak gives his orders with joy<br>
      All Enemy Cats to destroy.<br>
      A Squadron of rats sally out<br>
      to put all the Cats to the rout.<br>
      The Pussies when taken are banged<br>
      Drawn quarter'd beheaded & hang'd. 
      </p>

      <h4 class="py-2 text-xl font-bold">Footnotes</h4> 
      <ul class="list-[lower-roman] list-inside">
        <li class="py-2">For the anterior history of the motif, found in wall-painting from the twelfth century, see Malcolm Jones,  <cite>The Secret Middle Ages  </cite>(Stroud, 2002), p. 148.  </li>
        <li class="py-2">available at <a class="" href="http://www.diceyandmarshall.bham.ac.uk/refframe.htm" target="_blank">http://www.diceyandmarshall.bham.ac.uk/refframe.htm</a></li>
        <li class="py-2">A sixteenth-century two-block woodcut print, of which the right-hand half only survives in an eighteenth-century impression, reproduced in E.H. van Heurck and G.J. Boekenoogen, <cite>L'Imagerie Populaire des Pays-Bas: Belgique-Hollande </cite>(Paris, 1930), p. 29.</li>
      </ul> 
        
    </div>
</template>

<script>
    export default {
        data() {
          return { 
            imagePath : 'https://britishprintedimages.blob.core.windows.net/input-images/printsinfocus/',
            thumbnail : '',
            largeImage : ''
          }
        },
        methods: {
          getlargerImage() {
                let currentImagePath = this.imagePath + 'Cats.png' 
                window.open(currentImagePath, "_blank");
            },
        },
        created() {
          this.thumbnail = this.imagePath  + '2.jpg' 
        }
    }
  
  </script>