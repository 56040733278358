<template>

    <section id="accordion-collapse" data-accordion="collapse">
    <h2 id="accordion-collapse-heading-1">
        <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-700 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-stone-400 hover:bg-stone-200 dark:hover:bg-stone-800 gap-3" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
        <span class="text-2xl">Barlow, Francis</span>
        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
        </svg>
        </button>
    </h2>
    <div id="accordion-collapse-body-1" class="hidden" aria-labelledby="accordion-collapse-heading-1">
        <div class="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900 text-left overflow-y-auto"> 
            
            <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                (active 1648-1704) 'The central figure of British graphic art of the second half of the 17th century' (Griffiths, Print in Stuart Britain, p. 140). Said by George Vertue to have come from Lincolnshire and to have been apprenticed to the portrait painter, William Shepherd, Barlow was responsible for various large oil paintings of birds and animals which survive at Clandon Park, Surrey, and at Ham House. He was also a noted designer and etcher, producing high quality illustrations for Edward Benlowes' Theophila (1652) and for an edition of Aesop's Fables which he published himself in 1666. He also designed sets of prints of birds and animals which were etched by Wenceslaus Hollar, Richard Gaywood and others. During and after the Popish Plot, he produced propaganda prints on behalf of the Whigs, including sets of playing cards. According to Vertue, Barlow was poor when he died in 1704.
                </p>
        </div>
    </div>
    <h2 id="accordion-collapse-heading-2">
        <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-2" aria-expanded="false" aria-controls="accordion-collapse-body-2">
        <span class="text-2xl">Beckett, Isaac</span>
        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
        </svg>
        </button>
    </h2>
    <div id="accordion-collapse-body-2" class="hidden" aria-labelledby="accordion-collapse-heading-2">
        <div class="p-5 border border-b-0 border-gray-200 dark:border-gray-700 text-left">
            <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                (c. 1653-88) The first great mezzotint engraver of the English school. Said by George Vertue to have learned the technique from Edward Luttrell while apprenticed to a calico printer, Beckett's earliest known mezzotints date from c. 1681. Initially, his prints were published by Edward Cooper and Alexander Browne, but by 1686 he had set up his own business. His signed mezzotints are mostly portraits, particularly after paintings by Willem Wissing and Godfrey Kneller, though he also published landscapes and genre subjects. He died in 1688, leaving the field open to his pupil, John Smith, who took over his relationship with Kneller and much of his stock.
            </p>
    
        </div>
        </div>
        <h2 id="accordion-collapse-heading-3">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-3" aria-expanded="false" aria-controls="accordion-collapse-body-3">
            <span class="text-2xl">Blooteling, Abraham der </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
            <div id="accordion-collapse-body-3" class="hidden" aria-labelledby="accordion-collapse-heading-3">
                <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">

                    <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                        (1614-?66) Van der Borcht was the son of a man of the same name who had fled from the Netherlands to Germany, where he was trained partly by his father and partly by Matthaüs Merian, through whom he probably met Hollar. In 1636 he was taken into service by Thomas Howard, Earl of Arundel, returning with him to London where he took responsibility for Arundel's art collection. He was responsible for various prints, many of them of objects from the Arundel collection. In his later years he returned to Frankfurt and helped his brothers in the family art-dealing business.
                    </p>
                
                </div>
            </div>
        <h2 id="accordion-collapse-heading-4">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-4" aria-expanded="false" aria-controls="accordion-collapse-body-4">
            <span class="text-2xl">Burghers, Michael</span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-4" class="hidden" aria-labelledby="accordion-collapse-heading-4">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (1648-?1724) Burghers hailed from Amsterdam, and he started his career as a journeyman at Oxford assisting David Loggan on his Oxonia Illustrata. When Loggan returned to London in 1675 he took his place as the principal engraver for publications at Oxford, inheriting Loggan's position as 'Calcographus Academicus' in 1694. He was responsible for various Oxford almanacs and for many portraits, book illustrations and engraved title-pages.
                </p>
            </div>
        </div>
        <h2 id="accordion-collapse-heading-5">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-5" aria-expanded="false" aria-controls="accordion-collapse-body-5">
            <span class="text-2xl">Caus, Isaac de</span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-5" class="hidden" aria-labelledby="accordion-collapse-heading-5">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (active 1623-55) Son or nephew of the garden designer and architect, Salomon de Caus (1576-1626), Isaac probably came to England in 1611 and was later to act as an assistant to Inigo Jones. His publications comprise a set of etchings of the gardens of Wilton House, for which he was responsible, and a book on waterworks published in 1644.
                </p>  
            </div>
        </div>  
        <h2 id="accordion-collapse-heading-6">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-6" aria-expanded="false" aria-controls="accordion-collapse-body-6">
            <span class="text-2xl">Cecill, Thomas </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-6" class="hidden" aria-labelledby="accordion-collapse-heading-6">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (fl. 1626-40) Printmaker active in London in the years before the Civil War. He was responsible for a large number of engraved title-pages as well as some fine portraits and the popular print, 'A New Year's Guift for Shrews'. Cecill clearly owed much to the de Passe brothers, while he is stylistically like John Payne.
                </p>  
            </div>
        </div>  
        <h2 id="accordion-collapse-heading-7">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-7" aria-expanded="false" aria-controls="accordion-collapse-body-7">
            <span class="text-2xl">Clein, Francis </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-7" class="hidden" aria-labelledby="accordion-collapse-heading-7">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (?1582-1658) 'One of the most significant artists working in England in the first half of the 17th century (Griffiths, Print in Stuart Britain, p. 118). Clein was born at Rostock on the Baltic and worked for Christian IV of Denmark before coming to England in 1623, where he was patronised first by James I and then by Charles I. Clein's most significant activity was as designer of the tapestries produced at Mortlake, and he also executed decorations for various country houses, including Ham House. When the Civil War forced Charles I to discontinue his subsidy to the Mortlake factory, Clein instead turned to printmaking, and most of his designs date from between 1645 and his death, including various sets of prints with mythological subjects and illustrations for John Ogilby's Fables of Aesop Paraphrased (1651) and his editions of Virgil and Homer.
                </p>  
            </div>
        </div>  
        <h2 id="accordion-collapse-heading-8">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-8" aria-expanded="false" aria-controls="accordion-collapse-body-8">
            <span class="text-2xl">Cockson, Thomas </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-8" class="hidden" aria-labelledby="accordion-collapse-heading-8">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (active c. 1591-1636) Cockson was the most prolific producer of portrait prints in Tudor England, whose activity continued well into the 17th century. Among his most notable works were equestrian portraits of the Earls of Cumberland, Devonshire, Essex and Nottingham, and he also contributed to Baziliωlogia (1618). In addition, he was responsible for various engraved title-pages and other book illustrations and for the single sheet print, The Revells of Christendome (1609).
                </p>  
            </div>
        </div>  
        <h2 id="accordion-collapse-heading-9">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-9" aria-expanded="false" aria-controls="accordion-collapse-body-9">
            <span class="text-2xl">Collins, Jacob  </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-9" class="hidden" aria-labelledby="accordion-collapse-heading-9">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (fl. 1675-1713) A little-known etcher and engraver, who usually signed himself 'I. Collins', he has been identified on one mount as Jacob Collins for reasons that are not clear. Collins made an interesting series of prints of comic actors, and collaborated with Nicholas Yeates and William Sherwin.
                </p>  
            </div>
        </div> 
       
        <h2 id="accordion-collapse-heading-10">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-10" aria-expanded="false" aria-controls="accordion-collapse-body-10">
            <span class="text-2xl">Cross, Thomas </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-10" class="hidden" aria-labelledby="accordion-collapse-heading-10">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (fl. 1644-82 or later) The engraver of many title-pages and book illustrations, Cross also produced portraits, mainly for booksellers, and he appears to have published prints. He was a specialist in the engraving of shorthand, of which he invented a system of his own, and he also engraved plates of scripts, including those for Josiah Ricraft's Peculiar Characters of the Oriental Languages (?1645). The fact that the title page to William Evats' translation of Grotius' Of the Rights of Peace and War (London, Ralph Smith, 1682) is signed ‘T. Cross Senior Sculpsit.' suggests that there was another printmaker of the same name, perhaps his son.
                </p>  
            </div>
        </div>   
        <h2 id="accordion-collapse-heading-11">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-11" aria-expanded="false" aria-controls="accordion-collapse-body-11">
            <span class="text-2xl">Davis, Edward Le </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-11" class="hidden" aria-labelledby="accordion-collapse-heading-11">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    Le (active 1671-after 1691) Davis was originally apprenticed to David Loggan, but he ran away to France, where he produced various prints between 1671 and 1674 and added ‘Le' to his name. By 1675 he had returned to England, where he was active as a dealer in paintings as well as an engraver, conducting joint sales with the auctioneer, Edward Millington.
                </p>  
            </div>
        </div> 
        <h2 id="accordion-collapse-heading-12">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-12" aria-expanded="false" aria-controls="accordion-collapse-body-12">
            <span class="text-2xl">Delaram, Francis</span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-12" class="hidden" aria-labelledby="accordion-collapse-heading-12">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (active 1615-24) All that is known of Delaram is what can be deduced from his prints, the earliest of which dates from 1615 and is a portrait of the French botanist, Matthias de Lobel. Delaram was probably of foreign origin, but it is unclear where he trained. His output included portraits and title-pages, and he was also responsible for some of the earliest books of flowers, beasts and birds to be produced in this country and the earliest English drawing book, although no copy of the latter survives.
                </p>  
            </div>
        </div>     
        <h2 id="accordion-collapse-heading-13">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-13" aria-expanded="false" aria-controls="accordion-collapse-body-13">
            <span class="text-2xl">Delff, Willem Jacobsz. </span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-13" class="hidden" aria-labelledby="accordion-collapse-heading-13">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (1580-1638) Delff, who spent his entire life at Delft, was the son of a portrait painter, an occupation also followed by two brothers. Delff himself, however, was an engraver, and his main output was of plates based on portraits by Michiel van Mierevelt (1567-1641), who also lived in Delft and for whom Delff effectively became official engraver. The portraits so produced followed a standard formula with an oval shape which proved highly influential in England as elsewhere.
                </p>  
            </div>
        </div>     

        <h2 id="accordion-collapse-heading-14">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-14" aria-expanded="false" aria-controls="accordion-collapse-body-14">
            <span class="text-2xl">Des Granges, David</span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-14" class="hidden" aria-labelledby="accordion-collapse-heading-14">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (1611-?1671/2) A Huguenot who later became a Catholic, Des Granges was principally active as a miniaturist, but he also produced a few engravings.
                </p>  
            </div>
        </div>   
        
        <h2 id="accordion-collapse-heading-15">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-15" aria-expanded="false" aria-controls="accordion-collapse-body-15">
            <span class="text-2xl">Droeshout, John</span>
            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-15" class="hidden" aria-labelledby="accordion-collapse-heading-15">
            <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                    (1596-c. 1652) Descended from a family that had emigrated from Brussels to London c. 1570. in the Elizabethan period and son of Michael Droeshout, who himself produced two engravings. He married in 1595 and his sons included John, a shadowy figure who made his will on 12 January 1652. His engraving include a number of illustrative title-pages.
                </p>  
            </div>
        </div>      
    </section>

</template>
<script setup>

  import { initFlowbite } from 'flowbite';

  // ensures that the prop data is received by the accordion
  setTimeout(() => {
        initFlowbite();
  }, 500);

  const init = async () => {};
  init();
  
</script>

<style>
div[data-panel-id] {
	border-bottom: 1px !important;
}
</style>

