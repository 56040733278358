<template>
 <div>
        <h3 class="py-6 text-3xl">Women and the Men of the Four Elements</h3>
        <h4 class="py-2 text-xl">No.27 March 2009</h4>
    </div>
    <div class='relative text-left overflow-y-auto'>
      <p class="py-2"> <strong>Copyright of the Trustees of the British Museum.</strong> </p>
      <p class="pt-2 pb-4"> <strong>British Museum 1870,0514.341. Dimensions of original: 99 mm (trimmed) x 133 mm (trimmed).</strong></p> 
        <span class="float-left pr-6 pb-2"><img :src="thumbnail" width="200"/> <button type="button" @click="getlargerImage()" class="underline font-bold" >View larger image</button></span> 

        <p class="pb-2">The British Museum holds just one lone survivor of a suite of a dozen prints satirising relations between the sexes; fortunately, the complete series is preserved (in the third state) in the Folger Shakespeare Library, bearing the manuscript <span class="italic">imprimatur</span> of Roger L'Estrange and dated 28 October 1672, probably before being issued by John Overton, the present print bearing the imprint of his predecessor, Peter Stent, whose activity is dated c.1642-1665. A glance at the costume, however, will confirm that Stent cannot have been the first print-seller to issue the series, and, in fact, another plate in the Folger series bears the imprint 'Sould by Hugh Perry, 1628', which is assumed to be the first state. If we may thus assume a set of twelve prints issued in London in 1628, I believe we may thus importantly point to the existence of a 'print book', i.e. a number of uniform-size prints bound together and issued as a series, of the type popularised by Crispijn de Passe on the continent,<sup>i</sup> and which were eagerly copied by other publishers to   the growing enthusiasm for illustrations in students' <span class="italic">alba amicorum</span>. Although the artist has not signed any of the dozen etched plates, on stylistic grounds, they may be confidently attributed to the Frankfurt engraver, Friedrich von Hulsen who is known to have worked in London in 1627 when he also etched the illustrations for George Carleton's <span class="italic">A Thankfull Remembrance of Gods Mercy</span> (1627).</p>

        <p class="pb-2">
        In our present etching, a woman stands four-square in the centre of the composition, two men on either side of her engaged in activities that clearly symbolise the Four Elements, one man at a forge (Fire), the others digging (Earth), fishing (Water) and hawking (Air). In the English version the woman's hand rests innocuously enough on her belly, while in earlier Continental versions of the motif she points unmistakably to her crotch.<sup>ii</sup>  
        </p>

        <p>The verse-caption reads:</p>

        <p class="pb-2 italic">
        Fyer, Ayre, Earth, Water, represents,<br>
        to thee their sundry Elaments. [sic]<br>
        By which thou maist receive thy fill,<br>
        that powerfull cann preserve & kill:
        </p>

        <p class="pb-2">
        We should perhaps understand the verses to mean that the author compares Woman to the Four Elements, essential to life, but sometimes also the cause of death. This was certainly not the original message of this motif, however, as we can tell from its immediate source, a print in a German work entitled the <span class="italic">Pugillus Facetiarum</span>, printed in Strasbourg in 1608 and again in 1618 (and subsequently)—but which is only one of several representatives of the composition.
        </p>

        <p class="pb-2">
        The bilingual Latin/German captions read thus:
        </p>

        <p class="pb-2">
        <span class="italic">Aera perlustrant & aquas & viscera terrae<br>
        Et flammas fatui o coram quam quaeritis adsum</span><br>
        [They comb the skies, the waters, the flames, the bowels of the earth.<br>
        Fools, what you seek is here right in front of you!]<br>
        <span class="italic">O ihr narren alle vier<br>
        Waβ ihr sucht das fint ihr hier</span><br>
        [Oh you fools, all four,<br>
        What you seek you'll find right here!]
        </p>

        <p class="pb-2">
        With her right hand she points to the general region of her crotch. Two years after the first edition of the  <span class="italic">Pugillus</span>, the print was reissued in the  <span class="italic">Jeucht Spieghel</span> (1610), and again in the  <span class="italic">Nieuwen Ieucht Spieghe</span>l (Arnhem, 1617). But these seventeenth-century versions are rather less explicit than those of the previous century—at least these women are fully clothed, whereas their predecessors are entirely naked, and drive home the moral rather more pointedly. The  <span class="italic">Pugillus</span> designer may have taken his inspiration from a print engraved by Johannes Wierix issued in 1601, where the words spoken by the naked woman are, if anything, more explicit:
        </p>

        <p class="pb-2">
        <span class="italic">Graeft, vist, voghelt, En soeckt int vier<br>
        Tis niet om vinden dan recht alhier</span><br>
        [Dig, fish, fowl, seek it in the fire],<br>
        Or as her French couplet baldly puts it:<br>
        <span class="italic">En vain cherches en terre eau ou flame<br>
        car c'est a faire au seul trou de madame</span><br>
        [In vain you search in earth, water or fire<br>
        for it is only to be found in my lady's hole]
        </p>

        <p class="pb-2">And there are at least three further sixteenth-century examples of the motif: the words issuing from the mouth of Balthasar Jenichen's naked woman, engraved some time in the 1580s, her fingers in her crotch, mock the vain strivings of the four fully-clothed men who surround her, busily engaged in what she clearly sees as no more than 'displacement activity':</p>

        <p class="pb-2">
        <span class="italic">Habt Ir kein Weibsbilt nie erkennt<br>
        Das Irs svcht in den 4 Element?<br>
        Solts Ir sehn habn an den Kindn<br>
        Drvm svchts alda hie wert Irs findn</span><br>
        [Have you never known a woman<br>
        that you seek her in the Four Elements?<br>
        You're looking all over for it like these children<br>
        Though it's here you will find it]
        </p>

        <p class="pb-2">It seems likely that an earlier and almost certainly woodcut version of such a sheet was once in the extraordinary print-collection of Ferdinand Columbus (d. 1539), for Inventory No. 2071 is described as featuring a standing naked woman with hair down to her buttocks, her right hand over her 'natura', and her left hand over her left breast, while below to our right is a man (presumably clothed) with a dog and a glove on his left hand, above which is a bird—so he is presumably out hawking. But though this is categorised as one of the sheets featuring five persons,<sup>iii</sup> the other three are not described. If we can accept that the cataloguer is describing a (centrally placed) naked woman and four other men, one of whom is hawking, I believe we may quite reasonably accept this as an example of <span class="italic">Woman and the Men of the Four Elements</span>, not least, because we know the subject was available this early, in the form of a unique woodcut sheet attributed to Hans Weiditz c.1521. This, however, cannot have been the copy in Columbus's collection, as there the representative of Air is not accompanied by a dog.Having, I hope, demonstrated the Europe-wide popularity of the motif, I believe it was also familiar to the poet John Donne—but chronologically the 1628 English version is ruled out—and I set out my reasons in <span class="italic">The Print in Early Modern England: an Historical Oversight</span> (London/New Haven, 2010), 318-320.</p>

      <h4 class="py-2 text-xl font-bold">Footnotes</h4> 
      <ul class="list-[lower-roman] list-inside">
        <li class="py-2">See I.M. Veldman, <cite>Profit and Pleasure: Print Books by Crispijn de Passe</cite> (Rotterdam, 2001).  </li>
        <li class="py-2">This motif is fully explored in Malcolm Jones & Dana Jenei, “Woman and the men of the Four Elements” in ed, Dana Jenei, In honorem Răzvan Theodorescu. Profesorul la 80 de ani ( Bucharest, 2021), 190-204. </li>
        <li class="py-2">'pliego de 5 de hombres vestidos'; but despite the literal meaning -'five clothed men' - this is precisely how the Columbus taxonomy would describe four clothed men and one naked woman! See M. McDonald (ed.), <cite>The Print Collection of Ferdinand Columbus</cite> (London, 2004). 
        </li>
      </ul> 

  </div>   
</template>

<script>
    export default {
        data() {
          return { 
            imagePath : 'https://britishprintedimages.blob.core.windows.net/input-images/printsinfocus/',
            thumbnail : '',
            largeImage : ''
          }
        },
        methods: {
          getlargerImage() {
                let currentImagePath = this.imagePath + 'Women and Men and the Elements.png' 
                window.open(currentImagePath, "_blank");
            },
        },
        created() {
          this.thumbnail = this.imagePath  + '28.jpg' 
        }
    }
  
  </script>
