<template>
    <section v-show="imagedata">
        <div class="mt-2 mb-2 text-2xl p-6 bg-stone-100">{{ imagedata.title }}</div>  
        <div class="py-8 pb-8 mb-8 grid grid-cols-3 gap 4 bg-white">

            <div v-if="imagedata.jpgNum"><img :src="sampleImage + '/'+ imagedata.jpgNum"></div>
            <div v-else class=""><img :src="defaultImage"></div>
 
            <div class="col-span-2 row-span-2 mr-5 mb-25"> 
      
                <div  id="accordion-collapse" data-accordion="collapse">
                    <h2 id="accordion-collapse-heading-1">
                        <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-700 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-stone-400 hover:bg-stone-200 dark:hover:bg-stone-800 gap-3" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                        <span class="text-2xl">Description</span>
                        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
                        </svg>
                        </button>
                    </h2>
                    <div id="accordion-collapse-body-1" class="hidden" aria-labelledby="accordion-collapse-heading-1">
                       <div class="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900 text-left overflow-y-auto"> 
                            
                            <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                            {{  imagedata.description }}
                                </p>

                            <h4 class="pb-2 text-gray-800 text-xl">Inscription</h4>
                            <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                            {{ props.inscription }}
                            </p>  
                            
                           
                            <h4 class="pb-2 text-gray-800 text-xl">Image Dimensions</h4>
                            <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                            {{ props.imagedimensions }}
                            </p>  
                            
                        </div>
                    </div>
                    <h2 id="accordion-collapse-heading-2">
                        <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-2" aria-expanded="false" aria-controls="accordion-collapse-body-2">
                        <span class="text-2xl">Production</span>
                        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
                        </svg>
                        </button>
                    </h2>
                    <div id="accordion-collapse-body-2" class="hidden" aria-labelledby="accordion-collapse-heading-2">
                        <div class="p-5 border border-b-0 border-gray-200 dark:border-gray-700 text-left">

                            <h4 class="pb-2 text-gray-800 text-xl">Producers</h4>
                            <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                                <ul>
                                    <li v-for="item in props.producerinfo" :key="item.id" > {{ item.description  }} : {{ item.person }}</li> 
                                </ul>
                            </p>
                            <h4 class="pb-2 text-gray-800 text-xl">Production Place</h4>
                            <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                                {{ props.producerplace }}
                            </p> 
                      
                            <h4 class="pb-2 text-gray-800 text-xl">Schools</h4>
                            <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                                <ul>
                                    <li v-for="item in props.producerschools" :key="item.id" > {{ item.school  }} </li> 
                                </ul>
                            </p> 
                            <h4 class="pb-2 text-gray-800 text-xl">Copyright</h4>
                                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left"> &#169; Copyright The Trustees of The British Museum
                                </p>  
                        </div>
                    </div>
                    <h2 id="accordion-collapse-heading-3">
                        <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-3" aria-expanded="false" aria-controls="accordion-collapse-body-3">
                        <span class="text-2xl">Subjects</span>
                        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
                        </svg>
                        </button>
                    </h2>
                    <div id="accordion-collapse-body-3" class="hidden" aria-labelledby="accordion-collapse-heading-3">
                        <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">

                            <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                                <ul>
                                    <li v-for="item in props.imagesubjects" :key="item.id" > {{ item.subject  }} </li> 
                                </ul>
                            </p>
                            <h4 class="pb-2 text-gray-800 text-xl">Associated Names</h4>
                            <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                                <ul>
                                    <li v-for="item in props.imageassocnames" :key="item.id" > {{ item.desc  }} {{  item.name }} </li>
                                </ul> 
                            </p>
                        </div>
                    </div>
                    
                    <h2 id="accordion-collapse-heading-4">
                        <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-4" aria-expanded="false" aria-controls="accordion-collapse-body-4">
                        <span class="text-2xl">Comment</span>
                        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
                        </svg>
                        </button>
                    </h2>
                    <div id="accordion-collapse-body-4" class="hidden" aria-labelledby="accordion-collapse-heading-4">
                        <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700 text-left">
                            <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                             {{ imagedata.comments }}
                            </p>
                            <h4 class="pb-2 text-gray-800 text-xl">Bibliographic References</h4> 
                                <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">
                                <ul>
                                    <li v-for="item in props.biblioref" :key="item.id" > <strong>{{ item.ref  }} </strong> : {{ item.desc }}</li> 
                                </ul>
                            </p>
                        </div>
                    </div>  
                </div>

            </div>
            <div class="text-center">
                <h4 class="font-bold p-4">BPI Number {{  imagedata.bpiNumber  }}</h4>              
               <!--<div class="p-2" v-show="largerImageAvailable"> -->
                <div class="p-2">
                    <a href="#" @click="getlargerImage(imagedata.jpgNum)" @keydown.enter="getlargerImage(imagedata.jpgNum)" class="underline font-bold" >View larger image </a> <br>(opens in new tab)
                </div>
            </div>
        </div>
    </section>    
  </template>
     
  
  <script setup>

  import { defineProps, ref } from "vue";
  import { initFlowbite } from 'flowbite';

  // ensures that the prop data is received by the accordion
  setTimeout(() => {
        initFlowbite();
  }, 500);

  const imagePath = 'https://britishprintedimages.blob.core.windows.net/input-images/web';
  const largerImagePath = 'https://britishprintedimages.blob.core.windows.net/input-images/1024';
  const sampleImage = imagePath;
  const defaultImage = ref(require('@/assets/torture.jpeg'));
  // let largerImageAvailable = ref(false);
  // checkForLargerImage(this.imagedata.jpgNum);

  function getlargerImage(num) {
    let currentImagePath = largerImagePath + '/' + num
    window.open(currentImagePath, "_blank");
  }

  // prop destructuring
  const props = defineProps({
    imagedata:  {
        type: Object,
        default : () => ({})
    },
    imageassocnames : {
        type: Object,
        default :() => ({})
    },
    imageassoctitles : {
        type: Object,
        default :() => ({})
    },
    producerinfo: {
        type: Object,
        default :() => ({})
    },
    producerschools: {
        type: Object,
        default : () => ({})
    },
    imagesubjects: {
        type: Object,
        default : () => ({})
    },
    biblioref: {
        type: Object,
        default : () => ({})
    },
    imagelocation : {
        type: Object,
        default : () => ({})
    }, 
    inscription : {
        type: String,
        default : ''
    },
    imagedimensions : {
        type: String,
        default: ''
    },
    producerplace: {
        type: String,
        default : ''
    },
  }); 

  const init = async () => {};
  init();
  
</script>

<style>
div[data-panel-id] {
	border-bottom: 1px !important;
}
</style>

