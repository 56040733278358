<template>
 <div>
        <h3 class="py-6 text-3xl">The lawyer settles a dispute over an oyster</h3>
        <h4 class="py-2 text-xl">No.18 March 2008</h4>
    </div>
    <div class='relative text-left overflow-y-auto'>
      <p class="py-2"> <strong>Copyright of the Trustees of the British Museum.</strong> </p>
      <p class="pt-2 pb-4"> <strong>British Museum 1874,0808.2304. Dimensions of original: 211 mm x 153 mm</strong></p> 
        <span class="float-left pr-6 pb-2"><img :src="thumbnail" width="300"/> <button type="button" @click="getlargerImage()" class="underline font-bold" >View larger image</button></span> 

        <p class="pb-2">Of all the professions the public would most like to see get their comeuppance, lawyers are probably top of the list, and Shakespeare was clearly picking up on this popular antipathy to the legal profession when he famously has the rebel Dick Butcher in <span class="italic">King Henry VI Part 2</span> say, that as soon as their rebellion is successful, <span class="italic">The first thing we do, let's kill all the lawyers</span>. While probably very few early modern English men and women would have wanted to go that far, lawyers were fair game as the target for visual satire throughout our period.</p>

        <p class="pb-2">A broadside ballad of c.1630 which offers general advice (e.g. <span class="italic">keepe still in Compasse</span>—a maxim which was itself the subject of a fine Elizabethan print<sup>i</sup>   ) is also a general satire against Jesuits, quack doctors, whores, fortune-tellers and lawyers. Those who resort too readily to the last-named are addressed thus:</p>

        <p class="pb-2 italic">
        You that for nothing<br>
        will goe to Law,<br>
        Vexing your neighbours,<br>
        for a sticke or a straw,<br>
        Because of your lawing,<br>
        your purse will grow low:<br>
        You'l prove your selves Coxe-combs<br>
        I tell you but so.
        </p>

        <p class="pb-2 italic">
        Forget not I say,<br>
        that Embleme so rare,<br>
        Which teaches you how<br>
        the Oyster to share,<br>
        Thou must have one shell,<br>
        the other thy foe,<br>
        The fish is the Lawyers,<br>
        I tell you but so.
        </p>

        <p class="pb-2">That anti-lawyer <span class="italic">Embleme</span> so rare was, in fact, pictorialised in the early eighteenth century by the engravers John Simon<sup>ii</sup> and Sutton Nicholls; the latter's etching entitled, <span class="italic">The Lawyer and his Clients</span>, from the form of address given, must have been issued c.1710, Simon's untitled mezzotint probably in the following decade.<sup>iii</sup>  <span class="italic">Dobson pinx</span>. on the Nicholls print indicates it was etched after a lost painting by William Dobson (d.1646).</p>

        <p class="pb-4">The broadside ballad and Dobson's lost painting are thus the earliest examples of the motif of which I am aware, but it was to enjoy considerable popularity throughout the rest of the century. <span class="italic">Poor Robins Almanack</span> for 1665, like all English almanacs, lists the law terms,<sup>iv</sup> but adds anti-lawyer verses for each term, that for Michaelmas Term alluding to the subject of the present 'emblem' thus:</p>

        <p class="pb-2 italic">
        Two finds an Oyster, which they will not part,<br>
        Both will have all or none, the Lawyers art<br>
        Must end the strife; he fitts their humour well,<br>
        Eats up the fish, and gives them each a shell.
        </p>

        <p class="pb-4">The caption to Nicholls' etching runs to two verses (only the first appears on Simon's mezzotint): </p>

        <p class="pb-2 italic">
        Blind Plaintiff, lame Defendant Share<br>
        The Friendly Laws impartial Care<br>
        A Shell for Him a Shell for Thee<br>
        The Middle is the Lawyers Fee.<br>
        <br>  
        When willful Blind doth Law Commence<br>
        And Lame in Purs acts in Defence;<br>
        The Lawyer gets the greatest Gains,<br>
        And next to nought for them remains.<br>
        </p>

        <p class="pb-2">The first verse is also found in Matthew Prior's <span class="italic">Two Beggars disputing their Right to an Oyster they had Found; a Lawyer thus decides the Cause </span>(?1720), though not therefore necessarily derived from it - it may  well have traditionally accompanied the anecdote and have simply been quoted by Prior -- an anecdote which was also the subject of Ned Ward's tenth fable, <span class="italic"></span>The Plaintiff and Defendant, which appeared in his <span class="italic">Aesop at Tunbridge or, A few select Fables in Verse</span> (1698). A single unillustrated sheet published in Dublin in 1727 entitled <span class="italic">A satyr on the lawyers concerning two clowns and an oyster</span> also retells the story, also in verse. Late in the century our 'emblem' was again the subject of a mezzotint compositionally based on Simon's but now moved to an indoor setting, issued by R. Sayer & J. Bennet in 1779 (re-issued by Sayer alone in 1787),<sup>v</sup> and of another contemporary mezzotint also in an indoor setting but compositionally different, published by Carrington Bowles under the title, <span class="italic">A Sharp Between Two Flats</span>.   I know of two impressions of Simon's mezzotint, that in the British Museum was published by Edward Cooper,<sup>vi</sup>that in the Library of Congress, Washington, by John Smith.</p>

        <p class="pb-4">The notion that the lawyer is only too likely to 'fleece' both parties in any dispute is instanced again in a detail represented in a magnificent print probably issued in the 1620s entitled <router-link :to="`/printsInFocusView/4`" class="text-blue-600 dark:text-blue-500 hover:underline">The Common Weales Canker Worms</router-link>.<sup>viii</sup>  Not only does he say to the men who precede him in the order, <span class="italic">I fleece these five</span>, but his 'emblem'<sup>ix</sup> is the fox about to pounce on the simple goose, with the disturbing motto, <span class="italic">Legem pone!</span> [Set aside the law], while his verse reads as follows: </p>

        <p class="pb-2 italic">
        What lett fall such an advantageous cause,<br>
        And beare such wronges why tis against the lawes,<br>
        Ile prosecute it & uppon my troth,<br>
        Ile never leave it till I haue fleecd you bothe [i.e. both parties to the case].
        </p>

        <p class="pb-2">Another instance of a lawyer shown accepting purses—fees, or more likely, bribes—from both parties in a case, can be seen as the detail which occupies the top two quarters of the shield which features as part of a burlesque coat of arms. <span class="italic">The lawyers coat of arms, dedicated to their clients for the use of Michaelmas Term</span> is undated,<sup>x</sup> but an advertisement in The Post Boy of 21-24 February 1708, which cites the full title as just given verbatim, states that it is to be had from <span class="italic">J. Morphew near Stationers Hall</span>.<sup>xi</sup> The verse describes the burlesque coat well enough and includes a reference to this inset scene:</p>

        <p class="pb-2">
        <span class="italic">Nay; rather than his Modesty he'll hide,<br>
        He'll take a Private Dawb</span><sup>xii</sup> <span class="italic">o't'other side:</span>
        </p>

        <p class="pb-2">The crest is a fox-headed lawyer (hence the verse's <span class="italic"></span>Sly Volpone and <span class="italic"></span>old Reynard), and the motto <span class="italic">Dum vivo thrivo</span> [While I live, I thrive]. It seems likely that it was still being sold in the 1760s, because Dicey & Marshall's catalogue of that date lists a woodcut print of <span class="italic">The Lawyers Coat of Arms, &c. Or, a Looking-Glass to all hasty, unthinking, and Purse-proud People, fit to be seen by all Degrees and Stations of People</span>, in which description the word <span class="italic">purse-proud</span> which is used in the verse of the early eighteenth-century version is particularly suggestive of the identity with that print. Of particular interest is the fact that the image of the burlesque coat was transfer-printed c.1760 onto the inside of Liverpool delftware punch-bowls.<sup>xiii</sup></p>


        <h4 class="py-2 text-xl font-bold">Footnotes</h4> 
        <ul class="list-[lower-roman] list-inside">
          <li class="py-2">Uniquely preserved in Harvard University's Houghton Library, albeit in an eighteenth-century impression. </li>
          <li class="py-2">There are impressions of the mezzotint by John Simon in the Library of Congress in Washington and in the British Museum, Department of Prints & Drawings, 1874,0808.2304—they bear slightly different imprint information (see below).  </li>
          <li class="py-2">I base this latter estimate on Simon's biography as given on the British Museum database: <cite>Early work for Edward Cooper ; by c.1720 published most plates himself</cite>.  </li>
          <li class="py-2">The rhyme—as given in the print-captions—is found, for example, in the 1733 edition of <cite>Poor Richard's Almanack</cite> where it is entitled <cite>The Benefit of Going to Law</cite>. </li>
          <li class="py-2">Offered for sale by Bloomsbury Auctions on 15 April 2007. </li>
          <li class="py-2">BM Satires 3762; described in F.G.Stephens, <cite>Catalogue of Prints and Drawings in the British Museum: Satirical and Personal Subjects</cite>, vol. 3, part 1 (London, 1877), p. 1250. </li>
          <li class="py-2">BM Satires 1284. See below, n. 10. </li>
          <li class="py-2">Again, only two impressions are known to me, in the Brotherton Library of Leeds University, and the Ashmolean Museum, Oxford.</li>
          <li class="py-2">And also to be found in the same context on a riddle print issued in 1623. In The <cite>Commonweales Canker Worms</cite> I set out the evidence for believing this print was issued in the 1620s, not c.1650 as dated by Globe. </li>
          <li class="py-2">Two impressions are known; the title survives only on that in the Bodleian Library, Oxford, shelfmark Harding 2° (1). Timothy Clayton , <cite>The English Print 1688-1802</cite> (New Haven and London, 1997), p. 78, notes that it was one of the <cite>Merry Fancies</cite> being sold by Henry Overton</li>
          <li class="py-2">A reference I owe to the 'Advertisements for Prints' section of C.H.L. George's website, <cite>Early Modern Rambler</cite>— <a href="http://newspaperadvertisements.wordpress.com/page/3/" class="text-blue-600 dark:text-blue-500 hover:underline" target="_blank">http://newspaperadvertisements.wordpress.com/page/3/</a>. The situation regarding this print is confusing. As BM Satires 1284 it is dated '1692?' in F.G.Stephens, <cite>Catalogue of Prints and Drawings in the British Museum: Satirical and Personal Subjects</cite>, vol. 2 (London, 1873), p. 42, Stephens' uncertainty doubtless due to the fact that the date, squeezed uncomfortably in between the bottom of the cut and the verse, may well have been added in manuscript, and need not be contemporary with the print. </li>
          <li class="py-2">Not recorded in this sense as a noun by OED, but cf. <span class="italic">daub</span> v., sense 2c. To bribe,'grease'. slang : B. E., <cite>Dictionary of the Canting Crew</cite> (London, 1700), <cite>Dawbing, bribing</cite>; Francis Grose, <cite>Dictionary of the Vulgar Tongue</cite> (London, 1785), <cite>The cull was scragged [hanged] because he could not dawb</cite></li>
          <li class="py-2">R. Massey, 'Satirical Delftware: The Lawyers Coat of Arms', <cite>ECC Transactions</cite>, 16 (1997), 235-6. </li>
        </ul> 

  </div>   
</template>

<script>
    export default {
        data() {
          return { 
            imagePath : 'https://britishprintedimages.blob.core.windows.net/input-images/printsinfocus/',
            thumbnail : '',
            largeImage : ''
          }
        },
        methods: {
          getlargerImage() {
                let currentImagePath = this.imagePath + 'lawyer osyter.png' 
                window.open(currentImagePath, "_blank");
            },
        },
        created() {
          this.thumbnail = this.imagePath  + '13.jpg' 
        }
    }
  
  </script>