<template>
  <section class="text-left bg-stone-200 border border-2 border-slate-500 pl-8 pt-4 pb-4 relative">
    <label for="BPI_number" class="text-md sm:text-xl font-bold tracking-tight text-gray-900 sm:text-xl text-left mb-4 mt-4">Search by BPI</label>
    <p class="mt-2"> Bpi numbers range from 1 to 10,186. Please enter numbers without any commas and click the Go! button.</p>
    <span class="p-4 m-4 text-red-800 absolute bottom-0 left-0 " v-if="invalidInput">{{ invalidMessage }}</span>
    <div class="mt-2 mr-2 flex pb-8 pt-4 mb-4">
      <div class="relative focus-within:z-10"> 
        <form class=""> 
          <input type="number" required v-model="bpi_number" id="BPI_number" class="block w-24 border-0 py-2.5 pl-5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
          <div class="mt-2">
            <button type="button" aria-label="submit search" @click="fetchBpiItem" @keydown.enter="fetchBpiItem" class="bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-white" >Go!</button>
          </div>
        </form>
      </div>
    </div>
    <div v-if="bpi_item != ''">
        <h3 class="text-2xl mb-2">BPI search result</h3>
      </div>
    </section>
</template>

<script>
//import axios from 'axios'

export default {
      data() {
          return { 
             bpi_number : '',
             bpi_item : '',
             invalidInput : false,
             invalidMessage : ''
          }
      },
      components: { 
      },
      methods: {
            // emits a bpi number to the parent component
            async fetchBpiItem() {
               if(this.bpi_number > 0 && this.bpi_number <= 10186 && !isNaN(this.bpi_number)) {
                this.$emit("bpi-number", this.bpi_number);
               }
               else {
                 this.invalidInput = true;
                 this.invalidMessage = 'Valid BPI numbers range from 1 to 10,186 - please check the value entered';
               }
            }
        }
  }

</script>

<style>


</style>