<template>
    <div class="absolute top-0 left-0">
        <img :src="bpiLogo" @click="goHome()" @keydown.enter="goHome()" alt="Link to the the home page" width="80">
      </div>
</template>

<script>

import bpiLogo from  "../assets/bpi-logo.svg";
  
export default {
  data() {
      return { 
           bpiLogo, 
      }
  },
  components: { 
  },
  methods: {
    goHome() {
      this.$router.push({ name: 'Home'})
    },
  }
}

</script>

